/* eslint-disable @typescript-eslint/no-shadow */
import * as glide from "@glide/plugins";

import { GlideDateTime, convertDateToTimeZoneAgnostic } from "@glide/data-types";
import { makeTierList } from "@glide/plugins";
import {
    ConcurrencyLimiterWithBackpressure,
    audioProperties,
    isEmptyOrUndefined,
    isEmptyOrUndefinedish,
    parseNumber,
} from "@glide/support";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import { isLeft } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { getDenseCaptions, getDocumentKVOCR, getDocumentOCR, withRetries } from "../azure-ml";
import { getOcrText, ocrFeature } from "../google-vision";
import { chatPrompt, speechToText, type ModelParams } from "../openai";
const defaultChatModelParams: Pick<ModelParams, "model" | "max_tokens" | "seed"> = {
    model: "gpt-3.5-turbo",
    max_tokens: 2048,
    seed: 42,
};

const ifNonePrompt = ` If none, do not respond`;
const numberPrompt = ` Respond with only the appropriate digits/numbers/floats from request. If not appropriate say X. Only use digits/numbers/floats. No units, words, formula or equations.`;

const DefaultAIColors = {
    fgColor: "var(--gv-icon-base)",
    bgColor: "var(--gv-pink500)",
};
export function init(plugin: glide.NativePlugin<glide.ParameterRecord>) {
    plugin.addComputation({
        id: "complete-prompt",
        deprecated: true,
        name: "Generate Text",
        description: "Use AI to generate text based on a prompt.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["complete", "prompt", "completion", "openai", "ai", "summarize"],
        billablesConsumed: 1,
        icon: { kind: "monotone", icon: "mt-column-generate-text", ...DefaultAIColors },
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "Add instructions to guide the AI.",
                emptyByDefault: true,
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
            }),
        },
        results: {
            result: glide.makeParameter({ type: "string", name: "Result" }),
        },
        execute: async (context, { message, instructionsForAI }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const overrideHeaders = {
                Authorization: `Bearer ${apiKey}`,
            };
            const cachedCompletion = await context.useCache(async () => {
                const completion = await chatPrompt(
                    message,
                    instructionsForAI,
                    context,
                    [],
                    {
                        ...defaultChatModelParams,
                    },
                    overrideHeaders
                );
                if (completion.ok === true) {
                    context.consumeBillable();
                    context.trackMetric("tokensUsed", completion.result.tokensUsed);
                }
                return completion;
            }, [message, instructionsForAI]);

            if (cachedCompletion.ok === false) {
                return cachedCompletion;
            }

            const { result } = cachedCompletion;
            return glide.Result.Ok({
                result: result.message,
            });
        },
    });

    plugin.addComputation({
        id: "text-to-choice",
        deprecated: true,
        name: "Text to Choice",
        icon: { kind: "monotone", icon: "mt-column-choice", ...DefaultAIColors },
        description: "Use AI to pick from list of choices.",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["choice", "categorize", "select", "ai", "structure", "parse", "classify"],
        billablesConsumed: 1,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. You are reviewing comments for profanity",
                emptyByDefault: true,
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
            }),
            choices: glide.makeParameter({
                type: "string",
                name: "Choices",
                placeholder: "e.g. Profane,Neutral",
                required: true,
                emptyByDefault: true,
            }),
        },
        results: {
            result: glide.makeParameter({ type: "string", name: "Result" }),
        },
        execute: async (context, { choices, message, instructionsForAI = "" }) => {
            if (isEmptyOrUndefined(choices) || !choices.includes(",")) {
                return glide.Result.FailPermanent(`Please provide comma seperated choices`, {
                    isPluginError: false,
                });
            }

            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const overrideHeaders = {
                Authorization: `Bearer ${apiKey}`,
            };
            const functionName = "c";
            const cleanedChoices = choices.split(",").map(s => s.trim());
            const functions = [
                {
                    name: functionName,
                    parameters: {
                        type: "object",
                        properties: { choice: { type: "string", enum: cleanedChoices } },
                    },
                },
            ];
            const systemInstrunctions = instructionsForAI + ifNonePrompt;

            const cachedCompletion = await context.useCache(async () => {
                const completion = await chatPrompt(
                    message,
                    systemInstrunctions.trim(),
                    context,
                    [],
                    {
                        ...defaultChatModelParams,
                    },
                    overrideHeaders,
                    functions,
                    { name: functionName }
                );
                if (completion.ok === true) {
                    context.consumeBillable();
                    context.trackMetric("tokensUsed", completion.result.tokensUsed);
                }
                if (completion.ok === false) return completion;
                const resultMessage = completion.result.message;
                try {
                    const parsedJson = JSON.parse(resultMessage);
                    let parsedValue: string | undefined;
                    if (
                        hasOwnProperty(parsedJson, "choice") &&
                        typeof parsedJson.choice === "string" &&
                        cleanedChoices.includes(parsedJson.choice)
                    ) {
                        parsedValue = parsedJson.choice;
                    }
                    return glide.Result.Ok({ result: parsedValue ?? undefined });
                } catch {
                    // Fail return below..
                }
                return glide.Result.Fail("Could not parse result", { data: resultMessage });
            }, [functions, functionName, message, overrideHeaders, systemInstrunctions]);

            return cachedCompletion;
        },
    });

    const dateResponseCodec = t.type({
        year: t.number,
        month: t.number,
        day: t.number,
        hours: t.number,
        minutes: t.number,
        seconds: t.number,
    });

    plugin.addComputation({
        id: "text-to-date",
        name: "Text to Date",

        // this is not yet replaced in v2
        deprecated: false,

        description: "Use AI to write a date result",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["date", "ai", "parse", "structure"],
        icon: { kind: "monotone", icon: "mt-column-text-to-date", ...DefaultAIColors },
        billablesConsumed: 1,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. Extract the start datetime",
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
                useTemplate: "withLabel",
            }),
        },
        results: {
            result: glide.makeParameter({ type: "dateTime", name: "Result" }),
        },
        execute: async (context, { message, instructionsForAI }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const overrideHeaders = {
                Authorization: `Bearer ${apiKey}`,
            };
            const functionName = "d";
            const functions = [
                {
                    name: functionName,
                    parameters: {
                        type: "object",
                        properties: {
                            year: { type: "number" },
                            month: { type: "number", description: "january(1) through december(12)" },
                            day: { type: "number" },
                            hours: { type: "number", description: "1-24 (military time)" },
                            minutes: { type: "number" },
                            seconds: { type: "number" },
                        },
                        required: ["year", "month", "day", "hours", "minutes", "seconds"],
                    },
                },
            ];
            const cachedCompletion = await context.useCache(async () => {
                const completion = await chatPrompt(
                    message,
                    instructionsForAI,
                    context,
                    [{ role: "system", content: `The current datetime is: ${GlideDateTime.now().toString()}` }],
                    {
                        ...defaultChatModelParams,
                        model: "gpt-4o",
                    },
                    overrideHeaders,
                    functions,
                    { name: functionName }
                );
                if (completion.ok === true) {
                    context.consumeBillable();
                    context.trackMetric("tokensUsed", completion.result.tokensUsed);
                }
                if (completion.ok === false) return completion;
                const resultMessage = completion.result.message;
                let parsedValue;
                try {
                    parsedValue = JSON.parse(resultMessage);
                } catch {
                    return glide.Result.Fail("Could not parse result message", { data: resultMessage });
                }
                const decoded = dateResponseCodec.decode(parsedValue);
                if (isLeft(decoded)) {
                    return glide.Result.Fail("Could not decode result", { data: resultMessage });
                }
                const { year, month, day, hours, minutes, seconds } = decoded.right;
                // This date conversion is because this is a backend computation, so the GlideDateTime was
                // create with whatever time zone the backend thinks it's in, which is different
                // from the frontend
                return glide.Result.Ok({
                    result: GlideDateTime.fromTimeZoneAgnosticDate(
                        convertDateToTimeZoneAgnostic(new Date(year, month - 1, day, hours, minutes, seconds))
                    ),
                });
            }, [functions, functionName, message, overrideHeaders, instructionsForAI]);

            return cachedCompletion;
        },
    });

    plugin.addComputation({
        id: "text-to-boolean",
        deprecated: true,
        name: "Text to Boolean",
        description: "Use AI to write a boolean result",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["boolean", "true", "false", "ai", "yes", "no", "structure"],
        icon: { kind: "monotone", icon: "mt-column-boolean", ...DefaultAIColors },
        billablesConsumed: 1,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. Does the message need customer service?",
                emptyByDefault: true,
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
            }),
        },
        results: {
            result: glide.makeParameter({ type: "boolean", name: "Result" }),
        },
        execute: async (context, { message, instructionsForAI }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const overrideHeaders = {
                Authorization: `Bearer ${apiKey}`,
            };
            const functionName = "b";
            const functions = [
                {
                    name: functionName,
                    parameters: {
                        type: "object",
                        properties: { bool: { type: "boolean" } },
                        required: ["bool"],
                    },
                },
            ];
            const cachedCompletion = await context.useCache(async () => {
                const completion = await chatPrompt(
                    message,
                    instructionsForAI,
                    context,
                    [],
                    {
                        ...defaultChatModelParams,
                    },
                    overrideHeaders,
                    functions,
                    { name: functionName }
                );
                if (completion.ok === true) {
                    context.consumeBillable();
                    context.trackMetric("tokensUsed", completion.result.tokensUsed);
                }
                if (completion.ok === false) return completion;
                const resultMessage = completion.result.message;
                try {
                    const parsedValue = JSON.parse(resultMessage)["bool"];
                    if (typeof parsedValue === "boolean") {
                        return glide.Result.Ok({ result: parsedValue });
                    }
                } catch {
                    // Fail return below..
                }
                return glide.Result.Fail("Could not parse result", { data: resultMessage });
            }, [functions, functionName, message, overrideHeaders, instructionsForAI]);

            return cachedCompletion;
        },
    });

    plugin.addComputation({
        id: "text-to-number",
        deprecated: true,
        name: "Text to Number",
        description: "Use AI to write a number result",
        configurationDescriptionPattern: "To ${result}",
        keywords: ["number", "ai", "parse", "decimal", "structure"],
        icon: { kind: "monotone", icon: "mt-column-number", ...DefaultAIColors },
        billablesConsumed: 1,
        parameters: {
            instructionsForAI: glide.makeParameter({
                type: "string",
                multiLine: true,
                name: "Instructions",
                placeholder: "e.g. Extract the quantity from the request",
                emptyByDefault: true,
            }),
            message: glide.makeParameter({
                multiLine: true,
                type: "string",
                name: "Input",
                placeholder: "Enter text to send to AI or select a column",
                required: true,
                emptyByDefault: true,
            }),
        },
        results: {
            result: glide.makeParameter({ type: "number", name: "Result" }),
        },
        execute: async (context, { message = "", instructionsForAI = "" }) => {
            if (isEmptyOrUndefined(message)) {
                return glide.Result.FailPermanent(`Please provide message`, {
                    isPluginError: false,
                });
            }

            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.Fail("API key is not set");
            }

            const overrideHeaders = {
                Authorization: `Bearer ${apiKey}`,
            };
            const systemInstrunctions = instructionsForAI + numberPrompt;
            const cachedCompletion = await context.useCache(async () => {
                const completion = await chatPrompt(
                    message,
                    systemInstrunctions.trim(),
                    context,
                    [],
                    {
                        ...defaultChatModelParams,
                    },
                    overrideHeaders
                );
                if (completion.ok === true) {
                    context.consumeBillable();
                    context.trackMetric("tokensUsed", completion.result.tokensUsed);
                }
                if (completion.ok === false) return completion;
                const resultMessage = completion.result.message;
                const maybeNumber = parseNumber(resultMessage);
                if (maybeNumber === undefined) {
                    return glide.Result.Fail("No valid number", { data: resultMessage });
                } else {
                    return glide.Result.Ok({ result: maybeNumber });
                }
            }, [message, overrideHeaders, systemInstrunctions]);

            return cachedCompletion;
        },
    });

    plugin.addComputation({
        id: "audio-to-text",
        name: "Audio to Text",
        tier: makeTierList("starter"),
        icon: {
            kind: "monotone",
            icon: "mt-column-audio",
            ...DefaultAIColors,
        },
        description: "Transcribe audio speech to text",
        configurationDescriptionPattern: "To ${text}",
        keywords: ["audio", "transcribe", "whisper", "ai", "speech"],
        billablesConsumed: 3,
        parameters: {
            url: glide.makeParameter({
                type: "url",
                name: "Audio URL",
                description: "The audio to transcribe",
                required: true,
                preferredNames: [...audioProperties],
                emptyByDefault: true,
            }),
        },
        results: {
            text: glide.makeParameter({
                type: "string",
                name: "Text",
            }),
        },

        execute: async (context, { url }) => {
            const apiKey = context.getSecret("openai");
            if (apiKey === undefined) {
                return glide.Result.FailPermanent("API key is not set");
            }

            if (url === undefined || !/^[a-z][a-z0-9+.-]*:/.test(url)) {
                return glide.Result.FailPermanent("Path to audio file must be an absolute URL");
            }

            const result = await context.useCache(async () => {
                return await speechToText(context, url, apiKey);
            }, [url, apiKey]);

            return result;
        },
    });

    enum imageToTextMethod {
        Describe = "describe",
        OcrKV = "ocr-kv",
        OcrText = "ocr-text",
    }

    plugin.addComputation({
        id: "image-to-text",
        name: "Image to Text",
        deprecated: true,
        tier: makeTierList("starter"),
        description: "Extract information from image to text",
        configurationDescriptionPattern: "To ${text}",
        keywords: ["image", "picture", "describe", "extract", "caption", "ai", "json"],
        icon: { kind: "monotone", icon: "mt-column-image", ...DefaultAIColors },
        billablesConsumed: 2,
        parameters: {
            image: glide.makeParameter({
                type: "url",
                name: "Image",
                description: "Choose the column that has the image",
                required: true,
                emptyByDefault: true,
            }),
            method: glide.makeParameter({
                type: "enum",
                name: "Method",
                values: [
                    { label: "Describe", value: imageToTextMethod.Describe },
                    { label: "Extract Text as JSON", value: imageToTextMethod.OcrKV },
                    { label: "Extract Text", value: imageToTextMethod.OcrText },
                ],
                required: true,
                defaultValue: imageToTextMethod.Describe,
            }),
        },
        results: {
            text: glide.makeParameter({
                type: "string",
                name: "Text",
            }),
        },
        execute: async (context, { image = "", method = imageToTextMethod.Describe }) => {
            if (method === imageToTextMethod.Describe) {
                const apiKey = context.getSecret("azureVisionApiKey");
                if (isEmptyOrUndefinedish(apiKey)) {
                    return glide.Result.Fail("API key is not set");
                }
                const endpointDomain = context.getSecret("azureVisionEndpointDomain");
                if (isEmptyOrUndefinedish(endpointDomain)) {
                    return glide.Result.Fail(`API domain is not set`);
                }
                const result = await context.useCache(async () => {
                    const apiResult = await getDenseCaptions(endpointDomain, context, apiKey, image);

                    if (apiResult.ok === true) {
                        context.consumeBillable();
                    }
                    return apiResult;
                }, [method, image, apiKey]);

                if (!result.ok) return result;

                return glide.Result.Ok({ text: result.result });
            } else if (method === imageToTextMethod.OcrKV) {
                const apiKey = context.getSecret("azureFormRecognizerApiKey");
                if (isEmptyOrUndefinedish(apiKey)) {
                    return glide.Result.Fail("API key is not set");
                }
                const endpointDomain = context.getSecret("azureFormRecognizerEndpointDomain");
                if (isEmptyOrUndefinedish(endpointDomain)) {
                    return glide.Result.Fail(`API domain is not set`);
                }
                const result = await context.useCache(async () => {
                    const apiResult = await getDocumentKVOCR(endpointDomain, context, apiKey, image);

                    if (apiResult.ok === true) {
                        context.consumeBillable();
                    }
                    return apiResult;
                }, [method, image, apiKey]);

                if (!result.ok) return result;

                return glide.Result.Ok({ text: result.result });
            } else if (method === "ocr-text") {
                const apiKey = context.getSecret("googleVisionApiKey");
                if (isEmptyOrUndefinedish(apiKey)) {
                    return glide.Result.Fail("API key is not set");
                }

                const result = await context.useCache(async () => {
                    const result = await getOcrText(context, apiKey, image, ocrFeature.Document, "en");
                    if (result.ok === true) {
                        context.consumeBillable();
                    }

                    return result;
                }, [method, image, apiKey]);

                return result;
            } else {
                return glide.Result.FailPermanent("Needs a type");
            }
        },
    });

    plugin.addComputation({
        id: "document-to-text",
        name: "Document to Text",
        tier: makeTierList("starter"),
        description: "Extract information from document to text",
        configurationDescriptionPattern: "To ${text}",
        keywords: ["pdf", "word", "docx", "describe", "extract", "ai"],
        icon: { kind: "monotone", icon: "mt-column-document", ...DefaultAIColors },
        billablesConsumed: 5,
        parameters: {
            image: glide.makeParameter({
                type: "stringArray",
                name: "Document",
                description:
                    "Choose the column that has the file to process or that has the URL of the file to process. Supported file types include PDF, MS Word, MS Excel, MS PowerPoint, and HTML.",
                required: true,
                emptyByDefault: true,
            }),
        },
        results: {
            text: glide.makeParameter({
                type: "string",
                name: "Text",
            }),
        },
        execute: async (context, { image: images = [] }) => {
            if (images.length === 0) {
                return glide.Result.FailPermanent("No images provided");
            }
            const apiKey = context.getSecret("azureFormRecognizerApiKey");
            if (isEmptyOrUndefinedish(apiKey)) {
                return glide.Result.Fail("API key is not set");
            }
            const endpointDomain = context.getSecret("azureFormRecognizerEndpointDomain");
            if (isEmptyOrUndefinedish(endpointDomain)) {
                return glide.Result.Fail(`API domain is not set`);
            }

            const limiter = new ConcurrencyLimiterWithBackpressure(5);
            const results: glide.Result<string>[] = [];

            const imagesWithIndex: [string, number][] = images.map((v, i) => [v, i]);
            await limiter.forEach(imagesWithIndex, async ([image, index]) => {
                const result = await context.useCache(async () => {
                    const apiResult = await withRetries(
                        context.log,
                        () => getDocumentOCR(endpointDomain, context, apiKey, image),
                        "Invalid response | Too Many Requests",
                        1000,
                        4
                    );

                    if (apiResult.ok === true) {
                        context.consumeBillable();
                    }
                    return apiResult;
                }, [image, apiKey]);
                results[index] = result;
            });
            await limiter.finish();

            const errors = results.filter(result => result.ok === false);

            if (errors.length > 0) {
                const error = errors[0];
                if (error.ok === false) return error;
            }

            const text: string = results.map(r => (r.ok ? r.result : "")).join("\n");
            return glide.Result.Ok({ text });
        },
    });
}
