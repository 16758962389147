import type { GlideDateTime } from "@glide/data-types";
import type { JSONData, JSONObject, ChangeObservable } from "@glide/support";
import md5 from "blueimp-md5";
import type express from "express";
import type * as t from "io-ts";
import type { Either } from "fp-ts/Either";
import type {
    ActionProps,
    AuthDefinition,
    BillablesConsumed,
    ClientGlideAPI,
    EndpointData,
    InputValueTypes,
    JSONValue,
    ParameterPropsBase,
    PluginKeyPair,
    PluginTable,
    PluginTier,
    PluginTierList,
    primitiveValueCodec,
    valueCodec,
    ValueType,
    GlideIconProps,
} from "@glide/plugins-codecs";
import { Result } from "./result";
import type { PluginComputationEvents } from "./track-events";
import { assert, exceptionToString, isArray, mapRecord } from "@glideapps/ts-necessities";

export type PluginPrimitiveValue = t.TypeOf<typeof primitiveValueCodec>;
export type ValueTypeType = t.TypeOf<typeof valueCodec>;

type ValueTypeToTSType = {
    string: string;
    number: number;
    url: string;
    enum: string;
    boolean: boolean;
    secret: string;
    array: readonly PluginPrimitiveValue[];
    stringArray: readonly string[];
    object: Record<string, PluginPrimitiveValue>;
    stringObject: Record<string, string>;
    dateTime: GlideDateTime;
    table: PluginTable;
    generatedKeyPair: PluginKeyPair;
    json: JSONValue;
    jsonObject: Record<string, JSONValue>;
    jsonPath: readonly string[] | string;
};

// this is just a dirty hack to make the type system infer T correctly.
export type ParameterProps<T extends InputValueTypes = InputValueTypes> = ParameterPropsBase & { readonly type: T };

export class Parameter<T extends ValueTypeType = string> {
    public props: ParameterPropsBase;

    constructor(p: ParameterPropsBase) {
        if (p.emptyByDefault === undefined) {
            this.props = {
                ...p,
                emptyByDefault: true,
            };
        } else {
            this.props = p;
        }
    }

    public when<U extends string | number | boolean>(
        dependsOn: Parameter<U>,
        operator: "is" | "is-not",
        when: readonly U[] | U
    ): Parameter<T> {
        return new Parameter<T>({
            ...this.props,
            // FIXME: why is this the display name???
            dependsOn: dependsOn.props.name,
            operator,
            when: isArray(when) ? when : [when],
        });
    }

    public whenAction(): Parameter<T> {
        return new Parameter<T>({
            ...this.props,
            whenInContext: "action",
        });
    }

    public whenColumn(): Parameter<T> {
        return new Parameter<T>({
            ...this.props,
            whenInContext: "column",
        });
    }
}

export type UseCache = <T>(
    factory: () => Promise<Result<T>>,
    dependencies: any[],
    useInAction?: true,
    itemTTL?: number,
    pendingTTL?: number
) => Promise<Result<T>>;

type ScreenSize = "small" | "large";

export interface BaseAppData {
    /** The app ID */
    readonly id: string;
    /** The app name */
    readonly name: string;
    /** Short subtitle shown below the app Icon in certain cases, i.e.: App content emails */
    readonly appIconSubtitle: string;
    /** Whether the app is on the free plan */
    readonly isFreeEminence: boolean;
    /** The primary accent color of the app */
    readonly appPrimaryAccentColor: string;
    /** Short textual description of the app */
    readonly description?: string;
    /** Callback to get the app icon */
    readonly getIcon: () => Promise<string | undefined>;
    /** Callback to get the app play URL */
    readonly getPlayURL: () => Promise<string | undefined>;
}

export interface AppData extends BaseAppData {
    readonly screenSize: ChangeObservable<ScreenSize | undefined>;
}

type Icon = string | GlideIconProps;

interface LoggingExecutionContext {
    readonly log: (...messages: any[]) => void;
    readonly error: (...messages: any[]) => void;
}

interface CommonExecutionContext extends LoggingExecutionContext {
    readonly app: BaseAppData;
    readonly fetch: typeof fetch;
    /**
     * If `mimeType` is not given, it will be taken from the response to
     * `url`.
     */
    readonly rehostFile: (name: string, url: string, mimeType?: string) => Promise<Result<string>>;
    readonly uploadFile: (name: string, mimeType: string, contents: string | ArrayBuffer) => Promise<Result<string>>;
    readonly consumeBillable: (amount?: number) => void;
    readonly trackEventMetadata: (key: string, value: string | number | boolean) => void;
}

export interface ClientExecutionContext extends CommonExecutionContext {
    readonly app: AppData;
    readonly sendPushNotification: (
        title: string,
        body: string,
        link?: string,
        emails?: readonly string[]
    ) => Promise<Result>;
}

export interface KeyStore {
    // FIXME: These should really be using `Result`
    getValue(key: string): Promise<Either<Error, JSONData | undefined>>;
    setValue(appID: string, key: string, value: JSONData): Promise<Error | undefined>;
    /**
     * Return the value for `key`, if it exists, or sets it to `value` and
     * returns `value`, if it doesn't.
     */
    getOrSetValue(appID: string, key: string, value: JSONData): Promise<Either<Error, JSONData>>;
}

interface TriggerInvoker<TSearch> {
    // If there was no error, returns the number of triggers that were
    // invoked.  Otherwise it returns one of the errors.
    readonly invokeTriggers: <TRawData, TResultParameters extends ParameterRecord, TParameters extends ParameterRecord>(
        trigger: Trigger<TRawData, TResultParameters, TParameters>,
        search: TSearch,
        rawData: TRawData,
        /** This must be a UUID */
        idempotencyKey: string | undefined
    ) => Promise<Result<number>>;
}

export interface AppTriggerSearch {
    readonly triggerID?: string;
    readonly pluginConfigID?: string;
}

interface KeyStoreExecutionContext {
    readonly keyStore: KeyStore;
}

export interface ServerExecutionContext
    extends CommonExecutionContext,
        SecretGetter,
        KeyStoreExecutionContext,
        TriggerInvoker<AppTriggerSearch>,
        EndpointURLMaker {
    readonly useCache: UseCache;

    /**
     * This function will return a failure if the server decides the caller is
     * not trusted and will not be allowed access to secrets.
     */
    readonly refreshAccessToken: () => Promise<Result<string>>;
    /**
     * This function will return a failure if the server decides the caller is
     * not trusted and will not be allowed access to secrets.
     */
    readonly getAccessToken: () => Promise<Result<string>>;
    readonly trackMetric: <Name extends keyof PluginComputationEvents>(
        event: Name,
        options: PluginComputationEvents[Name]
    ) => void;
    readonly removeAPIKey: (clientID: string) => Promise<Result<void>>;
}

// We need the type parameter here so that we can ensure that `execute`
// returns with a signal of the correct type.
export class WaitForSignalResult<_TResultParameters extends ParameterRecord> {
    constructor(
        public readonly signalScope: string,
        public readonly signalID: string,
        public readonly timeoutMS: number
    ) {}
}

export interface ServerActionExecutionContext<TResultParameters extends ParameterRecord = ParameterRecord>
    extends ServerExecutionContext {
    readonly waitForSignal: (
        signal: Signal<TResultParameters>,
        signalID: string,
        timeoutMS: number
    ) => Result<WaitForSignalResult<TResultParameters>>;
}

export const defaultFetchTimeout = 15_000;

export abstract class CommonExecutionContextBase implements CommonExecutionContext {
    constructor(
        public readonly app: BaseAppData,
        protected readonly action: Action | Computation | undefined,
        protected readonly plugin: Plugin | NativePlugin,
        public readonly fetch: typeof globalThis.fetch,
        protected readonly fetchTimeout: number | undefined
    ) {
        // Pass
    }

    private get metadata() {
        return {
            app: this.app.name,
            plugin: this.plugin.fields.name,
            action: this.action?.name,
        };
    }

    private get signature() {
        const { app, plugin, action } = this.metadata;
        return `[${app}/${plugin}/${action ?? ""}]`;
    }

    public log(...messages: any[]) {
        // eslint-disable-next-line no-console
        console.log(this.signature, ...messages);
    }

    // TODO this should show feedback in the builder
    public error(...messages: any[]) {
        // eslint-disable-next-line no-console
        console.error(this.signature, ...messages);
    }

    public abstract uploadFile(name: string, mimeType: string, contents: string | ArrayBuffer): Promise<Result<string>>;

    public consumeBillable(_amount?: number | undefined) {
        // do nothing
    }

    public trackEventMetadata(_key: string, _value: string | number | boolean) {
        // do nothing
    }

    public async rehostFile(name: string, url: string, mimeType: string | undefined): Promise<Result<string>> {
        let signal: AbortSignal | undefined;
        let timer: ReturnType<typeof setTimeout> | undefined;
        if (this.fetchTimeout !== undefined) {
            const controller = new AbortController();
            timer = setTimeout(() => controller.abort(), this.fetchTimeout);
            signal = controller.signal;
        }

        try {
            const downloadResult = await this.fetch(url, { signal });

            if (!downloadResult.ok) {
                return Result.FailFromHTTPStatus(`Could not download url: ${url}`, downloadResult.status);
            }

            return await this.uploadFile(
                name ?? md5(url),
                mimeType ?? downloadResult.headers.get("content-type") ?? "text/plain",
                await downloadResult.arrayBuffer()
            );
        } catch (e: unknown) {
            return Result.Fail(exceptionToString(e));
        } finally {
            if (timer !== undefined) {
                clearTimeout(timer);
            }
        }
    }
}

export abstract class ClientExecutionContextBase extends CommonExecutionContextBase implements ClientExecutionContext {
    constructor(
        public readonly app: AppData,
        protected readonly action: Action | Computation | undefined,
        protected readonly plugin: Plugin | NativePlugin,
        public readonly fetch: typeof globalThis.fetch,
        protected readonly fetchTimeout: number | undefined
    ) {
        super(app, action, plugin, fetch, fetchTimeout);
    }
    public abstract sendPushNotification(
        title: string,
        body: string,
        link?: string,
        emails?: readonly string[]
    ): Promise<Result>;
}

export interface SecretGetter {
    readonly getSecret: (name: string) => string | undefined;
}

// TODO: `SignOnContext` is not a good name if it's a superclass of
// `EndpointExecutionContext`.  Should this be merged with `SecretGetter`?
export interface SignOnContext extends LoggingExecutionContext, SecretGetter {
    readonly appID: string | undefined;

    // TODO: This probably actually belongs in the `ServerExecutionContext`,
    // and we should be passing that in to the `finish` function for the
    // endpoint.
    readonly makeEndpointURL: (endpointName: string, data?: Record<string, string>) => Promise<string | undefined>;
}

interface EndpointResultResponse {
    readonly kind: "response";
    readonly status: number;
    readonly body: unknown;
    readonly headers: Record<string, string>;
}

export interface UserInfo {
    readonly email: string;
    readonly displayName: string | undefined;
}

interface EndpointResultAuthenticateUser {
    readonly kind: "authenticate-user";
    readonly signOnID: string;
    readonly user: UserInfo;
    // See note below.
    readonly state: string | undefined;
}

interface EndpointResultAuthorizeUser {
    readonly kind: "authorize-user";
    // The ID of the sign-on that the plugin defines.
    readonly signOnID: string;
    readonly user: UserInfo;
    // why do we have a separate type for this?
    // they each get used only once, by a different integration
    // should we not just delegate response control flow to the plugins
    readonly state?: string;
}

interface EndpointErrorAuthorizeUser {
    readonly kind: "authorize-user-error";
}

export type FinishWithAppContinuation<TPluginParameters extends ParameterRecord = ParameterRecord> = (
    parameters: Partial<UnwrapGeneric<TPluginParameters>>,
    context: ServerExecutionContext
) => Promise<EndpointResult<TPluginParameters>>;

interface EndpointResultFinishWithApp<TPluginParameters extends ParameterRecord> {
    readonly kind: "finish-with-app";
    readonly finish: FinishWithAppContinuation<TPluginParameters>;
    readonly published: boolean;
}

export type EndpointResult<TPluginParameters extends ParameterRecord = ParameterRecord> =
    | EndpointResultResponse
    | EndpointResultAuthenticateUser
    | EndpointResultAuthorizeUser
    | EndpointErrorAuthorizeUser
    | EndpointResultFinishWithApp<TPluginParameters>;

type SignOnRedirectData = unknown;

export interface EndpointTriggerSearch {
    readonly oauthCredentialID: string;
}

export interface EndpointExecutionContext<TPluginParameters extends ParameterRecord = ParameterRecord>
    extends SignOnContext,
        TriggerInvoker<EndpointTriggerSearch> {
    readonly useCache: UseCache;
    readonly keyStore: KeyStore;

    readonly __glideUser: UserInfo | undefined;

    readonly makeResponseResult: (
        status: number,
        body: unknown,
        headers?: Record<string, string>
    ) => EndpointResult<TPluginParameters>;
    readonly makeResponseResultFromPluginResult: (result: Result<any>) => EndpointResult<TPluginParameters>;
    // This result means that the user is authenticated, but not yet
    // authorized, so they still have to go through the app's regular
    // authorization checks.
    readonly makeAuthenticateUserResult: (
        // The ID of the sign-on that the plugin defines.
        signOnID: string,
        user: UserInfo,
        state: string | undefined
    ) => EndpointResult<TPluginParameters>;
    // This result means that the user is authenticated as well as authorized
    // to use the app.
    readonly makeAuthorizeUserResult: (
        // The ID of the sign-on that the plugin defines.
        signOnID: string,
        user: UserInfo,
        state: string | undefined
    ) => EndpointResult<TPluginParameters>;
    readonly makeAuthorizeUserError: () => EndpointResult<TPluginParameters>;
    readonly finishWithApp: (
        appID: string,
        published: boolean,
        finish: FinishWithAppContinuation<TPluginParameters>
    ) => EndpointResult<TPluginParameters>;
    readonly sendSignal: <TResults extends ParameterRecord>(
        signal: Signal<TResults>,
        signalID: string,
        params: Partial<UnwrapGeneric<TResults>>
    ) => Promise<Result>;
}

export type ParameterRecord = Record<string, Parameter<ValueTypeType>>;

type TypeWithGeneric<T extends ValueTypeType> = Parameter<T>;
type ExtractGeneric<Type> = Type extends TypeWithGeneric<infer X> ? X : never;
export type UnwrapGeneric<T> = { [P in keyof T]: ExtractGeneric<T[P]> };

export type FetchBehaviorRetryFunction<TState> = (
    req: { input: RequestInfo | URL; init: RequestInit },
    resp: Response | undefined,
    state: TState | undefined
) => Promise<{ waitFor: number | false; nextState?: TState }>;

export interface FetchBehavior<TState> {
    headerTimeout?: number;
    bodyTimeout?: number;
    retryOnError?: "default-retries" | "no-retries" | FetchBehaviorRetryFunction<TState>;
}

export interface Action<
    TResultParameters extends ParameterRecord = {},
    TParameters extends ParameterRecord = ParameterRecord,
    TPluginParameters extends ParameterRecord = ParameterRecord,
    TSelfType extends "server" | "client" = "server" | "client",
    TFetchState = any
> extends Omit<ActionProps, "parameters" | "results"> {
    readonly name: string;
    readonly description: string;
    readonly type: TSelfType;
    readonly parameters?: TParameters;
    readonly results?: TResultParameters;
    readonly tier?: readonly PluginTier[];
    readonly fetchBehavior?: FetchBehavior<TFetchState>;
    readonly icon?: Icon;
    readonly execute: (
        context: TSelfType extends "server" ? ServerActionExecutionContext<TResultParameters> : ClientExecutionContext,
        parameters: Partial<UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>>,
        formattedParamters: Record<
            keyof (UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>),
            string | undefined
        >
    ) => Promise<
        Result<
            | Partial<UnwrapGeneric<TResultParameters>>
            | (TSelfType extends "server" ? WaitForSignalResult<TResultParameters> : void)
            | void
        >
    >;
}

export interface Computation<
    TResultParameters extends ParameterRecord = ParameterRecord,
    TParameters extends ParameterRecord = ParameterRecord,
    TPluginParameters extends ParameterRecord = ParameterRecord,
    TSelfType extends "server" | "client" = "server" | "client",
    TFetchState = any
> extends Omit<ActionProps, "parameters" | "results"> {
    readonly name: string;
    readonly description: string;
    readonly type: TSelfType;
    readonly parameters: TParameters;
    readonly results: TResultParameters;
    readonly tier?: readonly PluginTier[];
    readonly deprecated?: boolean;
    readonly fetchBehavior?: FetchBehavior<TFetchState>;
    readonly icon?: Icon;
    readonly keywords?: string[];
    /**
     * If `true`, this computation is treated as non-idempotent when used as
     * an action in an automation.  In general computations should be
     * idempotent, but we have at least Call API, which is used in
     * non-idempotent ways.
     *
     * https://github.com/glideapps/glide/pull/31097
     */
    readonly nonIdempotent?: boolean;
    /**
     * If `true`, this computation is available as a "special value" wherever
     * special values are available.  Note that this can only be set if the
     * computation is on the client, has no parameters, and a single result.
     */
    readonly showAsSpecialValue?: TSelfType extends "server" ? never : boolean;
    readonly execute: (
        context: TSelfType extends "server"
            ? Omit<ServerExecutionContext, "uploadFile" | "rehostFile">
            : Omit<ClientExecutionContext, "uploadFile" | "rehostFile">,
        parameters: Partial<UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>>,
        formattedParamters: Record<
            keyof (UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>),
            string | undefined
        >
    ) => Promise<
        | Result<Partial<UnwrapGeneric<TResultParameters>>>
        | (TSelfType extends "server" ? never : ChangeObservable<Result<Partial<UnwrapGeneric<TResultParameters>>>>)
    >;
}

export type AnyServerComputation = Computation<any, any, any, "server">;

export interface SimpleComputation<
    TResultValueType extends ValueType,
    TParameters extends ParameterRecord = ParameterRecord,
    TPluginParameters extends ParameterRecord = ParameterRecord,
    TSelfType extends "server" | "client" = "server" | "client",
    TFetchState = any
> extends Omit<ActionProps, "parameters" | "results"> {
    readonly name: string;
    readonly description: string;
    readonly type: TSelfType;
    readonly parameters: TParameters;
    readonly result: TResultValueType;
    readonly tier?: readonly PluginTier[];
    readonly deprecated?: boolean;
    readonly fetchBehavior?: FetchBehavior<TFetchState>;
    readonly execute: (
        context: TSelfType extends "server" ? ServerExecutionContext : ClientExecutionContext,
        parameters: Partial<UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>>,
        formattedParamters: Record<
            keyof (UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>),
            string | undefined
        >
    ) => Promise<Result<ValueTypeToTSType[TResultValueType]>>;
}

export interface QueryableDataSource<
    TConnectionObject extends JSONObject = JSONObject,
    TParameters extends ParameterRecord = ParameterRecord,
    TPluginParameters extends ParameterRecord = ParameterRecord
> {
    readonly id: string;
    readonly name: string;
    readonly icon?: string;
    readonly description: string;
    readonly parameters: TParameters;
    readonly tier?: readonly PluginTier[];
    // No fetchBehavior for this one yet.

    readonly makeConnectionObject: (
        pluginContext: ServerExecutionContext,
        parameters: Partial<UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>>
    ) => Promise<Result<TConnectionObject>>;
    readonly getConnectionDisplayName: (
        parameters: Partial<UnwrapGeneric<TParameters> & UnwrapGeneric<TPluginParameters>>
    ) => string | undefined;
}

export type MiddlewareResult =
    | {
          readonly success: true;
      }
    | {
          readonly success: false;
          readonly status: number;
          readonly message: string;
      };

export interface Endpoint<TPluginParameters extends ParameterRecord = ParameterRecord> {
    // We don't have an ID.  The name already has to be unique, and can't be
    // easily changed because it's part of a URL, and not for display
    // purposes.
    readonly name: string;

    readonly handle: (
        endpointExecutionContext: EndpointExecutionContext<TPluginParameters>,
        req: express.Request,
        data: EndpointData
    ) => Promise<EndpointResult<TPluginParameters>>;
    readonly middleware?: (req: express.Request, res: express.Response) => Promise<MiddlewareResult>;
}

// This will eventually include notifications other than for pins.
export interface NotificationData {
    readonly kind: "pin";
    readonly appTitle: string;
    readonly pin: string;
    readonly magicLink: string | undefined;
}

export interface NotificationTarget {
    // Add more as needed.
    readonly method: "email" | "sms";
    // The "address" the notification was sent to, like an email address,
    // phone number, etc.  That will be displayed to the user who logs in.
    readonly address: string;
}

export interface NotificationSender<TPluginParameters extends ParameterRecord = ParameterRecord> {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    // Lower numbers are higher priority.  Right now we use
    // * 100-199 for SMS
    // * 200-299 for email
    readonly priority: number;
    readonly billablesConsumed?: BillablesConsumed;

    readonly sendNotification: (
        context: ServerExecutionContext,
        parameters: Partial<UnwrapGeneric<TPluginParameters>>,
        receiverEmail: string,
        subject: string,
        body: string,
        htmlBody: string,
        notificationData: NotificationData
    ) => Promise<Result<NotificationTarget>>;
}

interface LoginTokenExpiryInfo {
    // Should the login token be renewed automatically if the user has a valid
    // one?  That means they'll potentially never have to log in again.
    readonly renewAutomatically: boolean;
    // How long is the login token valid for?
    readonly timeToLiveMS: number;
}

// This type can be expanded if the backend needs to return multiple different errors to the frontend. As of now, any
// error generating the sign on button results in the same frontend error message.
export interface SignOnButton {
    readonly label: string;
    readonly url: string;
}

interface SignOnButtonResult extends SignOnButton {
    readonly __withGlideUser?: boolean;
}

export interface SignOn<TPluginParameters extends ParameterRecord> {
    readonly id: string;
    readonly name: string;
    readonly description: string;

    readonly makeSignOnButton: (
        signOnContext: SignOnContext,
        signOnRedirectData: SignOnRedirectData,
        parameters: Partial<UnwrapGeneric<TPluginParameters>>,
        state: string | undefined
    ) => Promise<Result<SignOnButtonResult>>;

    // At some point we'll probably need the parameters here, too.
    readonly getLoginTokenExpiryInfo: () => LoginTokenExpiryInfo;
}

interface EndpointURLMaker {
    readonly makeEndpointURL: (endpointName: string) => string;
}

interface NoteInfo extends EndpointURLMaker {
    readonly appID: string;
    readonly pluginConfigID: string;
    readonly triggerID: string;
}

interface MarkdownNote {
    readonly kind: "markdown";
    readonly markdown: string;
}

interface CopyToClipboardNote {
    readonly kind: "copy-to-clipboard";
    readonly label: string;
    readonly textToCopy: string;
}

export type Note = MarkdownNote | CopyToClipboardNote;

export interface Trigger<
    TRawData,
    TResultParameters extends ParameterRecord = ParameterRecord,
    TParameters extends ParameterRecord = ParameterRecord
> {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    /**
     * The configuration parameters for the trigger.  These are typically used
     * to specify under which circumstances the trigger should fire.
     */
    readonly parameters: TParameters;
    /**
     * The data produced by the trigger when it fires.
     */
    readonly results: TResultParameters;
    /**
     * Decides whether the trigger should run, given `params` and `rawData`.
     * If not, must return `undefined`.  If it should trigger, must return the
     * trigger results.  If the `rawData` is malformed, it should return an
     * error result.
     */
    readonly shouldTrigger: (
        params: Partial<UnwrapGeneric<TParameters>>,
        rawData: TRawData,
        context: ServerExecutionContext
    ) => Promise<Result<Partial<UnwrapGeneric<TResultParameters>> | undefined>>;
    /**
     * Returns notes to be displayed to the user in the trigger configuration
     * UI.
     */
    readonly getNotes?: (info: NoteInfo, context: KeyStoreExecutionContext) => Promise<Result<readonly Note[]>>;

    readonly experimentFlag?: string;
    readonly badge?: string;
}

export interface Signal<TResults extends ParameterRecord> {
    readonly id: string;
    readonly results: TResults;
}

interface IdentifyEvent {
    kind: "identify";
    userID: string;
    username: string;
    email: string;
}

interface NavigateEvent {
    kind: "navigate";
    title: string;
}

interface LoadEvent {
    kind: "load";
}

interface ActionEvent {
    kind: "action";
    name: string;
    data: Record<string, PluginPrimitiveValue>;
}

export type AnalyticsEvent = IdentifyEvent | NavigateEvent | LoadEvent | ActionEvent;
interface EventTracker<TPluginParameters extends ParameterRecord = {}> {
    readonly track: (pluginParameters: UnwrapGeneric<TPluginParameters>, event: AnalyticsEvent) => void;
}

interface HeaderSnippet<TPluginParameters extends ParameterRecord = {}> {
    readonly getSnippet: (pluginParameters: UnwrapGeneric<TPluginParameters>) => string;
}

interface NativePluginFields<TFetchState = any> {
    readonly id: string;
    readonly name: string;
    readonly icon?: string | GlideIconProps;
    readonly description?: string;
    readonly tier?: PluginTierList;
    readonly documentationUrl?: string;
    readonly disclosure?: string;

    // We don't have access to experiment flags here because of the packages order.
    // We need to keep this value in sync with the one in `experiments.ts`.
    // We can't put experiments up in a common package because they depend
    // on feature settings as well, so we'd have to pull those as well.
    // That is kind of a big task.
    // ##experimentFlagInPlugin:
    readonly experimentFlag?: string;

    // Hide plugins from the builder.
    readonly deprecated?: boolean;

    readonly fetchBehavior?: FetchBehavior<TFetchState>;

    readonly badge?: string;
}

type LogFn<T extends ParameterRecord> = (msg: string, param?: keyof T) => void;
export interface ValidationLog<T extends ParameterRecord> {
    readonly warn: LogFn<T>;
    readonly error: LogFn<T>;
}

interface ExternalConfigurationBase {
    readonly title: string;
    readonly description: string;
    readonly buttonLabel: string;
    readonly buttonUrl: string;
    readonly expiresAt: Date | undefined;
}

export interface IncompleteExternalConfigurationStep extends ExternalConfigurationBase {
    readonly complete: false;
    readonly backgroundImageUrl: string;
    readonly revalidateLabel: string;
}
export interface CompleteExternalConfigurationStep extends ExternalConfigurationBase {
    readonly complete: true;
}

export type ExternalConfigurationStep = CompleteExternalConfigurationStep | IncompleteExternalConfigurationStep;

type ValidateFn<T extends ParameterRecord> = (
    params: UnwrapGeneric<T>,
    log: ValidationLog<T>,
    context: ServerExecutionContext
) => Promise<ExternalConfigurationStep | undefined>;

export interface AppWithOwner {
    readonly appID: string;
    readonly ownerID: string | undefined;
}

type CompleteConfigFn<T extends ParameterRecord> = (
    params: UnwrapGeneric<T>,
    appWithOwner: AppWithOwner,
    log: ValidationLog<T>,
    context: ServerExecutionContext
) => Promise<Partial<UnwrapGeneric<T>>>;

type OnRemovedFn<T extends ParameterRecord> = (
    params: UnwrapGeneric<T>,
    appWithOwner: AppWithOwner,
    log: ValidationLog<T>,
    context: ServerExecutionContext
) => Promise<boolean>;

interface PluginFields<TParams extends ParameterRecord = ParameterRecord, TFetchState = any>
    extends NativePluginFields<TFetchState> {
    readonly parameters?: TParams;
    readonly auth?: AuthDefinition;
    readonly validate?: ValidateFn<TParams>;
    readonly completeConfig?: CompleteConfigFn<TParams>;
    readonly onRemoved?: OnRemovedFn<TParams>;
    readonly supportsMultipleInstances?: boolean;

    // ##pluginUserFeature:
    // If this is set, the app's owner needs to have the `plugin-${pluginID}`
    // user feature set or it won't show up in the builder.  Note that this
    // will not enforce any restrictions other than configuring the plugin in
    // the app.  It's meant for giving early testers access, nothing more.
    // TODO: This will currently only remove the plugin from the integrations
    // panel, but it will still show actions and computations.  When we have
    // experimental plugins with actions and computations, we'll need to
    // implement filtering out those, too.
    readonly isExperimental?: boolean;

    // deprecated will not show the plugin in the builder
    readonly deprecated?: boolean;
}

type AsyncEnum<TPluginParameters> = (
    ctx: ServerExecutionContext,
    params: Partial<UnwrapGeneric<TPluginParameters>>
) => Promise<{ value: string; label: string }[]>;

export class NativePlugin<TPluginParameters extends ParameterRecord = ParameterRecord> {
    public readonly actions: Action[] = [];
    public readonly computations: Computation<any, any>[] = [];
    public readonly queryableDataSources: QueryableDataSource<any, any, TPluginParameters>[] = [];
    public readonly notificationSenders: NotificationSender<TPluginParameters>[] = [];
    private readonly asyncParameters: Record<string, AsyncEnum<TPluginParameters>> = {};
    public get eventTracker(): EventTracker | undefined {
        return undefined;
    }

    constructor(public fields: NativePluginFields) {
        // pass
    }

    private tuneResults(results: ParameterRecord | undefined): ParameterRecord | undefined {
        if (results === undefined) return undefined;
        return mapRecord(results, x => {
            if (x.props.propertySection === undefined) {
                x = {
                    when: x.when,
                    whenAction: x.whenAction,
                    whenColumn: x.whenColumn,
                    props: {
                        ...x.props,
                        propertySection: {
                            name: "Results",
                            order: 10000,
                            collapsed: false,
                        },
                    },
                };
            }
            return x;
        });
    }

    public addAction<TResultParameters extends ParameterRecord, TParameters extends ParameterRecord>(
        action: Omit<Action<TResultParameters, TParameters, TPluginParameters, "server">, "type">
    ) {
        this.actions.push({
            ...action,
            results: this.tuneResults(action.results),
            type: "server",
            isIdempotent: false,
        } as any);
    }

    public addClientAction<TResultParameters extends ParameterRecord, TParameters extends ParameterRecord>(
        action: Omit<Action<TResultParameters, TParameters, TPluginParameters, "client">, "type">
    ) {
        this.actions.push({
            ...action,
            results: this.tuneResults(action.results),
            type: "client",
            isIdempotent: false,
        } as any);
    }

    public addComputation<TResultParameters extends ParameterRecord, TParameters extends ParameterRecord>(
        computation: Omit<
            Computation<TResultParameters, TParameters, TPluginParameters, "server">,
            "type" | "isIdempotent"
        >
    ) {
        this.computations.push({
            ...computation,
            results: this.tuneResults(computation.results),
            type: "server",
            isIdempotent: computation.nonIdempotent !== true,
        } as any);
    }

    public addClientComputation<TResultParameters extends ParameterRecord, TParameters extends ParameterRecord>(
        computation: Omit<
            Computation<TResultParameters, TParameters, TPluginParameters, "client">,
            "type" | "isIdempotent"
        >
    ) {
        this.computations.push({
            ...computation,
            results: this.tuneResults(computation.results),
            type: "client",
            isIdempotent: computation.nonIdempotent !== true,
        } as any);
    }

    public addColumn<TResultValueType extends ValueType, TParameters extends ParameterRecord>(
        column: Omit<
            SimpleComputation<TResultValueType, TParameters, TPluginParameters, "server">,
            "type" | "isIdempotent"
        >
    ) {
        // Server columns can't require a client
        assert(column.needsClient !== true);
        this.computations.push({
            id: column.id,
            description: column.description,
            configurationDescriptionPattern: column.configurationDescriptionPattern,
            execute: async (context, params, formattedParamters) => {
                const r = (await column.execute(
                    context as any,
                    params,
                    formattedParamters
                )) as Result<TResultValueType>;
                if (r.ok) {
                    return Result.Ok({ result: r.result });
                }
                return r;
            },
            type: "server",
            isIdempotent: true,
            name: column.name,
            parameters: column.parameters,
            needsAutomation: column.needsAutomation,
            results: this.tuneResults({
                result: makeParameter({
                    name: "result",
                    description: "The result",
                    type: column.result as
                        | "string"
                        | "number"
                        | "object"
                        | "url"
                        | "secret"
                        | "array"
                        | "stringArray"
                        | "stringObject"
                        | "dateTime",
                }),
            }),
            deprecated: column.deprecated,
            billablesConsumed: column.billablesConsumed,
        });
    }

    public addClientColumn<TResultValueType extends ValueType, TParameters extends ParameterRecord>(
        column: Omit<
            SimpleComputation<TResultValueType, TParameters, TPluginParameters, "client">,
            "type" | "isIdempotent"
        >
    ) {
        // A column can't require both a client and an automation
        assert(column.needsClient !== true || column.needsAutomation !== true);
        this.computations.push({
            id: column.id,
            description: column.description,
            group: column.group,
            icon: column.icon,
            needsClient: column.needsClient,
            needsAutomation: column.needsAutomation,
            execute: async (context, params, formattedParamters) => {
                const r = (await column.execute(
                    context as any,
                    params,
                    formattedParamters
                )) as Result<TResultValueType>;
                if (r.ok) {
                    return Result.Ok({ result: r.result });
                }
                return r;
            },
            type: "client",
            isIdempotent: true,
            name: column.name,
            parameters: column.parameters,
            results: this.tuneResults({
                result: makeParameter({
                    name: "result",
                    description: "The result",
                    type: column.result as
                        | "string"
                        | "number"
                        | "object"
                        | "url"
                        | "secret"
                        | "array"
                        | "stringArray"
                        | "stringObject"
                        | "dateTime", // this type is the same type of column.result, yet somehow this makes TS happy
                }),
            }),
        });
    }

    public addQueryableDataSource<TConnectionObject extends JSONObject, TParameters extends ParameterRecord>(
        dataSource: QueryableDataSource<TConnectionObject, TParameters, TPluginParameters>
    ) {
        this.queryableDataSources.push(dataSource);
    }

    public addNotificationSender(notificationSender: NotificationSender<TPluginParameters>) {
        this.notificationSenders.push(notificationSender);
    }

    public async getEnumValues(
        ctx: ServerExecutionContext,
        key: string,
        params: Partial<UnwrapGeneric<TPluginParameters>>
    ): Promise<{ value: string; label: string }[]> {
        return await this.asyncParameters[key]?.(ctx, params);
    }

    public makeAsyncParameter(
        props: OmitUnion<ParameterProps<"enum">, "type" | "dependsOn" | "when" | "values"> & {
            key: string;
            defaultDisplayLabel: string;
            values: (
                ctx: ServerExecutionContext,
                params: Partial<UnwrapGeneric<TPluginParameters>>
            ) => Promise<{ value: string; label: string }[]>;
        }
    ): Parameter<string> {
        const { key, values, name, defaultDisplayLabel, ...rest } = props;

        this.asyncParameters[key] = values;

        return makeParameter({
            ...rest,
            type: "enum",
            name,
            values: {
                async: true,
                fetchKey: key,
                defaultDisplayLabel,
            },
        });
    }
}

export type PluginSecretFormInjection = {
    kind: "form-data";
    value: Parameter<ValueTypeType>;
    key: string;
};

export type PluginSecretJSONInjection = {
    kind: "json";
    value: Parameter<ValueTypeType>;
    // This isn't JSONPath, but instead a simplified string/number access mechanism.
    // The good parsers are kind of abandoned, and only really work well for
    // access and not value replacement. We have a well-tested mechanism for
    // deep object value replacement, and it takes an array of strings and numbers,
    // so we'll internally use that instead.
    path: (string | number)[];
};

type PluginSecretInjection = (
    | {
          readonly kind: "authorization-bearer";
          readonly value: Parameter<ValueTypeType>;
          readonly includeBearer?: boolean;
      }
    | PluginSecretFormInjection
    | PluginSecretJSONInjection
    | {
          readonly kind: "authorization-basic";
          readonly username: Parameter<ValueTypeType>;
          readonly password: Parameter<ValueTypeType>;
      }
) & {
    readonly baseUrl: string;
};

export class Plugin<
    TPluginParameters extends ParameterRecord = ParameterRecord
> extends NativePlugin<TPluginParameters> {
    public readonly headerSnippets: HeaderSnippet[] = [];
    public readonly secretInjections: PluginSecretInjection[] = [];
    public readonly endpoints: Endpoint<TPluginParameters>[] = [];
    public readonly signOns: SignOn<TPluginParameters>[] = [];
    public readonly triggers: Trigger<any, any, any>[] = [];
    public readonly signals: Signal<any>[] = [];
    public readonly glideAPIClients: ClientGlideAPI[] = [];
    private _eventTracker: EventTracker | undefined;
    public get eventTracker(): EventTracker | undefined {
        return this._eventTracker;
    }

    constructor(public fields: PluginFields<TPluginParameters>) {
        super(fields);
        // pass
    }

    public addHeader(header: HeaderSnippet<TPluginParameters> | HeaderSnippet<TPluginParameters>["getSnippet"]) {
        if (typeof header === "function") {
            header = { getSnippet: header };
        }
        this.headerSnippets.push(header as HeaderSnippet);
    }

    public setEventTracker(tracker: EventTracker<TPluginParameters> | EventTracker<TPluginParameters>["track"]) {
        if (typeof tracker === "function") {
            tracker = { track: tracker };
        }
        this._eventTracker = tracker as EventTracker;
    }

    public useSecret(params: PluginSecretInjection) {
        this.secretInjections.push(params);
    }

    public addEndpoint(endpoint: Endpoint<TPluginParameters>): void {
        this.endpoints.push(endpoint);
    }

    public addSignOn(signOn: SignOn<TPluginParameters>): void {
        this.signOns.push(signOn);
    }

    public addTrigger<TRawData, TResultParameters extends ParameterRecord, TParameters extends ParameterRecord>(
        trigger: Trigger<TRawData, TResultParameters, TParameters>
    ): Trigger<TRawData, TResultParameters, TParameters> {
        this.triggers.push(trigger);
        return trigger;
    }

    public addSignal<TResults extends ParameterRecord>(signal: Signal<TResults>): Signal<TResults> {
        this.signals.push(signal);
        return signal;
    }

    /**
     *  If set, the Glide API will be available to the plugin host for this tier.
     */
    public addGlideAPIClient(params: ClientGlideAPI): void {
        this.glideAPIClients.push(params);
    }
}

// Primary API

export interface Pack {
    readonly packID: string;
    readonly name: string;
    readonly description?: string;
    readonly author?: {
        readonly name: string;
        readonly description: string;
        readonly homepage?: string;
    };
    readonly pluginIDs: readonly string[];
    readonly getPlugin: (pluginID: string) => Promise<Plugin | NativePlugin>;
}

export const allTiers: PluginTierList = ["free", "starter", "pro", "business", "enterprise", "education"];
export function makeTierList(lowestSupported: PluginTier | undefined): PluginTierList {
    if (lowestSupported === undefined) {
        return allTiers;
    }
    const index = allTiers.indexOf(lowestSupported);
    return allTiers.slice(index);
}

export function newPlugin<TPluginParameters extends ParameterRecord>(
    fields: Omit<PluginFields<TPluginParameters>, "tier"> & {
        tier?: PluginTier;
    }
): Plugin<TPluginParameters> {
    return new Plugin<TPluginParameters>({
        ...fields,
        tier: makeTierList(fields.tier),
    });
}

export function newNativePlugin(
    fields: Omit<NativePluginFields, "tier"> & {
        tier?: PluginTier;
    }
): NativePlugin {
    return new NativePlugin({
        ...fields,
        tier: makeTierList(fields.tier),
    });
}

type OmitUnion<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export function makeParameter<T extends InputValueTypes>(
    fields: OmitUnion<ParameterProps<T>, "dependsOn" | "when" | "isUserProfileProperty">
): Parameter<ValueTypeToTSType[T]> {
    return new Parameter(fields);
}

export function makeUserProfileParameter<T extends InputValueTypes>(
    fields: OmitUnion<ParameterProps<T>, "dependsOn" | "when" | "isUserProfileProperty">
): Parameter<ValueTypeToTSType[T]> {
    return new Parameter({ ...fields, isUserProfileProperty: true });
}

interface PackData extends Omit<Pack, "pluginIDs" | "getPlugin"> {
    plugins: Record<string, () => Promise<NativePlugin | Plugin>>;
}

export function makePack(data: PackData): Pack {
    const { plugins, ...rest } = data;
    return {
        ...rest,
        pluginIDs: Object.keys(plugins),
        getPlugin: async (pluginID: string): Promise<NativePlugin | Plugin> => {
            const plugin = plugins[pluginID];
            if (plugin === undefined) {
                throw new TypeError(`Can't find plugin ${pluginID}`);
            }
            return await plugin();
        },
    };
}

export const isPlugin = (plugin: Plugin | NativePlugin): plugin is Plugin => {
    return plugin instanceof Plugin;
};
