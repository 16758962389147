import { areValuesEqual } from "@glide/common-core/dist/js/components/primitives";
import {
    type GroundValue,
    type LoadedGroundValue,
    type LoadingValue,
    type PrimitiveValue,
    type Row,
    Table,
    isLoadingValue,
    isPrimitive,
    type Path,
    type RelativePath,
    type RootPath,
    getSymbolicRepresentationForPath,
    isKeyPath,
    isRootPath,
    type Dirt,
    type DirtyState,
    type ProcessDirtProcessor,
    type QueryResolveInfo,
    type RootPathResolver,
    type TableAggregateComputation,
    type TableAggregateDataProvider,
    type ConditionValuePath,
    type LoadingValueWrapper,
    type Query,
    RollupKind,
    isQuery,
} from "@glide/computation-model-types";
import {
    asBoolean,
    asMaybeDate,
    asMaybeNumber,
    asMaybeString,
    asPrimitive,
    deconstructTableColumnPath,
    getRowColumn,
    isNotEmpty,
    isRow,
    isTable,
    tableForEach,
} from "@glide/common-core/dist/js/computation-model/data";
import { rowIndexColumnName } from "@glide/type-schema";
import { sortItems } from "@glide/generator/dist/js/wire/utils";
import { assert, defined, panic, DefaultMap } from "@glideapps/ts-necessities";
import { areSetsOverlapping, isArray, isEmptyOrUndefined } from "@glide/support";
import sample from "lodash/sample";

import {
    type Condition,
    computeCondition,
    getPathsForCondition,
    getSymbolicRepresentationForCondition,
} from "./conditions";
import { getRelationKeys } from "./handlers";
import { DateTimeAsyncParser } from "./parse-date-time";
import { follow, getValueAt, makePathOrGroundValueGetter } from "./getters";

interface ExtractRowByPositionState {
    currentKey: PrimitiveValue;
    currentRowID: string | undefined;
}

export enum FirstOrLast {
    First,
    Last,
}

abstract class ExtractRowByPositionComputation<TState extends ExtractRowByPositionState>
    implements TableAggregateComputation<TState>
{
    public abstract readonly canCacheResultsForContainer: boolean;

    public abstract readonly displayName: string;
    public abstract readonly symbolicRepresentation: string;

    constructor(
        // `_valuePath` can be used to produce a column in the result
        // row, vs the whole result row.  That's how we implement Single
        // Value.
        protected readonly _valuePath: RelativePath | undefined
    ) {}

    // If this other dirt potentially invalidates the existing extremum,
    // `proc.setDirty()` must be called, which will force a full
    // recomputation.  In the future we might make it possible to be more
    // selective.
    public abstract processOtherDirt(proc: ProcessDirtProcessor, d: Dirt): boolean;

    public abstract recompute(
        proc: TableAggregateDataProvider<TState>,
        aggregatedTableDirtyState: DirtyState,
        contextTableDirtyState: DirtyState | undefined
    ): GroundValue;

    public getContextPaths(): readonly Path[] {
        return [];
    }

    public getAggregatePaths(): readonly Path[] {
        if (this._valuePath !== undefined) {
            return [this._valuePath];
        } else {
            return [];
        }
    }

    // ##wrapLoadingValues
    // returns an unwrapped value
    protected getResultForState(state: TState, table: Table, wrapper: LoadingValueWrapper): GroundValue {
        if (state.currentRowID === undefined) return undefined;
        const row = defined(table.get(state.currentRowID));
        if (this._valuePath === undefined) {
            return row;
        } else {
            return wrapper.unwrap(follow(row, this._valuePath));
        }
    }

    public makeQuery(): Query | undefined {
        return undefined;
    }
}

// Produces either the first or last row, sorted by some value path, and
// given a filter condition for rows.  We use this to
// * Get the first and last rows in a table.  In this case the sort value
//   is the row index, and the filter allows all rows.
// * Compute a single relation.  That's the first row, by row index, where
//   the filter only allows rows matching the relation.
//
// The way this works is that we're keeping track of the current minimum
// key and row.  In most circumstances we won't have to do much recomputation.
abstract class ExtractMatchingRowByExtremumComputation extends ExtractRowByPositionComputation<ExtractRowByPositionState> {
    protected abstract isRowIncluded(r: Row, proc: TableAggregateDataProvider<ExtractRowByPositionState>): boolean;

    constructor(
        private readonly keyPath: RelativePath,
        // `_valuePath` can be used to produce a column in the result
        // row, vs the whole result row.  That's how we implement Single
        // Value.
        valuePath: RelativePath | undefined,
        private readonly position: FirstOrLast,
        protected readonly includeInvisible: boolean
    ) {
        super(valuePath);
    }

    public getAggregatePaths(): readonly Path[] {
        return [this.keyPath, ...super.getAggregatePaths()];
    }

    protected getKeyForRow(r: Row, wrapper: LoadingValueWrapper): PrimitiveValue | LoadingValue {
        const v = wrapper.unwrap(follow(r, this.keyPath));
        if (isLoadingValue(v)) return v;
        return asPrimitive(v);
    }

    protected doesNewKeyOverride(newKey: PrimitiveValue, oldKey: PrimitiveValue): boolean {
        assert(newKey !== undefined && oldKey !== undefined);
        if (this.position === FirstOrLast.First) {
            return newKey < oldKey;
        } else {
            return newKey > oldKey;
        }
    }

    public recompute(
        proc: TableAggregateDataProvider<ExtractRowByPositionState>,
        aggregatedTableDirtyState: DirtyState,
        contextDirtyState: DirtyState | undefined
    ): GroundValue {
        let maybeState = proc.get();
        if (maybeState === undefined) {
            maybeState = { currentRowID: undefined, currentKey: undefined };
            proc.set(maybeState);
        }
        const state = maybeState;

        let searchWholeTable = false;
        const table = proc.getAggregatedTable();
        if (isLoadingValue(table)) return table;

        if (state.currentRowID !== undefined) {
            const row = table?.get(state.currentRowID);
            if (
                row === undefined ||
                this.getKeyForRow(row, proc.loadingValueWrapper) !== state.currentKey ||
                !this.isRowIncluded(row, proc)
            ) {
                state.currentKey = undefined;
                state.currentRowID = undefined;
                searchWholeTable = true;
            }
        }

        if (table === undefined) return undefined;

        const { dirtyRowIDs } = aggregatedTableDirtyState;
        assert(dirtyRowIDs !== false || contextDirtyState?.isDirty === true);

        let loadingValue: LoadingValue | undefined;
        const processRow = (row: Row) => {
            if (!row.$isVisible && !this.includeInvisible) return;
            if (!this.isRowIncluded(row, proc)) return;

            const key = this.getKeyForRow(row, proc.loadingValueWrapper);
            if (isLoadingValue(key)) {
                loadingValue = key;
                return;
            }

            if (
                state.currentRowID === undefined ||
                (key !== undefined &&
                    (state.currentKey === undefined || this.doesNewKeyOverride(key, state.currentKey)))
            ) {
                state.currentRowID = row.$rowID;
                state.currentKey = key;
            }
        };

        // Are we too conservative here wth `searchWholeTable`?  Should we
        // always search the whole table if `state.currentRowID` is
        // `undefined`?  Technically that shouldn't be necessary, since it was
        // also `undefined` last time we recomputed, only dirty rows can
        // possibly make a difference.
        if (searchWholeTable || dirtyRowIDs === true || contextDirtyState?.isDirty === true) {
            tableForEach(table, this.includeInvisible, processRow);
        } else {
            assert(typeof dirtyRowIDs !== "boolean");
            for (const rowID of dirtyRowIDs) {
                const row = table.get(rowID);
                if (row === undefined) continue;
                processRow(row);
            }
        }

        if (loadingValue !== undefined) return loadingValue;

        return this.getResultForState(state, table, proc.loadingValueWrapper);
    }
}

export class ExtractRowByMinimumComputation extends ExtractMatchingRowByExtremumComputation {
    public readonly canCacheResultsForContainer = true;

    public readonly displayName = "first";
    public readonly symbolicRepresentation: string;

    constructor(keyPath: RelativePath, valuePath: RelativePath | undefined, includeInvisible: boolean) {
        super(keyPath, valuePath, FirstOrLast.First, includeInvisible);

        this.symbolicRepresentation = `(extract-row by-minimum: ${getSymbolicRepresentationForPath(
            keyPath
        )} value: ${getSymbolicRepresentationForPath(valuePath)})`;
    }

    protected isRowIncluded(r: Row): boolean {
        return this.includeInvisible || r.$isVisible;
    }

    public processOtherDirt(): boolean {
        return panic("This doesn't use other paths");
    }
}

export class ExtractRowByMaximumComputation extends ExtractMatchingRowByExtremumComputation {
    public readonly canCacheResultsForContainer = true;

    public readonly displayName = "last";
    public readonly symbolicRepresentation: string;

    constructor(keyPath: RelativePath, valuePath: RelativePath | undefined) {
        super(keyPath, valuePath, FirstOrLast.Last, false);

        this.symbolicRepresentation = `(extract-row by-maximum: ${getSymbolicRepresentationForPath(
            keyPath
        )} value: ${getSymbolicRepresentationForPath(valuePath)})`;
    }

    protected isRowIncluded(r: Row): boolean {
        assert(r.$isVisible);
        return true;
    }

    public processOtherDirt(): boolean {
        return panic("This doesn't use other paths");
    }
}

export class SingleRelationComputation extends ExtractMatchingRowByExtremumComputation {
    // The target can come from the containing row, so we can't cache.
    public readonly canCacheResultsForContainer = false;

    public readonly displayName = "singleRelation";
    public readonly symbolicRepresentation: string;

    constructor(
        private readonly _sourceKeyColumnPath: Path, // in the context, or global
        private readonly _targetKeyColumnPath: Path, // in the matching rows, or global
        sortKeyPath: RelativePath,
        private readonly _sourceKeyIsArray: boolean,
        private readonly _targetKeyIsArray: boolean
    ) {
        super(sortKeyPath, undefined, FirstOrLast.First, false);

        this.symbolicRepresentation = `(single-relation source-column: ${getSymbolicRepresentationForPath(
            _sourceKeyColumnPath
        )} target-column: ${getSymbolicRepresentationForPath(
            _targetKeyColumnPath
        )} sort-by: ${getSymbolicRepresentationForPath(sortKeyPath)})`;
    }

    public getContextPaths(): readonly Path[] {
        return [...super.getContextPaths(), this._sourceKeyColumnPath];
    }

    public getAggregatePaths(): readonly Path[] {
        return [...super.getAggregatePaths(), this._targetKeyColumnPath];
    }

    protected isRowIncluded(r: Row, proc: TableAggregateDataProvider<ExtractRowByPositionState>): boolean {
        assert(r.$isVisible);

        // FIXME: Get the key only once for all rows, in
        // `ExtractMatchingRowByExtremumComputation.recompute`.
        const keys = getRelationKeys(
            getValueAt(proc.rootPathResolver, proc.getContextRow(), this._sourceKeyColumnPath),
            this._sourceKeyIsArray,
            proc.loadingValueWrapper
        );
        if (isLoadingValue(keys)) return false;
        const values = getRelationKeys(
            getValueAt(proc.rootPathResolver, r, this._targetKeyColumnPath),
            this._targetKeyIsArray,
            proc.loadingValueWrapper
        );
        if (isLoadingValue(values)) return false;
        return areSetsOverlapping(new Set(keys), values);
    }

    public processOtherDirt(proc: ProcessDirtProcessor): boolean {
        return proc.setDirty();
    }
}

interface ExtractRandomRowState {
    rowID: string | undefined;
    lastNumRows: number;
}

export class ExtractRandomRowComputation implements TableAggregateComputation<ExtractRandomRowState, readonly Row[]> {
    public readonly canCacheResultsForContainer = false;

    public readonly displayName = "random";

    constructor(
        // `_valuePath` can be used to produce a column in the result
        // row, vs the whole result row.  That's how we implement Single
        // Value.
        private readonly _valuePath: RelativePath | undefined,
        private readonly _randomOrderSerialPath: RootPath
    ) {}

    public getContextPaths(): readonly Path[] {
        return [this._randomOrderSerialPath];
    }

    public getAggregatePaths(): readonly Path[] {
        if (this._valuePath === undefined) {
            return [];
        } else {
            return [this._valuePath];
        }
    }

    public processOtherDirt(proc: ProcessDirtProcessor): boolean {
        return proc.setDirty();
    }

    public recompute(proc: TableAggregateDataProvider<ExtractRandomRowState, readonly Row[]>): GroundValue {
        let maybeState = proc.get();
        if (maybeState === undefined) {
            maybeState = { rowID: undefined, lastNumRows: 0 };
            proc.set(maybeState);
        }
        const state = maybeState;

        let table = proc.getAggregatedTable();
        if (isLoadingValue(table)) return table;
        if (table === undefined) {
            table = new Table();
        }

        // Returns unwrapped rows
        function getTableRows() {
            const aggregatedTable = proc.getAggregatedTable();
            if (aggregatedTable === undefined || isLoadingValue(aggregatedTable)) {
                return aggregatedTable;
            }

            return aggregatedTable.asMutatingVisibleRowsArray();
        }

        if (state.rowID !== undefined) {
            const row = table.get(state.rowID);
            if (row === undefined) {
                state.rowID = undefined;
            }
        }

        if (state.rowID === undefined || state.lastNumRows !== table.size) {
            const rows = getTableRows();
            if (rows === undefined || isLoadingValue(rows)) return rows;
            // This will be `undefined` if `table` is empty.
            state.rowID = sample(rows)?.$rowID;
            state.lastNumRows = table.size;
        }

        if (state.rowID === undefined) return undefined;
        const row = defined(table.get(state.rowID));
        if (this._valuePath === undefined) {
            return row;
        } else {
            return proc.loadingValueWrapper.unwrap(follow(row, this._valuePath));
        }
    }

    public makeQuery(): Query | undefined {
        return undefined;
    }

    public get symbolicRepresentation(): string {
        return `(extract-random-row value: ${getSymbolicRepresentationForPath(this._valuePath)})`;
    }
}

export class ExtractRandomItemComputation implements TableAggregateComputation<GroundValue> {
    public readonly canCacheResultsForContainer = false;

    public readonly displayName = "randomItem";
    public readonly symbolicRepresentation = "(extract-random-item)";

    constructor(private readonly _randomOrderSerialPath: RootPath) {}

    public getContextPaths(): readonly Path[] {
        return [this._randomOrderSerialPath];
    }

    public getAggregatePaths(): readonly Path[] {
        return [];
    }

    public processOtherDirt(proc: ProcessDirtProcessor): boolean {
        return proc.setDirty();
    }

    public recompute(proc: TableAggregateDataProvider<GroundValue>): GroundValue {
        let maybeItem = proc.get();

        const array = proc.getAggregatedAsArray();
        if (array === undefined || isLoadingValue(array)) {
            proc.set(array);
            return array;
        }

        if (array.includes(maybeItem)) {
            return maybeItem;
        }

        maybeItem = sample(array);
        proc.set(maybeItem);
        return maybeItem;
    }

    public makeQuery(): Query | undefined {
        return undefined;
    }
}

export class ExtractNthRowComputation extends ExtractRowByPositionComputation<ExtractRowByPositionState> {
    public readonly displayName = "nth";
    public readonly symbolicRepresentation: string;

    constructor(
        // `valuePath` can be used to produce a column in the result
        // row, vs the whole result row.  That's how we implement Single
        // Value.
        valuePath: RelativePath | undefined,
        private readonly _position: FirstOrLast,
        private readonly _offsetPath: Path
    ) {
        super(valuePath);

        this.symbolicRepresentation = `(extract-nth-row ${getSymbolicRepresentationForPath(
            _offsetPath
        )} value: ${getSymbolicRepresentationForPath(valuePath)})`;
    }

    public get canCacheResultsForContainer(): boolean {
        // ##cacheAggregatesWithOtherInputs:
        // If the offset is relative, it comes from the context row, which
        // means that we can't cache just based on the aggregated table, but
        // if it's a root path then its value is the same for all context
        // rows, so we can.
        return isRootPath(this._offsetPath);
    }

    public getContextPaths(): readonly Path[] {
        return [...super.getContextPaths(), this._offsetPath];
    }

    // This can happen when the position is a global path, for example.
    public processOtherDirt(proc: ProcessDirtProcessor): boolean {
        return proc.setDirty();
    }

    public recompute(
        proc: TableAggregateDataProvider<ExtractRowByPositionState>,
        _aggregatedTableDirtyState: DirtyState
    ): GroundValue {
        const offsetValue = proc.loadingValueWrapper.unwrap(
            getValueAt(proc.rootPathResolver, proc.getContextRow(), this._offsetPath)
        );

        if (isLoadingValue(offsetValue)) return offsetValue;

        const offset = asMaybeNumber(offsetValue) ?? 0;

        let maybeState = proc.get();
        if (maybeState === undefined) {
            maybeState = { currentRowID: undefined, currentKey: undefined };
            proc.set(maybeState);
        }
        const state = maybeState;

        let table = proc.getAggregatedTable();
        if (isLoadingValue(table)) return table;
        if (table === undefined) {
            table = new Table();
        }

        const rows = table.asMutatingVisibleRowsArray();

        let row: Row | undefined;
        if (this._position === FirstOrLast.First) {
            row = rows[offset];
        } else {
            row = rows[rows.length - 1 - offset];
        }

        state.currentRowID = row?.$rowID;
        state.currentKey = undefined;

        return this.getResultForState(state, table, proc.loadingValueWrapper);
    }
}

abstract class TableAggregateComputationBase<T = undefined> implements TableAggregateComputation<T> {
    public abstract readonly displayName: string;
    protected abstract readonly rollupKind: RollupKind | undefined;

    constructor(private readonly _keyPath: Path | undefined, private readonly _columnName: string | undefined) {}

    public getContextPaths(): readonly Path[] {
        return [];
    }

    public getAggregatePaths(): readonly Path[] {
        if (this._keyPath === undefined) return [];
        return [this._keyPath];
    }

    protected abstract readonly canCacheResultsFromOtherKeys: boolean;

    public get canCacheResultsForContainer(): boolean {
        // It's unlikely that the key path would be a root path, because that
        // wouldn't make much sense, but it's possible: you could configure a
        // computed column that's global, and then sum it up over all rows
        // (which is like multplying it by the number of rows).  In that case
        // we can't cache the result for the aggregated table because it
        // depends on that global value, which is outside the table.
        if (this._keyPath !== undefined && isRootPath(this._keyPath)) return false;

        // Subclasses can have other conditions that forbid aggregating.
        return this.canCacheResultsFromOtherKeys;
    }

    protected getKeyForRow(ns: RootPathResolver, r: GroundValue, wrapper: LoadingValueWrapper): GroundValue {
        if (this._keyPath === undefined) return r;
        return wrapper.unwrap(getValueAt(ns, r, this._keyPath));
    }

    public processOtherDirt(proc: ProcessDirtProcessor): boolean {
        return proc.setDirty();
    }

    public abstract recompute(proc: TableAggregateDataProvider<T>): GroundValue;

    protected addGroupByToQuery(
        query: Query,
        columnName: string,
        _proc: TableAggregateDataProvider<T>
    ): Query | LoadingValue | undefined {
        if (this.rollupKind === undefined) return undefined;

        return query.withGroupBy({
            columns: [],
            limit: 1,
            aggregates: [{ column: columnName, name: "agg", kind: this.rollupKind }],
            sort: [],
        });
    }

    public makeQuery(query: Query, proc: TableAggregateDataProvider<T>): Query | undefined {
        let columnName = this._columnName;
        if (columnName === undefined) {
            // NOTE: It's technically incorrect to use the key path as a
            // fallback to the column name here, but we're being conservative
            // just in case we're not correctly passing in the column name
            // sometimes.
            // https://github.com/glideapps/glide/issues/22651
            if (this._keyPath !== undefined && isKeyPath(this._keyPath)) {
                columnName = this._keyPath.key;
            }
        }
        if (columnName === undefined) return undefined;

        const groupByQuery = this.addGroupByToQuery(query, columnName, proc);
        if (groupByQuery === undefined || isLoadingValue(groupByQuery)) return undefined;

        return groupByQuery.withPostProcess(t => {
            const row = t.asMutatingArray()[0];
            if (row === undefined) return undefined;
            // This can never be loading based on how aggregate queries work.
            // No need to unwrap.
            const v = getRowColumn(row, "agg");
            assert(!isLoadingValue(v) && !isQuery(v));
            return v;
        });
    }

    public get symbolicRepresentation(): string {
        return `(${this.displayName} ${getSymbolicRepresentationForPath(this._keyPath)})`;
    }
}

// This is an aggregate that computes the sum of contributions from each
// row.  It can be used for counting and summing.
abstract class CountComputation extends TableAggregateComputationBase {
    public readonly canCacheResultsFromOtherKeys = true;

    protected abstract getValue(v: LoadedGroundValue): number;
    protected abstract readonly emptyValue: number | undefined;

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        let value = 0;
        let numRows = 0;
        const tableDefinedAndLoaded = proc.forEachInAggregated(row => {
            const key = this.getKeyForRow(proc.rootPathResolver, row, proc.loadingValueWrapper);
            if (isLoadingValue(key)) return key;
            value += this.getValue(key);
            numRows++;
            return;
        });
        if (tableDefinedAndLoaded !== true) return tableDefinedAndLoaded;
        if (numRows === 0) return this.emptyValue;
        return value;
    }
}

export class CountNonEmptyComputation extends CountComputation {
    public readonly displayName = "count-non-empty";
    protected readonly emptyValue = 0;
    protected readonly rollupKind = RollupKind.CountNonEmpty;

    protected getValue(v: LoadedGroundValue): number {
        return isNotEmpty(v) ? 1 : 0;
    }
}

export class CountTrueComputation extends CountComputation {
    public readonly displayName = "count-true";
    protected readonly emptyValue = 0;
    protected readonly rollupKind = RollupKind.CountTrue;

    protected getValue(v: LoadedGroundValue): number {
        if (!isPrimitive(v)) return 0;
        return areValuesEqual(v, true) === true ? 1 : 0;
    }
}

export class CountNotTrueComputation extends CountComputation {
    public readonly displayName = "count-not-true";
    protected readonly emptyValue = 0;
    protected readonly rollupKind = RollupKind.CountNotTrue;

    protected getValue(v: LoadedGroundValue): number {
        if (!isPrimitive(v)) return 0;
        return areValuesEqual(v, true) === true ? 0 : 1;
    }
}

export class SumComputation extends CountComputation {
    public readonly displayName = "sum";
    protected readonly emptyValue = 0;
    protected readonly rollupKind = RollupKind.Sum;

    protected getValue(v: LoadedGroundValue): number {
        return asMaybeNumber(v) ?? 0;
    }
}

// This could also be implemented as a `ReduceComputation`.
export class AverageComputation extends TableAggregateComputationBase {
    public readonly canCacheResultsFromOtherKeys = true;

    public readonly displayName = "average";
    protected readonly rollupKind = RollupKind.Average;

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        let s = 0;
        let n = 0;
        const isDefined = proc.forEachInAggregated(row => {
            const v = this.getKeyForRow(proc.rootPathResolver, row, proc.loadingValueWrapper);
            if (isLoadingValue(v)) return v;
            const x = asMaybeNumber(v);
            if (x === undefined) return;
            s += x;
            n++;
            return;
        });
        if (isDefined !== true) return isDefined;
        if (n === 0) return undefined;
        return s / n;
    }
}

abstract class ReduceComputation<T> extends TableAggregateComputationBase {
    // Does one step in the reduction, i.e. reduces one row.  `current` is
    // always `undefined` for the first row.  For every following row it's
    // whatever the last `step` returned.
    //
    // TODO: We could be smarter here and allow individual aggregates to
    // decide whether they want to continue if a `LoadingValue` was
    // encountered.  For example, "all true" will evaluate to `false` is a
    // single `false` is found, not matter whether any other values are still
    // loading.
    protected abstract step(current: T | undefined, next: LoadedGroundValue): T | undefined;
    // Produce the result after all rows have been reduced.
    protected abstract finish(current: T | undefined): LoadedGroundValue;

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        let current: T | undefined;
        const isDefined = proc.forEachInAggregated(row => {
            const key = this.getKeyForRow(proc.rootPathResolver, row, proc.loadingValueWrapper);
            if (isLoadingValue(key)) return key;
            current = this.step(current, key);
            return;
        });
        if (isDefined !== true) return isDefined;
        return this.finish(current);
    }
}

abstract class ExtremumComputation extends ReduceComputation<LoadedGroundValue> {
    public readonly canCacheResultsFromOtherKeys = true;

    protected abstract doesValueOverride(newValue: LoadedGroundValue, oldValue: LoadedGroundValue): boolean;

    protected step(current: LoadedGroundValue, next: LoadedGroundValue): LoadedGroundValue {
        if (this.doesValueOverride(next, current)) {
            return next;
        } else {
            return current;
        }
    }

    protected finish(current: LoadedGroundValue): LoadedGroundValue {
        return current;
    }
}

export class MinimumComputation extends ExtremumComputation {
    public readonly displayName = "minimum";
    protected readonly rollupKind = RollupKind.Minimum;

    protected doesValueOverride(newValue: LoadedGroundValue, oldValue: LoadedGroundValue): boolean {
        const newNumber = asMaybeNumber(newValue);
        if (newNumber === undefined) return false;

        const oldNumber = asMaybeNumber(oldValue);
        return oldNumber === undefined || newNumber < oldNumber;
    }
}

export class MaximumComputation extends ExtremumComputation {
    public readonly displayName = "maximum";
    protected readonly rollupKind = RollupKind.Maximum;

    protected doesValueOverride(newValue: LoadedGroundValue, oldValue: LoadedGroundValue): boolean {
        const newNumber = asMaybeNumber(newValue);
        if (newNumber === undefined) return false;

        const oldNumber = asMaybeNumber(oldValue);
        return oldNumber === undefined || newNumber > oldNumber;
    }
}

export class EarliestComputation extends ExtremumComputation {
    public readonly displayName = "earliest";
    protected readonly rollupKind = RollupKind.Earliest;

    protected doesValueOverride(newValue: LoadedGroundValue, oldValue: LoadedGroundValue): boolean {
        const newNumber = asMaybeDate(newValue);
        if (newNumber === undefined) return false;

        const oldNumber = asMaybeDate(oldValue);
        return oldNumber === undefined || newNumber.compareTo(oldNumber) < 0;
    }
}

export class LatestComputation extends ExtremumComputation {
    public readonly displayName = "latest";
    protected readonly rollupKind = RollupKind.Latest;

    protected doesValueOverride(newValue: LoadedGroundValue, oldValue: LoadedGroundValue): boolean {
        const newNumber = asMaybeDate(newValue);
        if (newNumber === undefined) return false;

        const oldNumber = asMaybeDate(oldValue);
        return oldNumber === undefined || newNumber.compareTo(oldNumber) > 0;
    }
}

export class AllTrueComputation extends ReduceComputation<boolean> {
    public readonly canCacheResultsFromOtherKeys = true;

    public readonly displayName = "all-true";
    protected readonly rollupKind = RollupKind.AllTrue;

    protected step(current: boolean | undefined, next: LoadedGroundValue): boolean {
        if (current === false) return false;
        return asBoolean(next);
    }

    protected finish(current: boolean | undefined): LoadedGroundValue {
        return current ?? true;
    }
}

export class SomeTrueComputation extends ReduceComputation<boolean> {
    public readonly canCacheResultsFromOtherKeys = true;

    public readonly displayName = "some-true";
    protected readonly rollupKind = RollupKind.SomeTrue;

    protected step(current: boolean | undefined, next: LoadedGroundValue): boolean {
        if (current === true) return true;
        return asBoolean(next);
    }

    protected finish(current: boolean | undefined): LoadedGroundValue {
        return current ?? false;
    }
}

export class RangeComputation extends ReduceComputation<[number, number]> {
    public readonly canCacheResultsFromOtherKeys = true;

    public readonly displayName = "range";
    protected readonly rollupKind = RollupKind.Range;

    protected step(current: [number, number] | undefined, next: LoadedGroundValue): [number, number] | undefined {
        const n = asMaybeNumber(next);
        if (n === undefined) return current;
        if (current === undefined) return [n, n];
        let [min, max] = current;
        if (n < min) {
            min = n;
        } else if (n > max) {
            max = n;
        }
        return [min, max];
    }

    protected finish(current: [number, number] | undefined): LoadedGroundValue {
        if (current === undefined) return undefined;
        return current[1] - current[0];
    }
}

type UniqueCountMap = DefaultMap<PrimitiveValue, number>;

function incrementUniqueCount(map: UniqueCountMap, key: PrimitiveValue): void {
    map.update(key, c => c + 1);
}

export class CountUniqueComputation extends TableAggregateComputationBase {
    public readonly canCacheResultsFromOtherKeys = true;

    public readonly displayName = "count-unique";
    protected readonly rollupKind = RollupKind.CountUnique;

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        const countMap: UniqueCountMap = new DefaultMap(() => 0);
        const isDefined = proc.forEachInAggregated(row => {
            const key = this.getKeyForRow(proc.rootPathResolver, row, proc.loadingValueWrapper);
            if (isLoadingValue(key)) return key;
            if (isNotEmpty(key)) {
                incrementUniqueCount(countMap, asPrimitive(key));
            }
            return;
        });
        if (isDefined !== true) return isDefined;
        return countMap.size;
    }
}

export class JoinStringsComputation extends TableAggregateComputationBase {
    public readonly displayName = "join-strings";
    protected readonly rollupKind = undefined;
    private readonly _symbolicRepresentation: string;

    constructor(keyPath: Path | undefined, columnName: string | undefined, private readonly _separatorPath: Path) {
        super(keyPath, columnName);

        this._symbolicRepresentation = `(${this.displayName} ${getSymbolicRepresentationForPath(
            keyPath
        )} separator: ${getSymbolicRepresentationForPath(_separatorPath)})`;
    }

    public get canCacheResultsFromOtherKeys(): boolean {
        // Sometimes we can ##cacheAggregatesWithOtherInputs.
        return isRootPath(this._separatorPath);
    }

    public getContextPaths(): readonly Path[] {
        return [...super.getContextPaths(), this._separatorPath];
    }

    // Returns the unwrapped value
    private getSeparator(proc: TableAggregateDataProvider<unknown>): string | LoadingValue | undefined {
        const separatorValue = proc.loadingValueWrapper.unwrap(
            getValueAt(proc.rootPathResolver, proc.getContextRow(), this._separatorPath)
        );
        if (isLoadingValue(separatorValue)) return separatorValue;

        return asMaybeString(separatorValue ?? "");
    }

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        const separator = this.getSeparator(proc);
        if (separator === undefined || isLoadingValue(separator)) return separator;

        const parts: string[] = [];
        const isDefined = proc.forEachInAggregated(row => {
            const keyValue = this.getKeyForRow(proc.rootPathResolver, row, proc.loadingValueWrapper);
            if (isLoadingValue(keyValue)) return keyValue;
            const key = asMaybeString(keyValue);
            if (isEmptyOrUndefined(key)) return;
            parts.push(key);
            return;
        });
        if (isDefined !== true) return isDefined;

        return parts.join(separator);
    }

    protected addGroupByToQuery(
        query: Query,
        columnName: string,
        proc: TableAggregateDataProvider<unknown>
    ): Query | LoadingValue | undefined {
        const separator = this.getSeparator(proc);
        if (separator === undefined || isLoadingValue(separator)) return separator;

        return query.withGroupBy({
            columns: [],
            limit: 1,
            aggregates: [{ column: columnName, name: "agg", kind: "join-strings", separator }],
            sort: [],
        });
    }

    public get symbolicRepresentation(): string {
        return this._symbolicRepresentation;
    }
}

// A lookup of primitive values from a multi-relation.  This produces an array
// of primitive values.
export class MultiPrimitiveLookupComputation extends TableAggregateComputationBase {
    public readonly canCacheResultsFromOtherKeys = true;

    public readonly displayName = "multi-primitive-lookup";
    protected readonly rollupKind = undefined;

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        const values: PrimitiveValue[] = [];
        function pushValue(v: LoadedGroundValue) {
            if (isPrimitive(v) && isNotEmpty(v)) {
                values.push(asPrimitive(v));
            } else if (isArray(v)) {
                for (const w of v) {
                    pushValue(w);
                }
            }
        }
        const isDefined = proc.forEachInAggregated(r => {
            const k = this.getKeyForRow(proc.rootPathResolver, r, proc.loadingValueWrapper);
            if (isLoadingValue(k)) return k;

            pushValue(k);
            return;
        });
        if (isDefined !== true) return isDefined;
        return values;
    }
}

// A lookup of single- or multi-relations from a multi-relation.  This
// produces a multi-relation, i.e. a table.
export class MultiRelationLookupComputation extends TableAggregateComputationBase {
    public readonly canCacheResultsFromOtherKeys = true;

    public readonly displayName = "multi-relation-lookup";
    protected readonly rollupKind = undefined;

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        const table = proc.getAggregatedTable();
        if (table === undefined || isLoadingValue(table)) return table;

        let loadingValue: LoadingValue | undefined;
        const result: Row[] = [];
        tableForEach(table, false, r => {
            if (loadingValue !== undefined) return;
            const k = this.getKeyForRow(proc.rootPathResolver, r, proc.loadingValueWrapper);
            if (k === undefined) return;
            if (isLoadingValue(k)) {
                loadingValue = k;
                return;
            }
            if (isTable(k)) {
                for (const v of k.values()) {
                    result.push(v);
                }
            } else if (isRow(k)) {
                result.push(k);
            } else {
                return panic("Multi-relation lookup target must be a row or a table");
            }
            return;
        });
        if (loadingValue !== undefined) return loadingValue;
        return new Table(result, table);
    }
}

export class ExtractNthItemComputation extends TableAggregateComputationBase {
    public readonly displayName = "extract-nth-item";
    protected readonly rollupKind = undefined;
    private readonly _symbolicRepresentation: string;

    constructor(
        keyPath: Path | undefined,
        private readonly _position: FirstOrLast,
        private readonly _offsetPath: Path
    ) {
        // We don't support n-th in queries
        super(keyPath, undefined);

        this._symbolicRepresentation = `(${this.displayName} ${getSymbolicRepresentationForPath(
            keyPath
        )} offset: ${getSymbolicRepresentationForPath(_offsetPath)})`;
    }

    public get canCacheResultsFromOtherKeys(): boolean {
        // Sometimes we can ##cacheAggregatesWithOtherInputs.
        return isRootPath(this._offsetPath);
    }

    public getContextPaths(): readonly Path[] {
        return [...super.getContextPaths(), this._offsetPath];
    }

    public recompute(proc: TableAggregateDataProvider<undefined>): GroundValue {
        const offsetValue = proc.loadingValueWrapper.unwrap(
            getValueAt(proc.rootPathResolver, proc.getContextRow(), this._offsetPath)
        );
        if (isLoadingValue(offsetValue)) return offsetValue;

        let offset = asMaybeNumber(offsetValue) ?? 0;
        if (offset === undefined) return undefined;

        const items = proc.getAggregatedAsArray();
        if (items === undefined || isLoadingValue(items)) return items;

        if (this._position === FirstOrLast.Last) {
            offset = items.length - 1 - offset;
        }

        return items[offset];
    }

    public get symbolicRepresentation(): string {
        return this._symbolicRepresentation;
    }
}

export interface FilterConditions {
    readonly combinator: "and" | "or";
    readonly conditions: readonly Condition<ConditionValuePath>[];
}

export class FilterSortLimitComputation extends DateTimeAsyncParser implements TableAggregateComputation<undefined> {
    public readonly canCacheResultsForContainer: boolean;

    public readonly displayName = "filterSortLimit";

    constructor(
        private readonly _conditions: FilterConditions,
        // This is technically redundant, since `_conditions` already has all
        // the information, but it's easier for us to generate this
        // transformer.  If this is `defined` then the target table must be
        // queryable.
        // ##wrapLoadingValues
        // This should return an unwrapped value
        private readonly _queryConditionsTransformer:
            | ((proc: TableAggregateDataProvider<unknown>, q: Query) => Query | LoadingValue | undefined)
            | undefined,
        private readonly _sortKeyTableColumnPath: RootPath | undefined,
        private readonly _additionalAggregatePaths: readonly RootPath[],
        private readonly _reverse: boolean,
        private readonly _limit: number | undefined,
        private readonly _multiple: boolean
    ) {
        super();

        // If none of the paths in the condition come from the containing row
        // then the results can be cached by container.
        this.canCacheResultsForContainer = _conditions.conditions.every(
            c => getPathsForCondition(c, true).length === 0
        );
    }

    public getContextPaths(): readonly Path[] {
        const paths: RelativePath[] = [];
        for (const c of this._conditions.conditions) {
            paths.push(...getPathsForCondition(c, true));
        }
        return paths;
    }

    public getAggregatePaths(): readonly Path[] {
        const paths: Path[] = [];

        for (const c of this._conditions.conditions) {
            paths.push(...getPathsForCondition(c, false));
        }

        if (this._queryConditionsTransformer === undefined) {
            // We don't want to be subscribed to any changes in the target
            // table if it's queryable, because some accidental ones will
            // fire, and they don't help us.  For example, we're always
            // setting up the sort handler which will fire every time a query
            // result adds a new row, and would then cause us to recreate the
            // query in turn, which is unnecessary and causes glitches,
            // including the one in the Data Editor where clicking on the
            // "Query" button first loads the query but then goes back to
            // showing the button.
            if (this._sortKeyTableColumnPath !== undefined) {
                const decomposed = deconstructTableColumnPath(this._sortKeyTableColumnPath);
                paths.push(decomposed.tablePath, decomposed.keyPath);
            }
        }

        paths.push(...this._additionalAggregatePaths);

        return paths;
    }

    public processOtherDirt(proc: ProcessDirtProcessor): boolean {
        return proc.setDirty();
    }

    public recompute(
        proc: TableAggregateDataProvider<unknown>,
        _aggregatedTableDirtyState: DirtyState,
        _contextTableDirtyState: DirtyState | undefined,
        setAllDirty: () => void,
        qri: QueryResolveInfo
    ): GroundValue {
        const table = proc.getAggregatedTable();
        if (table === undefined || isLoadingValue(table)) return table;

        const context = proc.getContextRow();

        const resolveQuery = (v: GroundValue, p: ConditionValuePath) => {
            return proc.loadingValueWrapper.unwrap(
                proc.rootPathResolver.resolveQueryWithFixup(v, p.path, context, qri.contextPath, qri.handler, true)
            );
        };

        let rows: Row[];
        if (this._conditions.conditions.length === 0) {
            rows = Array.from(table.asMutatingArray());
        } else {
            rows = [];
            tableForEach(table, false, r => {
                const getValueForPath = makePathOrGroundValueGetter(
                    proc.rootPathResolver,
                    r,
                    context,
                    proc.loadingValueWrapper
                );

                let isIncluded: boolean | LoadingValue = this._conditions.combinator === "and";
                for (const c of this._conditions.conditions) {
                    const result = computeCondition(
                        c,
                        getValueForPath,
                        v => this.parseDateTime(v, setAllDirty),
                        resolveQuery
                    );
                    if (this._conditions.combinator === "and") {
                        if (isLoadingValue(result)) {
                            isIncluded = result;
                            break;
                        } else if (result !== true) {
                            isIncluded = false;
                            break;
                        }
                    } else {
                        if (isLoadingValue(result)) {
                            isIncluded = result;
                            // We don't break here, because a true condition
                            // we haven't seen yet can still make the whole
                            // disjunction true.
                        } else if (result === true) {
                            isIncluded = true;
                            break;
                        }
                    }
                }
                if (isLoadingValue(isIncluded)) {
                    // We don't have to do anything here because
                    // `resolveQuery` uses `proc.loadingValueWrapper` to take
                    // note of any loading values, and our caller will use is
                    // to wrap our result if necessary.
                } else if (isIncluded) {
                    rows.push(r);
                }
            });
        }

        if (this._sortKeyTableColumnPath !== undefined) {
            const { keyPath } = deconstructTableColumnPath(this._sortKeyTableColumnPath);
            sortItems(rows, r => proc.loadingValueWrapper.unwrap(getValueAt(proc.rootPathResolver, r, keyPath)));
        }

        if (this._reverse) {
            rows.reverse();
        }

        if (!this._multiple) {
            // This can be `undefined`, which is fine
            return rows[0];
        }

        if (this._limit !== undefined && this._limit < rows.length) {
            rows.length = this._limit;
        }

        return new Table(rows, table);
    }

    public makeQuery(q: Query, proc: TableAggregateDataProvider<unknown>): Query | LoadingValue | undefined {
        const maybeQuery = this._queryConditionsTransformer?.(proc, q);
        if (maybeQuery === undefined || isLoadingValue(maybeQuery)) return maybeQuery;
        q = maybeQuery;

        if (this._sortKeyTableColumnPath !== undefined) {
            const { keyPath } = deconstructTableColumnPath(this._sortKeyTableColumnPath);
            q = q.withSort([{ columnName: keyPath.key, order: this._reverse ? "desc" : "asc" }]);
        } else if (this._reverse) {
            q = q.withSort([{ columnName: rowIndexColumnName, order: "desc" }]);
        }

        if (!this._multiple) {
            q = q.withLimit(1);
        } else if (this._limit !== undefined) {
            q = q.withLimit(this._limit);
        }
        return q;
    }

    public get symbolicRepresentation(): string {
        return `(filter-sort-limit filter: (${this._conditions.combinator} ${this._conditions.conditions
            .map(getSymbolicRepresentationForCondition)
            .join(" ")})`;
    }
}
