import { isResponseOK, logError, logInfo } from "@glide/support";
import mixpanel from "mixpanel-browser";
import { getLocationSettings } from "../location";
import { getPersistentDeviceID } from "../persistent-device-id";
import * as routes from "../routes";
import { getAppFacilities } from "../support/app-renderer";
import { loadPageJavascript, removeQueryStrings } from "../support/browser-hacks";
import { standalone } from "../support/device";
import { onTrackingConsentChange, withTrackingConsent } from "../tracking-consent";
import type { AppAnalyticsEvents } from "./app-events";
import type { AnalyticsEvents } from "./events";
import { HubspotCustomEvents, MarketingEvents } from "./events";
import * as koala from "./koala";
import { capturePosthogEvent, identifyPosthogUser, initializePosthog } from "./posthog";
import { Hotjar } from "./hotjar/hotjar";

interface FirebaseAnalyticsInstance {
    setUserId(userID: string): void;
    logEvent(name: string, properties: object): void;
}

let firebaseAnalyticsInstance: FirebaseAnalyticsInstance | undefined;

// HubSpot API
type HubspotTracker = Array<
    | ["identify", { email: string }]
    | ["doNotTrack", { track: boolean }]
    | ["revokeCookieConsent"]
    | ["trackPageView"]
    | ["trackEvent", { id: string }]
>;

// TODO move hardcoded key to indexeddb/src/location-common.ts
const intercomAppId = "rnjf1j5t";

const _hsq: HubspotTracker = (function () {
    try {
        return ((window as any)._hsq = (window as any)._hsq ?? []);
    } catch {
        return [];
    }
})();

// TODO - review, as NODE_ENV has cemented
// to always === 'production', even on dev
function isProduction(): boolean {
    return process.env.NODE_ENV === "production";
}

interface AnalyticsConfig {
    app_name: string;
    app_id: string;
    app_user_id: string;
    device_id: string;
    standalone: boolean;
    screen_name: string | undefined;
}

let currentAppUserID: string | undefined;
const anyWindow = window as any;
// This assumes that we don't let go of an appUserID
// within the same memory context: the transition is
// unauthenticated -> authenticated exactly once, and
// anything else requires a full context reset
// (e.g. reloading the whole page.)
function setCurrentAppUserID(appUserID: string) {
    currentAppUserID = appUserID;
}

function configForPlayerAnalytics(): AnalyticsConfig {
    const appID = routes.appIdOrCustomDomain();
    const deviceID = getPersistentDeviceID(appID);
    const appUserID = currentAppUserID ?? `device-${deviceID}`;
    return {
        app_name: document.title,
        app_id: appID,
        app_user_id: appUserID,
        device_id: deviceID,
        standalone,
        screen_name: undefined,
    };
}

export function globalWindowGoogleAnalyticsMeasureIDs(): string[] {
    return (window as any).additionalGAMeasureIDs ?? [];
}

export function appendGlobalWindowGoogleAnalyticsMeasureID(measureID: string): void {
    const addtlMeasureIDs = globalWindowGoogleAnalyticsMeasureIDs();
    if (addtlMeasureIDs.indexOf(measureID) < 0) {
        addtlMeasureIDs.push(measureID);
    }
    (window as any).additionalGAMeasureIDs = addtlMeasureIDs;
}

export function upsertGoogleAnalyticsMeasureID(
    measureID: string,
    configParams: AnalyticsConfig = configForPlayerAnalytics()
) {
    gtag("config", measureID, configParams);
}

/**
 * Load the Intercom script and initialize it.
 */
function loadIntercom() {
    anyWindow.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: intercomAppId,
        custom_launcher_selector: "#intercom_custom_launcher",
    };

    // This bizarre code is the Typescript-ified Intercom loading snippet
    // eslint-disable-next-line prefer-rest-params
    const intercom: any = () => intercom.c(arguments);
    intercom.q = [];
    intercom.c = (args: any) => intercom.q.push(args);
    anyWindow.Intercom = intercom;

    const loadIntercomScript = () => {
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://widget.intercom.io/widget/${intercomAppId}`;
        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode?.insertBefore(script, firstScript);
    };

    if (document.readyState === "complete") {
        loadIntercomScript();
    } else if (anyWindow.attachEvent !== undefined) {
        anyWindow.attachEvent("onload", loadIntercomScript);
    } else {
        window.addEventListener("load", loadIntercomScript, false);
    }
}

/**
 * Load Hubspot script and initialize it.
 */
function initializeHubspot() {
    // TODO handle case, on localhost, of hubspotAccountID being empty string
    // see indexeddb/src/location-common.ts
    const hubspotAccountID = getLocationSettings().hubspotAccountID;
    const hubspotScript = () => loadPageJavascript(`//js.hs-scripts.com/${hubspotAccountID}.js`);

    window.addEventListener("load", hubspotScript, false);

    // Make sure we don't track the user if they don't consent.
    onTrackingConsentChange((consent: boolean) => {
        _hsq.push(["doNotTrack", { track: consent }]);
    });
}

/**
 * Initialized Mixpanel through its `mixpanel-browser` package.
 */
function initializeMixpanel() {
    const mixpanelAPIToken = getLocationSettings().mixpanelAPIToken;
    mixpanel.init(mixpanelAPIToken);

    // Read Mixpanel distinct_id for users coming from marketing site
    const url = new URL(window.location.href);
    const mixpanelDistinctId = url.searchParams.get("mdid");
    if (mixpanelDistinctId !== null) {
        mixpanel.identify(mixpanelDistinctId);
        removeQueryStrings("mdid");
    }
}

/**
 * Load PartnerStack script into the script element and initialize it.
 */
function initializePartnerStack() {
    const partnerStackPublicKey = getLocationSettings().partnerStack.publicKey;

    anyWindow.growsumo =
        anyWindow.growsumo ??
        function (...args: any[]) {
            (anyWindow.growsumo.q = anyWindow.growsumo.q ?? []).push(...args);
        };

    const psScript = document.createElement("script");
    psScript.async = true;
    psScript.src = "https://js.partnerstack.com/v1/";
    psScript.type = "text/javascript";

    psScript.onload = anyWindow.onreadystatechange = function () {
        const rs = anyWindow.growsumo.readyState;
        if (rs !== "complete" && rs !== "loaded") return;
        try {
            anyWindow.growsumo._initialize(partnerStackPublicKey);
            if (typeof anyWindow.growsumoInit === "function") {
                anyWindow.growsumo.growsumoInit();
            }
        } catch (e: unknown) {
            logError("Unable to initialize growSumoInit", e);
        }
    };

    const prevScript = document.getElementsByTagName("script")[0];
    prevScript.parentNode?.insertBefore(psScript, prevScript);
}

// Global var to track if GTM script has been loaded.
let loadedGtmJS = false;

/**
 * Load Google Tag Manager script into the script element.
 */
function initializeGoogleTagManager() {
    if (loadedGtmJS) return;

    const gtmContainerId = getLocationSettings().gtmContainerID;
    const gtmJS = `https://www.googletagmanager.com/gtm.js?id=${gtmContainerId}&l=gtmLayer`;

    loadPageJavascript(gtmJS);
    loadedGtmJS = true;
}

/**
 * This function initializes all the analytics tools loading their scripts into the Script element.
 *
 * @param firebaseAnalyticsInstanceGetter
 */
export async function initializeAnalytics(firebaseAnalyticsInstanceGetter: () => FirebaseAnalyticsInstance) {
    if (routes.isPlayer() && !routes.isTemplatePlayer()) {
        // Elide all analytics in the player. We only want to support
        // custom Google Analytics properties here.
        const configParams = configForPlayerAnalytics();
        for (const measure of globalWindowGoogleAnalyticsMeasureIDs()) {
            upsertGoogleAnalyticsMeasureID(measure, configParams);
        }
    } else {
        // We don't want to mix Google Tag Manager and Firebase Analytics
        // events, so we'll give GTM its own data layer.
        (window as any).gtmLayer = (window as any).gtmLayer ?? [{ "gtm.start": new Date().getTime(), event: "gtm.js" }];

        // We are only doing this for side effects. Firebase Analytics is now loaded.
        // In the builder, we load Hubspot and mixpanel.
        withTrackingConsent(() => {
            firebaseAnalyticsInstance = firebaseAnalyticsInstanceGetter();
            initializeGoogleTagManager();
            initializePartnerStack();

            // We have a sandbox account for hubspot
            // So we don't need to be in prod
            initializeHubspot();
            koala.initKoala();
            initializePosthog();
            // To enable debug, Hotjar.init({debug: true});
            Hotjar.init();
            // TODO - reconsider use of isProduction,
            // as it is currently always true, even on dev
            loadIntercom();
            if (isProduction()) {
                initializeMixpanel();
            }
        });
    }
}

// Event names can be up to 40 characters long, may only contain alphanumeric
// characters and underscores ("_"), and must start with an alphabetic character.
// The "firebase_", "google_", and "ga_" prefixes are reserved and should not be used.
function sanitizeEventNameForFirebase(event: string): string {
    return event.replace(/ /g, "_").slice(0, 40);
}

type IdentifyUserInfo = {
    email?: string;
    name?: string;
    avatar?: string;
    verified?: boolean;

    // We can send other properties with identify calls
    [propertyName: string]: any;
};

export function builderDocURLWithCampaign(urlAsString: string): string {
    const url = new URL(urlAsString);
    url.searchParams.set("utm_source", "builder");
    url.searchParams.set("utm_medium", "builder_info_link");
    url.searchParams.set("utm_campaign", "BuilderDocLinks");
    url.searchParams.set("utm_content", "publish");
    return url.href;
}

function identifyInHubspot(userEmail: string, properties: Record<string, any> = {}) {
    _hsq.push([
        "identify",
        {
            email: userEmail,
            ...properties,
        },
    ]);

    // Track a page view so we actually identify this user
    _hsq.push(["trackPageView"]);
}

function identifyInMixPanel(userID: string, properties: { [key: string]: any } = {}) {
    // According to the latest Mixpanel docs, we are only
    // supposed to call identify again with new id, instead of alias.
    mixpanel.identify(userID);

    // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties
    const mixpanelProperties: { [key: string]: any } = {
        ...properties,
        // We could likely assume this but it makes reporting easier
        authenticated: true,
    };

    // We do not want to send PII to Mixpanel
    ["avatar", "email", "name"].forEach(prop => {
        if (prop in properties) {
            delete mixpanelProperties[prop];
        }
    });

    mixpanel.people.set(mixpanelProperties);
}

async function authenticateIntercomUser(): Promise<string | undefined> {
    let user_hash: string | undefined;

    try {
        const intercomAuthResponse = await getAppFacilities().callAuthCloudFunction("authenticateIntercom", {}, {});
        if (isResponseOK(intercomAuthResponse)) {
            const response = await intercomAuthResponse.json();
            user_hash = response.intercomUserHash;
            return user_hash;
        } else {
            const reason = intercomAuthResponse === undefined ? "Network error" : intercomAuthResponse.statusText;
            logError(`Could not authenticate Intercom operations: ${reason}`);
            return;
        }
    } catch (e: unknown) {
        logError(`Could not authenticate Intercom operations: ${e}`);
        return;
    }
}

async function identifyInIntercom(user_id: string, properties: { [key: string]: any } = {}): Promise<void> {
    const user_hash: string | undefined = await authenticateIntercomUser();
    if (user_hash === undefined) {
        logError("Unable to run identifyInIntercom");
        return;
    }
    const { avatar, ...additionalProperties } = properties;
    anyWindow.Intercom?.("update", {
        app_id: intercomAppId,
        user_id,
        user_hash,
        ...additionalProperties,
    });
}

async function updateUserAttributes(email: string, customAttributes: { [key: string]: any } = {}): Promise<void> {
    try {
        const updatedUserResponse = await getAppFacilities().callAuthCloudFunction("updateIntercomUser", {
            email,
            customAttributes,
        });
        if (isResponseOK(updatedUserResponse)) return;

        const reason = updatedUserResponse === undefined ? "Network error" : updatedUserResponse.statusText;
        logError(`Could not update Intercom user: ${reason}`);
        return;
    } catch (e: unknown) {
        logError(`Could not update Intercom user: ${e}`);
        return;
    }
}

export async function identifyUser(
    userID: string,
    userInfo: IdentifyUserInfo,
    customAttributes: { [key: string]: any } | undefined = undefined
): Promise<void> {
    // This code path was never used by the player, so it
    // really shouldn't ever do anything there.
    if (routes.isPlayer() && !routes.isTemplatePlayer()) return;
    withTrackingConsent(() => {
        firebaseAnalyticsInstance?.setUserId(userID);
        // This is for Google Tag Manager; the Firebase user ID is now available
        // as a Data Layer Variable.
        (window as any).gtmLayer?.push({ userID });

        if (userInfo.email !== undefined && userInfo.verified !== undefined) {
            identifyInHubspot(userInfo.email);
            koala.identifyKoalaUser(userID, userInfo);
            // https://posthog.com/docs/product-analytics/identify
            identifyPosthogUser(userID, { email: userInfo.email, name: userInfo.name, created_at: new Date() });
            void identifyInIntercom(userID, userInfo);
            Hotjar.identify(userID, {
                email: userInfo.email,
                is_verified: userInfo.verified,
            });
        }
        if (userInfo.email !== undefined && customAttributes !== undefined) {
            void updateUserAttributes(userInfo.email, customAttributes);
            void identifyInIntercom(userID, customAttributes);
        }
    });

    // TODO - reconsider use of isProduction,
    // as it is currently always true, even on dev
    if (isProduction()) {
        withTrackingConsent(() => {
            identifyInMixPanel(userID, userInfo);
        });
    }
}

/*
 * Attach additional properties to tools that support that.
 * Currently only Intercom
 */
export async function trackUserProperties(
    userAuthID: string,
    email: string,
    properties: Record<string, string | number | boolean | undefined>
): Promise<void> {
    if (routes.isPlayer() && !routes.isTemplatePlayer()) return;
    withTrackingConsent(() => {
        void identifyInIntercom(userAuthID, properties);
        identifyInHubspot(email, properties);
    });
}

// replace all functional pieces with firebase analytics and auth
function trackEventInFirebaseAnalytics(name: string, properties: object) {
    firebaseAnalyticsInstance?.logEvent(sanitizeEventNameForFirebase(name), properties);
}

function trackEventInIntercom<Name extends keyof AnalyticsEvents>(event: Name, properties: AnalyticsEvents[Name]) {
    if (!MarketingEvents.includes(event as any)) return;
    anyWindow.Intercom?.("trackEvent", event, properties);
}

function trackEventInMixPanel<Name extends keyof AnalyticsEvents>(event: Name, properties: AnalyticsEvents[Name]) {
    mixpanel.track(event, properties);
}

// Difference between this one and the trackEventInHubspot is that this one uses the new API that allows us to send the properties
async function trackCustomEventInHubspot<Name extends keyof AnalyticsEvents>(
    eventName: Name,
    properties: AnalyticsEvents[Name]
) {
    const event = eventName.replace(/ /g, "_");
    // TODO handle case, on localhost, of hubspotAccountID being empty string
    // see indexeddb/src/location-common.ts
    const hubspotAccountID = getLocationSettings().hubspotAccountID;
    const validatedEventName = `pe${hubspotAccountID}_${event}`;
    // We don't actually care about the body, but we have to drain it anyway.
    await getAppFacilities()
        .callAuthCloudFunction("sendHubspotCustomEvent", {
            eventName: validatedEventName,
            properties,
        })
        .then(r => r?.text());
}

function trackEventInHubspot<Name extends keyof AnalyticsEvents>(event: Name, options: AnalyticsEvents[Name]): void {
    if (!MarketingEvents.includes(event as any)) {
        return;
    }

    if (HubspotCustomEvents.includes(event as any)) {
        void trackCustomEventInHubspot(event, options);
    }

    _hsq.push(["trackEvent", { id: event }]);
}

function trackEventInGTM<Name extends keyof AnalyticsEvents>(event: Name, properties: AnalyticsEvents[Name]) {
    (window as any).gtmLayer?.push({ ...properties, event: sanitizeEventNameForFirebase(event) });
}

export function trackEvent<Name extends keyof AnalyticsEvents>(event: Name, options: AnalyticsEvents[Name]): void {
    const isStorybook = routes.isStorybook();
    if (isStorybook) {
        return;
    }

    const prod = isProduction();

    logInfo("ANALYTICS", event, options);

    if (routes.isPlayer() && !routes.isTemplatePlayer()) {
        // Note that we don't use Firebase Analytics in the player.
        // Instead we'll try to send to the user-configured analytics IDs.
        gtag("event", event, {
            ...options,
            send_to: globalWindowGoogleAnalyticsMeasureIDs(),
        });
    } else {
        withTrackingConsent(() => {
            trackEventInFirebaseAnalytics(event, options);
            trackEventInGTM(event, options);

            // We have a sandbox account for hubspot
            // So we don't need to be in prod
            trackEventInHubspot(event, options);
            koala.trackKoalaEvent(event, options);
            // https://posthog.com/docs/product-analytics/capture-events
            capturePosthogEvent(event, options);
            Hotjar.event(event);

            if (prod) {
                trackEventInMixPanel(event, options);
                trackEventInIntercom(event, options);
            }
        });
    }
}

const trackedEventOnce: Record<string, boolean> = {};
export function trackEventOnce<Name extends keyof AnalyticsEvents>(event: Name, options: AnalyticsEvents[Name]): void {
    if (trackedEventOnce[event] === undefined) {
        trackEvent(event, options);
        trackedEventOnce[event] = true;
    }
}

export function trackAppLogin(appID: string, appUserID: string): void {
    if (!routes.isPlayer()) return;

    setCurrentAppUserID(appUserID);
    trackEvent("login", { app_id: appID, app_user_id: appUserID });
}

export function trackPlayerEvent<Name extends keyof AppAnalyticsEvents>(
    event: Name,
    options: AppAnalyticsEvents[Name]
): void {
    if (!routes.isPlayer()) return;

    return trackEvent(event, options as unknown as any);
}

export function trackCustomPlayerEvent<Name extends keyof AppAnalyticsEvents>(
    event: Name,
    options: AppAnalyticsEvents[Name]
): void {
    if (globalWindowGoogleAnalyticsMeasureIDs().length === 0) return;

    return trackPlayerEvent(event, options);
}
