import type { BasePrimitiveValue } from "@glide/data-types";
import {
    type Icon,
    type AppDescription,
    type UserFeatures,
    type BuilderAction,
    type BuilderActionsForApp,
    type BuilderActionWithID,
    type ActionDescription,
    type ActionKind,
    type ClassOrArrayScreenDescription,
    type ComponentDescription,
    type FormScreenDescription,
    type LegacyPropertyDescription,
    type MutatingScreenKind,
    type PropertyDescription,
    type TabDescription,
    PropertyKind,
    type ConditionalActionNode,
    type ActionNode,
    type AutomationDetails,
    type AutomationRootNode,
    type FlowActionNode,
    type ActionOutputDescriptor,
    getActionProperty,
} from "@glide/app-description";
import type { AppKind } from "@glide/location-common";
import { defaultAppKind, getAppKind, getSourceMetadata } from "@glide/common-core/dist/js/components/SerializedApp";
import type { ComputationModel } from "@glide/computation-model-types";
import type { Owner, UserData, WebhookIntegrationWithID } from "@glide/common-core/dist/js/Database";
import {
    type TableName,
    areTableNamesEqual,
    type ColumnType,
    type ColumnTypeKind,
    type Description,
    type TableAndColumn,
    type TableGlideType,
    type TableRefGlideType,
    isNativeTable,
    sheetNameForTable,
    type Formula,
    type SchemaInspector,
} from "@glide/type-schema";
import type { EminenceFlags } from "@glide/billing-types";
import type { InputOutputTables, UpdateDescriptionFunc } from "@glide/common-core/dist/js/description";
import { getDocURL } from "@glide/common-core/dist/js/docUrl";
import {
    defined,
    mapRecord,
    panic,
    type ReadonlyDefaultMap,
    definedMap,
    hasOwnProperty,
} from "@glideapps/ts-necessities";
import { ArraySet, DefaultArrayMap, isArray, logError } from "@glide/support";
import type { PluginTierList, TriggerProps } from "@glide/plugins";
import { setUnionInto } from "collection-utils";
import type { SuperPropertySection } from "./property-sections";
import type { PropertySource } from "./property-source";
import { type ColumnFilterSpec, type GetAllowedColumnsFunction, getPrimitiveColumnsSpec } from "../column-filter-spec";

export interface AllowedDataEdits {
    readonly addRowToTable: ReadonlyDefaultMap<TableName, ReadonlySet<string>>;
    readonly setColumnsInRow: ReadonlyDefaultMap<TableName, ReadonlySet<string>>;
    readonly deleteRow: ArraySet<TableName>;
}

export interface MutableAllowedDataEdits extends AllowedDataEdits {
    readonly addRowToTable: DefaultArrayMap<TableName, Set<string>>;
    readonly setColumnsInRow: DefaultArrayMap<TableName, Set<string>>;
    readonly deleteRow: ArraySet<TableName>;
}

export function makeEmptyMutableAllowedDataEdits(): MutableAllowedDataEdits {
    return {
        addRowToTable: new DefaultArrayMap<TableName, Set<string>>(areTableNamesEqual, () => new Set()),
        setColumnsInRow: new DefaultArrayMap<TableName, Set<string>>(areTableNamesEqual, () => new Set()),
        deleteRow: new ArraySet<TableName>(areTableNamesEqual),
    };
}

export function mergeAllowedDataEdits(dst: MutableAllowedDataEdits, src: AllowedDataEdits | undefined): void {
    if (src === undefined) return;

    for (const [k, v] of src.addRowToTable) {
        setUnionInto(dst.addRowToTable.get(k), v);
    }
    for (const [k, v] of src.setColumnsInRow) {
        setUnionInto(dst.setColumnsInRow.get(k), v);
    }
    for (const tn of src.deleteRow) {
        dst.deleteRow.add(tn);
    }
}

export function convertEditedColumnsToAllowedDataEdits(editedColumns: EditedColumns): MutableAllowedDataEdits {
    const edits = makeEmptyMutableAllowedDataEdits();
    for (const [cn, , isAddRow, tn] of editedColumns) {
        if (isAddRow) {
            edits.addRowToTable.get(tn).add(cn);
        } else {
            edits.setColumnsInRow.get(tn).add(cn);
        }
    }
    return edits;
}

export function convertEditedColumnsToIndirect(editedColumns: EditedColumns): EditedColumns {
    return editedColumns.map(([cn, , isAddRow, tn]) => [cn, false, isAddRow, tn]);
}

export function updatesForUntypedProperty<T extends Description>(
    source: PropertySource,
    value: unknown | undefined
): Partial<T> {
    if (hasOwnProperty(source, "name")) {
        const updates: { [p: string]: PropertyDescription | undefined } = {};
        updates[source.name] = value as any;
        return updates as any;
    } else {
        return panic("Untyped updates not supported for indirect setters");
    }
}

export function updatesForProperty<T extends Description>(
    source: PropertySource,
    desc: Description,
    value: PropertyDescription | undefined,
    updater: InteractiveComponentConfiguratorContext | undefined
): Partial<T> {
    if (hasOwnProperty(source, "name")) {
        return updatesForUntypedProperty(source, value);
    } else {
        return source.update(desc, value, updater);
    }
}

export function updatesForPropertyValue<P, T extends Description>(
    kind: PropertyKind,
    source: PropertySource,
    desc: Description,
    value: P | undefined,
    updater: InteractiveComponentConfiguratorContext | undefined
): Partial<T> {
    return updatesForProperty(
        source,
        desc,
        definedMap(value, v => ({ kind, value: v })),
        updater
    );
}

export function isNamedPropertySource(source: PropertySource): source is { readonly name: string } {
    return hasOwnProperty(source, "name");
}

export function getPropertyDescription<P>(
    desc: Description,
    descr: PropertyDescriptor
): PropertyDescription | undefined {
    const source = descr.property;
    let value: LegacyPropertyDescription | undefined;
    if (isNamedPropertySource(source)) {
        value = (desc as any)[source.name] ?? source.defaultValue;
    } else {
        value = source.get(desc);
    }
    if (value === undefined) return undefined;

    const pdc = isMultiCasePropertyDescriptor(descr) ? defined(descr.cases[0]) : descr;
    // FIXME: Move this into description handler
    if (pdc.kind === PropertyKind.Formula || pdc.kind === PropertyKind.Transforms || pdc.kind === PropertyKind.Sorts) {
        // `P` kinda has to be `Formula` here.
        return { kind: pdc.kind, value: value as P };
    }
    if (typeof value === "object" && hasOwnProperty(value, "kind")) {
        return value as PropertyDescription;
    }
    if (pdc.kind === PropertyKind.Filter) {
        if (!hasOwnProperty(value, "operator")) {
            return panic("Filter property description is invalid");
        }
    } else if (pdc.kind === PropertyKind.Action) {
        if (isArray(value) && value.length === 0) return undefined;
        value = getActionProperty(value);
        if (value === undefined) {
            logError("Action property description is invalid", value);
            return undefined;
        }
    } else if (typeof value !== "string" && typeof value !== "boolean") {
        logError("Property description is neither string nor boolean", value);
        return undefined;
    }
    return { kind: pdc.kind, value };
}

export function getPropertyDescriptionValue<P>(desc: Description, descr: PropertyDescriptor): P | undefined;
export function getPropertyDescriptionValue<P, U>(
    desc: Description,
    descr: PropertyDescriptor,
    f: (v: LegacyPropertyDescription) => U | undefined
): U | undefined;
export function getPropertyDescriptionValue<P, U>(
    desc: Description,
    descr: PropertyDescriptor,
    f?: (v: LegacyPropertyDescription) => U | undefined
): P | U | undefined {
    const pd = getPropertyDescription<P>(desc, descr);
    if (pd === undefined) return undefined;
    if (f === undefined) return pd.value as P;
    return f(pd);
}

export type PropertyLabel = string | ((i: number | undefined) => string);

export interface Subcomponent {
    readonly name: string;
    readonly important: boolean;
    readonly order?: number; // defaults to 0
}

export type ToPropertyDescriptionOrUndefined<T> = { readonly [K in keyof T]: PropertyDescription | undefined };

export type WhenPredicate<TDesc, TRootDesc> = (
    desc: ToPropertyDescriptionOrUndefined<TDesc>,
    rootDesc: ToPropertyDescriptionOrUndefined<TRootDesc>,
    tables: InputOutputTables | undefined,
    adc: AppDescriptionContext,
    // `undefined` means the component isn't on a screen (yet).  In that case
    // the predicate should not exclude the property based on that flag.
    // We're only using this for "title style" in various collection components.
    // We are probably going to get rid of this.
    isFirstComponent: boolean | undefined
) => boolean;

interface PropertyDescriptorBase {
    readonly property: PropertySource;
    readonly label: PropertyLabel;
    readonly helpText?: string;
    readonly accessoryTo?: PropertyDescriptorBase;
    readonly section: SuperPropertySection;
    readonly subcomponent?: Subcomponent;
    // This is for properties that write data, but are not otherwise marked as
    // such.  Regular column properties that have `isEditedInApp` set don't
    // need this.
    readonly writesToColumn?: TableAndColumn;
    // All configurators will be sorted within their section.  The ones with a
    // position come first, sorted by the position.  After that come all the
    // rest.
    readonly position?: number;
    readonly isSearchable?: boolean; // defaults to `true`

    // This controls whether we show this property or not.
    // Before having this here, we used to just not construct the property descriptor.
    // The problem is that Array properties construct a single descriptor for all items
    // so we can't decide to show a property in one item and not show it in another.
    readonly when?: WhenPredicate<Description, Description>;

    // If a property is builder only we don't include it in the published app.
    // Defaults to `false`.
    readonly builderOnly?: boolean;

    readonly llmDescription?: string;
}

interface ConfigurablePropertyDescriptor extends PropertyDescriptorBase {
    // This must only be set to `true` if the property value doesn't actually
    // reside in the corresponding description, and will be rewritten at its
    // actual location, such as the source sheet for the Show New Screen action.
    readonly doNotRewrite?: boolean;
}

export type ColumnTypePredicate = ((t: ColumnType) => boolean) | ColumnTypeKind;

export type GetAllowedTablesFunction = (
    desc: Description,
    schema: SchemaInspector,
    componentTables: InputOutputTables | undefined
) => ReadonlyArray<TableGlideType>;

export interface PropertyTable {
    readonly table: TableGlideType;
    // If this is `true`, then it's direct, not indirect.
    //
    // `true` is when whatever values are gotten from the table are actually
    // in the screen's context. That has implications about what kinds of
    // writes are allowed or not. For example, you can have a Checklist that
    // displays "this row", in which case the checkbox is actually writing to
    // the current row, not to some other table. And if that Checklist is in a
    // Form screen, it means that when adding new rows it should be allowed to
    // set that Checklist column's value, but if it's not in a Form screen, it
    // should be allowed to set that value when updating rows.
    readonly inScreenContext: boolean;
}

interface IndirectTableGetter {
    getIndirectTable?(
        tables: InputOutputTables | undefined,
        rootDesc: Description,
        desc: Description,
        schema: SchemaInspector,
        actionNodesInScope: readonly ActionNodeInScope[]
    ): PropertyTable | undefined;
}

export interface PropertyDescriptorCase {
    readonly kind: PropertyKind;
}

// `"if-writable"` means the column is edited in the app if it's writable, but
// it's always in the input table.
//
// `"even-if-not-writable"` means the column doesn't even need to be writable
// to be counted as writable, and will always come from the output table.
// FIXME: This is a hack that we're using in the Link Picker, which is
// required because the column that's being edited is not the column that the
// user picks, which also means that the component handler has to do
// additional manual stuff to make things work, such as report its edited
// columns.  The proper solution would be to let the column report that it's
// actually a proxy for another column.
export type IsEditedInApp = boolean | "if-writable" | "even-if-not-writable";

// `"preferred"` means assign a full row (usually "this item") if possible.
// `true` means don't do it by default (unless it's the only option) but allow
// it.
export type AllowFullRow = boolean | "preferred";

export interface ColumnPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.Column;

    readonly required: boolean;
    readonly editable: boolean;
    readonly searchable: boolean;
    readonly emptyByDefault?: boolean; // defaults to `false`
    readonly isDefaultCaption?: boolean;
    readonly isLegacy?: boolean;
    readonly isEditedInApp?: IsEditedInApp;
    readonly isAddedInApp?: boolean;
    readonly applyFormat?: boolean;
    // ##forFilteringRows means that only columns are allowed that we can
    // filter or query by.  In "regular" tables it doesn't have an effect - we
    // can filter by any column.  In queryable tables it will only allow
    // columns that the data source can query by.
    readonly forFilteringRows?: boolean;
    readonly forSortingRows?: boolean;
    // The value must be already present, i.e. binding this to an output
    // column without a pre-existing input is invalid.  We use this for
    // the default value properties.
    readonly needsExistingValue?: boolean;
    readonly allowUserProfileColumns?: boolean; // defaults to `false`
    // In some cases we don't want to allow picking columns from prior steps
    // For example: in the increment action, you should only be able to pick
    // a column from the selected row (via getIndirectTable)
    readonly disallowPriorSteps?: boolean; // defaults to `false`
    readonly allowFullRow?: (t: TableGlideType, schema: SchemaInspector) => AllowFullRow; // defaults to `false`
    readonly columnFilter: ColumnFilterSpec;
    readonly preferredNames?: ReadonlyArray<string>;
    // This one is currently only used for the caption property, to discourage
    // Glide picking the same property as it picked for title or description.
    // I think this should go away, however, and we should by default not pick
    // a column that's used for another property.
    readonly ignoreColumnsFromProperties?: readonly string[];
    readonly preferredType?: ColumnTypePredicate;
    readonly emptyWarningText?: string;
    readonly warningText?: string;
    getEmptyCase?(tables: InputOutputTables): { label: string; linkURL: string };
}

export interface ColumnPropertyDescriptor extends ConfigurablePropertyDescriptor, ColumnPropertyDescriptorCase {}

export interface TablePropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Table;

    readonly required: boolean;
    readonly isDefaultCaption?: boolean;
    getAllowedTables: GetAllowedTablesFunction;
}

export interface TablePropertyDescriptor extends ConfigurablePropertyDescriptor, TablePropertyDescriptorCase {}

export interface TableViewPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.TableView;

    readonly required: boolean;
    readonly columnFilter: ColumnFilterSpec;
    readonly getAllowedTables: GetAllowedTablesFunction;
}

interface TableViewPropertyDescriptor extends ConfigurablePropertyDescriptor, TableViewPropertyDescriptorCase {}

export interface JSONPathPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.JSONPath;

    // JSONPath shows suggestions based on JSON we want to query.
    // that JSON comes from another property, so here we specify
    // that property name.
    readonly valueFromProperty: string;
}

interface JSONPathPropertyDescriptor extends ConfigurablePropertyDescriptor, JSONPathPropertyDescriptorCase {}

export interface InlineComputationPropertyOptions {
    isCaption?: string;
    defaultValue?: string;
    withLabel?: boolean;
    fullWidth?: boolean;
    allowUpload?: boolean;
}

export interface InlineComputationPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.InlineComputation;

    readonly required: boolean;
    readonly options: InlineComputationPropertyOptions;
    readonly getAllowedColumns: GetAllowedColumnsFunction;
}

export interface InlineComputationPropertyDescriptor
    extends ConfigurablePropertyDescriptor,
        InlineComputationPropertyDescriptorCase {}

export interface EnumPropertyCase<P extends BasePrimitiveValue> {
    readonly value: P;
    readonly label: string;
    readonly icon?: string;

    // This is currently used by the Favorites overlay button to indicate that
    // it's writing to the "Is Favorited?" column.
    readonly columnWritten?: string;
}

export type EnumVisual = "dropdown" | "small-images" | "large-images" | "slider" | "radio" | "text";

// `getIndirectTable` is only used if some of the cases have a `columnWritten`.
export interface EnumPropertyDescriptorCase<P extends BasePrimitiveValue>
    extends PropertyDescriptorCase,
        IndirectTableGetter {
    readonly kind: PropertyKind.Enum;

    readonly menuLabel: string;
    readonly cases: readonly EnumPropertyCase<P>[] | (() => Promise<readonly EnumPropertyCase<P>[]>);
    // If this is specified, the configurator will work even if
    // the value is `undefined`.
    readonly defaultCaseValue?: P;
    // This is used when the values are still being fetched for async properties.
    readonly defaultDisplayLabel?: string;
    readonly visual: EnumVisual;
    // Should be `true` if the value of this enum influences anything
    // else about the component descriptor.  The list layout in the
    // Inline List is an example.
    readonly changesDescriptor?: boolean;
}

export interface EnumPropertyDescriptor<P extends BasePrimitiveValue>
    extends ConfigurablePropertyDescriptor,
        EnumPropertyDescriptorCase<P> {}

export interface StringPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.String;

    readonly required: boolean;
    readonly placeholder: string;
    readonly isImageURL?: boolean;
    // `undefined` means it's not the caption.  A string means
    // it's the caption, and that string is the default caption.
    readonly isCaption?: string;
    readonly isMultiLine?: boolean;
    readonly menuLabel?: string;
    readonly defaultValue?: string;
    readonly syntaxMode?: string;
    readonly emptyWarningText?: string;
    readonly checkForValidJSON?: boolean;
    readonly showInlineWarning?: boolean;
    readonly isSecret?: boolean;
}

export interface SecretPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Secret;
    readonly required: boolean;
    readonly placeholder: string;
}

export interface StringPropertyDescriptor extends ConfigurablePropertyDescriptor, StringPropertyDescriptorCase {}

export interface SecretPropertyDescriptor extends ConfigurablePropertyDescriptor, SecretPropertyDescriptorCase {}

export enum NumberPropertyStyle {
    Entry,
    Stepper,
}

export enum RequiredKind {
    Required,
    NotRequiredDefaultPresent,
    NotRequiredDefaultMissing,
}

export interface NumberPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Number;

    readonly required: RequiredKind;
    readonly placeholder: string;
    readonly style?: NumberPropertyStyle;
    readonly defaultValue: number;
    readonly minValue?: number;
    // `undefined` means no limit
    readonly getMaxValue?: (
        componentTables: InputOutputTables,
        desc: Description,
        schema: SchemaInspector
    ) => number | undefined;
}

export interface NumberPropertyDescriptor extends ConfigurablePropertyDescriptor, NumberPropertyDescriptorCase {}

export interface SwitchPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.Switch;

    readonly defaultValue?: boolean;
    readonly withCondition?: boolean;
    readonly specPrefix?: string;
    readonly showContextAsContainingScreen?: boolean;

    readonly icon?: string;
    readonly conditionPrompt?: string;

    // If this is set, then the title should be configurable
    readonly defaultTitle?: string;
    readonly helpText?: string;
}

export interface SwitchPropertyDescriptor extends ConfigurablePropertyDescriptor, SwitchPropertyDescriptorCase {}

export interface ConstantPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Constant;

    readonly value: string;
}

interface ConstantPropertyDescriptor extends PropertyDescriptorBase, ConstantPropertyDescriptorCase {
    readonly doNotRewrite: true;
}

export interface SpecialValuePropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.SpecialValue;
    // If this is set, only "Clear Column" can be used
    readonly excludePrimitiveValues: boolean;
    // We've added this just for Numero.
    readonly withActionSource: boolean;
    readonly withClearColumn: boolean;
}

interface SpecialValuePropertyDescriptor extends ConfigurablePropertyDescriptor, SpecialValuePropertyDescriptorCase {}

export interface PaymentMethodPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.PaymentMethod;
}

interface PaymentMethodPropertyDescriptor extends ConfigurablePropertyDescriptor, PaymentMethodPropertyDescriptorCase {}

export interface ScreenPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Screen;
}

interface ScreenPropertyDescriptor extends ConfigurablePropertyDescriptor, ScreenPropertyDescriptorCase {}

export interface FilterPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.Filter;
}

export function getFilterTable(
    descr: FilterPropertyDescriptorCase,
    tables: InputOutputTables,
    rootDesc: Description,
    desc: Description,
    schema: SchemaInspector,
    actionNodesInScope: readonly ActionNodeInScope[]
): TableGlideType | undefined {
    return (
        definedMap(descr.getIndirectTable, f => f(tables, rootDesc, desc, schema, actionNodesInScope)?.table) ??
        tables.input
    );
}

interface FilterPropertyDescriptor extends ConfigurablePropertyDescriptor, FilterPropertyDescriptorCase {}

export interface FormulaPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Formula;
}

interface FormulaPropertyDescriptor extends ConfigurablePropertyDescriptor, FormulaPropertyDescriptorCase {}

export interface TransformsPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.Transforms;

    readonly addText: string;
    readonly description: string;
    readonly allowContextTable: boolean;
    readonly allowLHSUserProfileColumns: boolean;
    readonly allowSpecialValues: boolean;
    // ##forFilteringRows:
    // When filtering rows in a queryable table, we can only use columns that
    // we can push down into a query.  But when evaluating a condition on a
    // row we have in memory, anything goes.  Relatedly, when filtering rows
    // in a "regular" table, we don't allow columns that require queries to
    // evaluate, because that might cause thousands of queries to run for a
    // single filter.
    readonly forFilteringRows: boolean;
    readonly specPrefix?: string;
    readonly withContainingScreen: boolean;
}

export interface TransformsPropertyDescriptor
    extends ConfigurablePropertyDescriptor,
        TransformsPropertyDescriptorCase {}

export interface SortsPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.Sorts;
}

interface SortsPropertyDescriptor extends ConfigurablePropertyDescriptor, SortsPropertyDescriptorCase {}

export enum ArrayPropertyStyle {
    Default,
    // For these two, `properties` must have two items: first the
    // key/caption/title, then the value.
    KeyValue,
    ActionArray,
    Card,
    // To use Popup, you'll need to set the headerSelector and dataSelector.
    Popup,
}

export interface ArrayPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Array;

    readonly properties: readonly PropertyDescriptor[];
    readonly allowEmpty: boolean;
    readonly addItemLabels: readonly string[];
    readonly numDefaultItems?: number;
    readonly maxItems?: number;
    readonly specialItems?: number;
    readonly defaultValue?: boolean | readonly Description[];
    readonly allowReorder: boolean;
    readonly style: ArrayPropertyStyle;
    readonly addItem?: (prev: readonly Description[]) => readonly Description[];
    // This would be the new API for popup header display
    readonly getHeaderDisplay?: (
        desc: Description | undefined,
        rootDesc: Description | undefined,
        tables: InputOutputTables | undefined,
        adc: AppDescriptionContext
    ) => string;
    // ##popupConfiguratorSelectors
    // In order of priority, what's the property that we should select. This selects by display name.
    readonly dataSelectors?: string[];
}

interface ArrayPropertyDescriptor extends ConfigurablePropertyDescriptor, ArrayPropertyDescriptorCase {}

export interface ZapPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Zap;
}

export interface ZapPropertyDescriptor extends ConfigurablePropertyDescriptor, ZapPropertyDescriptorCase {}

export interface WebhookPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Webhook;
}

interface WebhookPropertyDescriptor extends ConfigurablePropertyDescriptor, WebhookPropertyDescriptorCase {}

export interface IconPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Icon;

    readonly defaultIcon?: Icon;
}

export interface IconPropertyDescriptor extends ConfigurablePropertyDescriptor, IconPropertyDescriptorCase {}

export interface EmojiPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Emoji;

    readonly defaultEmoji?: string;
}

interface EmojiPropertyDescriptor extends ConfigurablePropertyDescriptor, EmojiPropertyDescriptorCase {}

export interface ActionPropertyDescriptorCase extends PropertyDescriptorCase, IndirectTableGetter {
    readonly kind: PropertyKind.Action;

    readonly kinds: readonly ActionKind[];
    readonly defaultAction?: boolean | ((table: TableGlideType, desc: Description) => ActionDescription | undefined);
    // This is only used for ##inlineListDefaultAction.
    readonly defaultActionForUndefined?: boolean;
    readonly required?: boolean;
    readonly withCondition?: boolean;
    readonly conditionPrompt?: string;
}

export interface ActionPropertyDescriptor extends ConfigurablePropertyDescriptor, ActionPropertyDescriptorCase {}

/**
 * Most actions are available in both apps and automations. Some actions need
 * apps, for example navigation or actions that launch other apps on the user
 * device (e.g. compose an email).  Other actions are only available in
 * automations, in particular computed columns.
 */
export interface ActionAvailability {
    readonly apps: boolean;
    readonly automations: boolean;
}

export interface CompoundActionPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.CompoundAction;
    readonly allowPicking?: ActionAvailability;
}

interface CompoundActionPropertyDescriptor
    extends ConfigurablePropertyDescriptor,
        CompoundActionPropertyDescriptorCase {}

// This isn't really a property, since it doesn't store anything
export interface ConfigurationButtonPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.ConfigurationButton;
    readonly explanation: string | undefined;
    readonly buttonTitle: string;
    readonly onClick: (
        update: UpdateDescriptionFunc,
        tables: InputOutputTables,
        iccc: InteractiveComponentConfiguratorContext
    ) => void;
}

interface ConfigurationButtonPropertyDescriptor
    extends ConfigurablePropertyDescriptor,
        ConfigurationButtonPropertyDescriptorCase {}

// This isn't really a property, since it doesn't store anything
export interface WarningPropertyDescriptorCase extends PropertyDescriptorCase {
    readonly kind: PropertyKind.Warning;
    readonly text: string;
}

interface WarningPropertyDescriptor extends ConfigurablePropertyDescriptor, WarningPropertyDescriptorCase {}

export function getIndirectTableForProperty(
    descr: IndirectTableGetter,
    tables: InputOutputTables,
    rootDesc: Description,
    desc: Description,
    schema: SchemaInspector,
    actionNodesInScope: readonly ActionNodeInScope[]
): PropertyTable | undefined {
    if (descr.getIndirectTable !== undefined) {
        return descr.getIndirectTable(tables, rootDesc, desc, schema, actionNodesInScope);
    } else {
        return { table: tables.input, inScreenContext: true };
    }
}

export function shouldUseInputContextForTransform(mutatingScreenKind: MutatingScreenKind | undefined): boolean {
    // In a regular detail screen, the containing screen context is
    // the screen's context.  In mutating screens we have the input
    // and the output context.  We pick the output context as the
    // containing screen context.  Ideally we'd support both.
    return mutatingScreenKind === undefined;
}

export enum PropertyConfiguratorKind {
    CustomAIComponentPrompt = "custom-ai-component-prompt",
    ComputationAction = "computation-action",
}

export interface PropertyVisitor {
    visitFormula(formula: Formula): void;
}

export interface MultiCasePropertyDescriptor extends ConfigurablePropertyDescriptor {
    /**
     * If this is given, then this is the configurator that will be used.  If
     * not, `makePropertyConfig` will figure out which one to use based on the
     * `cases` given.  This is potentially a new way of making property
     * descriptors.  Right now we have this complicated code in
     * `makePropertyConfig` that makes it hard to understand which UI will
     * actually be used for which property.  But in most (all?) cases we know
     * which UI we want, so why not just explicitly specify it?
     */
    readonly configuratorKind?: PropertyConfiguratorKind;
    readonly visitProperty?: (rootDesc: Description, visitor: PropertyVisitor) => void;
    readonly cases: ReadonlyArray<PropertyDescriptorCase>;
}

export function isMultiCasePropertyDescriptor(descr: PropertyDescriptor): descr is MultiCasePropertyDescriptor {
    return (descr as any).kind === undefined;
}

export function getPropertyDescriptorCases(descr: PropertyDescriptor): readonly PropertyDescriptorCase[] {
    if (isMultiCasePropertyDescriptor(descr)) {
        return descr.cases;
    } else {
        return [descr];
    }
}

export type PropertyDescriptor =
    | ColumnPropertyDescriptor
    | TablePropertyDescriptor
    | EnumPropertyDescriptor<any>
    | StringPropertyDescriptor
    | SecretPropertyDescriptor
    | NumberPropertyDescriptor
    | SwitchPropertyDescriptor
    | ConstantPropertyDescriptor
    | MultiCasePropertyDescriptor
    | SpecialValuePropertyDescriptor
    | PaymentMethodPropertyDescriptor
    | ScreenPropertyDescriptor
    | FilterPropertyDescriptor
    | FormulaPropertyDescriptor
    | TransformsPropertyDescriptor
    | SortsPropertyDescriptor
    | ArrayPropertyDescriptor
    | ZapPropertyDescriptor
    | WebhookPropertyDescriptor
    | IconPropertyDescriptor
    | EmojiPropertyDescriptor
    | ActionPropertyDescriptor
    | CompoundActionPropertyDescriptor
    | ConfigurationButtonPropertyDescriptor
    | WarningPropertyDescriptor
    | TableViewPropertyDescriptor
    | JSONPathPropertyDescriptor
    | InlineComputationPropertyDescriptor;

export type PropertyTableGetter = (
    componentTables: InputOutputTables | undefined,
    rootDesc: Description,
    desc: Description,
    schema: SchemaInspector,
    actionNodesInScope: readonly ActionNodeInScope[]
) => PropertyTable | undefined;

// "optional" is ignored.  It's only here to do `flag ? "required" : "optional"`
export type StringyColumnPropertyFlag =
    | "required"
    | "optional"
    | "editable"
    | "searchable"
    | "edited-in-app"
    | "empty-by-default";

export function makeStringyColumnPropertyDescriptor(
    name: string,
    label: string,
    flags: readonly StringyColumnPropertyFlag[],
    section: SuperPropertySection,
    getPropertyTable: PropertyTableGetter | undefined,
    preferredNames: ReadonlyArray<string> | undefined,
    ignoreColumnsFromProperties: readonly string[] = [],
    preferredType: ColumnTypePredicate = "string"
): PropertyDescriptor {
    const required = flags.indexOf("required") >= 0;
    const editable = flags.indexOf("editable") >= 0;
    const searchable = flags.indexOf("searchable") >= 0;
    const isEditedInApp = flags.indexOf("edited-in-app") >= 0;
    const emptyByDefault = flags.indexOf("empty-by-default") >= 0;
    return {
        kind: PropertyKind.Column,
        property: { name },
        label,
        required,
        editable,
        searchable,
        isEditedInApp,
        emptyByDefault,
        getIndirectTable: getPropertyTable,
        columnFilter: getPrimitiveColumnsSpec,
        preferredNames,
        ignoreColumnsFromProperties,
        preferredType,
        section,
    };
}

export interface ComponentDescriptor {
    readonly name: string;
    readonly description: string;
    readonly img: string;
    readonly group: string;
    readonly isLegacy?: boolean;
    readonly isHidden?: boolean;
    readonly needsInputContext?: boolean; // default is `false`
    readonly properties: ReadonlyArray<PropertyDescriptor>;
    readonly keywords?: ReadonlyArray<string>;
    readonly helpUrl: string;
    readonly specialCaseDescriptor?: ComponentSpecialCaseDescriptor;
    readonly quotaWarning?: string;
    // Does this component let the user also configure the screen title?
    readonly configuresScreenTitle?: boolean; // defaults to `false`
}

export interface AppDescriptionContext extends SchemaInspector {
    readonly appID: string;
    readonly appKind: AppKind;
    readonly appDescription: AppDescription | undefined;
    readonly builderActions: BuilderActionsForApp;
    readonly userFeatures: UserFeatures;
    readonly eminenceFlags: EminenceFlags;
    readonly webhookIntegrations: readonly WebhookIntegrationWithID[];
    readonly builderComputationModel: ComputationModel | undefined;
    getBuilderAction(id: string): BuilderAction | undefined;
    getBuilderActionsForTable(table: TableName): BuilderActionWithID[];

    getIsRewritingComponentConfiguratorContext(): this is RewritingComponentConfiguratorContext;
}

export function isExistingAppDescriptionContext(adc: AppDescriptionContext): adc is ExistingAppDescriptionContext {
    return adc.appDescription !== undefined;
}

export function isNativeTableInAppDescriptionContext(adc: AppDescriptionContext, table: TableGlideType): boolean {
    return definedMap(adc.appDescription, a => isNativeTable(getSourceMetadata(a), table)) ?? false;
}

export function getAppKindFromAppDescriptionContext(adc: AppDescriptionContext): AppKind {
    return definedMap(adc.appDescription, getAppKind) ?? defaultAppKind;
}

export interface ExistingAppDescriptionContext extends AppDescriptionContext {
    readonly appDescription: AppDescription;
}

export interface RewritingComponentConfiguratorContext extends AppDescriptionContext {
    // Returns whether the screen was added successfully/is available
    requireScreen(screenName: string): boolean;
    requireMenuScreen(menuID: string): boolean;
}

export interface PasteboardState {
    readonly components: readonly ComponentDescription[];
    readonly appKind: AppKind;
}

export interface AppUpdater {
    readonly appID: string;
    setFreeScreenTable(
        screenName: string,
        fetchesData: boolean,
        isDetailScreen: boolean | undefined,
        tableRef: TableRefGlideType,
        rewriteScreen: boolean,
        isRootScreen: boolean
    ): void;
    demandRowIDColumn(tableName: TableName, explanation: string): void;
    updateTabDescription(tabScreenName: string, updates: Partial<TabDescription>): void;
}

export enum NewFreeScreenStyle {
    Empty,
    WithComponents,
    ForEasyTabConfiguration,
}

export interface InteractiveComponentConfiguratorContext extends RewritingComponentConfiguratorContext, AppUpdater {
    readonly appDescription: AppDescription;
    readonly user: UserData | undefined;
    readonly owner: Owner | undefined;
    readonly pasteboard: PasteboardState;
    readonly isInteractiveComponentConfiguratorContext: true;

    addFormScreen(screensCreated: Set<string>, makeScreen: () => FormScreenDescription): string;
    addFreeScreen(screensCreated: Set<string>, makeScreen: () => ClassOrArrayScreenDescription): string;
    addFreeScreenFromTable(
        table: TableGlideType,
        isRootScreen: boolean,
        style: NewFreeScreenStyle,
        componentTitle: string | undefined
    ): string | undefined;

    // Returns the index of the first tab added
    addTabTemplate(templateAppDescription: AppDescription, keepScreenNames: ReadonlySet<string>): Promise<number>;

    makeAction(
        actionKind: ActionKind,
        tables: InputOutputTables,
        mutatingScreenKind: MutatingScreenKind | undefined
    ): ActionDescription | undefined;
    // This will also add the form screen to the app description.
    makeFormActionForTables(tables: InputOutputTables): ActionDescription | undefined;

    makeInlineListForEasyTabConfiguration(
        table: TableGlideType,
        mutatingScreenKind: MutatingScreenKind | undefined,
        specialCaseDescriptor: ComponentSpecialCaseDescriptor | undefined,
        componentTitle: string | undefined
    ): ComponentDescription | undefined;

    saveBuilderAction(
        actionID: string | undefined,
        tableName: TableName | undefined,
        title: string | undefined,
        description: string | undefined,
        action: ConditionalActionNode | FlowActionNode | AutomationRootNode,
        automation: AutomationDetails | undefined
    ): Promise<string>;
    deleteBuilderAction(actionID: string): Promise<string | undefined>;

    makeDefaultCompoundAction(table: TableGlideType, firstAction: ActionDescription | undefined): Promise<string>;

    makeDefaultManualAction(table: TableGlideType, firstAction: ActionDescription | undefined): Promise<string>;

    makeDefaultScheduledAction(table: TableGlideType, firstAction: ActionDescription | undefined): Promise<string>;
    /**
     * If the config ID is `undefined` then this will add a new plugin config
     * for this plugin, if one doesn't exist.
     */
    makeDefaultPluginTriggerAutomation(
        table: TableGlideType,
        pluginID: string,
        pluginConfigID: string | undefined,
        trigger: TriggerProps
    ): Promise<string | undefined>;

    isComponentNew(descriptor: ComponentDescriptor | ComponentSpecialCaseDescriptor): boolean;

    upgradeAppToPro(source: string): void;

    pushUndoState(): void;
}

export function promoteAppDescriptionContext(
    adc: AppDescriptionContext
): InteractiveComponentConfiguratorContext | undefined {
    if (!hasOwnProperty(adc, "isInteractiveComponentConfiguratorContext")) return undefined;
    if (adc.isInteractiveComponentConfiguratorContext !== true) return undefined;
    return adc as InteractiveComponentConfiguratorContext;
}

export interface ComponentErrorAndLink {
    readonly errorMessage: string;
    readonly linkMessage: string;
    readonly linkURL: string;
}

export interface ComponentSpecialCaseDescriptor {
    readonly name: string;
    readonly analyticsName?: string;
    readonly description: string;
    readonly img?: string;
    readonly group: string;
    readonly isNew?: boolean;
    readonly isBeta?: boolean;
    readonly tier?: PluginTierList;
    readonly keywords?: readonly string[];
    readonly needsInputContext?: boolean;
    readonly appKinds: AppKind | "both";
    readonly getIsCurrent?: (d: ComponentDescription) => boolean;
    readonly setAsCurrent?: (
        desc: ComponentDescription,
        tables: InputOutputTables | undefined,
        itemTable: TableGlideType | undefined,
        iccc: AppDescriptionContext,
        mutatingScreenKind: MutatingScreenKind | undefined
    ) => ComponentDescription;

    // Marking a component/special case as legacy means we will not show it in the components panel
    readonly isLegacy?: boolean;
}

export enum ComponentPrerequisite {
    StripeConnect,
}

export interface ActionsRecord {
    readonly actions: readonly ActionDescription[];
    readonly [name: string]: readonly ActionDescription[];
}

export function mapActionsRecord(
    actions: ActionsRecord,
    f: (a: readonly ActionDescription[]) => readonly ActionDescription[]
): ActionsRecord {
    return mapRecord(actions, f) as ActionsRecord;
}

// [column name, edits in screen context (vs in indirect table), is Add Row, table name]
export type EditedColumn = readonly [
    columnName: string,
    editsInScreenContext: boolean,
    isAddRow: boolean,
    tableName: TableName
];
export type EditedColumns = readonly EditedColumn[];

export interface EditedColumnsAndTables {
    readonly editedColumns: EditedColumns;
    readonly deletedTables: readonly TableName[];
}

export const emptyEditedColumnsAndTables: EditedColumnsAndTables = { editedColumns: [], deletedTables: [] };

export function combineEditedColumnsAndTables(
    e1: EditedColumnsAndTables,
    e2: EditedColumnsAndTables | undefined
): EditedColumnsAndTables {
    if (e2 === undefined) return e1;
    return {
        editedColumns: [...e1.editedColumns, ...e2.editedColumns],
        deletedTables: [...e1.deletedTables, ...e2.deletedTables],
    };
}

export function componentErrorAndLinkForReference(
    table: TableGlideType,
    withMultiRelations: boolean
): ComponentErrorAndLink {
    return {
        errorMessage: `The "${sheetNameForTable(table)}" sheet doesn't have a ${
            withMultiRelations ? "" : "single-"
        }relation column.`,
        linkMessage: "Learn how to add one",
        linkURL: getDocURL("addRelationColumn"),
    };
}

export interface ActionNodeInScope {
    readonly node: ActionNode;
    readonly scopeID: string;
    readonly outputs: readonly ActionOutputDescriptor[];
    // The keys of the nodes whose outputs are available to this node. Top to bottom (root to leaf).
    readonly previousNodeKeys: readonly string[];
}
