import type { DataPlotRowData } from "@glide/fluent-components/dist/js/base-components";
import {
    DataPlotRollupKind,
    TimeSeriesInterval,
    TimeSeriesRelativeDuration,
} from "@glide/fluent-components/dist/js/fluent-components";
import { assertNever } from "@glideapps/ts-necessities";
import {
    eachDayOfInterval,
    eachMonthOfInterval,
    eachHourOfInterval,
    eachQuarterOfInterval,
    endOfHour,
    startOfHour,
    endOfDay,
    startOfDay,
    endOfMonth,
    endOfQuarter,
    startOfMonth,
    startOfQuarter,
} from "date-fns";
type TimeBucket = {
    start: Date;
    end: Date;
    name: string; // Formatted name for display
};

// FIXME: The rhs of the conditional these get passed to are somehow
// observed by the query, so something needs to be done to prevent
// re-fetching that is better than moving this here for use on initial load.
const now = new Date();

export function getDefaultIntervalFromDuration(duration: TimeSeriesRelativeDuration | "All"): TimeSeriesInterval {
    switch (duration) {
        case TimeSeriesRelativeDuration.Last7Days:
            return TimeSeriesInterval.Day;
        case TimeSeriesRelativeDuration.Last30Days:
            return TimeSeriesInterval.Day;
        case TimeSeriesRelativeDuration.Last90Days:
            return TimeSeriesInterval.Day;
        case TimeSeriesRelativeDuration.Last12Months:
            return TimeSeriesInterval.Month;
        case TimeSeriesRelativeDuration.Last24Hours:
            return TimeSeriesInterval.Hour;
        case "All":
            return TimeSeriesInterval.Day;
        default:
            assertNever(duration);
    }
}

function formatBucketLabel(interval: TimeSeriesInterval, date: Date): string {
    switch (interval) {
        case TimeSeriesInterval.Hour:
            return date.toLocaleString(undefined, { hour: "numeric", minute: "numeric" });
        case TimeSeriesInterval.Day:
            return date.toLocaleDateString(undefined, { month: "short", day: "numeric" });
        case TimeSeriesInterval.Week:
            return date.toLocaleDateString(undefined, { month: "short", day: "numeric" });
        case TimeSeriesInterval.Month:
            return date.toLocaleDateString(undefined, { month: "short", year: "numeric" });
        case TimeSeriesInterval.Quarter: {
            const quarter = Math.floor(date.getMonth() / 3) + 1;
            return `Q${quarter} ${date.getFullYear()}`;
        }
        case TimeSeriesInterval.Year:
            return date.getFullYear().toString();
        default:
            assertNever(interval);
    }
}

export function convertTimeDurationToBucket(duration: TimeSeriesRelativeDuration): TimeBucket {
    switch (duration) {
        case TimeSeriesRelativeDuration.Last7Days: {
            const start = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            return {
                start: start,
                end: now,
                name: `Last 7 days`,
            };
        }
        case TimeSeriesRelativeDuration.Last30Days: {
            const start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
            return {
                start: start,
                end: now,
                name: `Last 30 days`,
            };
        }
        case TimeSeriesRelativeDuration.Last90Days: {
            const start = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
            return {
                start: start,
                end: now,
                name: `Last 90 days`,
            };
        }
        case TimeSeriesRelativeDuration.Last12Months: {
            const start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
            return {
                start: start,
                end: now,
                name: `Last 12 months`,
            };
        }
        case TimeSeriesRelativeDuration.Last24Hours: {
            const start = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            return {
                start: start,
                end: now,
                name: `Last 24 hours`,
            };
        }
        default:
            assertNever(duration);
    }
}

export function makeTimeBucketsFromDurationAndInterval(
    duration: TimeSeriesRelativeDuration,
    interval: TimeSeriesInterval
): TimeBucket[] {
    const { start, end } = convertTimeDurationToBucket(duration);
    let buckets: TimeBucket[] = [];
    switch (interval) {
        case TimeSeriesInterval.Hour:
            buckets = eachHourOfInterval({ start, end }).map(date => ({
                start: startOfHour(date),
                end: endOfHour(date),
                name: formatBucketLabel(interval, date),
            }));
            break;
        case TimeSeriesInterval.Day:
            buckets = eachDayOfInterval({ start, end }).map(date => ({
                start: startOfDay(date),
                end: endOfDay(date),
                name: formatBucketLabel(interval, date),
            }));
            break;
        case TimeSeriesInterval.Month:
            buckets = eachMonthOfInterval({ start, end }).map(date => ({
                start: startOfMonth(date),
                end: endOfMonth(date),
                name: formatBucketLabel(interval, date),
            }));
            break;
        case TimeSeriesInterval.Quarter:
            buckets = eachQuarterOfInterval({ start, end }).map(date => ({
                start: startOfQuarter(date),
                end: endOfQuarter(date),
                name: formatBucketLabel(interval, date),
            }));
    }

    return buckets;
}

export function createTimeSeriesAggregationInBuckets(
    buckets: TimeBucket[],
    data: DataPlotRowData[],
    aggregationsMap: Map<string, DataPlotRollupKind | undefined>
): DataPlotRowData[] {
    const results: DataPlotRowData[] = buckets.map(bucket => ({
        name: bucket.name,
        points: {}, // Will be populated with aggregated values
    }));

    for (const row of data) {
        // Find which bucket this data point belongs to
        let bucketIndex = -1;
        const timestamp = new Date(row.timestamp ?? row.name).getTime();

        for (let i = 0; i < buckets.length; i++) {
            const bucket = buckets[i];
            if (timestamp >= bucket.start.getTime() && timestamp < bucket.end.getTime()) {
                bucketIndex = i;
                break;
            }
        }

        // No bucket
        if (bucketIndex === -1) continue;

        // Aggregating the values in data points
        const result = results[bucketIndex];
        for (const key in row.points) {
            if (Object.prototype.hasOwnProperty.call(row.points, key)) {
                // Initialize if this key hasn't been seen before
                if (result.points[key] === undefined) {
                    result.points[key] = {
                        value: 0,
                        color: row.points[key].color,
                    };
                    // pass an undefined current value to avoid the min/max zero bug.
                    result.points[key].value = applyAggregation(aggregationsMap, key, undefined, row.points[key].value);
                } else {
                    result.points[key].value = applyAggregation(
                        aggregationsMap,
                        key,
                        result.points[key].value,
                        row.points[key].value
                    );
                }
            }
        }
    }

    return results;
}

function applyAggregation(
    aggregationsMap: Map<string, DataPlotRollupKind | undefined>,
    dataKey: string,
    currentValue: number | undefined,
    nextValue: number
): number {
    const aggregateFunction = aggregationsMap.get(dataKey);
    // case where first point doesn't exist yet (min/max bug)
    if (currentValue === undefined) {
        return nextValue;
    }
    // default if there is no aggregation function specified
    if (aggregateFunction === undefined) {
        return currentValue + nextValue;
    }
    switch (aggregateFunction) {
        case DataPlotRollupKind.Sum:
            return currentValue + nextValue;
        case DataPlotRollupKind.Minimum:
            return Math.min(currentValue, nextValue);
        case DataPlotRollupKind.Maximum:
            return Math.max(currentValue, nextValue);
        case DataPlotRollupKind.Average:
            return (currentValue + nextValue) / 2;
        case DataPlotRollupKind.CountNonEmpty:
            return currentValue + 1;
        default:
            assertNever(aggregateFunction);
    }
}

export function autoFormatLabelsFromData(data: DataPlotRowData[]): DataPlotRowData[] {
    // since we know that data is sorted by date,
    // we can infer an interval based on first and last point
    const firstDate = new Date(data[0].timestamp ?? data[0].name);
    const lastDate = new Date(data[data.length - 1].timestamp ?? data[data.length - 1].name);
    const diff = lastDate.getTime() - firstDate.getTime();
    const diffInDays = diff / (1000 * 60 * 60 * 24);

    // re-map the labels based on the interval
    const newData = data.map(row => {
        const date = new Date(row.timestamp ?? row.name);
        const newRow = { ...row };
        if (diffInDays < 1) {
            newRow.name = date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
        } else if (diffInDays < 30) {
            newRow.name = date.toLocaleDateString(undefined, { month: "short", day: "numeric" });
        } else {
            newRow.name = date.toLocaleDateString(undefined, { year: "numeric", month: "short", day: "numeric" });
        }
        return newRow;
    });
    return newData;
}
