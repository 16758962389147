import type {
    FetchBehavior,
    FetchBehaviorRetryFunction,
    NativePlugin,
    Parameter,
    ParameterRecord,
    PluginSecretFormInjection,
    PluginSecretJSONInjection,
} from "@glide/plugins";
import { Plugin } from "@glide/plugins";
import type { JSONObject } from "@glide/support";
import { QuadraticBackoffController, deepSet } from "@glide/support";
import { DefaultMap, definedMap, exceptionToString, sleep } from "@glideapps/ts-necessities";
import { hasOwnProperty } from "collection-utils";

type BodyInjection = PluginSecretFormInjection | PluginSecretJSONInjection;

function updateFormIshBodyForSecretInjections(
    body: URLSearchParams | FormData,
    injections: readonly BodyInjection[],
    params: ReadonlyMap<Parameter, string>,
    secrets: ReadonlyMap<Parameter, string>
): URLSearchParams | FormData {
    for (const injection of injections) {
        if (injection.kind === "json") throw new TypeError("Cannot perform JSON injection in form injection");
        const replacement = secrets.get(injection.value) ?? params.get(injection.value);
        if (replacement === undefined) continue;
        body.append(injection.key, replacement);
    }
    return body;
}

function updateStringBodyForSecretInjections(
    body: string,
    contentType: string,
    injections: readonly BodyInjection[],
    params: ReadonlyMap<Parameter, string>,
    secrets: ReadonlyMap<Parameter, string>
): string {
    if (contentType === "application/json") {
        const bodyAsJSON = JSON.parse(body);
        for (const injection of injections) {
            if (injection.kind !== "json") {
                throw new TypeError(`Cannot mix injection kind '${injection.kind}' and 'json'`);
            }
            const value = secrets.get(injection.value) ?? params.get(injection.value);
            if (value === undefined) continue;
            deepSet(bodyAsJSON, injection.path, value, true);
        }
        return JSON.stringify(bodyAsJSON);
    } else if (contentType === "application/x-www-form-urlencoded") {
        const urlParams = new URLSearchParams(body);
        for (const injection of injections) {
            if (injection.kind !== "form-data") {
                throw new TypeError(`Cannot mix injection kind '${injection.kind}' and 'form-data'`);
            }
            const value = secrets.get(injection.value) ?? params.get(injection.value);
            if (value === undefined) continue;
            urlParams.append(injection.key, value);
        }
        return urlParams.toString();
    } else if (contentType === "multipart/form-data") {
        // FIXME: Somehow support these!
        throw new TypeError("Multipart forms are not supported yet");
    } else {
        throw new TypeError(`Unsupported Content-Type '${contentType}'`);
    }
}

function isIPAddress(hostname: string, isIP: (hostname: string) => number): boolean {
    return isIP(hostname) !== 0;
}

function isPrivateIP(ip: string, isIP: (hostname: string) => number) {
    if (!isIPAddress(ip, isIP)) return false; // Not an IP address

    if (isIP(ip) === 4) {
        // IPv4
        const parts = ip.split(".").map(Number);
        return (
            parts[0] === 10 || // 10.x.x.x
            parts[0] === 127 || // 127.x.x.x
            (parts[0] === 169 && parts[1] === 254) || // 169.254.x.x
            (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) || // 172.16.x.x - 172.31.x.x
            (parts[0] === 192 && parts[1] === 168) // 192.168.x.x
        );
    } else if (isIP(ip) === 6) {
        // IPv6
        return ip.startsWith("fc") || ip.startsWith("fd") || ip === "fd00:ec2::254"; // fc00::/7 block
    }

    return false;
}

export function wrapFetchForSafety(
    baseFetch: typeof fetch,
    isIP: (hostname: string) => number,
    resolveHostname: (hostname: string) => Promise<string[]>,
    doHostCheck: boolean,
    allowRedirect: boolean
): typeof fetch {
    if (!doHostCheck) return baseFetch;
    return (async (info: RequestInfo, init?: RequestInit): Promise<Response> => {
        const url = typeof info === "string" ? info : info.url;

        const isHttps = url.startsWith("https://");

        if (doHostCheck && !isHttps) {
            // We need to check if the host of the URL is publicly routable before proceeding
            // This way we avoid allowing access to our internal network

            const hostname = new URL(url).hostname;
            if (hostname.toLowerCase() === "localhost" || hostname === "::1") {
                // localhost is never allowed
                throw new TypeError("localhost is not allowed");
            } else if (isIPAddress(hostname, isIP)) {
                if (isPrivateIP(hostname, isIP)) {
                    throw new TypeError("Private IP address is not allowed");
                }
            } else {
                // resolve host to ensure it's not a private IP
                const addresses = await resolveHostname(hostname);
                for (const address of addresses) {
                    if (isPrivateIP(address, isIP)) {
                        throw new TypeError("Private IP address is not allowed");
                    }
                }
            }
        }

        return baseFetch(info, {
            ...init,
            // following redirects on http is unsafe, so we will disallow it as they could redirect to private addresses
            redirect: isHttps && allowRedirect ? "follow" : "manual",
        });
    }) as any;
}

export function wrapFetchForSecretInjection(
    baseFetch: typeof fetch,
    plugin: Plugin<ParameterRecord> | NativePlugin<ParameterRecord>,
    params: ReadonlyMap<Parameter, string>,
    secrets: ReadonlyMap<Parameter, string>,
    inspect: ((response: Response) => void) | undefined
): typeof fetch {
    if (!(plugin instanceof Plugin)) return baseFetch;
    if (plugin.secretInjections.length === 0) return baseFetch;
    const allFixedHeaders = new DefaultMap<string, Record<string, string>>(() => ({}));
    const allBodyInjections = new DefaultMap<string, BodyInjection[]>(() => []);
    for (const injection of plugin.secretInjections) {
        if (injection.kind === "authorization-basic") {
            const usernameValue = secrets.get(injection.username) ?? params.get(injection.username);
            const passwordValue = secrets.get(injection.password) ?? params.get(injection.password);
            if (usernameValue === undefined || passwordValue === undefined) continue;
            const basicText = `${usernameValue.replace(/:/g, "")}:${passwordValue}`;
            allFixedHeaders.get(injection.baseUrl)["Authorization"] = `Basic ${Buffer.from(basicText, "utf-8").toString(
                "base64"
            )}`;
        } else {
            const value = secrets.get(injection.value) ?? params.get(injection.value);
            if (value === undefined) continue;
            if (injection.kind === "form-data" || injection.kind === "json") {
                allBodyInjections.get(injection.baseUrl).push(injection);
                continue;
            }
            if (injection.kind === "authorization-bearer") {
                if (injection.includeBearer === false) {
                    allFixedHeaders.get(injection.baseUrl)["Authorization"] = `${value}`;
                } else {
                    allFixedHeaders.get(injection.baseUrl)["Authorization"] = `Bearer ${value}`;
                }
            }
        }
    }
    return (async (info: RequestInfo, init?: RequestInit): Promise<Response> => {
        const url = typeof info === "string" ? info : info.url;

        const bodyInjections: BodyInjection[] = [...allBodyInjections.entries()]
            .filter(([baseUrl]) => url.startsWith(baseUrl))
            .map(([, val]) => val)
            .reduce((acc, cv) => {
                acc.push(...cv);
                return acc;
            }, []);
        const fixedHeaders: Record<string, string> = [...allFixedHeaders.entries()]
            .filter(([baseUrl]) => url.startsWith(baseUrl))
            .map(([, val]) => val)
            .reduce((acc, cv) => ({ ...acc, ...cv }), {});

        const headers: Record<string, string> = {};
        const initHeaders = init?.headers;
        if (hasOwnProperty(initHeaders, "forEach")) {
            initHeaders.forEach(([key, value]) => {
                headers[key.toLowerCase()] = value;
            });
        } else if (Array.isArray(initHeaders)) {
            for (const [key, value] of initHeaders) {
                headers[key.toLowerCase()] = value;
            }
        } else if (initHeaders !== null && initHeaders !== undefined) {
            for (const [key, value] of Object.entries(initHeaders)) {
                headers[key.toLowerCase()] = value;
            }
        }
        for (const [key, value] of Object.entries(fixedHeaders)) {
            headers[key.toLowerCase()] = value;
        }
        if (bodyInjections.length === 0 || init?.body === null || init?.body === undefined) {
            return (await baseFetch(info as any, { ...init, headers } as any)) as any;
        }
        if (hasOwnProperty(init.body, "getReader")) throw new TypeError("Cannot inject secrets into streamable body");
        const contentType = headers["content-type"];
        if (contentType === undefined) throw new TypeError("Body requires Content-Type header");
        let body = init.body;
        if (typeof body === "string") {
            body = updateStringBodyForSecretInjections(body, contentType, bodyInjections, params, secrets);
        } else if (hasOwnProperty(body, "arrayBuffer")) {
            // It's a Blob
            const arrayBuffer = await body.arrayBuffer();
            body = updateStringBodyForSecretInjections(
                Buffer.from(arrayBuffer).toString("utf-8"),
                contentType,
                bodyInjections,
                params,
                secrets
            );
        } else if (hasOwnProperty(body, "buffer")) {
            // It's an ArrayBufferView
            body = updateStringBodyForSecretInjections(
                Buffer.from(body.buffer.slice(body.byteOffset, body.byteOffset + body.byteLength)).toString("utf-8"),
                contentType,
                bodyInjections,
                params,
                secrets
            );
        } else if (hasOwnProperty(body, "slice")) {
            // It's an ArrayBuffer
            body = updateStringBodyForSecretInjections(
                Buffer.from(body).toString("utf-8"),
                contentType,
                bodyInjections,
                params,
                secrets
            );
        } else if (hasOwnProperty(body, "sort")) {
            // It's URLSearchParams
            body = updateFormIshBodyForSecretInjections(body, bodyInjections, params, secrets);
        } else if (hasOwnProperty(body, "append")) {
            // It's a FormData
            body = updateFormIshBodyForSecretInjections(body, bodyInjections, params, secrets);
        } else {
            throw new TypeError("Cannot inject secrets into unknown body type");
        }
        const response: Response = (await baseFetch(info as any, { ...init, headers, body } as any)) as any;
        inspect?.(response);
        return response;
    }) as any;
}

const baseBackoffTime = 1_000;
interface DefaultTrueRetryOnErrorState {
    readonly backoff: QuadraticBackoffController;
}

const defaultHeaderTimeout = 60_000;
const defaultBodyTimeout = 300_000;
export const defaultMaxRetriesIndexedAtZero = 7;
const defaultFalseRetryOnError = async () => ({ waitFor: false as const });
const defaultTrueRetryOnError: FetchBehaviorRetryFunction<DefaultTrueRetryOnErrorState> = async (
    _req: { input: RequestInfo | URL; init: RequestInit },
    resp: Response | undefined,
    state: DefaultTrueRetryOnErrorState | undefined
) => {
    const nextState = state ?? { backoff: new QuadraticBackoffController(baseBackoffTime) };

    if (nextState.backoff.attempts >= defaultMaxRetriesIndexedAtZero) {
        return { waitFor: false as const, nextState };
    }

    if (resp === undefined || resp.status >= 500 || resp.status === 429) {
        return { waitFor: nextState.backoff.getWaitTime(), nextState };
    }

    return { waitFor: false as const, nextState };
};

class RetriesExceededFetchError extends Error {
    constructor() {
        super("Fetch retry limit exceeded");
    }
}

async function clearTimeoutWhenBodyUsed(
    response: Response,
    signal: AbortSignal,
    handle: NodeJS.Timeout,
    sleepFn: typeof sleep
) {
    while (response?.bodyUsed === false && !signal.aborted) {
        await sleepFn(1_000);
    }
    clearTimeout(handle);
}

type LogFn = (msg: string, params?: JSONObject) => void;
export const maxTotalAttempts = 128;

function wrapFetchForRetriesStage2<TState>(
    baseFetch: typeof fetch,
    headerTimeout: number,
    bodyTimeout: number,
    retryOnError: FetchBehaviorRetryFunction<TState>,
    logFn: LogFn,
    sleepFn: typeof sleep
): typeof fetch {
    const wrapped: typeof fetch = async (input: RequestInfo | URL, init?: RequestInit | undefined) => {
        let priorState: TState | undefined;
        let retrying = false,
            attempt = 0;
        const fallbackBackoff = new QuadraticBackoffController(baseBackoffTime);
        const origSignal = init?.signal;
        let response: Response | undefined, lastException: unknown;

        // TODO: This is the place where we would simulate `fetch` errors.

        do {
            attempt++;
            response = undefined;
            lastException = undefined;
            retrying = false;
            const internalController = new AbortController();
            const passthroughAbort = () => internalController.abort();
            origSignal?.addEventListener("abort", passthroughAbort);
            if (origSignal?.aborted === true) {
                passthroughAbort();
            }

            const headerTimeoutHandle = setTimeout(passthroughAbort, headerTimeout);

            const method = hasOwnProperty(input, "method") ? input.method : "GET";
            const url = hasOwnProperty(input, "href") ? input.href : hasOwnProperty(input, "url") ? input.url : input;
            const fetchInit: RequestInit =
                init === undefined
                    ? {
                          method,
                          signal: internalController.signal,
                      }
                    : { ...init, signal: internalController.signal };
            try {
                // Note that our TypeScript configuration thinks
                //     Type 'URL' is not assignable to type 'RequestInfo'.
                // But the `fetch` specification says that this is legal, so
                // we are passing through whatever we originally got.
                response = await baseFetch(input as any, fetchInit);

                // Note that we always do this, even when the response is not
                // OK, for two reasons:
                // 1. This body may be used in `retryOnError`, which can also
                //    stall out trying to read a body
                // 2. If we are no longer retrying, we do send the response
                //    back to the caller, which might try to read the body
                //    and stall out there.
                const bodyTimeoutHandle = setTimeout(passthroughAbort, bodyTimeout);
                void clearTimeoutWhenBodyUsed(response, internalController.signal, bodyTimeoutHandle, sleepFn);

                if (response.ok) return response;
                retrying = attempt < maxTotalAttempts && init?.signal?.aborted !== true;
            } catch (e: unknown) {
                lastException = e;
                retrying = attempt < maxTotalAttempts && init?.signal?.aborted !== true;
            } finally {
                clearTimeout(headerTimeoutHandle);
                origSignal?.removeEventListener("abort", passthroughAbort);
            }

            logFn("fetch was not successful", {
                method,
                url,
                responseStatus: response?.status,
                attempt,
                exception: definedMap(lastException, exceptionToString),
            });

            if (!retrying) break;

            try {
                const shouldRetry = await retryOnError({ input, init: fetchInit }, response, priorState);
                if (shouldRetry.waitFor === false) break;

                // If `retryOnError` doesn't read the body, we may end up "leaking" a network connection, unless
                // we either
                // 1. Attempt to read the body, or
                // 2. Abort the request, which is what we are doing here.
                passthroughAbort();

                priorState = shouldRetry.nextState;
                await sleepFn(shouldRetry.waitFor);
            } catch (e: unknown) {
                logFn("retryOnError threw exception", {
                    method,
                    url,
                    responseStatus: response?.status,
                    attempt,
                    exception: exceptionToString(e),
                });
                if (fallbackBackoff.attempts >= defaultMaxRetriesIndexedAtZero) break;

                passthroughAbort();
                await sleepFn(fallbackBackoff.getWaitTime());
            }
        } while (retrying);

        if (response !== undefined) return response;
        throw lastException ?? new RetriesExceededFetchError();
    };
    return wrapped;
}

export function wrapFetchForRetries<TState>(
    baseFetch: typeof fetch,
    plugin: Plugin<ParameterRecord> | NativePlugin<ParameterRecord>,
    operationParams: { fetchBehavior?: FetchBehavior<TState> },
    logFn: LogFn,
    // This is only explicit so that we can mock it in unit tests. Jest can't handle retry loops with timers:
    // any time we want to advance all of the timers, our loop hasn't finished executing yet, so when it comes
    // around to the sleep, it gets stuck.
    sleepFn: typeof sleep = sleep
): typeof fetch {
    const behavior = operationParams.fetchBehavior ?? plugin.fields.fetchBehavior;

    const headerTimeout = behavior?.headerTimeout ?? defaultHeaderTimeout;
    const bodyTimeout = behavior?.bodyTimeout ?? defaultBodyTimeout;
    if (behavior?.retryOnError === undefined || behavior.retryOnError === "no-retries") {
        return wrapFetchForRetriesStage2(
            baseFetch,
            headerTimeout,
            bodyTimeout,
            defaultFalseRetryOnError,
            logFn,
            sleepFn
        );
    } else if (behavior.retryOnError === "default-retries") {
        return wrapFetchForRetriesStage2(
            baseFetch,
            headerTimeout,
            bodyTimeout,
            defaultTrueRetryOnError,
            logFn,
            sleepFn
        );
    } else {
        return wrapFetchForRetriesStage2(baseFetch, headerTimeout, bodyTimeout, behavior.retryOnError, logFn, sleepFn);
    }
}
