import { browserMightBeOniOS, GlideIcon, useDebouncedValue, useEventListener } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { type TextEntrySize, TextComponentStyle } from "@glide/component-utils";
import { AppKind } from "@glide/location-common";
import { ignore, isDefined, parseNumber } from "@glide/support";
import classNames from "classnames";
import React from "react";
import tw from "twin.macro";
import { Text } from "../../components/text/text";
import { css, authThemeAccent, authThemeDark, authThemeHighlight, Tooltip } from "@glide/common-components";
import { useFieldStyles } from "../../utils/use-field-styles";
import { useVoiceTranscriptControls } from "../wire-voice-entry/record-and-transcribe-component";
import type { VoiceVisualizerHandle } from "../wire-voice-entry/voice-visualizer";
import type { AnimationPlaybackControls } from "framer-motion";
import { AnimatePresence, motion, useMotionValue, animate } from "framer-motion";
import { InlineVoiceVisualizer } from "../wire-voice-entry/inline-voice-visualizer";

export type FieldType = "text" | "number" | "phone" | "email" | "tel" | "password";

interface Props extends React.PropsWithChildren {
    readonly placeholder?: string;
    readonly dataType?: FieldType;
    readonly dataTypePattern?: string;
    readonly dataTypeInputMode?: "text";
    readonly isEnabled: boolean;
    readonly value: string;
    readonly error?: string;
    readonly onChange?: (newVal: string) => void;
    readonly isRequired?: boolean;
    readonly minimumChars?: number;
    readonly maximumChars?: number;
    readonly size?: TextEntrySize;
    readonly title?: string;
    readonly autoFocus?: boolean;
    readonly isSignInEmail?: boolean;
    readonly voiceTranscription?: boolean;
}

export const WireField: React.FC<Props> = p => {
    const {
        isEnabled,
        value,
        dataType,
        dataTypePattern,
        dataTypeInputMode,
        onChange,
        placeholder,
        title,
        isRequired,
        error,
        size,
        maximumChars,
        autoFocus,
        isSignInEmail,
        voiceTranscription,
    } = p;

    const [innerValue, setInnerValue] = React.useState(value);

    const onValueChange = React.useCallback(
        (newValue: string) => {
            setInnerValue(newValue);
            onChange?.(newValue);
        },
        [onChange]
    );

    React.useEffect(() => {
        setInnerValue(value);
    }, [value]);

    const numberInputRef = React.useRef<HTMLInputElement>(null);

    const onInputWheel = React.useCallback((e: WheelEvent) => {
        if (document.activeElement === numberInputRef.current) {
            e.preventDefault();
        }
    }, []);

    useEventListener("wheel", onInputWheel, numberInputRef.current, false);

    // The HTML5 spec has left us a pothole regarding number inputs and locales
    // using , as the decimal separator. The string you pass to "value" needs
    // to use . as the decimal separator, because that's the default American-
    // centric locale browsers have always historically used.
    //
    // What this means is that attempting to input a number with , as the
    // decimal separator will look "wrong" to the browser, and as a consequence
    // of the operation happening by way of the shadow DOM in React, when this
    // happens you end up with an empty value landing in the real DOM. And this
    // means your input gets cleared.
    //
    // There is a simple way to cheese this: if it's a number input, always set
    // the current value to an actual number. We have to parse the current
    // state to do so, but this keeps the browser happy.

    const signInStyles = css`
        .${authThemeAccent}.mobile & {
            ${tw`text-w50A`}
        }

        .has-background-image &,
        .${authThemeDark} & {
            ${tw`text-w60A`}
        }

        .${authThemeHighlight} & {
            color: rgba(51, 51, 51, 0.7);
        }

        .${authThemeHighlight}.has-background-image &,
        .${authThemeHighlight}.has-background-image.mobile & {
            ${tw`text-w50A`}
        }
    `;

    const fieldStyles = useFieldStyles();
    const debouncedError = useDebouncedValue(error, 500);

    return (
        <label tw="flex flex-col text-text-dark" onClick={e => e.stopPropagation()}>
            {isSignInEmail ? (
                <Text
                    element="p"
                    variant={TextComponentStyle.small}
                    css={css`
                        ${tw`gp-sm:(mb-10) mb-6 font-normal text-center`}

                        ${signInStyles}
                    `}
                    data-testid="wf-title">
                    {title}
                </Text>
            ) : (
                <div tw="mb-2 text-sm font-semibold flex justify-between items-baseline gp-md:text-base">
                    <div data-testid="wf-title" css={signInStyles} tw="shrink text-text-contextual-dark">
                        {title}
                    </div>
                    {isRequired && (
                        <div data-testid="wf-required" tw="shrink-0 ml-2 text-xs text-text-contextual-pale font-normal">
                            {getLocalizedString("required", AppKind.Page)}
                        </div>
                    )}
                </div>
            )}

            {dataType === "text" &&
                (voiceTranscription ? (
                    <TextareaWithRecorder
                        data-testid="wf-input"
                        className={size}
                        css={css`
                            &.medium {
                                min-height: 76px;
                            }

                            &.large {
                                min-height: 152px;
                            }

                            ${fieldStyles}
                        `}
                        tw="px-3 py-2"
                        value={innerValue}
                        disabled={!isEnabled}
                        placeholder={placeholder}
                        onChange={e => onValueChange?.(e.target.value)}
                        onValueChange={onValueChange}
                        autoFocus={autoFocus}
                    />
                ) : (
                    <Textarea
                        data-testid="wf-input"
                        className={size}
                        css={css`
                            &.medium {
                                min-height: 76px;
                            }

                            &.large {
                                min-height: 152px;
                            }

                            ${fieldStyles}
                        `}
                        tw="px-3 py-2"
                        value={innerValue}
                        disabled={!isEnabled}
                        placeholder={placeholder}
                        onChange={e => onValueChange?.(e.target.value)}
                        autoFocus={autoFocus}
                    />
                ))}
            {dataType === "number" && (
                <input
                    ref={numberInputRef}
                    data-testid="wf-input"
                    tw="px-3 py-2 transition-all"
                    css={css`
                        ${fieldStyles}
                    `}
                    type="number"
                    pattern={dataTypePattern}
                    inputMode={dataTypeInputMode}
                    value={parseNumber(innerValue) ?? ""}
                    disabled={!isEnabled}
                    placeholder={placeholder}
                    onChange={e => onValueChange?.(e.target.value)}
                    autoFocus={autoFocus}
                />
            )}
            {dataType !== "text" && dataType !== "number" && (
                <input
                    data-testid="wf-input"
                    className={classNames(isSignInEmail && "is-signin")}
                    css={css`
                        .${authThemeAccent} {
                            ${tw`shadow-none bg-n100 hover:bg-n200A focus:bg-n200A placeholder:text-n400 ring-offset-0`}
                        }

                        .${authThemeHighlight} & {
                            ${tw`shadow-none text-black bg-[rgb(245, 245, 245)] hover:bg-[rgba(128,128,128,0.12)] focus:bg-[rgba(128,128,128,0.12)] placeholder:text-[rgb(173, 173, 173)] ring-offset-0`}
                        }

                        .${authThemeAccent}.mobile & {
                            ${tw`shadow-none bg-w05A hover:bg-w20A focus:bg-w20A placeholder:text-w70A text-w100A ring-offset-0`}
                        }

                        .has-background-image.mobile &,
                        .has-background-image &,
                        .${authThemeDark} & {
                            ${tw`shadow-none bg-w10A hover:bg-w20A focus:bg-w20A data-[state=open]:bg-w20A hover:shadow-none placeholder:text-w70A text-white ring-offset-0`}
                        }

                        ${fieldStyles}
                    `}
                    tw="px-3 py-2 [&.is-signin]:(py-3 rounded-[10px]) transition-all"
                    type={dataType}
                    pattern={dataTypePattern}
                    inputMode={dataTypeInputMode}
                    value={innerValue}
                    disabled={!isEnabled}
                    placeholder={placeholder}
                    onChange={e => onValueChange?.(e.target.value)}
                    autoFocus={autoFocus}
                />
            )}
            <div tw="shrink-0 mt-1 text-xs flex justify-between">
                <div tw="text-text-danger">{debouncedError}</div>
                {maximumChars !== undefined && (
                    <div
                        css={css`
                            &.error {
                                ${tw`text-text-danger`};
                            }
                        `}
                        tw="text-text-xpale"
                        className={classNames(value.length > maximumChars && "error")}>
                        {`${value.length}/${maximumChars}`}
                    </div>
                )}
            </div>
        </label>
    );
};

type TextAreaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

type WireGrowingEntryProps = TextAreaProps & {
    onValueChange: (value: string) => void;
};

const PRESS_TIME_CONSIDERED_HOLD_MS = 500;

const TextareaWithRecorder: React.FC<WireGrowingEntryProps> = p => {
    const { onValueChange } = p;
    const [isLongPress, setIsLongPress] = React.useState(false);
    const pressProgress = useMotionValue(0);
    const mouseDownTimestamp = React.useRef(0);
    const pressAnimationRef = React.useRef<AnimationPlaybackControls>();
    const recordingStartTime = React.useRef(0);
    const MINIMUM_RECORDING_DURATION = 1000; // 1000ms minimum recording time
    const [state, setStateInner] = React.useState<"idle" | "recording" | "processing" | "error">("idle");

    const setState = React.useCallback((newState: "idle" | "recording" | "processing" | "error") => {
        setStateInner(newState);
        const isRecording = newState === "recording";
        if (isRecording) {
            recordingStartTime.current = Date.now();
        }
    }, []);

    const isRecording = state === "recording";

    const { onStartRecording, onFinishRecording, onCancelRecording, getAudioData } = useVoiceTranscriptControls({
        onTranscribedText: onValueChange ?? ignore,
        onTemporaryTranscription: ignore,
        state,
        setState,
    });

    const visualizerRef = React.useRef<VoiceVisualizerHandle>(null);

    const finishRecordingAndCleanUpVisualizer = React.useCallback(() => {
        const recordingDuration = Date.now() - recordingStartTime.current;

        if (recordingDuration < MINIMUM_RECORDING_DURATION) {
            onCancelRecording();
        } else {
            if (isDefined(pressAnimationRef.current)) {
                pressAnimationRef.current.stop();
            }
            animate(pressProgress, 0, { duration: 0.2 });
            visualizerRef.current?.cleanup();
            onFinishRecording();
        }
        setIsLongPress(false);
    }, [onFinishRecording, onCancelRecording, pressProgress]);

    const isRecordingInProgress = state === "recording" || state === "processing" || state === "error";

    const startPressAnimation = React.useCallback(() => {
        pressProgress.set(0);
        pressAnimationRef.current = animate(pressProgress, 1, {
            duration: PRESS_TIME_CONSIDERED_HOLD_MS / 1000,
            ease: "linear",
            onComplete: () => {
                setIsLongPress(true);
                onStartRecording();
            },
        });
    }, [onStartRecording, pressProgress]);

    const onPointerDown = React.useCallback(
        (e: React.PointerEvent) => {
            if (isRecordingInProgress) {
                return;
            }

            e.currentTarget.setPointerCapture(e.pointerId);
            mouseDownTimestamp.current = Date.now();

            startPressAnimation();
        },
        [isRecordingInProgress, startPressAnimation]
    );

    const onPointerUp = React.useCallback(
        (e: React.PointerEvent) => {
            const mouseUpTimestamp = Date.now();
            const pressedTime = mouseUpTimestamp - mouseDownTimestamp.current;

            e.currentTarget.releasePointerCapture(e.pointerId);

            if (isDefined(pressAnimationRef.current)) {
                pressAnimationRef.current.stop();
            }
            // If already recording, any click/tap should stop it
            if (isRecordingInProgress) {
                finishRecordingAndCleanUpVisualizer();
                return;
            }

            // Handle quick tap/click for starting recording
            if (pressedTime < PRESS_TIME_CONSIDERED_HOLD_MS) {
                onStartRecording();
            }

            // Handle long press completion
            if (isLongPress) {
                animate(pressProgress, 0, { duration: 0.2 });
                if (isRecordingInProgress) {
                    finishRecordingAndCleanUpVisualizer();
                }
            }
        },
        [finishRecordingAndCleanUpVisualizer, isLongPress, isRecordingInProgress, pressProgress, onStartRecording]
    );

    const onPointerLeave = React.useCallback(() => {
        if (!isRecordingInProgress) {
            if (isDefined(pressAnimationRef.current)) {
                pressAnimationRef.current.stop();
            }
            animate(pressProgress, 0, { duration: 0.2 });
            setIsLongPress(false);
        }
    }, [pressProgress, isRecordingInProgress]);

    const recordButtonRef = React.useRef<HTMLButtonElement>(null);

    return (
        <div tw="relative grow h-full">
            <div tw="relative all-child:(p-0 m-0 [color:inherit] [font-size:inherit] [font-weight:inherit])">
                <AnimatePresence>
                    {isRecording && (
                        <div tw="absolute right-0 h-[39px] overflow-hidden w-[94px] rounded-lg">
                            <motion.div
                                initial={{ x: "100%" }}
                                animate={{ x: "0%" }}
                                exit={{ x: "100%", transition: { duration: 0.1, type: "tween" } }}
                                transition={{
                                    duration: 0.1,
                                    type: "tween",
                                }}
                                tw="absolute inset-0 bg-accent opacity-10 rounded-l-full pointer-events-none"
                            />
                        </div>
                    )}
                </AnimatePresence>

                <div style={{ padding: browserMightBeOniOS ? "0 3px" : undefined }} tw="w-full invisible">
                    <div className={p.className} tw="whitespace-pre-wrap break-words">
                        {(p.value ?? p.placeholder ?? "") + "\n"}
                    </div>
                </div>

                <textarea
                    className={p.className}
                    tw="appearance-none absolute inset-0 rounded-none resize-none whitespace-pre-wrap w-full h-full
            overflow-hidden bg-transparent disabled:(opacity-50 pointer-events-none) !pr-[82px]"
                    autoFocus={p.autoFocus}
                    {...p}
                    placeholder={isRecordingInProgress ? " " : p.placeholder}
                    value={p.value}
                />

                {isRecording && (
                    <button
                        data-recording={state === "recording"}
                        tw="flex items-center justify-center !w-[39px] transition-colors duration-200 !h-[39px] rounded-full bg-transparent text-text-pale hover:text-accent data-[recording=true]:text-icon-dark absolute right-12  top-0"
                        onClick={onCancelRecording}>
                        <GlideIcon icon={"st-close"} kind="stroke" iconSize={20} strokeColor="currentColor" />
                    </button>
                )}

                <AnimatePresence>
                    {isRecording && (
                        <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 10 }}
                            transition={{
                                type: "spring",
                                stiffness: 500,
                                damping: 30,
                            }}
                            tw="absolute -top-[34px] right-0 p-2 text-accent text-sm pointer-events-none">
                            {isLongPress ? "Release to finish" : "Recording"}
                        </motion.div>
                    )}
                </AnimatePresence>

                <div tw="absolute w-[39px] h-[39px] right-0 top-0 flex items-center justify-center">
                    {state === "idle" && (
                        <Tooltip target={recordButtonRef} position="top" delayMS={1000}>
                            <div>Tap or hold to record</div>
                        </Tooltip>
                    )}

                    <button
                        ref={recordButtonRef}
                        disabled={state === "processing"}
                        data-recording={state === "recording"}
                        tw="flex items-center justify-center !w-[32px] !h-[32px] relative text-text-pale hover:text-accent data-[recording=true]:text-accent touch-none"
                        onPointerDown={onPointerDown}
                        onPointerUp={onPointerUp}
                        onPointerLeave={onPointerLeave}>
                        {state === "processing" ? (
                            <GlideIcon icon="st-spinner" kind="stroke" iconSize={20} strokeColor="currentColor" spin />
                        ) : (
                            <InlineVoiceVisualizer isRecording={isRecording} getAudioData={getAudioData} />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

const Textarea: React.FC<TextAreaProps> = p => {
    return (
        <div tw="relative grow h-full">
            <div tw="relative all-child:(p-0 m-0 [color:inherit] [font-size:inherit] [font-weight:inherit])">
                <div style={{ padding: browserMightBeOniOS ? "0 3px" : undefined }} tw="w-full invisible">
                    <div className={p.className} tw="whitespace-pre-wrap break-words">
                        {(p.value ?? p.placeholder ?? "") + "\n"}
                    </div>
                </div>

                <textarea
                    className={p.className}
                    tw="appearance-none absolute inset-0 rounded-none resize-none whitespace-pre-wrap w-full h-full
            overflow-hidden bg-transparent disabled:(opacity-50 pointer-events-none)"
                    placeholder={p.placeholder}
                    value={p.value ?? ""}
                    autoFocus={p.autoFocus}
                    {...p}
                />
            </div>
        </div>
    );
};
