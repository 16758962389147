import type { Row } from "@glide/computation-model-types";
import {
    asMaybeDate,
    asMaybeNumber,
    asMaybeString,
    isBound,
    isLoadingValue,
    RollupKind,
} from "@glide/computation-model-types";
import {
    ArrayScreenFormat,
    getArrayProperty,
    getEnumProperty,
    getStringProperty,
    getColumnProperty,
    getSwitchProperty,
    getSourceColumnProperty,
} from "@glide/app-description";
import type { DataPlotRowData, WireDataPlotComponent } from "@glide/fluent-components/dist/js/base-components";
import {
    TimeSeriesRelativeDuration,
    ChartingGradientMapping,
    DataPlotColorMode,
    type DataPlotDataPointDescription,
    DataPlot,
    ChartingColorScheme,
    DataPlotChartSize,
    DataPlotRollupKind,
} from "@glide/fluent-components/dist/js/fluent-components";

import { getTableAndColumnForSourceColumn, type InlineListComponentDescription } from "@glide/function-utils";
import type { WireAlwaysEditableValue, WireRowComponentHydrationBackend } from "@glide/wire";
import { DataPlotType, type WireRowHydrationValueProvider, WireComponentKind } from "@glide/wire";

import {
    inflateComponentEnricher,
    inflateDateTimeProperty,
    inflateNumberProperty,
    inflateStringProperty,
    makeSimpleWireTableComponentHydratorConstructor,
} from "../../wire/utils";
import { makeFluentArrayContentHandler } from "../fluent-array-handler";
import { assertNever, definedMap, mapFilterUndefined } from "@glideapps/ts-necessities";
import type { FilterCondition } from "@glide/type-schema";
import { BinaryPredicateCompositeOperator, getTableColumnDisplayName, maybeGetTableColumn } from "@glide/type-schema";
import { isEmpty } from "@glide/support";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { isQueryableColumn } from "../../computed-columns";
import { decomposeAggregateRow } from "../../wire/aggregates";
import { GlideDateTime } from "@glide/data-types";
import {
    autoFormatLabelsFromData,
    convertTimeDurationToBucket,
    createTimeSeriesAggregationInBuckets,
    getDefaultIntervalFromDuration,
    makeTimeBucketsFromDurationAndInterval,
} from "./data-plot-time-series-helpers";

function convertDataPlotRollupKindToRollupKind(rollupKind: DataPlotRollupKind): RollupKind {
    switch (rollupKind) {
        case DataPlotRollupKind.Sum:
            return RollupKind.Sum;
        case DataPlotRollupKind.Average:
            return RollupKind.Average;
        case DataPlotRollupKind.Minimum:
            return RollupKind.Minimum;
        case DataPlotRollupKind.Maximum:
            return RollupKind.Maximum;
        case DataPlotRollupKind.CountNonEmpty:
            return RollupKind.CountNonEmpty;
        default:
            assertNever(rollupKind);
    }
}

function isTimeSeriesRelativeDurationOrShowAll(value: unknown): value is TimeSeriesRelativeDuration | "All" {
    switch (value) {
        case TimeSeriesRelativeDuration.Last24Hours:
        case TimeSeriesRelativeDuration.Last7Days:
        case TimeSeriesRelativeDuration.Last30Days:
        case TimeSeriesRelativeDuration.Last90Days:
        case TimeSeriesRelativeDuration.Last12Months:
        case "All":
            return true;
    }

    return false;
}

export const dataPlotFluentArrayContentHandler = makeFluentArrayContentHandler(
    DataPlot,
    (ib, desc, containingRowIB) => {
        if (containingRowIB === undefined) {
            return undefined;
        }

        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.title, true);
        const dataPoints = getArrayProperty<DataPlotDataPointDescription>(desc.points);
        if (dataPoints === undefined || dataPoints.length === 0) {
            return undefined;
        }

        const dataPointRowValueGetters = dataPoints.map(p => {
            return inflateNumberProperty(ib, p.value);
        });

        const undefinedColorGetter = () => undefined;
        const dataPointColorValueGetters = dataPoints.map(p => {
            const colorMode = getEnumProperty<DataPlotColorMode>(p.colorMode);
            if (colorMode === DataPlotColorMode.Auto) {
                return [undefinedColorGetter];
            }
            return inflateStringProperty(ib, p.color, true);
        });

        // One way we enable dynamic colors is by using a column as the color property.
        // But, if this is the case we need to make sure we don't show the legend since
        // it is possible multiple colors can be resolved from this getter.
        let hasColumnBasedColorBinding = false;
        const columnsToDisplay = mapFilterUndefined(dataPoints, p => {
            const dataKey = getColumnProperty(p.value);
            const dataKeyColumn = definedMap(getSourceColumnProperty(p.value), sc =>
                getTableAndColumnForSourceColumn(ib.adc, sc, ib.tables.input, undefined)
            )?.column;
            const colorMode = getEnumProperty<DataPlotColorMode>(p.colorMode);
            const color = getStringProperty(p.color);
            const colorColumn = getSourceColumnProperty(p.color);
            if (colorColumn !== undefined && colorMode === DataPlotColorMode.Manual) {
                hasColumnBasedColorBinding = true;
            }
            if (dataKey === undefined || dataKeyColumn === undefined) {
                return undefined;
            }

            const caption = getStringProperty(p.caption);
            const label = caption ?? getTableColumnDisplayName(dataKeyColumn);
            const columnType = dataKeyColumn.type;
            const rollupKind =
                columnType.kind === "number"
                    ? getEnumProperty<DataPlotRollupKind>(p.rollupKind)
                    : DataPlotRollupKind.CountNonEmpty;
            return {
                label,
                dataKey,
                color: isEmpty(color) || colorMode === DataPlotColorMode.Auto ? undefined : color,
                type: getEnumProperty<DataPlotType>(p.displayType) ?? DataPlotType.Bar,
                rollupKind,
                // Seems like TS should probably complain here, but it doesn't
                // The type might be useful for the display in the future
                // We may want to handle rounding here as well using
                // the precision specified on the column.
                columnType: dataKeyColumn.type,
            };
        });

        const [labelGetter] = inflateStringProperty(ib, desc.label, true);
        const [labelAsTimeGetter] = inflateDateTimeProperty(ib, desc.label);
        const labelAxisColumnName = getColumnProperty(desc.label);
        const labelAxisColumn = maybeGetTableColumn(ib.tables.input, labelAxisColumnName);
        const chartSize = getEnumProperty<DataPlotChartSize>(desc.chartSize) ?? DataPlotChartSize.Medium;
        const colorScheme = getEnumProperty<ChartingColorScheme>(desc.colorScheme) ?? ChartingColorScheme.Default;
        const gradientColorProperties = (getArrayProperty<{ color?: string }>(desc.gradientColors) ?? []).map(g =>
            getStringProperty(g.color)
        );

        const gradientColors = mapFilterUndefined(gradientColorProperties, c => (!isEmpty(c) ? c : undefined));
        const gradientMapping =
            dataPoints.length === 1
                ? getEnumProperty<ChartingGradientMapping>(desc.gradientMapping)
                : ChartingGradientMapping.Index;

        const componentEnricher = inflateComponentEnricher<WireDataPlotComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        const gbtComputedColumnsAlpha = isExperimentEnabled("gbtComputedColumnsAlpha", ib.adc.userFeatures);
        const gbtDeepLookups = isExperimentEnabled("gbtDeepLookups", ib.adc.userFeatures);

        const timeSeriesRelativeDuration =
            getEnumProperty<TimeSeriesRelativeDuration | "All">(desc.timeSeriesRelativeDuration) ?? "All";

        const [captionGetter] = inflateStringProperty(ib, desc.caption, true);

        const enableTimeFilters = getSwitchProperty(desc.enableTimeFilters) ?? false;

        const canShowLegend =
            !(colorScheme === ChartingColorScheme.Gradient && gradientMapping === ChartingGradientMapping.Data) &&
            !hasColumnBasedColorBinding;

        function makeComponent(
            containingRowHB: WireRowHydrationValueProvider,
            data: DataPlotRowData[],
            timeSeriesRelativeDurationState: WireAlwaysEditableValue<"All" | TimeSeriesRelativeDuration>
        ) {
            const graphTitle = componentTitleGetter(containingRowHB);
            const component = componentEnricher({
                kind: WireComponentKind.List,
                format: ArrayScreenFormat.DataPlot,
                data,
                columnsToDisplay,
                graphTitle,
                caption: captionGetter(containingRowHB) ?? "",
                showYAxisLabels: getSwitchProperty(desc.showYAxisLabels),
                showLegend: canShowLegend && getSwitchProperty(desc.showLegend),
                chartSize,
                colorScheme,
                gradientColors,
                gradientMapping,
                timeSeriesRelativeDurationState,
                enableTimeFilters,
            });

            return { component, isValid: true };
        }

        function makeChartDataFromRow(rhb: WireRowComponentHydrationBackend, row: Row): DataPlotRowData | undefined {
            if (dataPoints === undefined || dataPoints.length === 0) {
                return undefined;
            }

            const labelName = labelGetter(rhb);
            const maybeLabelAsDate = timeSeriesRelativeDuration === "All" ? undefined : labelAsTimeGetter(rhb);
            // FIXME: I guess we have to deal with timezones here probably.
            const labelTimestamp = isBound(maybeLabelAsDate) ? maybeLabelAsDate?.getUTCValue() : undefined;

            if (!isBound(labelName)) {
                return undefined;
            }

            const chartRowData: DataPlotRowData = {
                name: labelName,
                timestamp: labelTimestamp,
                points: {},
            };

            // if the row has a group property, we need to decompose it to get the grouped labels.
            // fortunately, the values we expect get set at correct data key via withGroupBy
            if (row.hasOwnProperty("group")) {
                const { group } = decomposeAggregateRow(row);
                const stringGroup = asMaybeString(group);
                const dateGroup = asMaybeDate(group);
                if (stringGroup !== undefined) {
                    chartRowData.name = stringGroup;
                }
                if (dateGroup !== undefined) {
                    chartRowData.timestamp = dateGroup.getUTCValue();
                }
            }

            dataPoints.forEach((_, index) => {
                const [valueGetter] = dataPointRowValueGetters[index];
                const [colorGetter] = dataPointColorValueGetters[index];
                const value = valueGetter(rhb);
                const color = colorGetter(rhb);
                const maybeNumericValue = asMaybeNumber(value);
                const dataKey = columnsToDisplay[index]?.dataKey;
                if (value === undefined || maybeNumericValue === undefined || dataKey === undefined) {
                    return;
                }
                chartRowData.points[dataKey] = {
                    value: maybeNumericValue,
                    color: isBound(color) ? color : undefined,
                };
            });
            return chartRowData;
        }

        return makeSimpleWireTableComponentHydratorConstructor(
            ib,
            () => {
                // noop 🎉 see withOnlyQueries in fluent spec.
                return undefined;
            },
            (rhb, query) => {
                if (labelAxisColumn === undefined) {
                    return undefined;
                }

                const timeSeriesRelativeDurationState = rhb.getState<TimeSeriesRelativeDuration | "All">(
                    "timeSeriesRelativeDurationState",
                    isTimeSeriesRelativeDurationOrShowAll,
                    timeSeriesRelativeDuration,
                    true
                );

                const timeSeriesRelativeDurationValue = enableTimeFilters
                    ? timeSeriesRelativeDurationState.value
                    : timeSeriesRelativeDuration;

                query = query.withGroupBy({
                    columns: [labelAxisColumn.name],
                    aggregates: mapFilterUndefined(dataPoints, (dataPoint, i) => {
                        const columnName = getColumnProperty(dataPoint.value);
                        const column = maybeGetTableColumn(ib.tables.input, columnName);
                        const rollupKind = columnsToDisplay[i].rollupKind;
                        if (column === undefined || columnName === undefined || rollupKind === undefined) {
                            return undefined;
                        }
                        const columnIsQueryable = isQueryableColumn(
                            ib.adc,
                            ib.tables.input,
                            column,
                            gbtComputedColumnsAlpha,
                            gbtDeepLookups
                        );

                        if (!columnIsQueryable) {
                            return undefined;
                        }

                        return {
                            column: columnName,
                            kind: convertDataPlotRollupKindToRollupKind(rollupKind),
                            name: columnName,
                        };
                    }),
                    sort: [{ columnName: labelAxisColumn.name, order: "asc" }],
                    // since we always aggregate we can make this fairly high number of rows
                    limit: 4_000,
                });

                if (timeSeriesRelativeDurationValue !== "All") {
                    const { start, end } = convertTimeDurationToBucket(timeSeriesRelativeDurationValue);
                    const afterCondition: FilterCondition = {
                        kind: BinaryPredicateCompositeOperator.IsOnOrAfter,
                        lhs: { columnName: labelAxisColumn.name },
                        rhs: GlideDateTime.fromTimeZoneAwareDate(start).toDocumentData(),
                        negated: false,
                    };
                    const beforeCondition: FilterCondition = {
                        kind: BinaryPredicateCompositeOperator.IsOnOrBefore,
                        lhs: { columnName: labelAxisColumn.name },
                        rhs: GlideDateTime.fromTimeZoneAwareDate(end).toDocumentData(),
                        negated: false,
                    };
                    query = query.withCondition(afterCondition);
                    query = query.withCondition(beforeCondition);
                }

                const table = rhb.resolveQueryAsTable(query);

                if (table === undefined || isLoadingValue(table)) {
                    return undefined;
                }

                const rows = table.asMutatingArray();

                let chartData: DataPlotRowData[] = [];
                for (const row of rows) {
                    const queryableRhb = rhb?.makeHydrationBackendForRow(row, undefined, ib.tables);
                    if (queryableRhb === undefined) {
                        continue;
                    }
                    const chartDataRow = makeChartDataFromRow(queryableRhb, row);
                    if (chartDataRow !== undefined) {
                        chartData.push(chartDataRow);
                    }
                }

                // Eventually, it would be better to perform this aggregation server side
                // but we will need to support date_trunc or similar in SQL mapping.
                if (timeSeriesRelativeDurationValue !== "All") {
                    const timeSeriesInterval = getDefaultIntervalFromDuration(timeSeriesRelativeDurationValue);
                    const aggregationsMap = new Map<string, DataPlotRollupKind | undefined>();
                    for (const column of columnsToDisplay) {
                        aggregationsMap.set(column.dataKey, column.rollupKind as DataPlotRollupKind);
                    }
                    chartData = createTimeSeriesAggregationInBuckets(
                        makeTimeBucketsFromDurationAndInterval(timeSeriesRelativeDurationValue, timeSeriesInterval),
                        chartData,
                        aggregationsMap
                    );
                }

                const formattedData =
                    timeSeriesRelativeDurationValue === "All" ? autoFormatLabelsFromData(chartData) : chartData;
                return makeComponent(rhb, formattedData, timeSeriesRelativeDurationState);
            }
        );
    }
);
