import type { WireDataPlotComponent } from "@glide/fluent-components/dist/js/base-components";
import type { WireRenderer } from "../wire-renderer";
import type { ComponentProps } from "react";
import React from "react";
import { ComposedChart as RechartsComposedChart } from "../charts/composed-loader";
import { TextComponentStyle } from "@glide/component-utils";
import { isEmptyOrUndefinedish } from "@glide/support";
import { defaultWireAppAccentContextOverlayUndo, mergeTheme, TailwindThemeProvider } from "@glide/theme";
import { Text } from "../../components/text/text";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";
import {
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
    WireListContainer,
} from "../wire-list-container/wire-list-container";
import { APP_MODAL_ROOT, UIStyleVariant, ValueChangeSource } from "@glide/wire";
import "twin.macro";
import { useWireValue } from "../../wire-lib";
import { TimeSeriesRelativeDuration } from "@glide/fluent-components/dist/js/fluent-components";
import { definedMap } from "@glideapps/ts-necessities";
import * as WireDropdown from "../../renderers/wire-dropdown-menu/wire-dropdown-menu";
import { GlideIcon } from "@glide/common";

type WireDataPlotRenderer = WireRenderer<Omit<WireDataPlotComponent, "format">, { isFirstComponent: boolean }>;

export const WireDataPlot: WireDataPlotRenderer = React.memo(p => {
    const {
        data,
        columnsToDisplay,
        graphTitle,
        caption,
        gradientColors,
        gradientMapping,
        colorScheme,
        showYAxisLabels,
        showLegend,
        chartSize,
        backend,
        searchBar,
        timeSeriesRelativeDurationState,
        enableTimeFilters,
    } = p;
    const mergedTheme = mergeTheme(useWireAppTheme(), [defaultWireAppAccentContextOverlayUndo]);

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);
    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);

    return (
        <div tw="px-4 pb-4 rounded-xl border border-border-base bg-bg-front gp-sm:px-5 gp-md:px-6">
            {!isEmptyOrUndefinedish(graphTitle) && (
                <TailwindThemeProvider theme={mergedTheme}>
                    <Text
                        tw="my-3 font-semibold [font-size: 22px]
                                    gp-md:([font-size: 23px] tracking-tight)
                                    gp-lg:[font-size: 24px]
                                    gp-sm:([letter-spacing: -0.01em])"
                        variant={TextComponentStyle.headlineMedium}
                        element="h2">
                        {graphTitle}
                    </Text>
                </TailwindThemeProvider>
            )}
            <WireListContainer
                appKind={backend.appKind}
                titleActions={[]}
                {...filterArgs}
                multipleFilterProps={multipleFilterProps}
                styleVariant={UIStyleVariant.Default}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                isFirstComponent={false}
                TimeSeriesFilterComponent={
                    enableTimeFilters ? (
                        <TimeSeriesSelector
                            timeSeriesRelativeDurationState={timeSeriesRelativeDurationState}
                            backend={backend}
                        />
                    ) : null
                }>
                <div tw="[span]:(text-xs text-text-xpale) [tspan]:(text-xs text-text-xpale)">
                    <RechartsComposedChart
                        data={data}
                        columnsToDisplay={columnsToDisplay}
                        colorScheme={colorScheme}
                        gradientMapping={gradientMapping}
                        gradientColors={gradientColors}
                        showYAxisLabels={showYAxisLabels}
                        showLegend={showLegend}
                        chartSize={chartSize}
                    />
                </div>
                {!isEmptyOrUndefinedish(caption) && (
                    <TailwindThemeProvider theme={mergedTheme}>
                        <Text
                            tw="text-center my-3 [font-size: 11px]
                                    gp-md:([font-size: 12px] tracking-tight)
                                    gp-lg:[font-size: 13px]
                                    gp-sm:([letter-spacing: -0.01em])"
                            variant={TextComponentStyle.footnote}
                            element="h5">
                            {caption}
                        </Text>
                    </TailwindThemeProvider>
                )}
            </WireListContainer>
        </div>
    );
});

const TimeSeriesSelector: React.FC<{
    timeSeriesRelativeDurationState: ComponentProps<WireDataPlotRenderer>["timeSeriesRelativeDurationState"];
    backend: ComponentProps<WireDataPlotRenderer>["backend"];
}> = p => {
    const { backend, timeSeriesRelativeDurationState } = p;
    const timeSeriesState = definedMap(timeSeriesRelativeDurationState, state =>
        // This is not a hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useWireValue(state, backend)
    );

    const onChangeTimeSeries = React.useCallback(
        (newTimeSerie: TimeSeriesRelativeDuration) => {
            const timeSerieToSet = newTimeSerie === timeSeriesState?.[0] ? "All" : newTimeSerie;
            timeSeriesState?.[1]?.(timeSerieToSet, ValueChangeSource.User);
        },
        [timeSeriesState]
    );
    const checkIfSelected = React.useCallback(
        (state: TimeSeriesRelativeDuration) => {
            return state === timeSeriesState?.[0];
        },
        [timeSeriesState]
    );
    const timeSerieItems = React.useMemo(
        () => [
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last24Hours),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last24Hours),
                value: TimeSeriesRelativeDuration.Last24Hours,
                displayValue: "Past 24 hours",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last7Days),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last7Days),
                value: TimeSeriesRelativeDuration.Last7Days,
                displayValue: "Past 7 days",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last30Days),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last30Days),
                value: TimeSeriesRelativeDuration.Last30Days,
                displayValue: "Past 30 days",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last90Days),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last90Days),
                value: TimeSeriesRelativeDuration.Last90Days,
                displayValue: "Past 90 days",
            },
            {
                isSelected: checkIfSelected(TimeSeriesRelativeDuration.Last12Months),
                onSelect: () => onChangeTimeSeries(TimeSeriesRelativeDuration.Last12Months),
                value: TimeSeriesRelativeDuration.Last12Months,
                displayValue: "Past 12 months",
            },
        ],
        [checkIfSelected, onChangeTimeSeries]
    );
    const selected = React.useMemo(() => timeSerieItems.filter(item => item.isSelected)[0], [timeSerieItems]);
    const selectedShortTitle = selected?.displayValue.replace("Past", "").trim() ?? "All";
    return (
        <WireDropdown.Root>
            <WireDropdown.Trigger
                tw="relative bg-n200A! border border-transparent flex shrink-0 grow-0 text-base text-text-contextual-xpale 
                items-center justify-between h-9 p-2 transition duration-75 rounded-lg first:ml-0 not-last:mr-2 gp-md:px-3
                page-hover:(ring-1 ring-border-dark border-border-dark) focus-within:(ring-1 border-text-contextual-accent ring-text-contextual-accent)"
                title={selectedShortTitle}
                onClick={e => e.stopPropagation()}>
                <div data-icon tw="transition-colors mr-1.5">
                    <GlideIcon tw="-mb-px shrink-0" kind="stroke" icon="st-calendar" iconSize={16} />
                </div>
                <div tw="overflow-hidden flex-1 text-left whitespace-nowrap text-ellipsis">{selectedShortTitle}</div>
                <div data-icon tw="transition-colors ml-2">
                    <GlideIcon tw="-mb-px shrink-0" kind="stroke" icon="st-caret" iconSize={14} />
                </div>
            </WireDropdown.Trigger>
            <WireDropdown.Portal container={document.getElementById(APP_MODAL_ROOT)}>
                <WireDropdown.Content align="start" side="bottom" sideOffset={2} tw="min-w-[200px]">
                    {timeSerieItems?.map(o => {
                        const isSelected = o.isSelected;
                        return (
                            <WireDropdown.CheckboxItem
                                key={o.value}
                                checked={isSelected}
                                onClick={e => e.stopPropagation()}
                                tw="p-1 w-52"
                                onSelect={o.onSelect}>
                                {o?.displayValue ?? "None"}
                            </WireDropdown.CheckboxItem>
                        );
                    })}
                </WireDropdown.Content>
            </WireDropdown.Portal>
        </WireDropdown.Root>
    );
};
