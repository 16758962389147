import type {
    ActionDescription,
    ActionOutputDescriptor,
    AutomationRootNode,
    PropertyDescription,
} from "@glide/app-description";
import { ActionNodeKind, ActionKind, getCompoundActionProperty, PropertyKind } from "@glide/app-description";
import type { AppDescriptionContext, PropertyDescriptor } from "@glide/function-utils";
import {
    makeInlineTemplatePropertyDescriptor,
    makeNumberPropertyDescriptor,
    RequiredKind,
    getPrimitiveNonHiddenColumnsSpec,
    ColumnPropertyFlag,
    ColumnPropertyHandler,
    PropertySection,
} from "@glide/function-utils";
import type {
    WireActionHydrator,
    WireActionInflationBackend,
    WireActionResult,
    WireActionResultBuilder,
    WireRowActionHydrationValueProvider,
} from "@glide/wire";
import { ICON_PALE } from "../plugins/icon-colors";
import type { StaticActionContext } from "../static-context";
import type { ActionDescriptor } from "./action-descriptor";
import { ActionGroup } from "./action-descriptor";
import { BaseActionHandler } from "./base";
import { triggerAutomation } from "@glide/backend-api";
import { isErrorInfo } from "@glide/error-info";
import type { ColumnType, PrimitiveGlideTypeKind } from "@glide/type-schema";
import {
    getNonHiddenColumns,
    getTableColumnDisplayName,
    isDateTimeTypeKind,
    isNumberTypeKind,
    isPrimitiveArrayType,
    isPrimitiveType,
    isStringyStringTypeKind,
    makePrimitiveType,
} from "@glide/type-schema";
import { isExperimentEnabled } from "@glide/common-core/dist/js/use-feature-settings";
import { assertNever, isArray, mapFilterUndefined } from "@glideapps/ts-necessities";
import type { GroundValue, LoadedGroundValue, Unbound } from "@glide/computation-model-types";
import { asMaybeDate, asMaybeNumber, asMaybeString, isBound, isLoadingValue } from "@glide/computation-model-types";
import type { ColumnValues } from "@glide/common-core";
import type { JSONValue, PluginTierList } from "@glide/plugins-codecs";
import { asMaybeBoolean } from "@glide/common-core/dist/js/type-conversions";
import { GlideDateTime, GlideJSON } from "@glide/data-types";
import type { DescriptionToken } from "./action-handler";
import { makeTierList } from "@glide/plugins";

export interface TriggerAutomationActionDescription extends ActionDescription {
    readonly kind: ActionKind.TriggerAutomation;
    readonly automation: PropertyDescription | undefined;
}

interface AutomationInfo {
    readonly actionID: string;
    readonly action: AutomationRootNode;
    readonly title: string | undefined;
}

export function findAutomationToTrigger(
    desc: TriggerAutomationActionDescription | undefined,
    adc: AppDescriptionContext,
    includeDisabled: boolean
): AutomationInfo | string {
    const actionID = getCompoundActionProperty(desc?.automation);
    if (actionID === undefined) return "No workflow chosen";

    const builderAction = adc.builderActions.get(actionID);
    if (builderAction === undefined) return "Workflow does not exist";
    const perApp = builderAction.perApp[adc.appID];
    if (perApp === undefined) return "Workflow is not configured for this app";
    if (perApp.automation === undefined) return "Not an automated workflow";
    // Must have at least one trigger that's enabled
    if (!includeDisabled && !perApp.automation.triggers.some(t => t.enabled)) return "Workflow is disabled";

    if (builderAction.action.kind !== ActionNodeKind.AutomationRoot) return "Workflow is invalid";

    return {
        actionID,
        action: builderAction.action,
        title: builderAction.title,
    };
}

function makeInputName(input: ActionOutputDescriptor): string {
    return `input_${input.name}`;
}

/**
 * This is the reverse of `convertJSONToInputData`, but just for a single
 * input.  `undefined` means something could not be converted, which is an
 * error.  Exported for testing.
 */
export function convertInputToJSON(value: LoadedGroundValue, type: ColumnType): JSONValue | undefined {
    function convertPrimitive(v: LoadedGroundValue, kind: PrimitiveGlideTypeKind): JSONValue | undefined {
        if (isStringyStringTypeKind(kind)) {
            return asMaybeString(v);
        } else if (isNumberTypeKind(kind)) {
            return asMaybeNumber(v);
        } else if (kind === "boolean") {
            return asMaybeBoolean(v);
        } else if (isDateTimeTypeKind(kind)) {
            return asMaybeDate(v)?.toISOStringWithTimeZone();
        } else if (kind === "json") {
            if (typeof v === "string" || typeof v === "number" || typeof v === "boolean") return v;
            if (v instanceof GlideDateTime) return v.toISOStringWithTimeZone();
            if (v instanceof GlideJSON) return v.jsonValue as JSONValue;
            if (isArray(v)) {
                const result: JSONValue[] = [];
                for (const item of v) {
                    const converted = convertPrimitive(item, kind);
                    if (converted === undefined) return undefined;
                    result.push(converted);
                }
                return result;
            }
            // Anything else (rows, tables, queries) can't be converted
            return undefined;
        } else {
            return assertNever(kind);
        }
    }

    function convertPrimitiveArray(v: LoadedGroundValue, kind: PrimitiveGlideTypeKind): JSONValue[] | undefined {
        if (!isArray(v)) {
            const converted = convertPrimitive(v, kind);
            if (converted === undefined) return undefined;
            return [converted];
        }

        const result: JSONValue[] = [];
        for (const item of v) {
            const converted = convertPrimitiveArray(item, kind);
            if (converted === undefined) return undefined;
            result.push(...converted);
        }
        return result;
    }

    if (isPrimitiveType(type)) {
        return convertPrimitive(value, type.kind);
    } else if (isPrimitiveArrayType(type) && isArray(value)) {
        return convertPrimitiveArray(value, type.items.kind);
    } else {
        return undefined;
    }
}

export class TriggerAutomationActionHandler extends BaseActionHandler<TriggerAutomationActionDescription> {
    public readonly kind = ActionKind.TriggerAutomation;
    public readonly name = "Trigger workflow";
    public readonly iconName = {
        kind: "stroke" as const,
        icon: "st-workflow" as const,
        strokeFgColor: ICON_PALE,
    };

    public getTier(): PluginTierList | undefined {
        return makeTierList("starter");
    }

    public getDescriptor(
        desc: TriggerAutomationActionDescription | undefined,
        env: StaticActionContext<AppDescriptionContext>
    ): ActionDescriptor {
        const automationProperty: PropertyDescriptor = {
            kind: PropertyKind.CompoundAction,
            property: { name: "automation" },
            label: "Workflow",
            section: PropertySection.Data,
            allowPicking: { apps: false, automations: true },
        };

        let inputDescriptors: PropertyDescriptor[];

        const automation = findAutomationToTrigger(desc, env.context, true);
        if (typeof automation !== "string") {
            inputDescriptors = mapFilterUndefined(automation.action.inputs, input => {
                const displayName = getTableColumnDisplayName(input);
                const inputName = makeInputName(input);
                if (isStringyStringTypeKind(input.type.kind)) {
                    return makeInlineTemplatePropertyDescriptor(
                        inputName,
                        displayName,
                        "",
                        false,
                        "withLabel",
                        undefined,
                        {}
                    );
                } else if (input.type.kind === "number") {
                    return makeNumberPropertyDescriptor(
                        inputName,
                        displayName,
                        "",
                        RequiredKind.NotRequiredDefaultMissing,
                        0,
                        undefined,
                        {}
                    );
                } else if (isPrimitiveType(input.type)) {
                    return new ColumnPropertyHandler(
                        inputName,
                        displayName,
                        [
                            ColumnPropertyFlag.Optional,
                            ColumnPropertyFlag.Editable,
                            ColumnPropertyFlag.EmptyByDefault,
                            ColumnPropertyFlag.AllowUserProfileColumns,
                        ],
                        undefined,
                        [displayName],
                        getPrimitiveNonHiddenColumnsSpec,
                        input.type.kind,
                        PropertySection.Data
                    );
                } else if (isPrimitiveArrayType(input.type)) {
                    return new ColumnPropertyHandler(
                        inputName,
                        displayName,
                        [
                            ColumnPropertyFlag.Optional,
                            ColumnPropertyFlag.Editable,
                            ColumnPropertyFlag.EmptyByDefault,
                            ColumnPropertyFlag.AllowUserProfileColumns,
                        ],
                        undefined,
                        [displayName],
                        {
                            columnTypeIsAllowed: t => isPrimitiveArrayType(t) || t.kind === "json",
                            getCandidateColumns: table => getNonHiddenColumns(table),
                        },
                        input.type.kind,
                        PropertySection.Data
                    );
                } else {
                    // This should not happen: automations don't take
                    // relations as inputs.
                    return undefined;
                }
            });
        } else {
            inputDescriptors = [];
        }

        const isEnabled = isExperimentEnabled("triggerAutomationAction", env.context.userFeatures);

        return {
            name: this.name,
            group: ActionGroup.WorkflowLogic,
            needsScreenContext: false,
            isLegacy: !isEnabled,
            properties: [automationProperty, ...inputDescriptors],
            outputs: [
                {
                    name: "runID",
                    type: makePrimitiveType("string"),
                    displayName: "Run ID",
                    description: "The ID of the workflow run",
                },
            ],
        };
    }

    // This action doesn't edit any columns.  If we let our base class handle
    // this, it would step into the compound action, which would lead to two
    // problems:
    // 1. Which columns are edited in the compound action is irrelevant,
    //    because this action isn't running it, it's just invoking it to be
    //    run independently.
    // 2. It can lead to an infinite recursion if any action triggers itself
    //    directly or indirectly recursively.
    public getEditedColumns(): undefined {
        return undefined;
    }

    public getTokenizedDescription(
        desc: TriggerAutomationActionDescription,
        env: StaticActionContext<AppDescriptionContext>
    ): readonly DescriptionToken[] | undefined {
        const automation = findAutomationToTrigger(desc, env.context, false);
        if (typeof automation === "string") return undefined;
        if (automation.title === undefined) return undefined;

        return [
            {
                kind: "column",
                value: automation.title,
            },
        ];
    }

    public inflate(
        ib: WireActionInflationBackend,
        desc: TriggerAutomationActionDescription,
        arb: WireActionResultBuilder
    ): WireActionHydrator | WireActionResult {
        const { adc, appFacilities, triggerAutomationOrigin } = ib;
        const { appID } = adc;

        const automation = findAutomationToTrigger(desc, adc, false);
        if (typeof automation === "string") return arb.inflationError(automation);
        const { actionID, action } = automation;

        const inputGetters: [
            ActionOutputDescriptor,
            (vp: WireRowActionHydrationValueProvider) => GroundValue | Unbound
        ][] = [];
        for (const input of action.inputs) {
            const [valueGetter, type, , errorMessage] = ib.getValueGetterForProperty(
                (desc as any)[makeInputName(input)],
                false
            );
            if (errorMessage !== undefined) {
                return arb.inflationError(`Error for input ${getTableColumnDisplayName(input)}: ${errorMessage}`);
            }
            if (type === undefined) continue;

            inputGetters.push([input, valueGetter]);
        }

        return (vp, skipLoading) => {
            const triggerData: ColumnValues = {};
            for (const [input, getter] of inputGetters) {
                const value = getter(vp);
                if (!isBound(value)) {
                    return arb.error(true, `Input ${getTableColumnDisplayName(input)} is misconfigured`);
                }
                if (isLoadingValue(value)) {
                    if (!skipLoading) return arb.loading();
                    continue;
                }
                if (value === undefined) continue;
                triggerData[input.name] = convertInputToJSON(value, input.type);
            }

            return async _ab => {
                const runID = await triggerAutomation(
                    appID,
                    actionID,
                    triggerData,
                    triggerAutomationOrigin,
                    appFacilities
                );
                if (isErrorInfo(runID)) return arb.errorFromHTTPStatus(runID.status, runID.message);

                return arb.withOutputs({ runID }).success();
            };
        };
    }
}
