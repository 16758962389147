import { load, save } from "./storage";
import { defined, definedMap } from "@glideapps/ts-necessities";

const featureFlagsKey = "featureFlags";

const defaultFlags = {
    debugPrint: false,
    forceStaging: false,
    saveSample: false,
    showExperimental: true,
    halloween: false,
    useLocalPageMetadataService: false,
    debugBrowserReload: false,
    injectLocalDatastorePersistenceFaults: false,
    injectFileUploadFaults: false,
    logStorageController: false,
    useBuilderLargeContainer: false,
    useFunctionsProxy: false,
    useBuilderFunctionsProxy: false,
    visualizeComputationGraph: false,
    useFirestorePersistence: false,
    stallAppSaver: false,
    // If enabled, this turns on the full Offline Queue, even in
    // situations where it normally would be turned off. This is only
    // useful for debugging.
    forceOfflineQueue: false,
    bypassPermanentServiceWorkerHangReset: false,
    simulateUnauthorizedActionPosting: false,
    injectActionPostingFaults: false,
    stallActionPosting: false,
    timeLoadAppFromFirebase: false,
};

type FeatureFlags = typeof defaultFlags;
type FeatureFlagName = keyof FeatureFlags;

interface FeatureFlagStorage {
    load(key: string, defaultFlags: FeatureFlags): FeatureFlags;
    save(key: string, flags: FeatureFlags): void;
}

let storage: FeatureFlagStorage | undefined;

let featureFlags: FeatureFlags | undefined;

function hasSelfStorage() {
    try {
        return self.localStorage !== undefined;
    } catch {
        return false;
    }
}

function getFlags(): FeatureFlags {
    if (storage === undefined && hasSelfStorage()) {
        // We can't use logError here, it depends on getFeatureFlag.
        // eslint-disable-next-line no-console
        console.trace("We should not be getting feature flags before storage is ready");
    }
    if (featureFlags === undefined) {
        featureFlags = definedMap(storage, s => s.load(featureFlagsKey, defaultFlags)) ?? defaultFlags;
    }
    return featureFlags;
}

function saveFlags(): void {
    if (storage === undefined) return;
    storage.save(featureFlagsKey, getFlags());
}

/**
 * Returns a feature setting flag that is settable locally via the JS console.
 *
 * @deprecated You most likely want to use `getFeatureSetting`.
 */
export function getFeatureFlag(name: FeatureFlagName): boolean {
    const flags = getFlags();
    if (flags[name] === undefined) {
        flags[name] = defaultFlags[name];
        saveFlags();
    }
    return flags[name];
}

function initFeatureFlags(s: FeatureFlagStorage, setGlobal: (name: string, value: unknown) => void): void {
    if (storage !== undefined) return;

    storage = s;
    featureFlags = storage.load(featureFlagsKey, defaultFlags);

    for (const k of Object.keys(defaultFlags) as (keyof FeatureFlags)[]) {
        setGlobal(`setFeatureFlag${k.substring(0, 1).toUpperCase()}${k.substring(1)}`, (flag: boolean = true) => {
            const flags = getFlags();
            flags[k] = flag;
            saveFlags();
        });
    }

    setGlobal("resetFeatureFlags", () => {
        featureFlags = defaultFlags;
        saveFlags();
    });

    setGlobal("getFeatureFlags", getFlags);
}

export function initFeatureFlagsWithStorage(): void {
    initFeatureFlags({ load: (k, v) => defined(load(k, v, v)), save }, (name, value) => {
        (window as any)[name] = value;
    });
}
