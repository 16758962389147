import type { FlattenInterpolation, ThemedStyledFunction, ThemeProps } from "styled-components";
import styled, { css } from "styled-components";

import { disabledProps } from "../../lib/shared-style";
import type {
    ButtonIcon,
    ButtonSizes,
    ButtonStyleProps,
    ButtonStyles,
    ButtonType,
    ButtonVariants,
    IconType,
    SplitType,
} from "./button-lib";
import { ButtonSizeMap, backgroundColorMapping, backgroundHoverStyles, fontColorMapping } from "./button-lib";
import { systemFontFamily } from "@glide/base-theme";

const GradientFontColor = css`
    .label {
        color: ${p => p.theme.aqua500};
    }
`;

const splitLeftStyle = css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

const splitRightStyle = css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const iconMargins = {
    xsm: 2,
    sm: 4,
    md: 6,
    lg: 6,
    xlg: 8,
    "2xlg": 10,
};

const buttonSizes = {
    xsm: css`
        height: 24px;
        border-radius: 8px;
        font-size: 12px;
    `,
    sm: css`
        height: 28px;
        border-radius: 8px;
        font-size: 13px;
    `,
    md: css`
        height: 32px;
        border-radius: 8px;
        font-size: 13px;
    `,
    lg: css`
        height: 36px;
        border-radius: 9px;
        font-size: 14px;
    `,
    xlg: css`
        height: 40px;
        border-radius: 9px;
        font-size: 14px;
    `,
    "2xlg": css`
        height: 48px;
        border-radius: 10px;
        font-size: 15px;
    `,
};

const buttonPadding = {
    xsm: css`
        padding: 0 8px;
    `,
    sm: css`
        padding: 0 10px;
    `,
    md: css`
        padding: 0 12px;
    `,
    lg: css`
        padding: 0 14px;
    `,
    xlg: css`
        padding: 0 16px;
    `,
    "2xlg": css`
        padding: 0 24px;
    `,
};

const iconButtonPadding = {
    xsm: css`
        padding: 0 5px 0 3px;
    `,
    sm: css`
        padding: 0 5px 0 3px;
    `,
    md: css`
        padding: 0 7px 0 5px;
    `,
    lg: css`
        padding: 0 7px 0 5px;
    `,
    xlg: css`
        padding: 0 9px 0 7px;
    `,
    "2xlg": css`
        padding: 0 9px 0 7px;
    `,
};

const getButtonStyles = (type: ButtonType, variant: ButtonVariants): ButtonStyles => {
    const buttonStyle = `${type}${variant}` as ButtonStyles;
    return buttonStyle;
};

const getHoverBGColor = (btnStyle: ButtonStyles) => {
    return backgroundHoverStyles[btnStyle];
};

const getButtonPadding = (size: ButtonSizes, iconType?: IconType, split?: SplitType, icon?: ButtonIcon) => {
    if (split === "right" && icon && iconType === "iconOnly") {
        return iconButtonPadding[size];
    } else if (!split && icon && iconType === "iconOnly") {
        return css`
            padding: 0px;
        `;
    }
    return buttonPadding[size];
};

const getButtonWidth = (
    size: ButtonSizes,
    isFullWidth?: boolean,
    iconType?: IconType,
    split?: SplitType,
    icon?: ButtonIcon
) => {
    if (!split && icon && iconType === "iconOnly") {
        return css`
            width: ${ButtonSizeMap[size]}px;
        `;
    } else if (isFullWidth) {
        return css`
            width: 100%;
        `;
    } else
        return css`
            width: auto;
        `;
};

const getMinWidth = (size: ButtonSizes, iconType?: IconType, minWidth?: number) => {
    if (iconType === "iconOnly") {
        return css`
            min-width: ${ButtonSizeMap[size]}px;
        `;
    }

    if (minWidth !== undefined) {
        return css`
            min-width: ${minWidth}px;
        `;
    }

    return css`
        min-width: ${ButtonSizeMap[size] * 2}px;
    `;
};

const getFontColor = (buttonStyle: ButtonStyles) => {
    let result: FlattenInterpolation<ThemeProps<any>> = css`
        color: ${p => p.theme.n0};
        :hover {
            color: ${p => p.theme.n0};
        }
    `;

    if (Object.keys(fontColorMapping).includes(buttonStyle)) {
        const val = fontColorMapping[buttonStyle];
        result = css`
            color: ${p => p.theme[val]};
            :hover {
                color: ${p => p.theme[val]};
            }
            .button-icon,
            .dropdown-icon {
                opacity: 0.75;
            }
            &:hover {
                .button-icon,
                .dropdown-icon {
                    opacity: 1;
                }
            }
        `;
    } else if (buttonStyle.includes("primary") && buttonStyle !== "primaryinverse") {
        result = css`
            color: #ffffff;
            :hover {
                color: #ffffff;
            }
        `;
    } else if (buttonStyle.includes("gradient") && buttonStyle !== "primarygradient") {
        result = GradientFontColor;
    }

    return result;
};

const getHoverStyles = (type: ButtonType, variant: ButtonVariants) => {
    const buttonStyle = getButtonStyles(type, variant);
    let result: FlattenInterpolation<ThemeProps<any>> = css``;
    if (type === "primary") {
        result = css`
            &:hover,
            &:focus {
                .button-container:after {
                    background-color: rgba(255, 255, 255, 0.12);
                }
            }
        `;
    } else if (type === "secondary" || type === "minimal") {
        const bg = getHoverBGColor(buttonStyle);
        if (bg !== undefined)
            result = css`
                &:hover,
                &:focus {
                    background: ${p => p.theme[bg]};
                }
            `;
    } else if (type === "tertiary") {
        result = css`
            box-shadow: ${p => p.theme.shadowDark1};
            &:hover,
            &:focus {
                box-shadow: ${p => p.theme.shadowXdark1};
            }
        `;
    }
    return result;
};

const getBackgroundColor = (buttonStyle: ButtonStyles) => {
    // Default to light grey if no background matches
    let result: FlattenInterpolation<ThemeProps<any>> = css`
        background-color: ${p => p.theme.accentPrimary};
    `;

    if (buttonStyle.includes("tertiary")) {
        result = css`
            background-color: ${p => p.theme.bgFronter};
        `;
    } else if (buttonStyle.includes("minimal")) {
        result = css`
            background-color: transparent;
        `;
    } else if (Object.keys(backgroundColorMapping).includes(buttonStyle)) {
        const val = backgroundColorMapping[buttonStyle];
        result = css`
            background: ${p => p.theme[val]};
        `;
    }
    return result;
};

const getIconTypeStyle = (type: IconType, size: ButtonSizes) => {
    const margin = iconMargins[size];
    switch (type) {
        case "default":
        case "iconLeading":
            return css`
                .label {
                    margin-left: ${margin}px;
                }
                .button-icon {
                    margin-left: -${margin / 2}px;
                }
            `;
        case "iconTrailing":
        case "segmented":
            return css`
                flex-direction: row-reverse;
                .label {
                    margin-right: ${margin}px;
                }
                .button-icon {
                    margin-right: -${margin / 2}px;
                }
            `;
        case "iconOnly":
            return css`
                flex-direction: row;
                .label {
                    display: none;
                }
            `;
    }
};

const getDropdownStyle = (size: ButtonSizes) => {
    const margin = iconMargins[size];
    return css`
        .label {
            margin-right: ${margin}px;
        }
        .dropdown-icon {
            margin-right: -${margin / 2}px;
        }
    `;
};

const nonDOMprops: (string | number)[] = ["iconType", "buttonType", "localHref", "centeredTextAndIcon", "isFullWidth"];

const shouldForwardProp = (prop: string | number) => !nonDOMprops.includes(prop);

const cleanedUpStyledButton: ThemedStyledFunction<"button", any, ButtonStyleProps, never> = styled.button.withConfig({
    shouldForwardProp,
});

export const ButtonContainer = cleanedUpStyledButton`
    display: inline-block;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    transition: all ${p => p.theme.transitionSlow};
    cursor: pointer;
    font-family: ${systemFontFamily};

    ${p => getButtonWidth(p.size, p.isFullWidth, p.iconType, p.split, p.icon)};
    ${p => buttonSizes[p.size]};
    ${p => getBackgroundColor(getButtonStyles(p.buttonType, p.variant))}
    ${p => getFontColor(getButtonStyles(p.buttonType, p.variant))};
    ${p => getHoverStyles(p.buttonType, p.variant)}
    ${p => getMinWidth(p.size, p.iconType)};

    &.middle,
    &.left {
        ${splitLeftStyle}
        .button-container {
            ${splitLeftStyle}
        }
    }

    &.middle,
    &.right {
        border-left: 1px solid ${p => (p.buttonType === "primary" ? "rgba(255, 255, 255, 0.14)" : "n200a")};

        ${splitRightStyle}
        .button-container {
            ${splitRightStyle}
        }
    }

    .hide {
        visibility: hidden;
        opacity: 0;
    }

    &:hover {
        transition: all ${p => p.theme.transitionBase};
    }

    .label {
        display: block;
        text-align: center;
        margin: auto 0;
        width: ${p => (p.centeredTextAndIcon ? "auto" : "100%")};
        transition: all ${p => p.theme.transitionBase};
    }

    .button-icon,
    .dropdown-icon {
        transition: all ${p => p.theme.transitionBase};
        flex: 0 0 auto;
    }

    .process-icon {
        display: flex;
        position: absolute;
        width: 100%;
        left: 0px;
        transition: all ${p => p.theme.transitionBase};
        color: ${p => (p.variant === "gradient" && p.buttonType !== "primary" ? p.theme.b400 : "currentColor")};
    }

    .button-container {
        ${p => getButtonPadding(p.size, p.iconType, p.split, p.icon)};
        position: relative;
        display: flex;
        align-items: center;
        justify-content: ${p => (p.iconType === "iconOnly" || p.centeredTextAndIcon ? "center" : "space-between")};
        transition: all ${p => p.theme.transitionBase};
        border-radius: inherit;
        ${p => p.iconType !== undefined && p.icon && getIconTypeStyle(p.iconType, p.size)};
        ${p =>
            p.isDropdown &&
            (p.iconType === undefined || p.iconType === "default" || p.iconType === "iconLeading") &&
            getDropdownStyle(p.size)}
        width: 100%;
        height: 100%;

        /* Only used for primary buttons */
        &:after {
            content: "";
            position: absolute;
            inset: 0;
            background: rgba(255, 255, 255, 0);
            transition: all ${p => p.theme.transitionBase};
            border-radius: inherit;
        }
    }
    // remove left padding and change text align if the right button starts with text
    ${p =>
        p.split === "right" && p.icon && p.iconType === "iconTrailing"
            ? `.label { text-align: left; } .button-container { padding-left: 0; }`
            : ""}

    ${p => p.disabled && disabledProps}
`;
