import type { Unbound } from "@glide/computation-model-types";
import { UIAlignment, UIAspect, UIImageStyle, UIMapAspect, UISize } from "@glide/wire";
import classNames from "classnames";
import tw from "twin.macro";

import { styled } from "@glide/common-components";

// https://github.com/quicktype/glide/issues/13103
// using aspect-ratio vs padding method avoids need to stretch  when centering (w flexbox)
// https://caniuse.com/mdn-css_properties_aspect-ratio
const WireAspectContainerStyle = styled.div`
    width: auto;

    &.${UIImageStyle.Rectilinear} {
        border-radius: 12px;
    }

    &.${UIImageStyle.Circle} {
        .aspect-container img {
            border-radius: 9999px;
            aspect-ratio: 1/1;
        }
    }

    .aspect-container {
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .aspect-container img {
        object-fit: cover;
        border-radius: 12px;
    }

    &.${UIAlignment.Start} {
        justify-content: start;
    }

    &.${UIAlignment.Center} .aspect-container {
        justify-content: center;
    }

    &.${UIAlignment.End} .aspect-container,
    &.${UIAlignment.End} {
        justify-content: end;
    }

    &.${UIAspect.Square} .aspect-container {
        aspect-ratio: 1/1;
    }

    &.${UIAspect.FourByThree} .aspect-container {
        aspect-ratio: 4/3;
    }

    &.${UIAspect.SixteenByNine} .aspect-container {
        aspect-ratio: 16/9;
    }

    &.${UIAspect.ThreeByOne} .aspect-container {
        aspect-ratio: 3/1;
    }

    &.${UIAspect.ThreeByTwo} .aspect-container {
        aspect-ratio: 3/2;
    }

    &.${UIMapAspect.Medium} .aspect-container {
        ${tw`[aspect-ratio: 1/1] page-md:(h-[400px] [aspect-ratio: none]) page-lg:(h-[600px] [aspect-ratio: none])`}
    }

    &.${UIMapAspect.Large} .aspect-container {
        ${tw`h-[530px] page-md:h-[625px] page-lg:h-[800px]`}
    }

    &.${UIMapAspect.Small} .aspect-container {
        ${tw`w-full h-40 page-md:h-[220px] page-lg:h-[280px]`}
    }

    &.${UIAspect.Auto} .aspect-container,
    &.${UIImageStyle.Circle} .aspect-container {
        height: auto;
        aspect-ratio: 0;
        > * {
            position: relative;
            height: auto;
        }
    }

    &.${UISize.Small} {
        ${tw`[max-width:150px] page-md:[max-width:200px] page-lg:[max-width:250px] page-xl:[max-width:300px]
          page-2xl:[max-width:350px]`}
    }

    &.${UISize.Medium} {
        ${tw`[max-width:300px] page-md:[max-width:400px] page-lg:[max-width:500px] page-xl:[max-width:600px]
          page-2xl:[max-width:700px]`}
    }

    &.${UISize.Small}.fullWidthOnMobile, &.${UISize.Medium}.fullWidthOnMobile {
        ${tw`max-w-full`}
    }

    &.${UISize.Small}.${UIAlignment.Start}, &.${UISize.Medium}.${UIAlignment.Start} {
        margin: auto;
        margin-left: 0;
    }

    /* always use full size images on size full */
    &.${UISize.Full}, &.${UISize.Medium}, &.${UISize.Full} img {
        ${tw`w-full`}
    }
`;

interface AspectContainerProps {
    readonly aspectRatio?: UIAspect | UIMapAspect | Unbound;
    readonly imageStyle?: UIImageStyle | Unbound;
    readonly size?: UISize;
    readonly align?: UIAlignment;
    readonly className?: string;
    readonly onClick?: () => void;
    readonly fullWidthOnMobile?: boolean;
}

export const WireAspectContainer: React.FC<React.PropsWithChildren<AspectContainerProps>> = p => {
    const { aspectRatio, align, imageStyle, children, size, className, onClick, fullWidthOnMobile } = p;

    return (
        <WireAspectContainerStyle
            onClick={onClick}
            className={classNames(
                aspectRatio ?? UIAspect.Square,
                align ?? UIAlignment.Center,
                imageStyle,
                size,
                { fullWidthOnMobile },
                className
            )}
            tw="w-full overflow-hidden relative">
            <div className="aspect-container">{children}</div>
        </WireAspectContainerStyle>
    );
};

interface ImageAlternateProps {
    className?: string;
}

export const ImageAlternate: React.VFC<ImageAlternateProps> = p => {
    return <div tw="bg-n200A h-full w-full rounded-full" {...p} />;
};
