import "twin.macro";

import { TextComponentStyle } from "@glide/component-utils";
import type { WirePageProgressComponent } from "@glide/fluent-components/dist/js/fluent-components";
import { isUndefinedish } from "@glide/support";
import chroma from "chroma-js";
import * as React from "react";
import { css } from "styled-components";
import { useWireAppTheme } from "../../utils/use-wireapp-theme";

import { Text } from "../../components/text/text";
import type { WireRenderer } from "../wire-renderer";

function clamp01(value: number): number {
    return Math.min(1, Math.max(0, value));
}

// Isn't there a proper name for inverse lerp???
function inverseLerp(min: number, max: number, value: number): number {
    return clamp01((value - min) / (max - min));
}

type ColorStep = { position: number; color: string };

export function getColor(colorSteps: ColorStep[], position: number): string {
    if (colorSteps.length === 0) throw new Error("No color steps provided");
    if (position <= colorSteps[0].position) return colorSteps[0].color;
    if (position >= colorSteps[colorSteps.length - 1].position) return colorSteps[colorSteps.length - 1].color;
    for (let i = 0; i < colorSteps.length - 1; i++) {
        const start = colorSteps[i];
        const end = colorSteps[i + 1];
        if (position >= start.position && position <= end.position) {
            const ratio = (position - start.position) / (end.position - start.position);
            return chroma.mix(start.color, end.color, ratio).css();
        }
    }
    throw new Error("No color found for position");
}

// Create steps from string
function createColorStepFromString(
    color: string,
    minValue: number,
    maxValue: number,
    defaultPosition: number
): ColorStep | undefined {
    let position = defaultPosition;
    // check if it has a position
    const colorParts = color.split("=");

    if (colorParts.length > 2) return undefined;

    if (colorParts.length === 2) {
        // check if the position is a number or a percentage
        const positionValue = colorParts[0].trim();
        const colorValue = colorParts[1].trim();
        if (!chroma.valid(colorValue)) return undefined;
        if (positionValue.endsWith("%")) {
            const percPosition = parseFloat(positionValue.replace("%", ""));
            if (isNaN(percPosition)) return undefined;
            if (percPosition < 0 || percPosition > 100) return undefined;
            position = percPosition / 100;
        } else {
            const p = parseFloat(positionValue);
            if (isNaN(p)) return undefined;
            if (p < minValue || p > maxValue) return undefined;
            position = (p - minValue) / (maxValue - minValue);
        }
        return {
            position,
            color: colorValue,
        };
    }

    const colorValue = colorParts[0].trim();
    if (!chroma.valid(colorValue)) return undefined;
    return {
        position,
        color: colorValue,
    };
}

export function createColorSteps(color: string, minValue: number, maxValue: number): ColorStep[] {
    const colors = color.split(",").map(c => c.trim());
    return colors
        .map((c, i) => {
            const position = i === 0 ? 0 : i / (colors.length - 1);
            return createColorStepFromString(c, minValue, maxValue, position);
        })
        .filter(c => c !== undefined) as ColorStep[];
}

export const WirePageProgress: WireRenderer<WirePageProgressComponent> = React.memo(p => {
    const { min, max, value, title, description, hideValue, formattedValue, color } = p;

    const theme = useWireAppTheme();

    const cleanedFormattedValue = React.useMemo(() => {
        if (formattedValue === null || formattedValue === undefined || formattedValue.trim() === "") return undefined;
        return formattedValue;
    }, [formattedValue]);

    const [backgroundColor, fillColor] = React.useMemo(() => {
        const minValue = min?.value ?? 0;
        const maxValue = max?.value ?? 100;
        if (color !== null && color !== undefined && color.trim() !== "" && minValue < maxValue) {
            const colorSteps = createColorSteps(color, minValue, maxValue);
            if (colorSteps.length > 1) {
                const fillRatio = Math.max(0, Math.min(1, inverseLerp(minValue, maxValue, value?.value ?? 100)));
                try {
                    return [theme.n100A, getColor(colorSteps, fillRatio)];
                } catch {
                    // accent color will be used
                }
            } else if (colorSteps.length === 1) {
                const colorStep = colorSteps[0];
                return [chroma(colorStep.color).alpha(0.1).css(), chroma(colorStep.color).css()];
            }
        }
        return [chroma(theme.textContextualAccent).alpha(0.1).css(), chroma(theme.textContextualAccent).css()];
    }, [color, theme, min, max, value]);

    if (isUndefinedish(min) || isUndefinedish(max) || isUndefinedish(value)) {
        return null;
    }

    const fillRatio = inverseLerp(min.value, max.value, value.value);
    const xPercentage = (fillRatio - 1) * 100;

    return (
        <div>
            <div tw="flex justify-between items-end mb-2">
                <div>
                    <Text element="p" variant={TextComponentStyle.headlineXXXSmall}>
                        {title}
                    </Text>
                    <Text element="p" variant={TextComponentStyle.small}>
                        {description}
                    </Text>
                </div>
                {hideValue ? null : (
                    <Text element="p" variant={TextComponentStyle.small}>
                        {cleanedFormattedValue ?? value.formattedValue}
                    </Text>
                )}
            </div>
            <div
                tw="h-2 w-full rounded-full overflow-hidden"
                css={css`
                    background-color: ${backgroundColor};
                `}>
                <div
                    tw="h-full w-full rounded-full bg-text-contextual-accent transition-transform"
                    css={css`
                        background-color: ${fillColor};
                    `}
                    style={{ transform: `translateX(${xPercentage}%)` }}
                />
            </div>
        </div>
    );
});
