import type { BasePrimitiveValue, GlideDateTime } from "@glide/data-types";
import { type LoadedGroundValue, isPrimitive, type Unbound } from "@glide/computation-model-types";
import {
    asBoolean,
    asMaybeArrayOfStrings,
    asMaybeDate,
    asMaybeNumber,
    asMaybeString,
    asString,
    asTable,
} from "@glide/common-core/dist/js/computation-model/data";
import {
    type ActionDescription,
    type ActionKind,
    type ArrayContentDescription,
    type ComponentDescription,
    type MutatingScreenKind,
    PropertyKind,
    getEmojiProperty,
} from "@glide/app-description";
import {
    type ColumnType,
    type Description,
    type StringGlideTypeKind,
    type TableGlideType,
    getPrimitiveColumns,
    getStringTypeOrStringTypeArrayColumns,
    isDateTimeTypeKind,
    isPrimitiveArrayType,
    isPrimitiveType,
    makePrimitiveType,
    isBigTableOrExternal,
} from "@glide/type-schema";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import {
    type ActionPropertyDescriptor,
    type AppDescriptionContext,
    type ColumnTypePredicate,
    type EnumPropertyCase,
    type EnumPropertyDescriptorCase,
    type EnumVisual,
    type GetAllowedColumnsFunction,
    type GetAllowedTablesFunction,
    type IsEditedInApp,
    type MultiCasePropertyDescriptor,
    type PropertyDescriptor,
    type PropertyTableGetter,
    type Subcomponent,
    type SuperPropertySection,
    type TextPropertyDescriptorFlags,
    type ToPropertyDescriptionOrUndefined,
    type WhenPredicate,
    makeTableOrRelationPropertyDescriptor,
    ColumnPropertyHandler,
    StringPropertyHandler,
    ArrayPropertyStyle,
    ColumnPropertyFlag,
    EnumPropertyHandler,
    IconPropertyHandler,
    PropertySection,
    RequiredKind,
    SwitchPropertyHandler,
    TablePropertyHandler,
    makeDateTimePropertyDescriptor,
    makeImagePropertyDescriptor,
    makeNumberPropertyDescriptor,
    makeTextPropertyDescriptor,
    phoneProperties,
    titleProperties,
    makeInlineTemplatePropertyDescriptor,
    getPrimitiveNonHiddenColumnsSpec,
} from "@glide/function-utils";
import { isArray } from "@glide/support";
import type {
    WireValueGetter,
    WireValueInflationBackend,
    WireAction,
    WireEditableValue,
    WirePrimitiveValueWithType,
    WireValueWithFormatted,
    WireEditableValuePrimitiveValue,
} from "@glide/wire";
import { assert, assertNever, filterUndefined, mapFilterUndefined } from "@glideapps/ts-necessities";
import sortBy from "lodash/sortBy";
import startCase from "lodash/startCase";
import { getTargetForLink } from "./base-components";

type ComponentOrArrayDescription = ComponentDescription | ArrayContentDescription;

interface PropertyDescriptorConstructorOptions {
    readonly desc: ComponentOrArrayDescription | undefined;
    readonly parentDesc: ComponentOrArrayDescription | undefined;
    readonly getPropertyTable: PropertyTableGetter | undefined;
    readonly adc: AppDescriptionContext;
    readonly tables: InputOutputTables | undefined;
    readonly mutatingScreenKind: MutatingScreenKind | undefined;
    readonly sectionOverride: SuperPropertySection | undefined;
    readonly isEditedInApp: boolean;
    readonly forEasyCRUD: boolean;
    readonly forEasyTabConfiguration: boolean;
    readonly isFirstComponent: boolean | undefined;
    readonly actionKinds: readonly ActionKind[];
}

export type PropertyDescriptorConstructor = (
    options: PropertyDescriptorConstructorOptions
) => PropertyDescriptor | undefined;

type WithEnumPropertyValuesConvenient<TEnum extends string> = readonly TEnum[] | EnumPropertyCase<TEnum>[];

function finalizeWithEnumPropertyValues<TEnum extends string>(
    values: WithEnumPropertyValuesConvenient<TEnum>
): EnumPropertyCase<TEnum>[] {
    if (typeof values[0] === "string") {
        return (values as TEnum[]).map(value => ({ value, label: startCase(value) }));
    }
    return values as EnumPropertyCase<TEnum>[];
}

export type DescriptionOfProperties<T> = T extends FluentProperties<infer U, unknown>
    ? ToPropertyDescriptionOrUndefined<U>
    : never;

interface EasyTabConfiguration {
    readonly propertySection: SuperPropertySection;
}

interface PropertyOptionsWithoutPreferredNames {
    readonly displayName: string;
    readonly description?: string;
    readonly required: boolean; // This is not used by all property kinds - maybe move it out?
    readonly emptyByDefault?: boolean;
    readonly propertySection?: SuperPropertySection;
    readonly subcomponent?: Subcomponent;
    // Add this component property after all the item properties?
    readonly addAtBottom?: boolean;
    readonly disableForEasyCRUD?: boolean;
    readonly easyTabConfiguration?: EasyTabConfiguration;
}

interface PropertyOptions extends PropertyOptionsWithoutPreferredNames {
    readonly preferredNames: readonly string[];
}

interface WhenOption<T, TRoot> {
    readonly when?: WhenPredicate<T, TRoot>;
}

export interface ConstructableSpec {
    readonly descriptorConstructor: PropertyDescriptorConstructor;
    // The `descriptorConstructor` already takes `when` into account, so this
    // is only required when doing something that doesn't go through that.
    readonly when?: WhenPredicate<Description, Description>;
}

interface SpecBase extends ConstructableSpec {
    readonly name: string;
    readonly description?: string;
}

type ValueConverter = (v: LoadedGroundValue) => LoadedGroundValue;

// Returns [value getter, column type, value is displayed]
//
// When a value that's "displayed" is not empty, the component must be drawn.
// For example, the style of a Title component is not displayed because a
// Title that has nothing but a style is still empty.  It's image is
// displayed, however.
type ValueGetterGetter = (
    desc: Description,
    ib: WireValueInflationBackend,
    inOutputRow: boolean
) => {
    valueGetter: WireValueGetter;
    formattedGetter: WireValueGetter | undefined;
    type: ColumnType | undefined;
    isDisplayed: boolean;
};

type ValueFormatOptions = "formatted" | "unformatted" | "both";

export interface PropertySpec extends SpecBase {
    readonly isSearchable: boolean;
    readonly emptyByDefault: boolean;
    // If this is not `false`, we hydrate a `WireEditableValue`.  If it's
    // `"if-writable"` then we also allow string literals.
    readonly isEditable: boolean | "if-writable";
    // If this is set, we hydrate a `WirePrimitiveValueWithType`
    readonly withType: boolean;
    readonly getValueGetter: ValueGetterGetter;
    readonly converter: ValueConverter;
    readonly preferredType: ColumnTypePredicate | undefined;
    readonly isComponentTitle: boolean;
    readonly addAtBottom: boolean;
    readonly format: ValueFormatOptions;
}

interface ArraySpec extends ConstructableSpec {
    readonly name: string;
    readonly itemsSpec: FluentPropertiesSpec;
    // If the key property is missing, we also remove all the value
    // properties.  We use this in action-with-title arrays to clear the title
    // when there's no action, so it doesn't render as a disabled button.
    readonly removeIfMissing: Record<string, readonly string[]>;
}

export interface ActionSpec extends SpecBase {
    readonly showIfCollectionEmpty: boolean;
}

export interface FluentPropertiesSpec {
    readonly propertySpecs: readonly PropertySpec[];
    readonly arraySpecs: readonly ArraySpec[];
    readonly actionSpecs: readonly ActionSpec[];
    readonly hasDisplayedProperties: boolean;
}

export interface InternalStateSpec<T> {
    readonly validator: (v: unknown) => v is T;
    readonly defaultValue: T;
    readonly persist: boolean;
}

export type FluentInternalStateSpec = Record<string, InternalStateSpec<unknown>>;

function ensurePropertyOptions(propertyName: string, opts: Partial<PropertyOptions>): PropertyOptions {
    const { displayName = startCase(propertyName), required = false, emptyByDefault } = opts;
    let preferredNames: readonly string[];
    if (opts.preferredNames !== undefined) {
        preferredNames = opts.preferredNames;
    } else if (propertyName === "title" || propertyName === "subtitle") {
        preferredNames = titleProperties;
    } else if (propertyName === "phone") {
        preferredNames = phoneProperties;
    } else {
        preferredNames = [propertyName.toLowerCase()];
    }
    return {
        // We need to keep all original properties because `when` might be in
        // there.
        ...opts,
        displayName,
        preferredNames,
        required,
        emptyByDefault,
        propertySection: opts.propertySection,
    };
}

export function makePropertyDescriptors(
    specs: readonly ConstructableSpec[],
    options: PropertyDescriptorConstructorOptions
): PropertyDescriptor[] {
    return mapFilterUndefined(specs, p => p.descriptorConstructor(options));
}

export function makeActionDescriptors(
    specs: readonly ConstructableSpec[],
    options: Omit<PropertyDescriptorConstructorOptions, "isEditedInApp">
): ActionPropertyDescriptor[] {
    return makePropertyDescriptors(specs, { ...options, isEditedInApp: false }) as ActionPropertyDescriptor[];
}

function makeFlagsForWillBeQueried(
    parentDesc: ComponentOrArrayDescription | undefined,
    tables: InputOutputTables | undefined,
    adc: AppDescriptionContext,
    getIndirectTable: PropertyTableGetter | undefined,
    allowLiteral: boolean | undefined,
    willBeQueried: boolean | undefined
) {
    let allowUserProfileColumns: boolean | undefined;
    let forFilteringRows: boolean | undefined;
    if (willBeQueried === true) {
        const itemsTable =
            tables !== undefined && parentDesc !== undefined
                ? getIndirectTable?.(tables, parentDesc, parentDesc, adc, [])
                : undefined;
        if (itemsTable !== undefined && isBigTableOrExternal(itemsTable.table)) {
            allowLiteral = false;
            allowUserProfileColumns = false;
            forFilteringRows = true;
        }
    }
    return {
        allowLiteral,
        allowUserProfileColumns,
        forFilteringRows,
    };
}

export class FluentProperties<TProps, TRoot> {
    constructor(public readonly spec: FluentPropertiesSpec) {}

    public withRoot<T>(): FluentProperties<TProps, T> {
        // Ugh.  The alternative to this would be to always construct it with
        // the parent type, but then how do we make sure that the `spec`
        // (which would have to be optional in that case) is of the correct
        // type?
        //
        // The custom CSS property can be there.
        assert(
            this.spec.propertySpecs.length <= 1 &&
                this.spec.arraySpecs.length === 0 &&
                this.spec.actionSpecs.length === 0
        );
        return this as unknown as FluentProperties<TProps, T>;
    }

    private withArgument<T>(
        name: string,
        descriptorConstructor: PropertyDescriptorConstructor,
        isSearchable: boolean,
        emptyByDefault: boolean,
        isEditable: boolean | "if-writable",
        withType: boolean,
        format: ValueFormatOptions,
        getValueGetter: ValueGetterGetter,
        converter: ValueConverter,
        preferredType: ColumnTypePredicate | undefined,
        isComponentTitle: boolean,
        isDisplayed: boolean,
        addAtBottom: boolean,
        disableForEasyCRUD: boolean,
        easyTabConfiguration: EasyTabConfiguration | undefined,
        when?: WhenPredicate<TProps, TRoot>,
        description?: string
    ): FluentProperties<T, TRoot> {
        if (easyTabConfiguration === undefined || disableForEasyCRUD || when !== undefined) {
            const construct = descriptorConstructor;
            descriptorConstructor = opts => {
                if (easyTabConfiguration === undefined && opts.forEasyTabConfiguration) return undefined;
                if (disableForEasyCRUD && opts.forEasyCRUD) return undefined;
                return construct(opts);
            };
        }

        return new FluentProperties({
            ...this.spec,
            propertySpecs: [
                ...this.spec.propertySpecs,
                {
                    name,
                    description,
                    descriptorConstructor,
                    when: when as WhenPredicate<Description, Description>,
                    isSearchable,
                    emptyByDefault,
                    isEditable,
                    withType,
                    getValueGetter,
                    converter,
                    preferredType,
                    isComponentTitle,
                    addAtBottom,
                    format,
                },
            ],
            hasDisplayedProperties: this.spec.hasDisplayedProperties || isDisplayed,
        });
    }

    private makePrimitiveValueGetterGetter(
        name: string,
        isDisplayed: boolean,
        withArray: boolean = false
    ): ValueGetterGetter {
        return (desc, ib, inOutputRow) => {
            const [valueGetter, type] = ib.getValueGetterForProperty((desc as any)[name], false, {
                inOutputRow,
            });
            const [formattedGetter] = ib.getValueGetterForProperty((desc as any)[name], true, {
                inOutputRow,
            });

            // Write everything twice
            return {
                valueGetter: hb => {
                    const v = valueGetter(hb);
                    if (isArray(v) && !withArray) {
                        return v[0];
                    } else {
                        return v;
                    }
                },
                formattedGetter: hb => {
                    const v = formattedGetter(hb);
                    if (isArray(v) && !withArray) {
                        return v[0];
                    } else {
                        return v;
                    }
                },
                type,
                isDisplayed,
            };
        };
    }

    private withPrimitiveProperty<T extends string, V>(
        name: T,
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot>>,
        {
            isSearchable,
            withFormat,
            isDisplayed,
            withType,
            preferredType,
            isComponentTitle,
            withArray,
            converter,
        }: {
            isSearchable: boolean;
            withFormat: boolean;
            isDisplayed: boolean;
            withType: boolean;
            preferredType: ColumnTypePredicate | undefined;
            isComponentTitle: boolean;
            withArray?: boolean;
            converter?: ValueConverter;
        },
        propertyDescriptorConstructor: PropertyDescriptorConstructor
    ): FluentProperties<TProps & Partial<Record<T, V | Unbound | undefined>>, TRoot> {
        const defaultConverterWithType: ValueConverter = x => {
            if (withArray === true) {
                const isArrayOfPrimitives = isArray(x) && x.every(item => isPrimitive(item));
                if (isPrimitive(x) || isArrayOfPrimitives) {
                    return x;
                }

                return undefined;
            }

            if (isPrimitive(x)) {
                return x;
            }

            return undefined;
        };

        const defaultConverterWithoutType: ValueConverter = x => {
            if (isPrimitive(x)) {
                return asMaybeString(x);
            }
            if (isArray(x)) {
                return asMaybeArrayOfStrings(x);
            }

            return undefined;
        };
        const defaultConverter: ValueConverter = withType ? defaultConverterWithType : defaultConverterWithoutType;

        return this.withArgument(
            name,
            propertyDescriptorConstructor,
            isSearchable,
            opts.emptyByDefault === true,
            false,
            withType,
            withFormat ? "formatted" : "unformatted",
            this.makePrimitiveValueGetterGetter(name, isDisplayed, withArray),
            converter ?? defaultConverter,
            preferredType,
            isComponentTitle,
            isDisplayed,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withEditableValue<T extends string>(
        name: T,
        type: "number",
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot>>,
        extraOpts: {
            isDisplayed?: boolean;
        }
    ): FluentProperties<TProps & Partial<Record<T, WireEditableValue<number | undefined> | Unbound>>, TRoot>;
    public withEditableValue<T extends string>(
        name: T,
        type: "string" | "image-uri",
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot>>,
        extraOpts: {
            isDisplayed?: boolean;
            allowLiteral?: boolean;
            isDefaultCaption?: boolean;
        }
    ): FluentProperties<TProps & Partial<Record<T, WireEditableValue<string> | Unbound>>, TRoot>;
    public withEditableValue<T extends string>(
        name: T,
        type: "boolean",
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot>>,
        extraOpts: {
            isDisplayed?: boolean;
            allowLiteral?: boolean;
        }
    ): FluentProperties<TProps & Partial<Record<T, WireEditableValue<boolean> | Unbound>>, TRoot>;
    public withEditableValue<T extends string>(
        name: T,
        type: "number" | "string" | "image-uri" | "boolean",
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot>>,
        {
            isDisplayed = true,
            allowLiteral = false,
            isDefaultCaption = false,
        }: {
            isDisplayed?: boolean;
            allowLiteral?: boolean;
            isDefaultCaption?: boolean;
        }
    ): FluentProperties<
        TProps & Partial<Record<T, WireEditableValue<string | boolean | number | undefined> | Unbound>>,
        TRoot
    > {
        const propertyOptions = ensurePropertyOptions(name, opts);
        const { displayName, preferredNames, required, emptyByDefault, propertySection } = propertyOptions;
        const flags: ColumnPropertyFlag[] = [
            ColumnPropertyFlag.Editable,
            ColumnPropertyFlag.EditedInApp,
            ColumnPropertyFlag.EditedInAppEvenIfNotWritable,
        ];
        if (required) {
            flags.push(ColumnPropertyFlag.Required);
        } else {
            flags.push(ColumnPropertyFlag.Optional);
        }
        if (emptyByDefault === true) {
            flags.push(ColumnPropertyFlag.EmptyByDefault);
        }

        const propertyDescriptorConstructor: PropertyDescriptorConstructor = ({
            getPropertyTable,
            sectionOverride,
        }) => {
            const actualPropertySection = sectionOverride ?? propertySection ?? PropertySection.Data;
            let actualFlags = flags;
            if (getPropertyTable === undefined) {
                actualFlags = [...actualFlags, ColumnPropertyFlag.AllowUserProfileColumns];
            }
            if (isDefaultCaption) {
                actualFlags = [...actualFlags, ColumnPropertyFlag.DefaultCaption];
            }

            const columnCase = new ColumnPropertyHandler(
                name,
                displayName,
                actualFlags,
                getPropertyTable,
                preferredNames,
                getPrimitiveNonHiddenColumnsSpec,
                type,
                actualPropertySection,
                undefined,
                undefined,
                opts.when
            );

            if (!allowLiteral) {
                return columnCase;
            }

            const stringCase = new StringPropertyHandler(
                name,
                displayName,
                "",
                required,
                isDefaultCaption ? displayName : undefined,
                actualPropertySection,
                false,
                undefined
            );

            // FIXME: We can probably do this with
            // `makeTextPropertyDescriptor`, which already does both cases.
            const multi: MultiCasePropertyDescriptor = {
                property: { name },
                label: displayName,
                cases: [columnCase, stringCase],
                section: sectionOverride ?? propertySection ?? PropertySection.Data,
                llmDescription: propertyOptions.description,
            };

            return multi;
        };

        const converter: ValueConverter = getValueConverterFromType(type);

        return this.withArgument(
            name,
            propertyDescriptorConstructor,
            false,
            opts.emptyByDefault === true,
            allowLiteral ? "if-writable" : true,
            false,
            "both",
            this.makePrimitiveValueGetterGetter(name, isDisplayed),
            converter,
            type,
            false,
            isDisplayed,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withEditablePrimitiveValue<T extends string>(
        name: T,
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot>>,
        {
            isDisplayed = true,
            allowLiteral = false,
            isDefaultCaption = false,
        }: {
            isDisplayed?: boolean;
            allowLiteral?: boolean;
            isDefaultCaption?: boolean;
        }
    ): FluentProperties<TProps & Partial<Record<T, WireEditableValuePrimitiveValue | Unbound>>, TRoot> {
        const propertyOptions = ensurePropertyOptions(name, opts);
        const { displayName, preferredNames, required, emptyByDefault, propertySection } = propertyOptions;
        const flags: ColumnPropertyFlag[] = [
            ColumnPropertyFlag.Editable,
            ColumnPropertyFlag.EditedInApp,
            ColumnPropertyFlag.EditedInAppEvenIfNotWritable,
        ];
        if (required) {
            flags.push(ColumnPropertyFlag.Required);
        } else {
            flags.push(ColumnPropertyFlag.Optional);
        }
        if (emptyByDefault === true) {
            flags.push(ColumnPropertyFlag.EmptyByDefault);
        }

        const propertyDescriptorConstructor: PropertyDescriptorConstructor = ({
            getPropertyTable,
            sectionOverride,
        }) => {
            const actualPropertySection = sectionOverride ?? propertySection ?? PropertySection.Data;
            let actualFlags = flags;
            if (getPropertyTable === undefined) {
                actualFlags = [...actualFlags, ColumnPropertyFlag.AllowUserProfileColumns];
            }
            if (isDefaultCaption) {
                actualFlags = [...actualFlags, ColumnPropertyFlag.DefaultCaption];
            }

            const preferredType: ColumnTypePredicate = isPrimitiveType;

            const columnCase = new ColumnPropertyHandler(
                name,
                displayName,
                actualFlags,
                getPropertyTable,
                preferredNames,
                getPrimitiveNonHiddenColumnsSpec,
                preferredType,
                actualPropertySection,
                undefined,
                undefined,
                opts.when
            );

            if (!allowLiteral) {
                return columnCase;
            }

            const stringCase = new StringPropertyHandler(
                name,
                displayName,
                "",
                required,
                isDefaultCaption ? displayName : undefined,
                actualPropertySection,
                false,
                undefined
            );

            // FIXME: We can probably do this with
            // `makeTextPropertyDescriptor`, which already does both cases.
            const multi: MultiCasePropertyDescriptor = {
                property: { name },
                label: displayName,
                cases: [columnCase, stringCase],
                section: sectionOverride ?? propertySection ?? PropertySection.Data,
            };

            return multi;
        };

        const converter: ValueConverter = v => v;

        return this.withArgument(
            name,
            propertyDescriptorConstructor,
            false,
            opts.emptyByDefault === true,
            allowLiteral ? "if-writable" : true,
            false,
            "both",
            this.makePrimitiveValueGetterGetter(name, isDisplayed),
            converter,
            undefined,
            false,
            isDisplayed,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withPrimitive<T extends string>(
        name: T,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly allowColumn: boolean;
                    readonly isDefaultCaption: boolean;
                    readonly isDisplayed: boolean; // Defaults to true
                    readonly isEditedInApp: IsEditedInApp;
                    readonly withArray: boolean;
                    readonly withRelation: boolean;
                    readonly useTemplate: "withoutLabel" | "withLabel" | "withLabelAndFullWidth" | boolean | undefined;
                    readonly preferredType: ColumnTypePredicate | undefined;
                }
        > = {}
    ) {
        const propertyOptions = ensurePropertyOptions(name, opts);
        const { displayName, preferredNames, required, emptyByDefault, propertySection } = propertyOptions;

        const getAllowedColumns: GetAllowedColumnsFunction = (t, _d, s) =>
            sortBy(
                t.columns.filter(c => {
                    const isNotHidden = c.hidden !== true;
                    const isPrimitiveColumn = isPrimitiveType(c.type);
                    const isPrimitiveArray = isPrimitiveArrayType(c.type);
                    const isAllowedRelation = getTargetForLink(t, c, s, true) !== undefined;

                    const isAllowedColumn =
                        isPrimitiveColumn ||
                        (opts.withArray === true && isPrimitiveArray) ||
                        (opts.withRelation === true && isAllowedRelation);

                    return isNotHidden && isAllowedColumn;
                }),
                c => (isPrimitiveType(c.type) ? 1 : 0)
            );

        return this.withPrimitiveProperty<T, WirePrimitiveValueWithType>(
            name,
            propertyOptions,
            {
                isSearchable: true,
                withFormat: true,
                withType: true,
                isDisplayed: opts.isDisplayed ?? true,
                preferredType: opts.preferredType,
                isComponentTitle: false,
                withArray: opts.withArray,
            },
            ({ getPropertyTable: getIndirectTable, mutatingScreenKind, sectionOverride }) => {
                const flagsConfig: TextPropertyDescriptorFlags = {
                    preferredNames,
                    preferredType: isPrimitiveType,
                    searchable: true,
                    columnFirst: true,
                    emptyByDefault,
                    propertySection: sectionOverride ?? propertySection,
                    getIndirectTable,
                    allowColumn: opts.allowColumn,
                    columnFilter: {
                        getCandidateColumns: getAllowedColumns,
                        columnTypeIsAllowed: () => true,
                    },
                    isEditedInApp: opts.isEditedInApp ?? false,
                    isDefaultCaption: opts.isDefaultCaption,
                };

                if (opts.useTemplate !== undefined) {
                    return makeInlineTemplatePropertyDescriptor(
                        name,
                        displayName,
                        "",
                        required,
                        opts.useTemplate,
                        mutatingScreenKind,
                        flagsConfig,
                        opts.when
                    );
                }

                return makeTextPropertyDescriptor(
                    name,
                    displayName,
                    "",
                    required,
                    mutatingScreenKind,
                    flagsConfig,
                    undefined,
                    opts.when
                );
            }
        );
    }

    public withIcon<T extends string>(
        name: T,
        opts: Partial<PropertyOptionsWithoutPreferredNames & WhenOption<TProps, TRoot>> & { defaultIcon?: string } = {}
    ): FluentProperties<TProps & Partial<Record<T, string>>, TRoot> {
        const { displayName, propertySection } = ensurePropertyOptions(name, opts);

        const makeDescr = (sectionOverride: SuperPropertySection | undefined) =>
            new IconPropertyHandler(
                name,
                displayName,
                sectionOverride ?? propertySection ?? PropertySection.Data,
                opts.defaultIcon,
                opts.when
            );
        const descr = makeDescr(undefined);

        return this.withArgument(
            name,
            ({ sectionOverride }) => makeDescr(sectionOverride),
            false,
            true,
            false,
            false,
            "unformatted",
            desc => ({
                valueGetter: () => descr.getIcon(desc as any),
                formattedGetter: undefined,
                type: makePrimitiveType("string"),
                isDisplayed: false,
            }),
            asMaybeString,
            undefined,
            false,
            false,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withCaption<T extends string>(
        name: T,
        defaultCaption: string = startCase(name),
        opts: Partial<
            PropertyOptionsWithoutPreferredNames &
                WhenOption<TProps, TRoot> & {
                    readonly allowColumn: boolean;
                    readonly isComponentTitle: boolean;
                    readonly propertyTableGetter: PropertyTableGetter;
                    readonly useTemplate: "withoutLabel" | "withLabel" | "withLabelAndFullWidth" | boolean | undefined;
                    readonly emptyWarningText: string;
                    readonly helpText: string;
                }
        > = {}
    ) {
        const propertyOptions = ensurePropertyOptions(name, opts);
        const { displayName, required, emptyByDefault, propertySection } = propertyOptions;
        const preferredType = "string";

        return this.withPrimitiveProperty<T, string>(
            name,
            propertyOptions,
            {
                isSearchable: true,
                withFormat: true,
                withType: false,
                isDisplayed: false,
                preferredType,
                isComponentTitle: opts.isComponentTitle === true,
            },
            ({ getPropertyTable, mutatingScreenKind, sectionOverride }) => {
                const getIndirectTable = opts.propertyTableGetter ?? getPropertyTable;

                const flagsConfig: TextPropertyDescriptorFlags = {
                    preferredType,
                    preferredNames: titleProperties,
                    searchable: false,
                    columnFirst: false,
                    emptyByDefault,
                    propertySection: sectionOverride ?? propertySection,
                    getIndirectTable,
                    allowColumn: opts.allowColumn,
                    isEditedInApp: false,
                    subcomponent: opts.subcomponent,
                    isCaption: defaultCaption,
                    emptyWarningText: opts.emptyWarningText,
                    helpText: opts.helpText,
                };

                // If columns are not allowed it makes no sense to use templates.
                if (opts.allowColumn !== false && opts.useTemplate !== undefined) {
                    return makeInlineTemplatePropertyDescriptor(
                        name,
                        displayName,
                        "",
                        required,
                        opts.useTemplate,
                        mutatingScreenKind,
                        flagsConfig,
                        opts.when
                    );
                }

                return makeTextPropertyDescriptor(
                    name,
                    displayName,
                    "",
                    required,
                    mutatingScreenKind,
                    flagsConfig,
                    undefined,
                    opts.when
                );
            }
        );
    }

    public withFormattedNumber<T extends string>(
        name: T,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly placeholder: string;
                    readonly defaultValue: number;
                    readonly isEditedInApp: IsEditedInApp;
                    readonly isAddedInApp: boolean;
                    readonly isDefaultCaption: boolean;
                    readonly helpText: string;
                    readonly emptyWarningText: string;
                }
        >
    ): FluentProperties<TProps & Partial<Record<T, WireValueWithFormatted<number> | Unbound>>, TRoot> {
        const propertyOptions = ensurePropertyOptions(name, opts);
        const { displayName, preferredNames, required, emptyByDefault, propertySection } = propertyOptions;

        const propertyDescriptorConstructor: PropertyDescriptorConstructor = ({
            getPropertyTable: getIndirectTable,
            mutatingScreenKind,
            sectionOverride,
        }) => {
            const requiredKind = required ? RequiredKind.Required : RequiredKind.NotRequiredDefaultPresent;
            return makeNumberPropertyDescriptor(
                name,
                displayName,
                opts.placeholder ?? "",
                requiredKind,
                opts.defaultValue ?? 0,
                mutatingScreenKind,
                {
                    applyFormat: true,
                    preferredNames,
                    searchable: true,
                    emptyByDefault,
                    propertySection: sectionOverride ?? propertySection,
                    getIndirectTable,
                    isEditedInApp: opts.isEditedInApp,
                    isAddedInApp: opts.isAddedInApp,
                    isDefaultCaption: opts.isDefaultCaption,
                    columnFilter: undefined,
                    columnFirst: opts.defaultValue === undefined,
                    helpText: opts.helpText,
                    emptyWarningText: opts.emptyWarningText,
                },
                opts.when
            );
        };

        return this.withArgument(
            name,
            propertyDescriptorConstructor,
            false,
            opts.emptyByDefault === true,
            false,
            false,
            "both",
            this.makePrimitiveValueGetterGetter(name, true),
            asMaybeNumber,
            "number",
            false,
            true,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withText<T extends string>(
        name: T,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly allowColumn: boolean;
                    readonly allowLiteral: boolean;
                    readonly allowArray: boolean;
                    readonly isDefaultCaption: boolean;
                    readonly preferredType: StringGlideTypeKind;
                    readonly defaultValue: string;
                    readonly isMultiLine: boolean;
                    readonly syntaxMode: string;
                    readonly placeholder: string;
                    readonly isEditedInApp: IsEditedInApp;
                    readonly isAddedInApp: boolean;
                    readonly helpText: string;
                    readonly emptyWarningText: string;
                    readonly isComponentTitle: boolean;
                    // If the table is a queryable table, then this must be a
                    // column that can be queried, i.e. it must be a column
                    // from the table, and it can't be computed.
                    readonly willBeQueried: boolean;
                    readonly allowUserProfileColumns: boolean;
                    readonly propertyTableGetter: PropertyTableGetter;
                    readonly withArray: boolean;
                    // Defaults to `true`
                    readonly searchable: boolean;
                    // if undefined, will not use inline templates
                    // if "withoutLabel" it will look fullwidth without a label
                    // if "withLabel" it will look like other properties, label to the left of the input
                    // if "withLabelAndFullWidth" it will have a label and be fullwidth
                    readonly useTemplate: "withoutLabel" | "withLabel" | "withLabelAndFullWidth" | boolean | undefined;
                    readonly allowUpload: boolean | undefined;
                    readonly builderOnly: boolean;
                }
        > = {}
    ) {
        const propertyOptions = ensurePropertyOptions(name, opts);
        const {
            displayName,
            preferredNames,
            required,
            emptyByDefault,
            propertySection: chosenPropertySection,
        } = propertyOptions;
        const preferredType = opts.preferredType ?? "string";
        const isSearchable = opts.searchable ?? true;
        return this.withPrimitiveProperty<T, string>(
            name,
            propertyOptions,
            {
                isSearchable,
                withFormat: true,
                withType: false,
                isDisplayed: true,
                preferredType,
                isComponentTitle: opts.isComponentTitle === true,
                withArray: opts.withArray,
            },
            ({ parentDesc, tables, adc, getPropertyTable, mutatingScreenKind, sectionOverride }) => {
                const propertySection = sectionOverride ?? chosenPropertySection ?? PropertySection.Data;

                const getIndirectTable = opts.propertyTableGetter ?? getPropertyTable;
                const getAllowedColumns =
                    opts.allowArray === true ? getStringTypeOrStringTypeArrayColumns : getPrimitiveColumns;

                const flags = makeFlagsForWillBeQueried(
                    parentDesc,
                    tables,
                    adc,
                    getIndirectTable,
                    opts.allowLiteral,
                    opts.willBeQueried
                );

                const allowUserProfileColumns = flags.allowUserProfileColumns ?? opts.allowUserProfileColumns;

                const textPropertyDescriptorFlags: TextPropertyDescriptorFlags = {
                    ...flags,
                    preferredType,
                    preferredNames,
                    searchable: isSearchable,
                    defaultValue: opts.defaultValue,
                    columnFirst: opts.defaultValue === undefined,
                    emptyByDefault,
                    propertySection,
                    getIndirectTable,
                    allowColumn: opts.allowColumn,
                    isEditedInApp: opts.isEditedInApp,
                    isAddedInApp: opts.isAddedInApp,
                    subcomponent: opts.subcomponent,
                    isMultiLine: opts.isMultiLine,
                    syntaxMode: opts.syntaxMode,
                    isDefaultCaption: opts.isDefaultCaption,
                    placeholder: opts.placeholder,
                    columnFilter: { getCandidateColumns: getAllowedColumns, columnTypeIsAllowed: () => true },
                    helpText: opts.helpText,
                    emptyWarningText: opts.emptyWarningText,
                    allowUserProfileColumns,
                };

                // If columns are not allowed it makes no sense to use templates.
                if (opts.allowColumn !== false && opts.useTemplate !== undefined) {
                    return makeInlineTemplatePropertyDescriptor(
                        name,
                        displayName,
                        opts.placeholder ?? "",
                        opts.required ?? false,
                        opts.useTemplate,
                        mutatingScreenKind,
                        textPropertyDescriptorFlags,
                        opts.when,
                        undefined,
                        opts.allowUpload,
                        opts.builderOnly
                    );
                }

                return makeTextPropertyDescriptor(
                    name,
                    displayName,
                    opts.placeholder ?? "",
                    required,
                    mutatingScreenKind,
                    textPropertyDescriptorFlags,
                    undefined,
                    opts.when,
                    opts.builderOnly
                );
            }
        );
    }

    public withDateTime<T extends string, E extends BasePrimitiveValue>(
        name: T,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly preferredType: "date" | "date-time";
                    readonly enumCase: EnumPropertyDescriptorCase<E>;
                    readonly willBeQueried: boolean;
                    readonly isEditedInApp: IsEditedInApp;
                    readonly isAddedInApp: boolean;
                    readonly allowUserProfileColumns: boolean;
                    readonly allowLiteral: boolean;
                }
        > = {}
    ) {
        const propertyOptions = ensurePropertyOptions(name, {
            preferredNames: ["date", "time", "start", "begin", "end"],
            ...opts,
        });
        const { displayName, preferredNames, required, emptyByDefault, propertySection } = propertyOptions;
        const preferredType: ColumnTypePredicate = t => isDateTimeTypeKind(t.kind);

        return this.withPrimitiveProperty<T, GlideDateTime>(
            name,
            propertyOptions,
            {
                isSearchable: false,
                withFormat: false,
                withType: false,
                isDisplayed: true,
                preferredType,
                isComponentTitle: false,
                converter: asMaybeDate,
            },
            ({ parentDesc, tables, adc, getPropertyTable: getIndirectTable, mutatingScreenKind, sectionOverride }) => {
                const flags = makeFlagsForWillBeQueried(
                    parentDesc,
                    tables,
                    adc,
                    getIndirectTable,
                    opts.allowLiteral,
                    opts.willBeQueried
                );

                return makeDateTimePropertyDescriptor(
                    name,
                    displayName,
                    "",
                    required
                        ? RequiredKind.Required
                        : emptyByDefault === true
                        ? RequiredKind.NotRequiredDefaultMissing
                        : RequiredKind.NotRequiredDefaultPresent,
                    mutatingScreenKind,
                    {
                        preferredType: opts.preferredType ?? "date-time",
                        preferredNames,
                        emptyByDefault: emptyByDefault === true,
                        getIndirectTable,
                        propertySection: sectionOverride ?? propertySection,
                        allowUserProfileColumns: flags.allowUserProfileColumns ?? opts.allowUserProfileColumns,
                        forFilteringRows: flags.forFilteringRows,
                        isAddedInApp: opts.isAddedInApp,
                        isEditedInApp: opts.isEditedInApp,
                        allowLiteral: flags.allowLiteral ?? opts.allowLiteral,
                    },
                    filterUndefined([opts.enumCase]),
                    opts.when
                );
            }
        );
    }

    public withURI<T extends string>(
        name: T,
        opts: Partial<
            PropertyOptions & WhenOption<TProps, TRoot> & { readonly defaultValue?: string; allowArrays?: boolean }
        > = {}
    ) {
        const propertyOptions = ensurePropertyOptions(name, { preferredNames: ["url", "uri"], ...opts });
        const { displayName, preferredNames, required, emptyByDefault, propertySection } = propertyOptions;
        const preferredType = "uri";
        const withArray = opts.allowArrays === true;
        return this.withPrimitiveProperty<T, string | string[]>(
            name,
            propertyOptions,
            {
                isSearchable: false,
                withFormat: false,
                withType: false,
                isDisplayed: true,
                preferredType,
                isComponentTitle: false,
                withArray,
            },
            ({ getPropertyTable: getIndirectTable, mutatingScreenKind, sectionOverride }) =>
                makeTextPropertyDescriptor(
                    name,
                    displayName,
                    "Enter URL",
                    required,
                    mutatingScreenKind,
                    {
                        preferredType,
                        preferredNames,
                        searchable: false,
                        columnFirst: opts.defaultValue === undefined,
                        emptyByDefault,
                        getIndirectTable,
                        propertySection: sectionOverride ?? propertySection,
                        defaultValue: opts.defaultValue,
                        subcomponent: opts.subcomponent,
                        columnFilter: {
                            getCandidateColumns: t =>
                                withArray ? getStringTypeOrStringTypeArrayColumns(t) : getPrimitiveColumns(t),
                            columnTypeIsAllowed: () => true,
                        },
                    },
                    undefined,
                    opts.when
                )
        );
    }

    public withImage<T extends string>(
        name: T,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly isEditedInApp?: boolean;
                    readonly isAddedInApp?: boolean;
                    readonly allowUserProfileColumns: boolean;
                    readonly allowLiteral: boolean;
                    readonly useTemplate: "withoutLabel" | "withLabel" | "withLabelAndFullWidth" | boolean | undefined;
                }
        > = {}
    ) {
        const propertyOptions = ensurePropertyOptions(name, opts);
        const { displayName, required, emptyByDefault, propertySection } = propertyOptions;
        const {
            isEditedInApp = false,
            isAddedInApp = false,
            allowLiteral = true,
            allowUserProfileColumns = true,
        } = opts;

        return this.withPrimitiveProperty<T, string | string[]>(
            name,
            propertyOptions,
            {
                isSearchable: false,
                withFormat: false,
                withType: false,
                isDisplayed: true,
                preferredType: "image-uri",
                isComponentTitle: false,
                withArray: true,
            },
            ({ getPropertyTable, mutatingScreenKind, sectionOverride }) =>
                makeImagePropertyDescriptor(
                    name,
                    displayName,
                    "From URL",
                    "Enter URL",
                    required,
                    emptyByDefault,
                    isEditedInApp,
                    false,
                    true,
                    getPropertyTable,
                    mutatingScreenKind,
                    { isAddedInApp, allowLiteral, allowUserProfileColumns },
                    sectionOverride ?? propertySection,
                    opts.subcomponent,
                    opts.when,
                    opts.useTemplate
                )
        );
    }

    public withVideo<T extends string>(
        name: T,
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot> & { readonly defaultValue: string }> = {}
    ) {
        const propertyOptions = ensurePropertyOptions(name, { preferredNames: ["video"], ...opts });
        const { displayName, required, emptyByDefault, propertySection } = propertyOptions;
        return this.withPrimitiveProperty<T, string>(
            name,
            propertyOptions,
            {
                isSearchable: false,
                withFormat: false,
                withType: false,
                isDisplayed: true,
                preferredType: "uri",
                isComponentTitle: false,
            },
            ({ getPropertyTable: getIndirectTable, mutatingScreenKind, sectionOverride }) =>
                makeTextPropertyDescriptor(
                    name,
                    displayName,
                    "Enter URL",
                    required,
                    mutatingScreenKind,
                    {
                        preferredType: "uri",
                        preferredNames: ["video"],
                        searchable: false,
                        columnFirst: opts.defaultValue === undefined,
                        emptyByDefault,
                        defaultValue: opts.defaultValue,
                        getIndirectTable,
                        propertySection: sectionOverride ?? propertySection,
                        subcomponent: opts.subcomponent,
                    },
                    undefined,
                    opts.when
                )
        );
    }

    public withEnum<T extends string, TEnum extends string>(
        name: T,
        values: WithEnumPropertyValuesConvenient<TEnum>,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    defaultValue: TEnum;
                    enumVisual?: EnumVisual;
                    isSearchable?: boolean;
                    readonly helpText: string;
                }
        > = {}
    ): FluentProperties<TProps & Record<T, TEnum>, TRoot> {
        const { displayName, propertySection } = ensurePropertyOptions(name, opts);

        const enumValues = finalizeWithEnumPropertyValues(values);
        const { defaultValue = enumValues[0].value } = opts;

        const makeDescr = (sectionOverride: SuperPropertySection | undefined) =>
            new EnumPropertyHandler(
                { [name]: defaultValue },
                displayName,
                displayName,
                enumValues,
                sectionOverride ?? propertySection ?? PropertySection.Options,
                opts.enumVisual ?? "dropdown",
                undefined,
                opts.subcomponent,
                opts.isSearchable ?? values.length > 4,
                undefined,
                opts.helpText,
                opts.when
            );
        const descr = makeDescr(undefined);

        return this.withArgument(
            name,
            ({ sectionOverride }) => makeDescr(sectionOverride),
            false,
            false,
            false,
            false,
            "unformatted",
            desc => ({
                valueGetter: () => descr.getEnum(desc as any),
                formattedGetter: undefined,
                type: makePrimitiveType("string"),
                isDisplayed: false,
            }),
            asMaybeString,
            undefined,
            false,
            false,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withSwitch<T extends string>(
        name: T,
        defaultValue: boolean,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly withCondition: boolean;
                    readonly specPrefix: string;
                    readonly icon: string;
                    readonly propertyTableGetter: PropertyTableGetter;
                    readonly helpText: string;
                }
        > = {}
    ): FluentProperties<TProps & Record<T, boolean>, TRoot> {
        const { displayName, propertySection } = ensurePropertyOptions(name, opts);

        const makeDescr = (
            getPropertyTable: PropertyTableGetter | undefined,
            sectionOverride: SuperPropertySection | undefined
        ) =>
            new SwitchPropertyHandler(
                { [name]: defaultValue },
                displayName,
                sectionOverride ?? propertySection ?? PropertySection.Options,
                {
                    getIndirectTable: opts.propertyTableGetter ?? getPropertyTable,
                    subcomponent: opts.subcomponent,
                    withCondition: opts.withCondition,
                    specPrefix: opts.specPrefix,
                    icon: opts.icon,
                    helpText: opts.helpText,
                },
                opts.when
            );

        // This `descr` does the right thing for the default value
        const descr = makeDescr(undefined, undefined);
        return this.withArgument(
            name,
            ({ getPropertyTable, sectionOverride }) => makeDescr(getPropertyTable, sectionOverride),
            false,
            false,
            false,
            false,
            "unformatted",
            desc => ({
                valueGetter: () => descr.getSwitch(desc as any),
                formattedGetter: undefined,
                type: makePrimitiveType("boolean"),
                isDisplayed: false,
            }),
            asBoolean,
            undefined,
            false,
            false,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withEmoji<T extends string>(
        name: T,
        defaultEmoji?: string,
        opts: Partial<PropertyOptions & WhenOption<TProps, TRoot> & { isDisplayed: boolean }> = {}
    ): FluentProperties<TProps & Partial<Record<T, string | undefined>>, TRoot> {
        const { displayName, propertySection } = ensurePropertyOptions(name, opts);

        return this.withArgument(
            name,
            ({ sectionOverride }) => ({
                kind: PropertyKind.Emoji,
                property: { name },
                label: displayName,
                defaultEmoji,
                section: sectionOverride ?? propertySection ?? PropertySection.Options,
                subcomponent: opts.subcomponent,
                when: opts.when as WhenPredicate<any, any>,
            }),
            false,
            false,
            false,
            false,
            "unformatted",
            desc => ({
                valueGetter: () => getEmojiProperty((desc as any)[name]),
                formattedGetter: undefined,
                type: makePrimitiveType("emoji"),
                isDisplayed: true,
            }),
            asString,
            undefined,
            false,
            opts.isDisplayed !== false,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }

    public withArray<T extends string, P>(
        name: T,
        props: FluentProperties<P, TRoot>,
        opts: Partial<
            PropertyOptions & {
                readonly style: ArrayPropertyStyle;
                readonly specialItems: number;
                readonly allowEmpty: boolean;
                readonly removeIfMissing: Partial<Record<keyof P, readonly (keyof P)[]>>;
                readonly addItemLabels: readonly string[];
                readonly defaultValue: boolean | readonly Description[];
                readonly numDefaultItems: number;
                readonly addItem: (prev: readonly Description[]) => readonly Description[];
                readonly when: WhenPredicate<P, TRoot>;
                readonly getHeaderDisplay: (
                    desc: ToPropertyDescriptionOrUndefined<P>,
                    rootDesc: ToPropertyDescriptionOrUndefined<TRoot>,
                    tables: InputOutputTables | undefined,
                    adc: AppDescriptionContext
                ) => string;
                // ##popupConfiguratorSelectors
                // In order of priority, what's the property that we should select. This selects by display name.
                readonly dataSelectors: string[];
                readonly maxItems: number;
                readonly builderOnly: boolean;
            }
        > = {}
    ): FluentProperties<TProps & Record<T, readonly P[]>, TRoot> {
        const { displayName, propertySection } = ensurePropertyOptions(name, opts);

        const propertyDescriptorConstructor: PropertyDescriptorConstructor = constructorOpts => {
            const { forEasyCRUD, forEasyTabConfiguration, sectionOverride } = constructorOpts;
            if (forEasyTabConfiguration && opts.easyTabConfiguration === undefined) return undefined;
            if (forEasyCRUD && opts.disableForEasyCRUD === true) return undefined;

            const section = sectionOverride ?? propertySection ?? PropertySection.Options;

            const subConstructorOptions: PropertyDescriptorConstructorOptions = {
                ...constructorOpts,
                desc: undefined,
                parentDesc: constructorOpts.desc,
            };
            return {
                kind: PropertyKind.Array,
                label: displayName,
                subcomponent: opts.subcomponent,
                property: { name },
                section,

                addItem: opts.addItem,
                properties: [
                    // TODO: get the array properties out of `desc` when we want
                    // to support nested arrays
                    ...makePropertyDescriptors(props.spec.propertySpecs, subConstructorOptions),
                    ...makeActionDescriptors(props.spec.actionSpecs, subConstructorOptions),
                ],
                specialItems: opts.specialItems,
                allowEmpty: opts.allowEmpty ?? false,
                allowReorder: true,
                addItemLabels: opts.addItemLabels ?? [],
                defaultValue: opts.defaultValue,
                numDefaultItems: opts.numDefaultItems,
                style: opts.style ?? ArrayPropertyStyle.Default,
                // P and TRoot are Descriptions, but we can't explain this to TS.
                getHeaderDisplay: opts.getHeaderDisplay as any,
                dataSelectors: opts.dataSelectors,
                when: opts.when as WhenPredicate<any, any>,
                maxItems: opts.maxItems,
                builderOnly: opts.builderOnly,
                llmDescription: opts.description,
            };
        };

        return new FluentProperties({
            ...this.spec,
            arraySpecs: [
                ...this.spec.arraySpecs,
                {
                    name,
                    descriptorConstructor: propertyDescriptorConstructor,
                    itemsSpec: props.spec,
                    removeIfMissing: (opts.removeIfMissing as Record<string, readonly string[]>) ?? {},
                },
            ],
            hasDisplayedProperties: this.spec.hasDisplayedProperties || props.spec.hasDisplayedProperties,
        });
    }

    public withAction<T extends string>(
        name: T,
        // If `showIfCollectionEmpty` is set, then this action, if it's
        // enabled, will make the collection show up even it's empty.
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly showIfCollectionEmpty: boolean;
                    /** where `true` means action is empty by default */
                    readonly defaultAction:
                        | boolean
                        | ((table: TableGlideType, desc: Description) => ActionDescription | undefined);
                }
        > = {}
    ): FluentProperties<TProps & Partial<Record<T, WireAction | Unbound | undefined>>, TRoot> {
        const { displayName, required, propertySection } = ensurePropertyOptions(name, opts);
        const descriptorConstructor: PropertyDescriptorConstructor = ({
            getPropertyTable: getIndirectTable,
            sectionOverride,
            forEasyCRUD,
            forEasyTabConfiguration,
            actionKinds: primitiveActionKinds,
        }) => {
            if (forEasyTabConfiguration && opts.easyTabConfiguration === undefined) return undefined;
            if (forEasyCRUD && opts.disableForEasyCRUD === true) return undefined;

            return {
                kind: PropertyKind.Action,
                property: { name },
                label: displayName,
                required,
                kinds: primitiveActionKinds,
                defaultAction:
                    opts.defaultAction === false || opts.defaultAction === undefined ? required : opts.defaultAction,
                getIndirectTable,
                section: sectionOverride ?? propertySection ?? PropertySection.ActionAction,
                withCondition: true,
                when: opts.when as WhenPredicate<any, any>,
            };
        };

        return new FluentProperties({
            ...this.spec,
            actionSpecs: [
                ...this.spec.actionSpecs,
                {
                    name,
                    descriptorConstructor,
                    showIfCollectionEmpty: opts.showIfCollectionEmpty === true,
                },
            ],
        });
    }

    public withTable<T extends string>(
        name: T,
        getAllowedTables: GetAllowedTablesFunction,
        opts: Partial<
            PropertyOptions &
                WhenOption<TProps, TRoot> & {
                    readonly allowRelation: boolean;
                    readonly propertyTableGetter: PropertyTableGetter;
                }
        > = {}
    ): FluentProperties<TProps & Partial<Record<T, WireAction | Unbound | undefined>>, TRoot> {
        const { displayName, required, propertySection } = ensurePropertyOptions(name, opts);

        function makeTablePropertyHandler(section: SuperPropertySection) {
            return new TablePropertyHandler(name, displayName, required, getAllowedTables, section, opts.when);
        }

        const descriptorConstructor: PropertyDescriptorConstructor = ({
            getPropertyTable,
            sectionOverride,
            mutatingScreenKind,
        }) => {
            const section = sectionOverride ?? propertySection ?? PropertySection.DataTop;

            if (opts.allowRelation === true) {
                return makeTableOrRelationPropertyDescriptor(
                    mutatingScreenKind,
                    displayName,
                    section,
                    {
                        allowTables: true,
                        allowSingleRelations: false,
                        allowMultiRelations: true,
                        allowRewrite: true,
                        allowUserProfile: true,
                        allowQueryableTables: true,
                        allowUserProfileTableAndRow: true,
                        sourceIsDefaultCaption: false,
                        preferFullRow: false,
                        forWriting: false,
                        getIndirectTable: opts.propertyTableGetter ?? getPropertyTable,
                    },
                    {
                        name,
                    },
                    true
                );
            } else {
                return makeTablePropertyHandler(section);
            }
        };

        const defaultTableHandler = makeTablePropertyHandler(PropertySection.DataTop);

        return this.withArgument(
            name,
            descriptorConstructor,
            false,
            false,
            false,
            false,
            "unformatted",
            desc => ({
                // These properties are used by `fluent-component-handlers`
                // It's not clear what we should automatically hydrate out of a table property.
                // Currently we only use this to let you bind a table as the source for New Table's choice column.
                valueGetter: () => null,
                formattedGetter: undefined,
                type: defaultTableHandler.getTableRef(desc),
                isDisplayed: false,
            }),
            asTable,
            undefined,
            false,
            false,
            opts.addAtBottom === true,
            opts.disableForEasyCRUD === true,
            opts.easyTabConfiguration,
            opts.when
        );
    }
}

function getValueConverterFromType(type: "string" | "image-uri" | "number" | "boolean" | "primitive"): ValueConverter {
    switch (type) {
        case "string":
        case "image-uri":
            return asString;

        case "number":
            return asMaybeNumber;

        case "boolean":
            return asBoolean;

        case "primitive":
            return v => v;

        default:
            assertNever(type, "Invalid type");
    }
}
