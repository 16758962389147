import type { BasePrimitiveValue } from "@glide/data-types";
import { ImageAspectRatio } from "@glide/common-core/dist/js/components/image-types";
import {
    type TableName,
    type Description,
    type TableGlideType,
    type TableRefGlideType,
    isTableWritable,
    makeTableRef,
    type SchemaInspector,
} from "@glide/type-schema";
import {
    type LegacyPropertyDescription,
    type PropertyDescription,
    type SwitchDescription,
    PropertyKind,
    getColumnProperty,
    getEnumProperty,
    getIconProperty,
    getNumberProperty,
    getStringProperty,
    getSwitchProperty,
    getSwitchWithConditionProperty,
    getTableProperty,
    getZapProperty,
    makeColumnProperty,
    makeEnumProperty,
    makeNumberProperty,
    makeStringProperty,
    makeSwitchProperty,
    makeTableProperty,
} from "@glide/app-description";
import type { InputOutputTables } from "@glide/common-core/dist/js/description";
import { defined, assert } from "@glideapps/ts-necessities";
import { definedMap, hasOwnProperty } from "collection-utils";
import type { ColumnFilterSpec, GetAllowedColumnsFunction } from "../column-filter-spec";
import {
    type AllowFullRow,
    type ColumnPropertyDescriptor,
    type ColumnTypePredicate,
    type EnumPropertyCase,
    type EnumPropertyDescriptor,
    type EnumVisual,
    type GetAllowedTablesFunction,
    type IconPropertyDescriptor,
    type InlineComputationPropertyDescriptor,
    type InlineComputationPropertyOptions,
    type IsEditedInApp,
    type MultiCasePropertyDescriptor,
    type NumberPropertyDescriptor,
    type NumberPropertyStyle,
    type PropertyDescriptor,
    type PropertyTableGetter,
    type StringPropertyDescriptor,
    type StringPropertyDescriptorCase,
    type Subcomponent,
    type SwitchPropertyDescriptor,
    type TablePropertyDescriptor,
    type WhenPredicate,
    type ZapPropertyDescriptor,
    RequiredKind,
    isMultiCasePropertyDescriptor,
} from "./lib";
import { type SuperPropertySection, PropertySection } from "./property-sections";
import type { PropertySource } from "./property-source";

class PropertyHandler<TKind extends PropertyKind> {
    constructor(private readonly _kind: TKind, public readonly name: string, public readonly label: string) {}

    public get kind(): TKind {
        return this._kind;
    }

    public get property(): PropertySource {
        return { name: this.name };
    }
}

export enum ColumnPropertyFlag {
    // "optional" is ignored.  It's only here to do `flag ? "required" : "optional"`
    Optional,
    Required,
    Editable,
    Searchable,
    Legacy,
    EmptyByDefault,
    DefaultCaption,
    EditedInApp,
    EditedInAppEvenIfNotWritable,
    AddedInApp,
    AllowUserProfileColumns,
    // Allows read-write full rows
    AllowFullRow,
    // Also allows read-only full rows.  Must also have `AllowFullRow` set.
    AllowFullRowReadonly,
    ForFilteringRows,
    // In some cases we don't want to allow picking columns from prior steps
    // For example: in the increment action, you should only be able to pick
    // a column from the selected row (via getIndirectTable)
    DisallowPriorSteps,
}

export class ColumnPropertyHandler extends PropertyHandler<PropertyKind.Column> implements ColumnPropertyDescriptor {
    constructor(
        name: string,
        label: string,
        private readonly _flags: ReadonlyArray<ColumnPropertyFlag>,
        public readonly getIndirectTable: PropertyTableGetter | undefined,
        public readonly preferredNames: ReadonlyArray<string> | undefined,
        public readonly columnFilter: ColumnFilterSpec,
        public readonly preferredType: ColumnTypePredicate,
        public readonly section: SuperPropertySection,
        public readonly emptyWarningText?: string,
        public readonly warningText?: string,
        public readonly when?: WhenPredicate<any, any>
    ) {
        super(PropertyKind.Column, name, label);
    }

    public get required(): boolean {
        return this._flags.includes(ColumnPropertyFlag.Required);
    }

    public get editable(): boolean {
        return this._flags.includes(ColumnPropertyFlag.Editable);
    }

    public get searchable(): boolean {
        return this._flags.includes(ColumnPropertyFlag.Searchable);
    }

    public get isLegacy(): boolean {
        return this._flags.includes(ColumnPropertyFlag.Legacy);
    }

    public get emptyByDefault(): boolean {
        return this._flags.includes(ColumnPropertyFlag.EmptyByDefault);
    }

    public get isDefaultCaption(): boolean {
        return this._flags.includes(ColumnPropertyFlag.DefaultCaption);
    }

    public get isEditedInApp(): IsEditedInApp {
        if (this._flags.includes(ColumnPropertyFlag.EditedInAppEvenIfNotWritable)) {
            return "even-if-not-writable";
        } else if (this._flags.includes(ColumnPropertyFlag.EditedInApp)) {
            return true;
        } else {
            return false;
        }
    }

    public get isAddedInApp(): boolean {
        return this._flags.includes(ColumnPropertyFlag.AddedInApp);
    }

    public get allowUserProfileColumns(): boolean {
        return this._flags.includes(ColumnPropertyFlag.AllowUserProfileColumns);
    }

    public get disallowPriorSteps(): boolean {
        return this._flags.includes(ColumnPropertyFlag.DisallowPriorSteps);
    }

    public get forFilteringRows(): boolean {
        return this._flags.includes(ColumnPropertyFlag.ForFilteringRows);
    }

    public allowFullRow(t: TableGlideType): AllowFullRow {
        if (!this._flags.includes(ColumnPropertyFlag.AllowFullRow)) return false;

        const allowReadonly = this._flags.includes(ColumnPropertyFlag.AllowFullRowReadonly);

        if (!allowReadonly && !isTableWritable(t)) return false;
        return "preferred";
    }

    public getProperty(desc: Description): LegacyPropertyDescription | undefined {
        return (desc as any)[this.name];
    }

    public getColumnName(desc: Description): string | undefined {
        return getColumnProperty(this.getProperty(desc));
    }

    public setInDescription<TDesc>(desc: TDesc, columnName: string): TDesc {
        desc = { ...desc };
        (desc as any)[this.name] = makeColumnProperty(columnName);
        return desc;
    }
}

export class TablePropertyHandler extends PropertyHandler<PropertyKind.Table> implements TablePropertyDescriptor {
    constructor(
        name: string,
        label: string,
        public readonly required: boolean,
        public readonly getAllowedTables: GetAllowedTablesFunction,
        public readonly section: SuperPropertySection,
        public readonly when?: WhenPredicate<any, any>
    ) {
        super(PropertyKind.Table, name, label);
    }

    public getTableName(desc: Description): TableName | undefined {
        return getTableProperty((desc as any)[this.name]);
    }

    public getTableRef(desc: Description): TableRefGlideType | undefined {
        return definedMap(this.getTableName(desc), makeTableRef);
    }

    public lookupTable(desc: Description, schema: SchemaInspector): TableGlideType | undefined {
        return definedMap(this.getTableRef(desc), ref => schema.findTable(ref));
    }

    public setInDescription<TDesc>(desc: TDesc, t: TableName): TDesc {
        desc = { ...desc };
        (desc as any)[this.name] = makeTableProperty(t);
        return desc;
    }
}

export class IconPropertyHandler extends PropertyHandler<PropertyKind.Icon> implements IconPropertyDescriptor {
    constructor(
        name: string,
        label: string,
        public readonly section: SuperPropertySection,
        public readonly defaultIcon?: string,
        public readonly when?: WhenPredicate<any, any>
    ) {
        super(PropertyKind.Icon, name, label);
    }

    public getIcon(desc: Description): string | undefined {
        return getIconProperty((desc as any)[this.name]) ?? this.defaultIcon;
    }
}

export class InlineComputationPropertyHandler
    extends PropertyHandler<PropertyKind.InlineComputation>
    implements InlineComputationPropertyDescriptor
{
    public readonly required = false;

    constructor(
        name: string,
        label: string,
        public readonly section: SuperPropertySection,
        public readonly getAllowedColumns: GetAllowedColumnsFunction,
        public readonly getIndirectTable?: PropertyTableGetter,
        public readonly when?: WhenPredicate<any, any>,
        public readonly options: InlineComputationPropertyOptions = {}
    ) {
        super(PropertyKind.InlineComputation, name, label);
    }
}

export class StringPropertyHandler extends PropertyHandler<PropertyKind.String> implements StringPropertyDescriptor {
    constructor(
        name: string,
        label: string,
        public readonly placeholder: string,
        public readonly required: boolean,
        public readonly isCaption: string | undefined,
        public readonly section: SuperPropertySection,
        public readonly isMultiLine: boolean = false,
        public readonly syntaxMode: string | undefined = undefined,
        public readonly builderOnly: boolean | undefined = undefined,
        public readonly when?: WhenPredicate<any, any>
    ) {
        super(PropertyKind.String, name, label);
    }

    public getString(desc: Description): string | undefined {
        return getStringProperty((desc as any)[this.name]);
    }

    public setInDescription<TDesc>(desc: TDesc, str: string): TDesc {
        desc = { ...desc };
        (desc as any)[this.name] = makeStringProperty(str);
        return desc;
    }
}

export class NumberPropertyHandler<TObject extends Record<string, number>>
    extends PropertyHandler<PropertyKind.Number>
    implements NumberPropertyDescriptor
{
    public readonly defaultValue: number;
    public getMaxValue:
        | ((componentTables: InputOutputTables, desc: Description, schema: SchemaInspector) => number | undefined)
        | undefined;
    constructor(
        defaultObject: TObject,
        label: string,
        public readonly placeholder: string,
        public readonly required: RequiredKind,
        public readonly style: NumberPropertyStyle,
        public readonly section: PropertySection,
        public readonly position?: number,
        maxValue?: number
    ) {
        super(PropertyKind.Number, defined(Object.keys(defaultObject)[0]), label);

        this.defaultValue = defaultObject[this.name];

        if (maxValue !== undefined) {
            this.getMaxValue = () => {
                return maxValue;
            };
        }
    }

    public getNumber(desc: Description | undefined): number | undefined {
        const value = getNumberProperty((desc as any)?.[this.name]);
        if (value !== undefined) return value;
        if (this.required !== RequiredKind.NotRequiredDefaultMissing) return this.defaultValue;
        return undefined;
    }

    public setInDescription<T>(desc: T, n: number): T {
        desc = { ...desc };
        (desc as any)[this.name] = makeNumberProperty(n);
        return desc;
    }
}

interface SwitchPropertyOptions {
    readonly withCondition?: boolean;
    readonly specPrefix?: string;
    readonly showContextAsContainingScreen?: boolean;
    readonly getIndirectTable?: PropertyTableGetter;
    readonly icon?: string;
    readonly subcomponent?: Subcomponent;
    readonly conditionPrompt?: string;
    readonly defaultTitle?: string;
    readonly helpText?: string;
}

export class SwitchPropertyHandler<TObject extends Record<string, boolean>>
    extends PropertyHandler<PropertyKind.Switch>
    implements SwitchPropertyDescriptor
{
    public readonly defaultValue: boolean;
    private readonly defaultValueWithCondition: SwitchDescription;
    constructor(
        private readonly defaultObject: TObject,
        label: string,
        public readonly section: SuperPropertySection,
        private readonly opts?: SwitchPropertyOptions,
        public readonly when?: WhenPredicate<any, any>
    ) {
        super(PropertyKind.Switch, defined(Object.keys(defaultObject)[0]), label);

        this.defaultValue = defaultObject[this.name];
        this.defaultValueWithCondition = { value: this.defaultValue, title: this.defaultTitle };
    }

    public get defaultTitle(): string | undefined {
        return this.opts?.defaultTitle;
    }

    public getProperty(desc: Description | undefined): LegacyPropertyDescription | undefined {
        return (desc as any)[this.name];
    }

    public getSwitch(desc: Description | undefined): boolean {
        if (desc === undefined) return this.defaultValue;
        return getSwitchProperty(this.getProperty(desc)) ?? this.defaultValue;
    }

    public getSwitchWithCondition(desc: Description | undefined): SwitchDescription {
        if (desc === undefined) return this.defaultValueWithCondition;

        let value = getSwitchWithConditionProperty(this.getProperty(desc));
        if (value === undefined) return this.defaultValueWithCondition;

        if (value.title === undefined && this.defaultTitle !== undefined) {
            value = {
                ...value,
                title: this.defaultTitle,
            };
        }
        return value;
    }

    public get defaultProperty(): PropertyDescription {
        return makeSwitchProperty(this.defaultValue);
    }

    public get withCondition(): boolean | undefined {
        return this.opts?.withCondition;
    }

    public get specPrefix(): string | undefined {
        return this.opts?.specPrefix;
    }

    public get showContextAsContainingScreen(): boolean | undefined {
        return this.opts?.showContextAsContainingScreen;
    }

    public get getIndirectTable(): PropertyTableGetter | undefined {
        return this.opts?.getIndirectTable;
    }

    public get icon(): string | undefined {
        return this.opts?.icon;
    }

    public get conditionPrompt(): string | undefined {
        return this.opts?.conditionPrompt;
    }

    public get subcomponent(): Subcomponent | undefined {
        return this.opts?.subcomponent;
    }

    public get helpText(): string | undefined {
        return this.opts?.helpText;
    }

    public withConditionEnabled(enabled: boolean): SwitchPropertyHandler<TObject> {
        return new SwitchPropertyHandler(this.defaultObject, this.label, this.section, {
            ...this.opts,
            withCondition: enabled,
        });
    }

    public withSection(section: SuperPropertySection): SwitchPropertyHandler<TObject> {
        return new SwitchPropertyHandler(this.defaultObject, this.label, section, this.opts);
    }

    public withIndirectTable(getter: PropertyTableGetter | undefined): SwitchPropertyHandler<TObject> {
        return new SwitchPropertyHandler(this.defaultObject, this.label, this.section, {
            ...this.opts,
            getIndirectTable: getter,
        });
    }

    public withoutTitle(): SwitchPropertyHandler<TObject> {
        return new SwitchPropertyHandler(this.defaultObject, this.label, this.section, {
            ...this.opts,
            defaultTitle: undefined,
        });
    }
}

export class EnumPropertyHandler<P extends BasePrimitiveValue, TObject extends Record<string, P>>
    extends PropertyHandler<PropertyKind.Enum>
    implements EnumPropertyDescriptor<P>
{
    public readonly defaultCaseValue: P;

    constructor(
        defaultObject: TObject,
        label: string,
        public readonly menuLabel: string,
        public readonly cases: readonly EnumPropertyCase<P>[] | (() => Promise<readonly EnumPropertyCase<P>[]>),
        public readonly section: SuperPropertySection,
        public readonly visual: EnumVisual,
        public readonly getIndirectTable?: PropertyTableGetter,
        public readonly subcomponent?: Subcomponent,
        public readonly isSearchable?: boolean,
        public readonly defaultDisplayLabel?: string,
        public readonly helpText?: string,
        public readonly when?: WhenPredicate<any, any>
    ) {
        super(PropertyKind.Enum, defined(Object.keys(defaultObject)[0]), label);

        this.defaultCaseValue = defaultObject[this.name];
    }

    public getEnum(desc: Description | undefined): P {
        if (desc === undefined) return this.defaultCaseValue;
        return getEnumProperty((desc as any)[this.name]) ?? this.defaultCaseValue;
    }

    public getProperty(value: P): { [K in keyof TObject]: PropertyDescription } {
        return { [this.name]: makeEnumProperty(value) } as {
            [K in keyof TObject]: PropertyDescription;
        };
    }

    public get default(): { [K in keyof TObject]: PropertyDescription } {
        return this.getProperty(this.defaultCaseValue);
    }

    public setInDescription<TDesc>(desc: TDesc, value: P): TDesc {
        return { ...desc, [this.name]: makeEnumProperty(value) };
    }

    public withGetIndirectTable(getIndirectTable: PropertyTableGetter | undefined): EnumPropertyHandler<P, TObject> {
        assert(this.getIndirectTable === undefined);

        if (getIndirectTable === undefined) return this;

        const defaultObject = { [this.name]: this.defaultCaseValue } as TObject;
        return new EnumPropertyHandler(
            defaultObject,
            this.label,
            this.menuLabel,
            this.cases,
            this.section,
            this.visual,
            getIndirectTable
        );
    }
}

export class ZapPropertyHandler extends PropertyHandler<PropertyKind.Zap> implements ZapPropertyDescriptor {
    constructor(name: string, label: string, public readonly section: PropertySection) {
        super(PropertyKind.Zap, name, label);
    }

    public getZap(desc: Description): string | undefined {
        return getZapProperty((desc as any)[this.name]);
    }
}

export const isRequiredPropertyHandler = new SwitchPropertyHandler(
    { isRequired: false },
    "Required",
    PropertySection.Options
);

function getDefaultCaption(descr: PropertyDescriptor): string | undefined {
    if (!hasOwnProperty(descr.property, "name")) return undefined;

    if (isMultiCasePropertyDescriptor(descr)) {
        for (const c of descr.cases) {
            if (c.kind === PropertyKind.String) {
                return (c as StringPropertyDescriptorCase).isCaption;
            }
        }
    } else if (descr.kind === PropertyKind.String) {
        return descr.isCaption;
    } else {
        return undefined;
    }
    return undefined;
}

type CaptionPropertyDescriptor = StringPropertyDescriptor | MultiCasePropertyDescriptor;

export function findCaptionDescriptor(
    properties: ReadonlyArray<PropertyDescriptor>
): CaptionPropertyDescriptor | undefined {
    return properties.find(d => getDefaultCaption(d) !== undefined) as CaptionPropertyDescriptor | undefined;
}

export function makeImageHeightPropertyHandler(
    defaultCaseValue: ImageAspectRatio,
    includeFit: boolean,
    includeCircle: boolean,
    includeExtendedOptions: boolean,
    label?: string
): EnumPropertyHandler<ImageAspectRatio, { size: ImageAspectRatio }> {
    const cases: EnumPropertyCase<ImageAspectRatio>[] = [
        {
            value: ImageAspectRatio.Square,
            label: "Square",
            icon: "borderSquare",
        },
        {
            value: ImageAspectRatio.ThreeByOne,
            label: "3:1",
            icon: "border3by1",
        },
        {
            value: ImageAspectRatio.ThreeByTwo,
            label: "3:2",
            icon: "border3by2",
        },
    ];
    if (includeCircle) {
        cases.push({ value: ImageAspectRatio.Circle, label: "Circle", icon: "borderCircle" });
    }

    if (includeExtendedOptions) {
        cases.push(
            {
                value: ImageAspectRatio.FourByThree,
                label: "4:3",
                icon: "border4by3",
            },
            {
                value: ImageAspectRatio.TwoByThree,
                label: "2:3 (Vertical)",
                icon: "border2by3",
            },
            {
                value: ImageAspectRatio.ThreeByFour,
                label: "3:4 (Vertical)",
                icon: "border3by4",
            }
        );
    }
    if (includeFit) {
        cases.push({
            value: ImageAspectRatio.Fit,
            label: "Original",
            icon: "borderOriginal",
        });
    }

    return new EnumPropertyHandler(
        { size: defaultCaseValue },
        label ?? "Size",
        "Image height",
        cases,
        PropertySection.Design,
        includeExtendedOptions ? "dropdown" : "small-images"
    );
}

export const visibilityPropertyDescriptor: PropertyDescriptor = {
    kind: PropertyKind.Transforms,
    property: { name: "visibilityFilters" },
    // FIXME: This label is not used
    label: "Show component",
    addText: "Add condition",
    description: "Set conditions for when the component should be visible.",
    specPrefix: "Show component when",
    allowContextTable: true,
    allowLHSUserProfileColumns: true,
    forFilteringRows: false,
    getIndirectTable: tables => definedMap(tables, t => ({ table: t.output, inScreenContext: true })),
    section: PropertySection.Visibility,
    withContainingScreen: false,
    allowSpecialValues: true,
};
