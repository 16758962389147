import {
    nonUserAppFeaturesCodec,
    paymentProcessorCodec,
    type TypeOfIOTS,
    appAuthenticationCodec,
    appDescriptionCodec,
    pluginConfigCodec,
    pluginConfigParametersCodec,
    iconImageCodec,
    type PropertyDescription,
    sharedIntegration,
} from "@glide/app-description";
import { eminenceFlagsCodec, StripeCustomerPortalFlowTypes, usagePeriodIDCodec } from "@glide/billing-types";
import {
    baseRowIndexCodec,
    rowIndexCodec,
    serializedQueryCodec,
    queryTableVersionsCodec,
    type QueryTableVersions,
} from "@glide/computation-model-types";
import { glideDateTimeDocumentDataCodec, glideJSONDocumentDataCodec, cellValueCodec } from "@glide/data-types";
import { appKindCodec } from "@glide/location-common";
import type { ExternalConfigurationStep, Note } from "@glide/plugins";
import { tierCodec, glideIconPropsCodec } from "@glide/plugins-codecs";
import { baseThemeCodec } from "@glide/base-theme";
import { hasOwnProperty } from "@glideapps/ts-necessities";
import { isLeft, isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";
import type { StripeCardInfo, StripeDate } from "./Database/stripe-info";
import { SubscriptionType } from "./app-plans";
import {
    nativeTableRowIDColumnName,
    rowIndexColumnName,
    tableNameCodec,
    type TypeSchema,
    tableColumnCodec,
    tableGlideTypeCodec,
    typeSchemaCodec,
    type ExternalSource,
    nativeTableSourceMetadata,
    sourceMetadataCodec,
    nativeTableIDCodec,
    userProfileTableInfoCodec,
    nativeTableQueryType,
} from "@glide/type-schema";
import { ActionKind } from "./database-strings";
import { gcpGmailScopeCodec, gcpScopeCodec } from "./gcp";
import { refreshResultCodec } from "./google-sheets";
import {
    type AppLoginTokenContainer,
    aeadDataCodec,
    airtableTokens,
    appLoginTokenContainerCodec,
    integration,
} from "./integration-types";
import { iotsdate } from "./io-ts-date";
import { msalScopeCodec } from "./msal";
import { DeviceFormFactor, PagePreviewDevice } from "./render/form-factor";
import { onboardingUseCases } from "./use-cases";
import { decodeGeneratedComponent } from "@glideapps/custom-component-client";
import { resultPluginValues } from "./plugin-value";

export function asAppLoginTokenContainer(x: unknown): AppLoginTokenContainer | undefined {
    const decoded = appLoginTokenContainerCodec.decode(x);
    return isLeft(decoded) ? undefined : decoded.right;
}

export const appIDBody = t.type({
    appID: t.string,
});

export const passwordBody = t.intersection([
    appIDBody,
    t.partial({
        // Note that this used to be required, but is being deprecated
        // by the new "token" field.
        // FIXME: Remove this field once app login passwords are removed
        password: t.string,
        // FIXME: Validate that it's an email address
        email: t.string,
        // This is a general replacement for the old email/password app login
        // system. We'll need to gradually roll this out.
        token: appLoginTokenContainerCodec,
    }),
]);

const newAppSurveyData = t.intersection([
    t.type({
        sourceUsed: t.string,
        sourceWanted: t.array(t.string),
        formFactor: t.string,
    }),
    t.partial({
        useCase: onboardingUseCases,
        otherUseCaseExplanation: t.string,
        jobLevel: t.string,
    }),
]);

export const generatePublishedAppDataFromSheetBody = t.intersection([
    newAppSurveyData,
    t.type({
        sheetsFileID: t.string,
        appKind: appKindCodec,
    }),
    t.partial({
        organizationID: t.string,
        importIntoNativeTables: t.boolean,
    }),
]);

// FIXME: finish
const typeSchema = t.unknown;

const generatePublishedAppDataFromSheetResult = t.intersection([
    appIDBody,
    t.type({
        sourceMetadataArray: t.readonlyArray(sourceMetadataCodec),
        schema: typeSchema,
    }),
    t.partial({
        spreadsheetName: t.string,
        numRowsUsedInApp: t.number,
        userProfileTableInfo: userProfileTableInfoCodec,
    }),
]);

const generatePublishedAppDataFromExcelResult = t.intersection([
    appIDBody,
    t.type({
        sourceMetadata: t.array(sourceMetadataCodec),
        schema: typeSchema,
    }),
    t.partial({
        spreadsheetName: t.string,
        numRowsUsedInApp: t.number,
        userProfileTableInfo: userProfileTableInfoCodec,
    }),
]);

export const prepareReplaceGoogleSheetsSourceBody = t.intersection([
    appIDBody,
    t.type({
        replacementFileID: t.string,
    }),
    t.partial({
        // The function doesn't actually use this anymore, other than for
        // logging.
        originalFileID: t.string,
    }),
]);

export const prepareRemoveGoogleSheetsSourceBody = t.intersection([
    appIDBody,
    t.type({
        originalFileID: t.string,
    }),
]);

export const prepareReplaceGoogleSheetsSourceResponse = t.union([
    t.type({
        status: t.literal("ok"),
        schema: typeSchema,
        title: t.string,
    }),
    t.type({
        status: t.literal("shared-drive-needs-upgrade"),
    }),
]);

export const prepareRemoveGoogleSheetsSourceResponse = t.type({
    status: t.literal("ok"),
    schema: typeSchema,
});

export const reloadPublishedAppDataFromSheetBody = t.intersection([
    appIDBody,
    t.type({
        checkCompatibilityIssues: t.boolean,
    }),
    t.partial({
        didReconnectSpreadsheet: t.boolean,
    }),
]);

export const reloadPublishedAppDataFromSheetResponse = t.intersection([
    t.type({
        status: t.number,
    }),
    t.partial({
        error: refreshResultCodec,
        result: refreshResultCodec,
        compatibilityProblems: t.unknown, // really `CompatibilityProblems | undefined`
    }),
]);

export const generateAppFromDescriptionBody = t.intersection([
    appIDBody,
    t.type({
        // Unless `force` is set, we won't publish
        // the app if it isn't already published. This is a
        // safeguard so we don't accidentally publish an app
        // that shouldn't be.
        force: t.boolean,
    }),
    t.partial({
        shortName: t.string,
    }),
]);

export const generateAppFromDescriptionResponse = t.intersection([
    t.type({
        status: t.number,
    }),
    t.partial({
        appURL: t.string,
        upgradeOrganization: t.string,
        error: t.string,
    }),
]);

const addUserProfileRowBody = t.partial({
    addUserProfileRow: t.boolean,
    // FIXME: This is only partial to be compatible with older frontends.
    deviceID: t.string,
});

const fromMagicLinkBody = t.partial({
    fromMagicLink: t.boolean,
});

export const privateMagicLinkCodeBody = t.intersection([
    appIDBody,
    t.partial({
        privateMagicLinkToken: t.string,
        // FIXME: Validate that it's an email address
        email: t.string,
        token: appLoginTokenContainerCodec,
    }),
]);

export const getCustomTokenForAppBody = t.intersection([
    passwordBody,
    addUserProfileRowBody,
    fromMagicLinkBody,
    privateMagicLinkCodeBody,
]);

export const authorizeUserForAppBody = t.intersection([
    passwordBody,
    addUserProfileRowBody,
    fromMagicLinkBody,
    privateMagicLinkCodeBody,
]);

export const getAppUserForAuthenticatedUserBody = appIDBody;

export const sendPinForEmailBody = t.intersection([
    appIDBody,
    t.type({ email: t.string }),
    t.partial({ glideCommit: t.string, privateMagicLinkToken: t.string }),
]);

const notAllowedToSignInReason = t.union([
    t.literal("non-personal-email-not-allowed"),
    t.literal("authentication-method-not-valid"),
    t.literal("other"),
    t.literal("email-pin-not-enabled"),
    t.literal("sign-in-not-enabled"),
    t.literal("email-domain-not-allowed"),
    t.literal("email-not-allowed"),
    t.literal("sign-ins-not-allowed"),
    t.literal("magic-link-invite-token-does-not-match"),
    t.literal("app-user-not-found"),
    t.literal("magic-link-creation-failure"),
]);

export type NotAllowedToSignInReason = t.TypeOf<typeof notAllowedToSignInReason>;

export const notAllowedToSignInResponse = t.readonly(
    t.partial({
        reason: notAllowedToSignInReason,
    })
);

export type NotAllowedToSignInResponse = TypeOfIOTS<typeof notAllowedToSignInResponse>;

export const sendActionErrorEmailBody = t.intersection([
    appIDBody,
    t.type({ actionID: t.string, failedActionName: t.string, errorMessage: t.string }),
    t.partial({ pageURL: t.string }),
]);
export type SendActionErrorEmailBody = TypeOfIOTS<typeof sendActionErrorEmailBody>;

export const requestAppUserAccessBody = t.intersection([appIDBody, t.type({ email: t.string })]);

export const requestAppUserAccessApprovalBody = t.intersection([
    appIDBody,
    t.type({ requestID: t.string }),
    t.partial({ deny: t.boolean }),
]);

export const getPasswordForEmailPinBody = t.intersection([
    appIDBody,
    t.type({
        // FIXME: Validate that it's an email address
        email: t.string,
        pin: t.string,
    }),
    t.partial({
        userAgreed: t.boolean,
    }),
    addUserProfileRowBody,
]);

export const getPasswordForOAuth2TokenBody = t.intersection([
    appIDBody,
    t.type({
        authToken: t.string,
        userAgreed: t.boolean,
    }),
    addUserProfileRowBody,
]);

export const getUnsplashImagesBody = t.intersection([
    appIDBody,
    t.type({
        query: t.string,
    }),
]);

export const unsplashPingDownloadBody = t.intersection([
    appIDBody,
    t.type({
        imageId: t.string,
    }),
]);

export const registerForPushNotificationsBody = t.intersection([
    appIDBody,
    t.type({
        appUserID: t.string,
        registrationToken: t.string,
    }),
]);

export const deleteAppBody = appIDBody;
export const getConfiguredPluginsForAppBody = appIDBody;

export const activationChecklist = t.union([
    t.literal("not-shown"),
    t.literal("glide-video"),
    t.literal("create-project"),
    t.literal("publish-project"),
    t.literal("share-project"),
    t.literal("complete"),
    t.literal("skipped"),
    t.literal("change-collection-style"),
    t.literal("edit-details-page"),
    t.literal("add-component"),
    t.literal("view-data"),
    t.literal("view-appearance"),
    t.literal("congratulations"),
    t.literal("intro"),
]);

export type ActivationChecklist = t.TypeOf<typeof activationChecklist>;

const userFlags = t.type({
    hasSeenOrganizeTip: t.boolean,
    hasSeenNavigationTip: t.boolean,
    hasSeenHireAnExpertDialog: t.boolean,
    hasSeenHireAnExpertHelpMenuItem: t.boolean,
    hasSeenLearningCenter: t.boolean,
    onboardingComplete: t.boolean,
    tourComplete: t.boolean,
    filterWarningComplete: t.boolean,
    visibilityWarningComplete: t.boolean,
    unprotectedColumnWarningComplete: t.boolean,
    allowEmail: t.boolean,
    disabledNewComponentsForNewColumns: t.boolean,
    disabledLinkTablesModal: t.boolean,
    // This isn't strictly a flag, but... whatever.
    lastAcceptedTermsOfService: t.number,
    builderTheme: t.union([t.literal("light"), t.literal("dark")]),
    useSystemTheme: t.boolean,
    // This flag is pretty loaded in terms of meaning.
    // It controls both auth persistence and analytics in the builder,
    // unless the user feature builderTrackingDisableAnalytics = true,
    // then it only controls auth persistence.
    builderTrackingOptOut: t.boolean,
    hidePagesAdvert: t.boolean,
    hideGettingStartedPrompt: t.boolean,
    templateMigrationTip: t.union([t.literal("not-needed"), t.literal("needs-notification"), t.literal("seen")]),
    activationChecklist,
    showActivationChecklist: t.union([t.literal("pending"), t.boolean]),
});

export type UserFlags = Readonly<t.TypeOf<typeof userFlags>>;

const randomizedReferralOptions = [
    "Community",
    "Instagram",
    "LinkedIn",
    "Online course",
    "Peer referral",
    "Friend or colleague",
    "Google, Bing, DuckDuckGo",
    "Search engines",
    "School",
    "ChatGPT, Claude, Gemini",
    "YouTube",
    "X (Twitter)",
];

export const selfReportedReferralSourceOptions: string[] = [
    ...randomizedReferralOptions.sort(() => (Math.random() < 0.5 ? -1 : 1)),
    "Other",
];

const selfReportedReferralSource = t.union([
    t.literal("google-search"),
    t.literal("word-of-mouth"),
    t.literal("social-media"),
    t.literal("blog-or-publication"),
    t.literal("other"),
    ...selfReportedReferralSourceOptions.map(s => t.literal(s)),
]);

export const selectableWorkRoles = [
    "CEO / Business Owner",
    "Operations",
    "IT",
    "Engineering",
    "Product Management",
    "Project Management",
    "Sales & Account Management",
    "Finance",
    "HR",
    "Education & Training",
    "Developer",
    "Other",
];

const workRoles: string[] = [
    ...selectableWorkRoles,
    "Customer Support",
    "IT & Engineering",
    "Marketing",
    "Product & Design",
];

const clientsWorkRoles = ["CEO / Business Owner", "Developer", "Operations", "Sales & Account Management", "Other"];

// didn't love that we were defining every enum-ish list twice for onboarding so I present to you...
// a silly helper, but io-ts' typescript signature of t.union
// requires you to have at least two elements in the passed array vs using map.
// so... you can't do ["one", "two", "three", "four"].map(t.literal) to build a codec
// for array of static valid values.
// this would have the same issue with Record<number, any> (enums),
// if we wanted to use actual enumerated list values in our TS programs :shrug:
// if someone more experienced with io-ts has suggestions super open to not doing this.
// More reading:
// https://github.com/gcanti/io-ts/issues/216
// https://github.com/gcanti/io-ts/blob/master/index.md#union-of-string-literals

export function arrayOfStringsToCodec(
    arr: string[]
): t.UnionC<[t.LiteralC<string>, t.LiteralC<string>, ...t.LiteralC<string>[]]> {
    const [one, two, ...rest] = arr;
    return t.union([t.literal(one), t.literal(two), ...rest.map(s => t.literal(s))]);
}

const workRole = arrayOfStringsToCodec(workRoles);
const clientResellerWorkRole = arrayOfStringsToCodec(clientsWorkRoles);

export type WorkRole = t.TypeOf<typeof workRole>;
export type ClientResellerWorkRole = t.TypeOf<typeof clientResellerWorkRole>;

const userDataUpdates = t.partial({
    email: t.string,
    displayName: t.string,
    phoneNumber: t.string,
    photoURL: t.string,
    flags: t.partial(userFlags.props),
    integrations: t.readonlyArray(integration),
    reasonForGlide: t.string,
    reasonForPersonalOrEducationUse: t.string,
    selfReportedReferralSource: selfReportedReferralSource,
    selfReportedReferralSourceOtherExplanation: t.string,
    workRole: workRole,
    otherWorkRole: t.string,
    partnerStackReferralPartnerKey: t.string,

    // to remain empty from Q4 2023 onwards:
    // TODO: related to https://github.com/glideapps/glide/pull/26787 and we should delete if possible.
    //  Search for this PR #26787 to get related lines.
    reasonForGlideOtherExplanation: t.string,
    clientResellerWorkRole: clientResellerWorkRole,
    otherClientResellerWorkRole: t.string,
    useCase: onboardingUseCases, // Check `UserData` comment on this field.
    otherUseCaseExplanation: t.string, // Check `UserData` comment on this field.
});

export type UserDataUpdates = t.TypeOf<typeof userDataUpdates>;

const userDataRemovals = t.partial({
    integrations: t.readonlyArray(integration),
});

export type UserDataRemovals = t.TypeOf<typeof userDataRemovals>;

export const setOrUpdateUserBody = t.intersection([
    t.type({
        updates: userDataUpdates,
        removals: userDataRemovals,
    }),
    t.partial({
        referredBy: t.string,
        emailAppendAuthorization: aeadDataCodec,
    }),
]);

export const storeAppDevicePreferenceBody = t.intersection([
    appIDBody,
    t.type({
        device: t.union([t.literal(PagePreviewDevice.Desktop), t.literal(PagePreviewDevice.Phone)]),
    }),
]);

export const isEmailSignedUpBody = t.type({
    email: t.string,
});

const cardPreview = t.type({
    last4: t.string,
    address: t.type({
        line1: t.string,
        line2: t.string,
        country: t.string,
        state: t.string,
        city: t.string,
        zip: t.string,
    }),
    email: t.string,
    name: t.string,
});
export type CardPreview = t.TypeOf<typeof cardPreview>;

const chargeResult = t.intersection([
    t.type({
        amountDue: t.number,
        nextDue: t.type({
            day: t.number,
            month: t.number,
            year: t.number,
        }),
    }),
    t.partial({ currentBalance: t.number, cardPreview }),
]);
export type ChargeResult = t.TypeOf<typeof chargeResult>;

export const previewChargeResponse = t.partial({
    immediate: chargeResult,
    monthly: chargeResult,
    annual: chargeResult,
    cardInfo: cardPreview,
    portalUrl: t.string,
});
export type PreviewChargeResponse = t.TypeOf<typeof previewChargeResponse>;

export const getOAuth2TokensForGoogleSheetsBody = t.partial({
    code: t.string,
    mergeWithFirebaseIDToken: t.string,
    isAuthentication: t.boolean,
});

export const duplicateAppBody = t.intersection([
    appIDBody,
    t.type({
        copySheet: t.boolean,
        // There are two ways this function gets called:
        // 1. From the dashboard, clicking "Duplicate" in the
        //    app menu.
        // 2. From the "Copy this app" button in the player.
        //
        // In case 1, the app is to be copied to wherever the
        // original was, My Apps vs an org.  In case 2, the app
        // always has to go to My Apps.  This flag is `true`
        // for case 2.
        fromTemplate: t.boolean,
    }),
    t.partial({
        dataOnly: t.boolean,
    }),
]);

export const duplicateAppResponseBody = t.intersection([
    appIDBody,
    t.partial({
        sourceMetadata: t.readonlyArray(sourceMetadataCodec),
        schema: typeSchemaCodec,
        title: t.string,
        error: t.string,
        stringifiedResponse: t.string,
    }),
]);

export const duplicateClassicAppAsPageBody = appIDBody;

// Returns the new app ID
export const duplicateClassicAppAsPageResponseBody = appIDBody;

export const duplicateClassicAppAnalysisBody = appIDBody;
export type DuplicateClassicAppAnalysisBody = TypeOfIOTS<typeof duplicateClassicAppAnalysisBody>;

export const classicFeaturesCodec = t.type({
    swipe: t.boolean,
    buyButton: t.boolean,
    shoppingCart: t.boolean,
    independentScreenConfig: t.boolean,
    stackChart: t.boolean,
    floatingButton: t.boolean,
    eventPicker: t.boolean,
    chat: t.boolean,
    comments: t.boolean,
    reaction: t.boolean,
    listRelation: t.boolean,
});

export type ClassicFeatures = t.TypeOf<typeof classicFeaturesCodec>;

export const duplicateClassicAppAnalysisResponseBody = t.type({
    appID: t.string,
    features: classicFeaturesCodec,
});
export type DuplicateClassicAppAnalysisResponseBody = TypeOfIOTS<typeof duplicateClassicAppAnalysisResponseBody>;

export const addTemplateTabToAppBody = t.intersection([
    appIDBody,
    t.type({
        templateAppID: t.string,
    }),
]);

const addTemplateTabToAppResponseBody = t.type({
    // From this template app description, add to the existing app
    // description:
    // * all source metadata (should all be native tables)
    // * all tabs
    // * all screens that don't already exist
    appDescription: appDescriptionCodec,
});

export enum TemplateFeatureChoices {
    Barcode = "barcode",
    Relations = "relations",
    Reactions = "reactions",
    Scheduling = "scheduling",
    FloatingButtons = "floating buttons",
    Charts = "charts",
    RichText = "rich text",
    BuyButton = "buy button",
    ProgressBar = "progress bar",
    BigNumbers = "big numbers",
    ActionButtons = "action buttons",
    SignIn = "sign-in",
}

export const templateFeatureChoice = t.union([
    t.literal(TemplateFeatureChoices.Barcode),
    t.literal(TemplateFeatureChoices.Relations),
    t.literal(TemplateFeatureChoices.Reactions),
    t.literal(TemplateFeatureChoices.Scheduling),
    t.literal(TemplateFeatureChoices.FloatingButtons),
    t.literal(TemplateFeatureChoices.Charts),
    t.literal(TemplateFeatureChoices.RichText),
    t.literal(TemplateFeatureChoices.BuyButton),
    t.literal(TemplateFeatureChoices.ProgressBar),
    t.literal(TemplateFeatureChoices.BigNumbers),
    t.literal(TemplateFeatureChoices.ActionButtons),
    t.literal(TemplateFeatureChoices.SignIn),
]);

const responsiveScreenshotUrlCodec = t.type({ mobileUrl: t.string, desktopUrl: t.string });
export type ResponsiveScreenshotUrl = t.TypeOf<typeof responsiveScreenshotUrlCodec>;

export const screenshotInfoCodec = t.intersection([
    responsiveScreenshotUrlCodec,
    t.type({
        tabIndex: t.number,
        isListItem: t.boolean,
    }),
]);

export type ScreenshotInfo = t.TypeOf<typeof screenshotInfoCodec>;

export const submitTemplateBody = t.intersection([
    appIDBody,
    t.type({
        name: t.string,
        author: t.string,
        subtitle: t.string,
        description: t.string,
        price: t.number,
        screenshots: t.union([t.array(t.string), t.array(screenshotInfoCodec)]),
    }),
    t.partial({
        usageVideoURL: t.string,
        requiredTier: tierCodec,
    }),
]);

export const shadowPublishTemplateBody = appIDBody;

const templateID = t.type({
    templateID: t.string,
});

export const templateIDWithPreviewUrl = t.intersection([
    templateID,
    t.type({
        url: t.string,
    }),
]);

export const shadowPublishTemplateResponse = templateIDWithPreviewUrl;

export const organizationBody = t.type({
    organizationID: t.string,
});

export const organizationWithAppBody = t.intersection([organizationBody, appIDBody]);

export const getAutomationsRunDataBody = t.intersection([
    organizationBody,
    t.type({
        actionID: t.string,
        runID: t.union([t.string, t.undefined]),
    }),
]);

export const getAutomationRunsBody = t.intersection([
    organizationBody,
    appIDBody,
    t.type({
        actionID: t.string,
    }),
]);

export const getAutomationRunBody = t.intersection([
    organizationBody,
    appIDBody,
    t.type({
        runID: t.string,
    }),
]);

export const getAutomationRunStatusForPollingBody = t.intersection([
    organizationBody,
    t.type({
        runID: t.string,
    }),
]);

export const getActionsStatusesBody = t.intersection([
    organizationBody,
    appIDBody,
    t.type({
        clientActionIDs: t.readonlyArray(t.string),
        automationIDs: t.readonlyArray(t.string),
    }),
]);
export type GetActionsStatusesBody = t.TypeOf<typeof getActionsStatusesBody>;
export const getActionsStatusesResponse = t.record(t.string, t.string);
export type GetActionsStatusesResponse = t.TypeOf<typeof getActionsStatusesResponse>;

// We have a bunch of partial members because
// - steps that never wait won't ever have `waitedAt` or `continueWithSignal`
//   fields.
// - steps that started waiting but haven't received their signal yet won't
//   have `finishedAt` or `outputs`.
const actionStepLogDataCodec = t.intersection([
    t.type({
        // The index is a monotonically increasing number, used for bookkeeping.
        index: t.number,
        nodeKey: t.string,
        // ISO-formatted date-time string
        startedAt: t.string,
        // The current indexes for the loops this step is in.  For steps outside
        // of loops, this will be empty.
        loopIndex: t.readonlyArray(t.number),
        resultKind: t.string,
        message: t.union([t.string, t.undefined]),
        data: t.record(t.string, t.unknown),
        outputs: t.record(t.string, t.unknown),
    }),
    t.partial({
        // These are ISO-formatted date-time strings
        waitedAt: t.string,
        finishedAt: t.string,
        errorData: t.record(t.string, t.unknown),
        continueWithSignal: t.record(t.string, t.unknown),
    }),
]);
export type ActionStepLogData = t.TypeOf<typeof actionStepLogDataCodec>;

// WARNING: this needs to be kept in sync with /packages/events/src/schemas/automation/run-automation.avsc
export const runAutomationTriggerKindCodec = t.union([
    t.literal("Builder"),
    t.literal("Schedule"),
    t.literal("CLI"),
    t.literal("App"),
    t.literal("Integration"),
]);
export type RunAutomationTriggerKind = t.TypeOf<typeof runAutomationTriggerKindCodec>;

export const automationRunInfoCodec = t.intersection([
    t.type({
        appID: t.string,
        actionID: t.string,
        runID: t.string,
        startedAt: t.string,
        finishedAt: t.union([t.string, t.undefined]),
        success: t.boolean,
        steps: t.number,
        actionDefinitionHash: t.string,
        triggerKind: runAutomationTriggerKindCodec,
        triggeredBy: t.union([t.string, t.undefined]),
    }),
    t.partial({
        triggerData: t.unknown,
        error: t.string,
    }),
]);
export type AutomationRunInfo = t.TypeOf<typeof automationRunInfoCodec>;

export const getAutomationRunResponseBodyCodec = t.type({
    action: t.unknown,
    run: automationRunInfoCodec,
    tableName: t.union([tableNameCodec, t.undefined]),
    logs: t.readonlyArray(actionStepLogDataCodec),
});
export type GetAutomationRunResponseBody = t.TypeOf<typeof getAutomationRunResponseBodyCodec>;

const getAutomationRunStatusForPollingResponseBodyCodec = t.type({
    run: automationRunInfoCodec,
});
export type GetAutomationRunStatusForPollingResponseBody = t.TypeOf<
    typeof getAutomationRunStatusForPollingResponseBodyCodec
>;

const orgMemberRole = t.union([t.literal("member"), t.literal("admin")]);

// Should be renamed to `setUserRole` or something
export const setOrgAdminBody = t.intersection([
    organizationBody,
    t.type({
        userIDOrEmail: t.string,
    }),
    t.partial({
        // The default is `"admin"`
        role: orgMemberRole,
    }),
]);
export type SetOrgAdminBody = t.TypeOf<typeof setOrgAdminBody>;

const range = t.type({
    min: t.number,
    max: t.number,
});

const companyInformation = t.type({
    industryKind: t.string,
    numEmployees: range,
    numAppEditors: range,
    numAppUsers: range,
});

export type CompanyInformation = t.TypeOf<typeof companyInformation>;

export const createOrganizationBody = t.type({
    displayName: t.string,
    inviteeEmails: t.readonlyArray(t.string),
    asAgencyClientTeam: t.union([t.string, t.undefined]),
    clientEmail: t.union([t.string, t.undefined]),
    companyInformation,
});

export const editOrganizationSettingsBody = t.intersection([
    organizationBody,
    t.partial({
        displayName: t.string,
        isAIEnabled: t.boolean,
        logoURL: t.string,
        defaultAppPrimaryColor: t.string,
    }),
]);

export const generateOrganizationIconUploadSignatureBody = t.intersection([
    organizationBody,
    t.type({
        publicID: t.string,
        timestamp: t.number,
    }),
]);
export type GenerateOrganizationIconUploadSignature = t.TypeOf<typeof generateOrganizationIconUploadSignatureBody>;

export const setOrgOnboardingBody = t.intersection([
    organizationBody,
    t.type({
        displayName: t.string,
        companyInformation,
    }),
    t.partial({
        logoURL: t.string,
        partnerStackReferrerPartnerKey: t.string,
    }),
]);

export const optIntoBetaBody = t.intersection([
    organizationBody,
    t.type({
        userFeature: t.string,
        value: t.boolean,
    }),
]);

export const finishOnboardingBody = t.partial({
    displayName: t.string,
    reasonForGlide: t.string,
    isForWork: t.boolean,
    allowEmail: t.boolean,
    company: t.string, // this is actually the team name
    companyForWork: t.string, // this the company name reported by the customer

    companySize: t.string,
    companySizeRange: range,
    workRole: t.string,
    otherWorkRole: t.string,
    workUseCase: t.string,
    otherUseCaseExplanation: t.string,
    selfReportedReferralSource: selfReportedReferralSource,
    otherSelfReportedReferralSource: t.string,
    reasonForPersonalOrEducationUse: t.string,
    jobLevel: t.string,

    // to remain empty from Q4 2023 onwards:
    // TODO: related to https://github.com/glideapps/glide/pull/26787 and we should delete if possible.
    //  Search for this PR #26787 to get related lines.
    reasonForGlideOtherExplanation: t.string,
    partnerStackReferralPartnerKey: t.string,
    clientResellerWorkRole: t.string,
    otherClientResellerWorkRole: t.string,
    // lineOfWork is mapped to "industry" which is no longer asked.
    // removed in https://github.com/glideapps/glide/issues/22553
    lineOfWork: t.string,
    otherLineOfWorkExplanation: t.string,
});

export const checkReverseFreeTrialEligibilityBody = t.intersection([
    organizationBody,
    t.type({
        isForWork: t.boolean,
        companySizeMin: t.number,
    }),
]);

export const maybeCreateReverseFreeTrialBody = organizationBody;

export const endReverseFreeTrialBody = organizationBody;

export const requestTrialExtensionBody = organizationBody;

const organizationMember = t.intersection([
    t.type({
        userID: t.string,
        role: orgMemberRole,
        status: t.string,
    }),
    t.partial({
        displayName: t.string,
        email: t.string,
        photoURL: t.string,
        emailNotificationsSettings: t.record(t.string, t.boolean),
    }),
]);

export type OrganizationMember = t.TypeOf<typeof organizationMember>;

export const getOrganizationMembersResult = t.type({
    members: t.array(organizationMember),
});

const createOrganizationResult = t.intersection([
    t.type({
        organizationID: t.string,
    }),
    getOrganizationMembersResult,
]);

export const getOrganizationMembersBody = organizationBody;
export const getDashboardDataBody = t.intersection([
    organizationBody,
    t.type({
        returnUrl: t.string,
    }),
]);

export const getOrganizationMemberIntegrationsBody = organizationBody;
export const getOrganizationMemberIntegrationsResult = t.array(sharedIntegration);

export const getOrganizationBillingBody = organizationBody;

export const getOrganizationPlansBody = organizationBody;

export const getOrganizationBillingResult = t.type({
    canUpgrade: t.boolean,

    basePrice: t.number,
    pricePerUser: t.number,
    pricePerApp: t.number,
    totalPrice: t.number,

    nextBillDate: t.number,

    publicAppIDs: t.array(t.string),
    includedUsers: t.number,
    includedApps: t.number,
    additionalUsers: t.number,
});

export const getOrganizationPersistentInviteLinkBody = t.type({
    organizationID: t.string,
    createNew: t.boolean,
});

export const getOrganizationPersistentInviteLinkResult = t.type({
    persistentInviteLink: t.string,
});

export const acceptInviteBody = t.type({
    acceptCode: t.string,
});

export const inviteToOrganizationBody = t.intersection([
    organizationBody,
    t.type({
        inviteeEmails: t.readonlyArray(t.string),
    }),
]);

export const acceptPluginAuthCodeBody = t.intersection([
    organizationBody,
    t.type({
        authorizationCode: t.string,
        pluginID: t.string,
        authProvider: t.string,
        appID: t.string,
        instanceID: t.string,
    }),
    t.partial({
        proofKey: t.string,
    }),
]);

export const ownerIDBody = t.type({
    ownerID: t.string,
});

export const acceptPluginAuthCodeForOwnerBody = t.intersection([
    ownerIDBody,
    t.type({
        authorizationCode: t.string,
        authProvider: t.string,
    }),
    t.partial({
        proofKey: t.string,
        displayName: t.string,
    }),
]);

const inviteToOrganizationResult = getOrganizationMembersResult;

export const renameOrganizationBody = t.intersection([
    organizationBody,
    t.type({
        newName: t.string,
    }),
]);

export const removeFromOrganizationBody = t.intersection([
    organizationBody,
    t.type({
        userID: t.string,
        userEmail: t.string,
    }),
]);

export const renameTableBody = t.intersection([
    appIDBody,
    t.type({
        tableName: tableNameCodec,
        newName: t.string,
    }),
]);

export const deleteOrganizationBody = organizationBody;

export const transferAppToOrganizationBody = t.intersection([
    organizationBody,
    appIDBody,
    t.type({ confirmed: t.boolean, asPublicApp: t.boolean }),
]);

export enum TransferAppToOrganizationResponse {
    Success = "success",
    ErrorCannotTransferOrgApp = "error-cannot-transfer-org-app",
    ConfirmTransferUnpublished = "confirm-transfer-unpublished",
    ErrorCannotTransferPublishedToFree = "error-cannot-transfer-published-to-free",
    ErrorCannotTransferPaidToFreeOrg = "error-cannot-transfer-paid-to-free-org",
    ErrorCannotTransferTemplateBasedApp = "error-cannot-transfer-template-based-app",
    ErrorCannotTransferAppWithQueryableTable = "error-cannot-transfer-app-with-queryable-table",
    ConfirmTransferPublished = "confirm-transfer-published",
    ErrorCannotTransferBetweenUnifiedAndNon = "error-cannot-transfer-between-unified-and-non",
    ErrorOther = "error-other",
}

export const setEmailOwnersColumnsBody = t.intersection([
    appIDBody,
    t.type({
        // updates for email owners
        updates: t.record(t.string, t.readonlyArray(t.string)),
    }),
]);

export const modifySyntheticColumnsBody = t.intersection([
    appIDBody,
    t.partial({
        set: t.record(t.string, t.readonlyArray(tableColumnCodec)),
        remove: t.record(t.string, t.readonlyArray(t.string)),
        // table -> [column, index][]
        move: t.record(t.string, t.readonlyArray(t.tuple([t.string, t.number]))),
        // table -> [column, isProtected][]
        setProtected: t.record(t.string, t.readonlyArray(t.tuple([t.string, t.boolean]))),
        rowIDColumns: t.readonlyArray(t.tuple([t.string, t.union([t.string, t.null])])),
        confirmDestructive: t.boolean,
    }),
]);

// Not just used for imports anymore
export const createNativeTableForImportBody = t.intersection([
    t.type({
        name: t.string,
        columns: t.array(tableColumnCodec),
    }),
    t.partial({
        // This is the ownerID of the _actual_ owner. If this is called
        // for an app owned by a Team, onBehalfOf is the ownerID of the Team.
        onBehalfOf: t.string,
        // If this is present, then add the table to this app, too.
        appID: t.string,
    }),
]);

export const createNativeTableForImportResponse = t.type({
    nativeTableID: nativeTableIDCodec,
    sourceMetadata: nativeTableSourceMetadata,
});

export const importRowsIntoNativeTableBody = t.intersection([
    t.type({
        nativeTableID: nativeTableIDCodec,
        rows: t.array(
            t.intersection([
                t.type({
                    [nativeTableRowIDColumnName]: t.string,
                    [rowIndexColumnName]: t.string,
                }),
                t.record(
                    t.string,
                    t.union([t.boolean, t.number, t.string, glideDateTimeDocumentDataCodec, glideJSONDocumentDataCodec])
                ),
            ])
        ),
    }),
    t.partial({
        // If we have it then we use this for the row quota
        appID: t.string,
        onBehalfOf: t.string,
    }),
]);

export const moveTableInSchemaBody = t.intersection([
    appIDBody,
    t.type({
        tableName: tableNameCodec,
    }),
    t.partial({
        afterTableName: tableNameCodec,
    }),
]);

export const deleteAllRowsInTableBody = t.intersection([
    appIDBody,
    t.type({
        tableName: tableNameCodec,
    }),
]);

export const moveOrgInUserBody = t.intersection([
    t.type({
        orgID: t.string,
    }),
    t.partial({
        afterOrgID: t.string,
    }),
]);

export const createAppFromTemplateBody = t.intersection([
    t.type({
        sourceAppID: t.string,
        appDescription: appDescriptionCodec,
    }),
    t.partial({
        ownerID: t.string,
    }),
]);

export const createTemplateFromAppBody = appIDBody;

export const reportGeocodesInAppBody = t.intersection([
    appIDBody,
    t.type({
        geocodes: t.number,
    }),
]);

export const actionLog = t.intersection([
    appIDBody,
    t.type({
        // serial id of the log itself - this sourced from postgresql
        id: t.number,
        // actionID not consitent over different runs. Unique ID created on front-end on client per action run.
        actionID: t.string,
        actionName: t.string,
        timestamp: iotsdate,
        ended: iotsdate,
    }),
    t.partial({
        // If you group over customActionID, thats all the actions that ran in that custom action.
        // This is stable until you edit the custom action. We use this to find all the logs in the
        // action logging view.
        customActionID: t.string,
        actionTitle: t.string,
        appUserID: t.string,
        authID: t.string,
        actionParameters: t.UnknownRecord,
        actionResult: t.UnknownRecord,
        error: t.string,
    }),
]);
export type ActionLog = t.TypeOf<typeof actionLog>;

export const actionLogs = t.readonlyArray(actionLog);
export type ActionLogs = t.TypeOf<typeof actionLogs>;

export const getAppActionLogsBody = t.intersection([
    appIDBody,
    t.partial({
        customActionID: t.string,
    }),
]);

export const getAppActionLogsResponse = t.type({
    actionLogs,
});

const writeActionLog = t.intersection([
    t.type({
        actionID: t.string,
        actionName: t.string,
        timestamp: t.number, // JS date
        ended: t.number, // JS date
    }),
    t.partial({
        customActionID: t.string,
        actionTitle: t.string,
        actionParameters: t.UnknownRecord,
        actionResult: t.UnknownRecord,
        error: t.string,
    }),
]);
export type WriteActionLog = t.TypeOf<typeof writeActionLog>;

export const writeActionLogBody = t.intersection([
    appIDBody,
    t.type({
        logs: t.readonlyArray(writeActionLog),
    }),
    t.partial({
        pageURL: t.string,
    }),
]);

export const reportQuotaIncreaseBody = t.intersection([
    appIDBody,
    t.type({ quotaChanges: t.record(t.string, t.number) }),
]);

export const getSimpleValueBody = t.intersection([
    appIDBody,
    t.type({
        value: t.string,
    }),
]);

export const checkDomainConfiguredBody = t.intersection([
    t.type({
        domain: t.string,
        user: t.string,
    }),
    t.partial({
        skipCheck: t.boolean,
    }),
]);

export const addTableBody = t.intersection([
    appIDBody,
    t.type({
        target: t.union([t.literal("native"), t.literal("queryable-native")]),
    }),
]);

// FIXME: be more precise with the values
export const columnValuesCodec = t.UnknownRecord;
export type ColumnValues = t.TypeOf<typeof columnValuesCodec>;

const triggerAutomationOriginCodec = t.intersection([
    t.type({
        triggerKind: runAutomationTriggerKindCodec,
    }),
    t.partial({
        triggeredBy: t.string,
    }),
]);
export type TriggerAutomationOrigin = t.TypeOf<typeof triggerAutomationOriginCodec>;

export const triggerAutomationBody = t.intersection([
    appIDBody,
    t.type({
        actionID: t.string,
    }),
    t.partial({
        // This will be passed through verbatim to `startAutomationRun` for
        // logging.  On the service side it will be converted by
        // `convertJSONToInputData`.
        triggerData: columnValuesCodec,
        // This will override if the call is from a Glide user (which is also
        // how the Automations service calls this endpoint).  Otherwise it's
        // ignored.
        originOverride: triggerAutomationOriginCodec,
    }),
]);

export const cancelAutomationRunBody = t.intersection([
    appIDBody,
    t.type({
        runID: t.string,
    }),
]);

const nativeTableBody = t.type({
    target: t.literal("native"),
    nativeTableID: nativeTableIDCodec,
});

const nativeTableAppIDBody = t.intersection([appIDBody, nativeTableBody]);
export const removeTableBody = nativeTableAppIDBody;
export const duplicateTableBody = nativeTableAppIDBody;

const googleSheetBody = t.type({
    target: t.literal("google"),
    fileID: t.string,
});

export const removeGoogleSheetBody = t.intersection([appIDBody, googleSheetBody]);

const nativeTablesBody = t.type({
    target: t.literal("native"),
    nativeTableIDs: t.readonlyArray(nativeTableIDCodec),
});

export type NativeTablesBody = t.TypeOf<typeof nativeTablesBody>;

const excelDataSource = t.literal("excel-online");
const airtableDataSource = t.literal("airtable");
const mysqlGcpDataSource = t.literal("mysql-gcp");
const bigqueryDataSource = t.literal("bigquery");
const queryablePluginDataSource = t.literal("queryable-plugin");
const dataPluginDataSource = t.literal("data-plugin");
// This should never be actually used on the backend;
// those need to have actual defined names.
const unknownDataSource = t.literal("unknown");

// bigqueryDataSource is actually not synced.
// FIXME: Remove it.
export const syncedDataSources = t.union([
    excelDataSource,
    airtableDataSource,
    mysqlGcpDataSource,
    bigqueryDataSource,
    unknownDataSource,
]);
export const queryableDataSources = t.union([bigqueryDataSource, queryablePluginDataSource, dataPluginDataSource]);

const externalDataSourceType = t.union([syncedDataSources, queryableDataSources]);
export type ExternalDataSourceType = Exclude<t.TypeOf<typeof externalDataSourceType>, "mysql-gcp">;

export function isValidExternalDataSourceType(type: ExternalSource["type"]): type is ExternalDataSourceType {
    return type !== "mysql-gcp";
}

export type SyncedDataSources = Exclude<t.TypeOf<typeof syncedDataSources>, "mysql-gcp">;
export function isValidSyncedDataSource(x: unknown): x is SyncedDataSources {
    return isRight(syncedDataSources.decode(x));
}

const syncedDataCollectionID = t.union([
    t.intersection([
        t.type({
            target: excelDataSource,
            workbookID: t.string,
            account: t.string,
        }),
        t.partial({ driveID: t.string }),
    ]),
    t.intersection([
        t.type({
            target: airtableDataSource,
            baseID: t.string,
        }),
        t.partial({
            authID: t.string,
        }),
    ]),
]);

export const linkTablesBody = t.intersection([
    appIDBody,
    t.union([nativeTablesBody, syncedDataCollectionID]),
    t.partial({ allowDuplicateExternalSource: t.literal(true) }),
]);
export const linkTablesResponseBody = t.intersection([
    t.type({
        sourceMetadatas: t.readonlyArray(nativeTableSourceMetadata),
    }),
    t.partial({
        existingSourceOwnerInfo: t.partial({
            displayName: t.string,
            email: t.string,
        }),
    }),
]);
export const syncTablesBody = t.intersection([appIDBody, nativeTablesBody]);

export const deleteTableBody = t.type({
    sourceMetadata: nativeTableSourceMetadata,
    editingAppID: t.string,
});

export type DataSourceChoice = "glide" | "google" | "imported-native-tables" | SyncedDataSources;

export const externalSourceTitleText: Record<ExternalDataSourceType, string> = {
    "excel-online": "Excel Workbook",
    airtable: "Airtable",
    bigquery: "BigQuery",
    "queryable-plugin": "External Source",
    "data-plugin": "External Source",
    unknown: "Unknown External Source",
};

export const listTablesBody = t.partial({
    organizationID: t.string,
    withQueryableTables: t.boolean, // defaults to true
});

const nativeTableInfo = t.intersection([
    t.type({
        id: nativeTableIDCodec,
        displayName: t.string,
        connectedApps: t.readonlyArray(t.string),
        numRows: t.number,
        lastModified: t.number, // JS date
    }),
    t.partial({
        externalDataSource: externalDataSourceType,
        needsQuery: t.boolean,
        preferredQueryType: nativeTableQueryType,
    }),
]);
export type NativeTableInfo = t.TypeOf<typeof nativeTableInfo>;

export const listTablesResult = t.type({
    nativeTables: t.readonlyArray(nativeTableInfo),
});

export const checkDomainRecord = t.intersection([
    t.type({
        host: t.string,
        type: t.string,
        value: t.string,
        expected: t.string,
    }),
    t.partial({
        error: t.string,
    }),
]);

export const checkDomainConfiguredResult = t.intersection([
    t.type({
        result: t.string,
        records: t.readonlyArray(checkDomainRecord),
    }),
    t.partial({
        errorType: t.string,
        errorMessage: t.string,
    }),
]);

export const setCustomDomainBody = t.intersection([
    appIDBody,
    t.type({
        domain: t.string,
    }),
]);

export const setShortNameBody = t.intersection([
    appIDBody,
    t.type({
        shortName: t.string,
    }),
]);

export const checkShortNameBody = t.intersection([
    appIDBody,
    t.type({
        shortName: t.string,
    }),
]);

export const isPersonalEmailBody = t.type({
    email: t.string,
});
export type IsPersonalEmailBody = t.TypeOf<typeof isPersonalEmailBody>;
export const isPersonalEmailResponse = t.boolean;
export type IsPersonalEmailResponse = t.TypeOf<typeof isPersonalEmailResponse>;

export const reportBillable = t.intersection([
    appIDBody,
    t.type({
        pluginID: t.string,
        actionID: t.string,
        count: t.number,
    }),
]);

export const generateScreenFromPromptBody = t.intersection([
    appIDBody,
    t.type({
        screenName: t.string,
        prompt: t.string,
    }),
]);
export type GenerateScreenFromPromptBody = t.TypeOf<typeof generateScreenFromPromptBody>;

export const setPublishingActiveBody = t.intersection([
    appIDBody,
    t.type({
        active: t.boolean,
    }),
]);
export const setPublishingActiveResponseBody = t.type({
    active: t.boolean,
});

const automationInteractiveTriggerMetadata = t.intersection([
    t.type({
        kind: t.union([t.literal("builder"), t.literal("app")]),
    }),
    t.partial({
        userEmail: t.string,
    }),
]);
const automationPluginTriggerMetadata = t.type({
    kind: t.literal("plugin"),
    pluginID: t.string,
    pluginConfigID: t.string,
    pluginTriggerID: t.string,
});
const automationTriggerMetadata = t.union([automationInteractiveTriggerMetadata, automationPluginTriggerMetadata]);
export type AutomationTriggerMetadata = t.TypeOf<typeof automationTriggerMetadata>;

export const startAutomationRunBody = t.intersection([
    appIDBody,
    t.type({
        actionID: t.string,
        runID: t.string,
        triggerKind: runAutomationTriggerKindCodec,
        triggerData: t.unknown,
    }),
    t.partial({
        triggeredBy: t.string,
        triggerMetadata: automationTriggerMetadata,
    }),
]);
export const startAutomationRunResponseBody = t.intersection([
    t.type({
        action: t.unknown,
        filter: t.unknown,
        limit: t.number,
        schema: typeSchemaCodec,
        sourceMetadata: t.readonlyArray(sourceMetadataCodec),
        pluginConfigs: t.readonlyArray(pluginConfigCodec),
        appFeatures: nonUserAppFeaturesCodec,
        eminenceFlags: eminenceFlagsCodec,
        adminIDToken: t.string,
        ownerID: t.string,
        adminAuthID: t.string,
        automationRunLogRowID: t.string,
        actionDefinitionHash: t.string,
        staticTimestamp: t.string,
    }),
    t.partial({
        /**
         * These are here so the "trigger workflow" action knows about them.
         * They don't contain the actual bodies, but they do specify the
         * inputs.  These are optional only because we added them later.
         */
        builderActions: t.readonlyArray(t.tuple([t.string, t.unknown])),
    }),
]);

export const automationFinishKindCodec = t.union([
    t.literal("CompletedNoErrors"),
    t.literal("CompletedWithErrors"),
    t.literal("TooManySteps"),
    t.literal("DidNotStart"),
    t.literal("AbortedOnError"),
    t.literal("TimedOut"),
    t.literal("SystemFailure"),
    t.literal("Canceled"),
]);
export type AutomationFinishKind = t.TypeOf<typeof automationFinishKindCodec>;

export const finishAutomationRunBody = t.intersection([
    appIDBody,
    t.type({
        actionID: t.string,
        automationRunLogRowID: t.string,
        finishKind: automationFinishKindCodec,
        stepsAttempted: t.number,
        stepsFailed: t.number,
    }),
    t.partial({
        error: t.string,
        firstFailureError: t.string,
    }),
]);

export const appendAutomationStepLogsBody = t.type({
    appID: t.string,
    runID: t.string,
    stepLogs: t.readonlyArray(t.intersection([actionStepLogDataCodec, t.partial({ rowID: t.string })])),
});
export type AppendAutomationStepLogsBody = t.TypeOf<typeof appendAutomationStepLogsBody>;

export const deleteExpiredAutomationRunLogsBody = t.type({
    tables: t.array(nativeTableIDCodec),
});

export type DeleteExpiredAutomationRunLogsBody = t.TypeOf<typeof deleteExpiredAutomationRunLogsBody>;

export const timeoutUnfinishedAutomationRunsBody = t.type({
    minAgeMS: t.number,
    maxAgeMS: t.number,
});
export const timeoutUnfinishedAutomationRunsResponseBody = t.type({
    // The run IDs of the runs we tried to time out.  If the value is `true`
    // it means we successfully timed it out.  Otherwise the value is the
    // error message.
    timedOutRuns: t.record(t.string, t.union([t.string, t.literal(true)])),
});

const actionKindCodec = t.union([
    t.literal(ActionKind.AddRowToTable),
    t.literal(ActionKind.SetColumnsInRow),
    t.literal(ActionKind.DeleteRow),
]);

export const getConfirmedMutationsBody = t.type({
    appID: t.string,
    mutations: t.readonlyArray(t.type({ kind: actionKindCodec, jobID: t.string })),
});

export const discardPausedAppChangesBody = appIDBody;

// FIXME: This isn't used anymore.
export const acquireStripeSessionBody = t.intersection([
    appIDBody,
    t.type({
        plan: t.keyof({ pro: null }),
        billingPlan: t.string,
        paidURL: t.string,
        canceledURL: t.string,
    }),
]);

const acquireStripeSessionCommonBody = t.intersection([
    appIDBody,
    t.type({
        paidURL: t.string,
        canceledURL: t.string,
    }),
    t.partial({
        idempotencyKey: t.string,
    }),
]);

export const acquireStripeSessionForProPurchaseBody = t.intersection([
    acquireStripeSessionCommonBody,
    t.type({
        idempotencyKey: t.string,
        plan: t.union([t.literal("pro-annual"), t.literal("pro-monthly")]),
    }),
]);

export const acquireStripeSessionForTemplatePurchaseBody = t.intersection([
    acquireStripeSessionCommonBody,
    t.partial({
        targetOwnerID: t.string,
    }),
]);

export const updateUnifiedPaymmentInformationBody = t.intersection([
    t.type({ ownerID: t.string }),
    t.partial({ paymentMethodID: t.union([t.string, t.literal(false)]) }),
]);

export const integrateWithStripeBody = t.union([
    t.intersection([
        t.type({
            postConnectURL: t.string,
        }),
        t.partial({
            onBehalfOf: t.string,
            forTemplateStore: t.boolean,
            closeOnSuccess: t.boolean,
        }),
    ]),
    t.type({
        postConnectURL: t.undefined,
        token: t.string,
        authorization: t.string,
    }),
    t.intersection([
        t.type({
            postConnectURL: t.undefined,
            token: t.undefined,
            disconnect: t.literal(true),
        }),
        t.partial({
            onBehalfOf: t.string,
            forTemplateStore: t.boolean,
        }),
    ]),
]);

export const integrateWithMicrosoftRequestBody = t.union([
    t.intersection([t.type({ kind: t.literal("request") }), t.partial({ scopes: t.array(msalScopeCodec) })]),
    t.type({
        kind: t.literal("response"),
        token: t.string,
        code: t.string,
        viaURL: t.string,
    }),
]);

export const integrateWithMicrosoftResponseBody = t.union([
    t.type({ kind: t.literal("request"), token: t.string }),
    t.type({ kind: t.literal("response"), microsoftID: t.string }),
    t.type({ kind: t.literal("error"), status: t.number, message: t.string }),
]);

export const integrateWithGCPRequestBody = t.union([
    t.type({ kind: t.literal("request"), scopes: t.union([t.array(gcpScopeCodec), t.array(gcpGmailScopeCodec)]) }),
    t.type({
        kind: t.literal("response"),
        token: t.string,
        code: t.string,
        viaURL: t.string,
    }),
]);

export const integrateWithGCPResponseBody = t.union([
    t.type({ kind: t.literal("request"), token: t.string }),
    t.type({ kind: t.literal("response"), googleAccountIDToken: t.string }),
    t.type({ kind: t.literal("error"), status: t.number, message: t.string }),
]);

export const integrateWithAirtableRequestBody = t.intersection([
    airtableTokens,
    t.type({
        testOnly: t.boolean,
    }),
]);

export const stripeInAppPurchaseConfirmIntentBody = t.intersection([
    appIDBody,
    t.type({
        purchaseID: t.string,
    }),
]);

const shippingAddressCodec = t.type({
    city: t.string,
    country: t.string,
    line1: t.string,
    line2: t.string,
    postalCode: t.string, // Many countries have non-numeric ZIP codes
    state: t.string,
});

export type ShippingAddress = t.TypeOf<typeof shippingAddressCodec>;

const requestedInAppPurchaseBase = t.intersection([
    appIDBody,
    t.type({
        paymentProcessor: paymentProcessorCodec,
        // FIXME: This should be specific to the paymentProcessor.
        processorPayload: t.intersection([
            t.type({
                paymentMethodID: t.string,
                idempotencyKey: t.string,
            }),
            t.partial({
                tdsRedirect: t.string,
            }),
        ]),
        billingName: t.string,
        // Be advised, some records on `staging` are missing this.
        receiptEmail: t.string,
    }),
    t.partial({
        shippingAddress: shippingAddressCodec,
        shippingPhone: t.string,
        testMode: t.boolean,
    }),
]);

const requestedInAppPurchaseItem = t.intersection([
    t.type({
        buttonID: t.string,
        productSKU: t.union([t.string, t.number]),
    }),
    t.partial({
        rowIndex: baseRowIndexCodec,
    }),
]);

export const requestedInAppPurchaseV1 = t.intersection([requestedInAppPurchaseBase, requestedInAppPurchaseItem]);

export const requestedInAppPurchaseV2 = t.intersection([
    requestedInAppPurchaseBase,
    t.type({
        version: t.literal(2),
        items: t.array(requestedInAppPurchaseItem),
    }),
]);

export const requestedInAppPurchase = t.union([requestedInAppPurchaseV1, requestedInAppPurchaseV2]);

export const geocodeAddressesBody = t.type({
    addresses: t.readonlyArray(t.string),
});

// Why is this not just part of io-ts?
const primitiveValue = t.union([t.string, t.number, t.boolean, t.null]);

const triggerZapValues = t.partial({
    val1: primitiveValue,
    val2: primitiveValue,
    val3: primitiveValue,
    val4: primitiveValue,
    val5: primitiveValue,
    val6: primitiveValue,
    val7: primitiveValue,
    val8: primitiveValue,
    val9: primitiveValue,
    val10: primitiveValue,
    val11: primitiveValue,
    val12: primitiveValue,
});

export const triggerZapBody = t.intersection([
    t.type({
        zapID: t.string,
        appID: t.string,
    }),
    triggerZapValues,
]);

export const transcribeAudioReponseBody = t.type({
    transcript: t.string,
});

const appSnapshotURLs = t.partial({
    dataSnapshot: t.string,
    privateDataSnapshot: t.string,
    unusedDataSnapshot: t.string,
    publishedAppSnapshot: t.string,
    nativeTableSnapshots: t.record(t.string, t.string),
});

export const getAppSnapshotRequestBody = appIDBody;
export const getAppSnapshotResponseBody = t.intersection([
    appIDBody,
    appSnapshotURLs,
    t.type({
        dataSnapshot: t.string,
    }),
    t.partial({
        schema: typeSchemaCodec,
        numRowsUsedInApp: t.number,
    }),
]);

export const ensureDataLivelinessBody = t.intersection([
    appIDBody,
    t.type({ editor: t.boolean }),
    t.partial({
        deviceID: t.string,
        standalone: t.boolean,
        builder: t.boolean,
        respondWithBody: t.boolean,
        locale: t.string,
    }),
]);

export const getPreviewAsUserBody = t.intersection([
    appIDBody,
    t.type({
        appUserEmail: t.string,
    }),
]);

const uploadComponentKind = t.union([
    t.literal("image-picker"),
    t.literal("image"),
    t.literal("file-picker"),
    t.literal("signature"),
    t.literal("user-profile"),
    t.literal("drop-image"),
    t.literal("data-editor"),
    t.literal("plugin"),
]);
export const uploadAppFileV2Body = t.union([
    t.intersection([
        appIDBody,
        t.type({
            operation: t.literal("begin"),
            contentType: t.string,
            contentLength: t.number,
            origin: t.string,
        }),
        t.partial({
            proposedExtension: t.string,
            proposedName: t.string,
            component: uploadComponentKind,
            deviceID: t.string,
        }),
    ]),
    t.type({ operation: t.literal("complete"), uploadID: t.string }),
    t.type({
        operation: t.literal("report-failure"),
        uploadID: t.string,
        permanent: t.boolean,
        kind: t.string,
        message: t.string,
    }),
]);

export const sendAppFeedbackBody = t.intersection([
    appIDBody,
    t.type({
        from: t.string,
        message: t.string,
    }),
]);

export const reportAppBody = t.intersection([
    appIDBody,
    t.type({
        from: t.string,
        message: t.string,
        reason: t.string,
    }),
]);

export const testIframeEmbeddableBody = t.type({
    url: t.string,
    referer: t.string,
});

export const makeSupportCodeForAppBody = appIDBody;

export const accessSupportCodeBody = t.type({
    code: t.string,
});

// This is used during promo code application
export enum CapturePromoCodeResult {
    Ok = 0,
    InvalidCode = 1,
    ExpiredCode = 2,
    OwnerIneligible = 3,
}

export const applyPromoCodeBody = t.type({
    ownerID: t.string,
    promoCode: t.string,
    subscriptionType: t.union([t.literal(SubscriptionType.Monthly), t.literal(SubscriptionType.Annual)]),
});

export const deliverEmailFromActionBody = t.intersection([
    appIDBody,
    t.type({
        to: t.readonlyArray(t.string),
        cc: t.readonlyArray(t.string),
        bcc: t.readonlyArray(t.string),
        subject: t.string,
        body: t.string,
        includeAppLink: t.boolean,
    }),
    t.partial({
        replyTo: t.string,
        inReplyTo: t.string,
    }),
]);

export const setAdditionalBillingBody = t.intersection([
    t.partial({
        companyName: t.string,
        additionalInfo: t.string,
    }),
    t.type({
        ownerID: t.string,
    }),
]);

// https://stripe.com/docs/api/customer_tax_ids/list
export const setTaxBody = t.type({
    ownerID: t.string,
    type: t.string,
    value: t.string,
});

export const setAppIsFavoriteBody = t.type({
    organizationID: t.string,
    appID: t.string,
    isFavorite: t.boolean,
});

export const getTaxBody = ownerIDBody;

export const deleteTaxBody = t.intersection([
    t.partial({
        taxID: t.string,
    }),
    t.type({
        ownerID: t.string,
    }),
]);
export const exportAppDataBody = appIDBody;

export const exportQueryableTableBody = t.intersection([
    appIDBody,
    t.type({
        tableName: tableNameCodec,
        columnNames: t.readonlyArray(t.string),
    }),
]);

export const requestDownloadLinkForExportBody = t.type({
    exportID: t.string,
});

export const deleteAppUserForAppBody = t.intersection([
    appIDBody,
    t.type({
        appUserEmail: t.string,
    }),
]);

export const requestDataExportBody = t.type({
    exportType: t.string,
    additionalInfo: t.string,
});

export const appBeaconBody = t.intersection([
    appIDBody,
    t.type({
        standalone: t.union([t.literal("true"), t.literal("false")]),
    }),
    t.partial({
        deviceID: t.string,
    }),
]);

const appSettingsRequired = {
    title: t.string,
    iconImage: iconImageCodec,
    theme: baseThemeCodec,
};
const appSettingsOptional = {
    author: t.string,
    description: t.string,
    authentication: appAuthenticationCodec,
};

const appSettings = t.intersection([t.type(appSettingsRequired), t.partial(appSettingsOptional)]);
export type AppSettings = t.TypeOf<typeof appSettings>;

const appSettingsOverrides = t.partial({ ...appSettingsRequired, ...appSettingsOptional });
export type AppSettingsOverride = t.TypeOf<typeof appSettingsOverrides>;

const pageTemplateKind = t.union([
    t.literal("basic"),
    t.literal("portal"),
    t.literal("directory"),
    t.literal("blank"),
    t.literal("form"),

    // these data sources are temporarily using templates
    // they should be removed once new data source workflows
    // are in place
    t.literal("glide-big-tables"),

    t.literal("mysql"),
    t.literal("postgresql"),
    t.literal("sqlserver"),
    t.literal("google-cloud-sql"),
    t.literal("bigquery"),

    onboardingUseCases,
]);
export type PageTemplateKind = t.TypeOf<typeof pageTemplateKind>;

export const newGlideTableAppBody = t.intersection([
    newAppSurveyData,
    t.type({
        overrides: appSettingsOverrides,
    }),
    t.partial({
        ownerID: t.string,
        appKind: appKindCodec,
        template: pageTemplateKind,
        forTemplate: t.boolean,
    }),
]);

export const newGlideTableAppResponse = t.readonly(
    t.intersection([
        t.type({
            appID: t.string,
        }),
        t.partial({
            pluginConfigID: t.string,
        }),
    ])
);

const airtableUserForm = t.intersection([
    t.type({
        baseID: t.string,
    }),
    t.partial({
        authID: t.string,
    }),
]);

const excelFormData = t.intersection([
    t.type({
        microsoftID: t.string,
        workbookID: t.string,
    }),
    t.partial({ driveID: t.string }),
]);

const dataSourceDetails = t.union([
    t.type({
        airtable: airtableUserForm,
    }),
    t.type({
        "excel-online": excelFormData,
    }),
    t.type({
        // This is an array of native table IDs.
        // We do not need any of the other metadata.
        "imported-native-tables": t.readonlyArray(nativeTableIDCodec),
    }),
]);

export const newAppBody = t.intersection([
    newAppSurveyData,
    dataSourceDetails,
    t.partial({
        ownerID: t.string,
        appKind: appKindCodec,
        // This is a hack to avoid having to retroactively store the document
        // information in the database, and is only used to report errors back
        // to the user.
        dataSourceDisplayName: t.string,
    }),
]);

export const checkExternalDataSourceExistsRequestBody = t.type({
    dataSourceDetails,
});

export const checkExternalDataSourceExistsResponseBody = t.type({
    exists: t.boolean,
});

const createAndSendAuthLinkBodyRequired = {
    emailAddress: t.string,
};
const createAndSendAuthLinkBodyOptional = {
    targetURL: t.string,
};
export const createAndSendAuthLinkBody = t.intersection([
    t.type(createAndSendAuthLinkBodyRequired),
    t.partial(createAndSendAuthLinkBodyOptional),
]);

export const sendEmailVerificationBody = t.type({
    emailAddress: t.string,
});

const storeChurnSurveyBodyRequired = {
    appID: t.string,
    reason: t.string,
};

const storeChurnSurveyBodyOptional = {
    explanation: t.string,
};

export const storeChurnSurveyBody = t.intersection([
    t.type(storeChurnSurveyBodyRequired),
    t.partial(storeChurnSurveyBodyOptional),
]);

const futureDataSource = t.union([
    t.literal("AirTable"),
    t.literal("MySQL"),
    t.literal("Excel"),
    t.literal("CSV"),
    t.literal("Salesforce"),
    t.literal("BigQuery"),
    t.literal("Redshift"),
    t.literal("GraphQL"),
    t.literal("Workday"),
]);

const mutationRowIndex = t.union([t.type({ rowIndex: t.number }), t.type({ rowID: t.string })]);
export type MutationRowIndex = t.TypeOf<typeof mutationRowIndex>;
const mutationTableName = t.type({ tableName: t.string });
const mutationColumnValues = t.type({ columnValues: t.record(t.string, t.unknown) });

const addRowTableMutation = t.intersection([
    mutationTableName,
    mutationColumnValues,
    t.type({
        kind: t.literal(ActionKind.AddRowToTable),
    }),
]);

const mutationTableNameAndRowIndex = t.intersection([mutationTableName, mutationRowIndex]);
export type MutationTableNameAndRowIndex = t.TypeOf<typeof mutationTableNameAndRowIndex>;

const setColumnsTableMutation = t.intersection([
    t.type({
        kind: t.literal(ActionKind.SetColumnsInRow),
    }),
    mutationTableNameAndRowIndex,
    mutationColumnValues,
]);

const deleteRowTableMutation = t.intersection([
    t.type({
        kind: t.literal(ActionKind.DeleteRow),
    }),
    mutationTableNameAndRowIndex,
]);

const tableMutation = t.union([addRowTableMutation, setColumnsTableMutation, deleteRowTableMutation]);
export type TableMutation = t.TypeOf<typeof tableMutation>;

export const mutateTablesRequestBody = t.intersection([
    appIDBody,
    t.type({
        mutations: t.readonlyArray(tableMutation),
    }),
]);

const tableQuery = t.intersection([
    t.union([
        t.intersection([
            mutationTableName,
            t.partial({
                startAt: t.string,
                limit: t.number,
            }),
        ]),
        t.intersection([
            t.type({
                sql: t.string,
            }),
            t.partial({
                params: t.array(t.union([t.string, t.number, t.boolean])),
            }),
        ]),
    ]),
    t.partial({
        // Return date-times in tz-aware columns as UTC?  Defaults to false.
        utc: t.boolean,
    }),
]);
export type TableQuery = t.TypeOf<typeof tableQuery>;

export const queryTablesRequestBody = t.intersection([
    appIDBody,
    t.type({
        queries: t.readonlyArray(tableQuery),
    }),
]);

const callOpenWeatherMapApiBodyRequired = {
    latitude: t.number,
    longitude: t.number,
};

const callOpenWeatherMapApiBodyOptional = {
    lang: t.string,
    units: t.union([t.literal("standard"), t.literal("metric"), t.literal("imperial")]),
};

export const callOpenWeatherMapApiBody = t.intersection([
    t.type(callOpenWeatherMapApiBodyRequired),
    t.partial(callOpenWeatherMapApiBodyOptional),
]);

const recentListItem = t.strict({
    remoteItem: t.intersection([
        t.strict({
            id: t.string,
            name: t.string,
            parentReference: t.strict({
                driveId: t.string,
            }),
            fileSystemInfo: t.union([
                t.strict({
                    lastModifiedDateTime: t.string,
                }),
                t.strict({
                    lastAccessedDateTime: t.string,
                }),
            ]),
            webUrl: t.string,
        }),
        t.partial({
            file: t.type({}),
            folder: t.type({}),
        }),
    ]),
});

const driveListItem = t.intersection([
    t.strict({
        id: t.string,
        name: t.string,
        parentReference: t.strict({
            driveId: t.string,
        }),
        lastModifiedDateTime: t.string,
        webUrl: t.string,
    }),
    t.partial({
        folder: t.unknown,
    }),
]);

export const deltaItem = t.strict({
    id: t.string,
    name: t.string,
    eTag: t.string,
    lastModifiedDateTime: t.string,
    parentReference: t.strict({
        id: t.string,
        driveId: t.string,
    }),
});

const workbookListSelectionItem = t.intersection([
    t.strict({
        id: t.string,
        name: t.string,
        dateTime: t.string,
        type: t.union([t.literal("file"), t.literal("folder")]),
        webUrl: t.string,
    }),
    t.partial({ driveID: t.string }),
]);

export const recentListItems = t.array(recentListItem);
export const driveListItems = t.array(driveListItem);
const workbookListSelectionItems = t.array(workbookListSelectionItem);

export const deltaPlusUnknownItem = t.union([deltaItem, t.UnknownRecord]);
export const deltaPlusUnknownItems = t.array(deltaPlusUnknownItem);
export const deltaItems = t.array(deltaItem);

export const deltaItemResponse = t.intersection([
    t.strict({
        value: deltaPlusUnknownItems,
    }),
    t.partial({
        "@odata.nextLink": t.string,
        "@odata.deltaLink": t.string,
    }),
]);

export const getExcelWorkbooksResponse = t.strict({
    workbooks: workbookListSelectionItems,
});

const listExcelDriveMethod = t.union([
    t.interface({ method: t.literal("recent") }),
    t.interface({ method: t.literal("shared") }),
    t.interface({ method: t.literal("folders"), path: t.string }),
]);

export type ListExcelDriveMethod = t.TypeOf<typeof listExcelDriveMethod>;

export const getExcelWorkbooksBody = t.intersection([
    t.type({
        microsoftID: t.string,
    }),
    listExcelDriveMethod,
    t.partial({ driveID: t.string }),
]);

export const getAvailableMicrosoftDrivesBody = t.type({
    microsoftID: t.string,
});

export const microsoftDriveInfoCodec = t.intersection([
    t.type({
        driveID: t.string,
        driveDisplayName: t.string,
        webURL: t.string,
        isPersonal: t.boolean,
    }),
    t.partial({
        ownerID: t.string,
        ownerDisplayName: t.string,
    }),
]);

export const microsoftDriveInfoResponse = t.type({
    drives: t.array(microsoftDriveInfoCodec),
});

export const removeMSAccountBody = t.type({
    microsoftID: t.string,
});

export const getAirtableBasesBody = t.partial({ authID: t.string });

export const getAirtableBasesResponse = t.strict({
    bases: t.readonlyArray(
        t.strict({
            id: t.string,
            name: t.string,
            isReadOnly: t.boolean,
        })
    ),
});

export const addon = t.type({
    kind: t.string,
    quantity: t.number,
    appIDs: t.array(t.string),
});

export const subscriptionAddon = t.intersection([
    t.type({
        kind: t.string,
        period: t.string,
        count: t.number,
    }),
    t.partial({
        appID: t.string,
    }),
]);

export const previewAddonChargeBody = t.type({
    ownerID: t.string,
    addons: t.array(subscriptionAddon),
});

export const getLatestPriceForAddonBody = t.type({
    addonKind: t.string,
    period: t.union([t.literal("annual"), t.literal("monthly")]),
});

export const subscribeToAddonsBody = t.intersection([
    previewAddonChargeBody,
    t.partial({
        attachPaymentMethodAsDefault: t.union([t.string, t.literal(false)]),
    }),
]);

export const cancelAppPlanBody = appIDBody;

export const subscribeToPlanBody = t.intersection([
    t.type({
        ownerID: t.string,
        plan: t.string,
        period: t.string,
        unsubscribeAppPlans: t.boolean,
        isFreeTrial: t.boolean,
    }),
    t.partial({
        attachPaymentMethodAsDefault: t.union([t.string, t.literal(false)]),
    }),
]);

export const getGlideSubscriptionBody = ownerIDBody;
// We allow sending either an owner ID or an app ID.  In the latter case it
// will use the owner ID for that app.
export const getOwnerEminenceBody = t.partial({
    ownerID: t.string,
    appID: t.string,
});

export const getOwnerEminenceResponse = t.readonly(
    t.type({
        ownerID: t.string,
        apps: t.record(t.string, eminenceFlagsCodec),
    })
);

export const getV4StripeCheckoutSessionBody = t.intersection([
    organizationBody,
    t.type({
        successUrl: t.string,
        cancelUrl: t.string,
        // FIXME: This should probably be a union from BillingPlan.
        plan: t.string,
    }),
]);

export const getV4StripeCheckoutSessionResponse = t.type({
    url: t.string,
});

export const v4PricingTableBody = t.readonly(organizationBody);

export const v4PricingTableResponse = t.readonly(
    t.intersection([
        t.type({
            clientSecret: t.string,
            pricingTableId: t.string,
        }),
        t.partial({
            // Timestamp in milliseconds when the client secret will expire, with margin.
            clientSecretExpiresAt: t.number,
            // Time duration in milliseconds until the client secret expires, with margin.
            timeUntilExpiration: t.number,
        }),
    ])
);

export const getOrganizationFromCheckoutSessionBody = t.readonly(
    t.type({
        checkoutSessionID: t.string,
    })
);

export const getOrganizationFromCheckoutSessionResponse = t.readonly(
    t.type({
        organizationID: t.string,
    })
);

const stripeCustomerPortalFlowTypeCodec = t.union([
    t.literal(StripeCustomerPortalFlowTypes.PaymentMethodUpdate),
    t.literal(StripeCustomerPortalFlowTypes.SubscriptionUpdate),
]);

export const getV4StripeCustomerPortalBody = t.readonly(
    t.intersection([
        organizationBody,
        t.type({
            returnUrl: t.string,
        }),
        t.partial({
            flowType: stripeCustomerPortalFlowTypeCodec,
        }),
    ])
);

export const getV4StripeCustomerPortalResponse = t.readonly(
    t.type({
        url: t.string,
    })
);

export const setPayAsYouGoBody = t.readonly(
    t.intersection([
        organizationBody,
        t.type({
            enable: t.boolean,
        }),
    ])
);

export const v4AppTransferCheckBody = t.intersection([
    organizationBody,
    t.type({
        originOrgIsAgency: t.boolean,
    }),
]);
export const v4AppTransferCheckResponse = t.readonly(
    t.type({
        allowed: t.boolean,
    })
);

export const getAllSubscriptionsForUserBody = t.type({});

export const getAppEminenceBody = appIDBody;

export const sendCustomHubspotEventBody = t.type({
    eventName: t.string,
    properties: t.record(t.string, t.union([t.string, t.number, t.boolean])),
});

export const loginLogType = t.type({
    appID: t.string,
    appPlanCode: t.string,
    authenticationMethod: t.union([t.literal("private"), t.literal("public")]),
    timestamp: t.number,
});

export const getLoginLogsBody = organizationBody;

export const getOrgLoginLogsBody = organizationBody;

export const appModificationType = t.type({
    endpoint: t.string,
    endpoint_result: t.string,
    timestamp: t.number,
});
// records: t.array(appModificationType),
export const getAppModificationsBody = organizationBody;

export const getOrgEditorBody = t.type({
    // FIXME: Remove this
    orgMemberCount: t.number,
});

export const orgModificationUsage = t.type({
    adds: t.number,
    edit: t.number,
    delete: t.number,
    syncs: t.number,
});

export const getAppModificationsResponse = t.type({
    appUsages: t.array(orgModificationUsage),
    limit: t.number,
});

export const getQuotaStateForOrgBody = organizationBody;
export const getOrganizationUsageBody = organizationBody;

const orgQuotas = t.union([
    t.literal("Edit"),
    t.literal("Delete"),
    t.literal("Add"),
    t.literal("Sync"),
    t.literal("Editors"),
    t.literal("PublishedApps"),
    t.literal("PublicUsers"),
    t.literal("PrivateUsers"),
    t.literal("FileStorage"),
]);

const quotaWithCap = t.type({
    current: t.number,
    softCap: t.number,
    hardCap: t.number,
});

export const dynamicAppModificationAggregate = t.type({
    category: t.union([t.literal("plugin"), t.literal("query")]),
    label: t.string,
    action: t.string,
    count: t.number,
});

export const getQuotasStateForOrgResponse = t.intersection([
    t.record(orgQuotas, quotaWithCap),
    t.type({ rowUsage: t.record(t.string, quotaWithCap) }),
    t.type({ dynamicQuotas: t.record(t.string, dynamicAppModificationAggregate) }),
]);

const icon = t.union([
    t.type({
        kind: t.literal("url"),
        url: t.string,
    }),
    t.type({
        kind: t.literal("named"),
        name: t.string,
    }),
    t.type({
        kind: t.literal("emoji"),
        emoji: t.string,
        color: t.string,
    }),
    glideIconPropsCodec,
]);

enum UsageGroup {
    app,
    feature,
}

export const getOrganizationUsageResponse = t.type({
    maxUpdates: t.number,
    features: t.array(
        t.type({
            name: t.string,
            current: t.number,
            description: t.union([t.string, t.undefined]),
            maximum: t.number,
            icon: icon,
        })
    ),
    updates: t.array(
        t.type({
            groupBy: t.keyof(UsageGroup),
            usages: t.array(
                t.type({
                    group: t.string,
                    name: t.string,
                    subtitle: t.string,
                    current: t.number,
                    description: t.union([t.string, t.undefined]),
                    icon: icon,
                })
            ),
        })
    ),
});

export type GetOrgFoldersBody = t.TypeOf<typeof organizationBody>;

export const addOrgFolderBody = t.intersection([
    organizationBody,
    t.type({
        folderName: t.string,
    }),
]);
export type AddOrgFolderBody = t.TypeOf<typeof addOrgFolderBody>;

export const addOrgFolderResponse = t.type({
    folderID: t.string,
    position: t.string,
});

export const renameOrgFolderBody = t.intersection([
    addOrgFolderBody,
    t.type({
        folderID: t.string,
    }),
]);
export type RenameOrgFolderBody = t.TypeOf<typeof renameOrgFolderBody>;

export const deleteOrgFolderBody = t.intersection([
    organizationBody,
    t.type({
        folderID: t.string,
    }),
]);
export type DeleteOrgFolderBody = t.TypeOf<typeof deleteOrgFolderBody>;

export const moveOrgFolderBody = t.intersection([
    organizationBody,
    t.type({
        folderID: t.string,
        position: t.string,
    }),
]);
export type MoveOrgFolderBody = t.TypeOf<typeof moveOrgFolderBody>;

export const moveAppIntoFolderBody = t.intersection([
    organizationBody,
    t.type({
        appID: t.string,
    }),
    t.partial({
        folderID: t.string, // undefined means the app is contained in the root folder
    }),
]);
export type MoveAppIntoFolderBody = t.TypeOf<typeof moveAppIntoFolderBody>;

export const updateOrgMemberNotificationsBody = t.intersection([
    organizationBody,
    t.type({
        userID: t.string,
        scope: t.union([t.literal("usage"), t.literal("errors"), t.literal("access_requests")]),
        enabled: t.boolean,
    }),
]);
export type UpdateOrgMemberNotificationsBody = t.TypeOf<typeof updateOrgMemberNotificationsBody>;

export const getAppsForOrgBody = organizationBody;
export const getQuotasForAppsBody = organizationBody;

export const loadBuilderActionsBody = appIDBody;

export const updateStoreEntryBody = t.type({
    templateID: t.string,
    data: t.partial({
        title: t.string,
        description: t.string,
        iconImage: iconImageCodec,
        categories: t.array(t.string),
        difficulty: t.string,
        features: t.array(t.string),
        isSample: t.boolean,
        screenshots: t.array(screenshotInfoCodec),
        subtitle: t.string,
        theme: baseThemeCodec,
        sampleAuthor: t.string,
        sampleAuthorEmail: t.string,
    }),
});
export type UpdateStoreEntryBody = t.TypeOf<typeof updateStoreEntryBody>;

export const captureScreenshotBody = t.intersection([
    appIDBody,
    t.type({
        appKind: appKindCodec,
        tabIndex: t.number,
        isListItem: t.boolean,
        formFactor: t.union([t.literal(DeviceFormFactor.Phone), t.literal(DeviceFormFactor.Desktop)]),
    }),
    t.partial({
        delay: t.number,
    }),
]);
export type CaptureScreenshotBody = t.TypeOf<typeof captureScreenshotBody>;

export const publishTemplateForScreenshotBody = appIDBody;
export type PublishTemplateForScreenshotBody = t.TypeOf<typeof publishTemplateForScreenshotBody>;

export const privateTemplateAccessBody = t.intersection([
    appIDBody,
    t.type({
        privateTemplateToken: t.string,
    }),
]);
export type PrivateTemplateAccessBodyBody = t.TypeOf<typeof privateTemplateAccessBody>;

export const sendAppMagicLinksBody = t.intersection([
    appIDBody,
    t.type({
        emails: t.readonlyArray(t.string),
        sendEmails: t.boolean,
    }),
]);

export const getAppMagicLinksBody = appIDBody;

const privateMagicLinkAction = t.union([t.literal("get"), t.literal("create"), t.literal("delete")]);

export type PrivateMagicLinkAction = t.TypeOf<typeof privateMagicLinkAction>;

export const privateMagicLinkBody = t.intersection([
    appIDBody,
    t.type({
        action: privateMagicLinkAction,
    }),
]);

export type PrivateMagicLinkBody = t.TypeOf<typeof privateMagicLinkBody>;

export const getTemplatePreviewUrlBody = appIDBody;

export const getTemplatePreviewUrlResponse = t.type({
    url: t.string,
});

const userProfileRowResponseCodec = t.intersection([
    t.type({
        userProfileRow: t.UnknownRecord,
    }),
    t.partial({
        emailColumnName: t.string,
    }),
]);
export type UserProfileRowResponse = t.TypeOf<typeof userProfileRowResponseCodec>;

export const delistTemplateBody = appIDBody;
export type DelistTemplateBody = t.TypeOf<typeof delistTemplateBody>;

export const storeAppDescriptionBody = t.intersection([
    appIDBody,
    t.type({
        appDescription: t.string,
        serial: t.number,
    }),
]);
export type StoreAppDescriptionBody = t.TypeOf<typeof storeAppDescriptionBody>;

export const getAppDescriptionBody = t.intersection([
    appIDBody,
    t.type({
        appDescriptionKey: t.string,
    }),
]);
export type GetAppDescriptionBody = t.TypeOf<typeof getAppDescriptionBody>;

export const getAppMagicLinksResponse = t.type({
    links: t.array(
        t.type({
            email: t.string,
            createdAt: iotsdate,
            token: t.string,
            pendingApproval: t.union([t.literal(true), t.undefined]),
            approvalID: t.string,
        })
    ),
});
export type GetAppMagicLinksResponse = t.TypeOf<typeof getAppMagicLinksResponse>;

const nativeTableQueryID = t.type({
    kind: t.literal("native-table"),
    value: nativeTableIDCodec,
});

export type NativeTableQueryID = t.TypeOf<typeof nativeTableQueryID>;

const querySavedIdentityCodec = t.union([
    t.type({
        kind: t.literal("new"),
        sourceKind: t.string,
        sourceID: t.string,
    }),
    t.type({
        kind: t.literal("existing"),
        id: nativeTableQueryID,
        lastSerial: t.number,
    }),
]);

const sqlTableNameCodec = t.readonlyArray(t.string);
export type SQLTableName = t.TypeOf<typeof sqlTableNameCodec>;

const sqlTableQueryBaseCodec = t.type({
    kind: t.literal("table"),
    name: sqlTableNameCodec,
});

const sqlQueryQueryBaseCodec = t.type({
    kind: t.literal("query"),
    query: t.string,
});

export const sqlQueryBaseCodec = t.union([sqlTableQueryBaseCodec, sqlQueryQueryBaseCodec]);
export type SQLQueryBase = t.TypeOf<typeof sqlQueryBaseCodec>;

export const previewQueryRequestBodyCodec = t.intersection([
    t.type({
        savedIdentity: querySavedIdentityCodec,
        queryID: t.string,
        queryName: t.string,
        queryBase: sqlQueryBaseCodec,
    }),
    t.partial({
        onBehalfOf: t.string,
        limit: t.number,
    }),
]);

export const queryContinuationCodec = t.intersection([
    t.type({
        contents: t.union([aeadDataCodec, t.string]),
    }),
    t.partial({
        expirationTimestampMS: t.number,
    }),
]);
export type QueryContinuation = t.TypeOf<typeof queryContinuationCodec>;

export type QueryIDsWithTableVersions = Record<string, QueryTableVersions>;

const queryResponseSuccessEntryCodec = t.intersection([
    t.type({
        kind: t.literal("success"),
        queryID: t.string,
        table: tableGlideTypeCodec,
        rows: t.readonlyArray(t.unknown),
    }),
    t.partial({
        continuation: queryContinuationCodec,
        numRowsInTable: t.number,
        version: t.number,
        // This must be treated as opaque by the client.
        tableVersions: queryTableVersionsCodec,
    }),
]);
export type QueryResponseSuccessEntry = t.TypeOf<typeof queryResponseSuccessEntryCodec>;

const queryResponseEntryCodec = t.union([
    queryResponseSuccessEntryCodec,
    t.type({
        kind: t.literal("error"),
        queryID: t.string,
        message: t.string,
    }),
]);

export const previewQueryResponseBodyCodec = t.type({
    response: queryResponseEntryCodec,
});

export const continuePreviewQueryRequestBodyCodec = t.intersection([
    t.type({
        savedIdentity: querySavedIdentityCodec,
        requests: t.array(t.type({ queryID: t.string, continuation: queryContinuationCodec })),
    }),
    t.partial({
        onBehalfOf: t.string,
    }),
]);

export const executeQueryResponseBodyCodec = t.type({
    responses: t.array(queryResponseEntryCodec),
});

export const saveQueryRequestBodyCodec = t.type({
    onBehalfOf: t.string,
    queryName: t.string,
    queryBase: sqlQueryBaseCodec,
    savedIdentity: querySavedIdentityCodec,
    appChanges: t.partial({
        add: t.array(t.string),
        remove: t.array(t.string),
    }),
    persistTable: tableGlideTypeCodec,
});

const saveQuerySuccessResponseBodyCodec = t.type({
    savedIdentity: t.type({
        nativeTableID: nativeTableIDCodec,
        currentSerial: t.number,
    }),
    queryBase: sqlQueryBaseCodec,
    requestWasSaved: t.boolean,
});
export type SaveQuerySuccessResponseBody = t.TypeOf<typeof saveQuerySuccessResponseBodyCodec>;

export const saveQueryResponseBodyCodec = t.intersection([
    t.type({
        queryName: t.string,
    }),
    t.union([
        saveQuerySuccessResponseBodyCodec,
        t.type({
            errors: t.array(
                t.intersection([
                    t.type({
                        message: t.string,
                    }),
                    t.partial({
                        column: t.number,
                        row: t.number,
                        run: t.number,
                    }),
                ])
            ),
        }),
    ]),
]);

export const loadQueryRequestBodyCodec = t.intersection([
    appIDBody,
    t.type({
        queryIDs: t.readonlyArray(nativeTableIDCodec),
    }),
    t.partial({
        onBehalfOf: t.string,
    }),
]);

// FIXME: Why is this not the same as `querySavedIdentityCodec`?
const loadQuerySavedIdentityCodec = t.type({
    queryID: nativeTableIDCodec,
    currentSerial: t.number,
});
export type LoadQuerySavedIdentity = t.TypeOf<typeof loadQuerySavedIdentityCodec>;

export const loadQueryResponseBodyCodec = t.type({
    queries: t.array(
        t.type({
            queryName: t.string,
            queryBase: sqlQueryBaseCodec,
            savedIdentity: loadQuerySavedIdentityCodec,
            sourceKind: t.string,
        })
    ),
});

export const customComponentProxyBody = t.intersection([
    t.type({
        appID: t.string,
        tableName: tableNameCodec,
        prompt: t.string,
        fields: t.array(
            t.type({
                name: t.string,
                type: t.union([t.literal("string"), t.literal("number"), t.literal("boolean")]),
            })
        ),
        actions: t.array(
            t.type({
                title: t.string,
            })
        ),
    }),
    t.partial({
        previousID: t.string,
        doNotSplitPrompt: t.boolean,
    }),
]);
export const customComponentProxyResponse = decodeGeneratedComponent;

export const customComponentRequestsProxyBody = t.type({
    path: t.string,
    headers: t.record(t.string, t.string),
});
export type CustomComponentRequestsProxyBody = t.TypeOf<typeof customComponentRequestsProxyBody>;

const builderQueryInfo = t.type({
    previewAsEmail: t.string,
    previewAsRoles: t.readonlyArray(t.string),
});
export type BuilderQueryInfo = t.TypeOf<typeof builderQueryInfo>;

/**
 * ##minRequiredVersion:
 * A number means that the frontend is aware that this version exists and
 * wants the backend to answer with data from at least that version.  If the
 * read replica's version is not at least this number (because of replica
 * lag), it will query the primary.
 *
 * `"latest"` means the backend should go to the primary no matter what.  We
 * use that in the Automations service in the case where a run switches
 * between workers.  The new worker doesn't know what version the previous
 * worker was at, so we play it safe.
 */
const minRequiredVersionCodec = t.union([t.number, t.literal("latest")]);
export type MinRequiredVersion = t.TypeOf<typeof minRequiredVersionCodec>;

const executeQueryRequest = t.intersection([
    t.type({
        queryID: t.string,
    }),
    t.partial({
        appUserID: t.string,
        minVersion: minRequiredVersionCodec,
    }),
    serializedQueryCodec,
]);
const executeQueryRequests = t.readonlyArray(executeQueryRequest);

const appDataQueryID = t.type({
    kind: t.literal("app-data"),
    value: tableNameCodec,
});

export const executeQueryRequestBodyCodec = t.intersection([
    appIDBody,
    t.type({
        savedID: t.union([nativeTableQueryID, appDataQueryID]),
        requests: executeQueryRequests,
    }),
    t.partial({
        // either `true` for the data editor, or BuilderQueryInfo for the "player" data store in the builder
        builderInfo: t.union([t.literal(true), builderQueryInfo]),
        // This is only for tracing
        deviceID: t.string,
    }),
]);

const continueQueryRequest = t.intersection([
    t.type({ queryID: t.string, continuation: queryContinuationCodec }),
    t.partial({ minVersion: minRequiredVersionCodec, limit: t.number }),
]);

const continueQueryRequests = t.readonlyArray(continueQueryRequest);

export const continueQueryRequestBodyCodec = t.intersection([
    appIDBody,
    t.type({
        savedID: nativeTableQueryID, // uuid
        requests: continueQueryRequests,
    }),
    t.partial({
        // either `true` for the data editor, or BuilderQueryInfo for the "player" data store in the builder
        builderInfo: t.union([t.literal(true), builderQueryInfo]),
        // This is only for tracing
        deviceID: t.string,
    }),
]);

export const checkQueryTableVersionsRequestBodyCodec = t.intersection([
    appIDBody,
    t.type({
        // query ID -> table versions
        queryTableVersions: t.record(t.string, queryTableVersionsCodec),
    }),
]);

const checkQueryTableVersionsResponseBodyCodec = t.type({
    // query ID -> did the table version change?
    queryResults: t.record(t.string, t.type({ didChange: t.boolean })),
});

export const listBigQueryProjectIDsRequestBodyCodec = t.intersection([
    t.type({ integrationName: t.string }),
    t.partial({ onBehalfOf: t.string }),
]);

export const listBigQueryProjectIDsResponseBodyCodec = t.type({
    projectIDs: t.array(t.string),
});

export const listBigQueryDatasetIDsRequestBodyCodec = t.intersection([
    listBigQueryProjectIDsRequestBodyCodec,
    t.type({ projectID: t.string }),
]);

export const listBigQueryDatasetIDsResponseBodyCodec = t.type({
    datasets: t.array(
        t.intersection([
            t.type({
                id: t.string,
            }),
            t.partial({
                location: t.string,
            }),
        ])
    ),
});

const setupBigQueryDataSourceRequestBodyCodec = t.intersection([
    t.type({
        target: t.literal("bigquery"),
        ownerID: t.string,
        integrationName: t.string,
        projectID: t.string,
    }),
    t.partial({
        datasetID: t.string,
    }),
]);

export const setupQueryablePluginDataSourceRequestBodyCodec = t.intersection([
    t.type({
        target: t.literal("queryable-plugin"),
        // FIXME: we only need appID here because plugins are associated to apps, not teams,
        //  however, data sources are associated to teams, so it's a little messy. Remove this
        //  if we can ever associate plugins to teams.
        appID: t.string,
        ownerID: t.string,
        configID: t.string,
        queryableDataSourceID: t.string,
    }),
    t.partial({
        tableName: sqlTableNameCodec,
    }),
]);
export type SetupQueryablePluginDataSourceRequestBody = TypeOfIOTS<
    typeof setupQueryablePluginDataSourceRequestBodyCodec
>;

export const setupQueryableDataSourceRequestBodyCodec = t.union([
    setupBigQueryDataSourceRequestBodyCodec,
    setupQueryablePluginDataSourceRequestBodyCodec,
]);
export type SetupQueryableDataSourceRequestBody = TypeOfIOTS<typeof setupQueryableDataSourceRequestBodyCodec>;

export const setupQueryableDataSourceResponseBodyCodec = t.union([
    t.type({ kind: t.literal("response"), sourceID: t.string }),
    t.type({ kind: t.literal("error"), message: t.string }),
]);

const sqlTableColumnCodec = t.intersection([
    t.type({
        sqlDataType: t.string,
        glideDataType: t.union([
            t.literal("number"),
            t.literal("string"),
            t.literal("boolean"),
            t.literal("date-time"),
            t.literal("date"),
            t.literal("time"),
            t.literal("json"),
            t.literal("unknown"),
        ]),
        nullable: t.boolean,
        readOnly: t.boolean,
    }),
    t.partial({
        primaryKey: t.boolean,
        hasDefaultValue: t.boolean,
        isIdentity: t.boolean,
        isArray: t.boolean,
        hasTimeZone: t.boolean,
    }),
]);
export type SQLTableColumn = t.TypeOf<typeof sqlTableColumnCodec>;

export const sqlTableSchemaCodec = t.record(t.string, sqlTableColumnCodec);
export type SQLTableSchema = t.TypeOf<typeof sqlTableSchemaCodec>;

export const sqlDatabaseSchemaCodec = t.record(t.string, sqlTableSchemaCodec);
export type SQLDatabaseSchema = t.TypeOf<typeof sqlDatabaseSchemaCodec>;

export const listSQLDataSourceTablesRequestBodyCodec = t.type({
    appID: t.string,
    ownerID: t.string,
    configID: t.string,
    queryableDataSourceID: t.string,
});
export type ListSQLDataSourceTablesRequestBody = t.TypeOf<typeof listSQLDataSourceTablesRequestBodyCodec>;

// For some reason we still get action documents that have
// string table names.
export const tableNameOrStringCodec = t.union([tableNameCodec, t.string]);

const actionMetadataBaseCodec = t.partial({
    appUserID: t.string,
    // For debugging
    provenance: t.string,
    // For audit logging
    screenPath: t.string,
    automationRunID: t.string,
});

export const writeSourceType = t.union([
    t.literal("data-editor"),
    t.literal("builder"),
    t.literal("player"),
    t.literal("automation"),
]);
export type WriteSourceType = t.TypeOf<typeof writeSourceType>;

export const actionDocumentCodec = t.intersection([
    actionMetadataBaseCodec,
    t.partial({
        authID: t.string,
        deviceID: t.string,
        fromBuilder: t.boolean,
        fromDataEditor: t.boolean,
        writeSource: writeSourceType,
    }),
]);
export type ActionDocument = t.TypeOf<typeof actionDocumentCodec>;

export const addRowToTableDocumentCodec = t.intersection([
    actionDocumentCodec,
    t.type({
        tableName: tableNameOrStringCodec,
        columnValues: columnValuesCodec,
    }),
]);
export type AddRowToTableDocument = t.TypeOf<typeof addRowToTableDocumentCodec>;

export const setColumnsInRowDocumentCodec = t.intersection([
    actionDocumentCodec,
    t.type({
        tableName: tableNameOrStringCodec,
        rowIndex: rowIndexCodec,
        columnValues: columnValuesCodec,
    }),
]);
export type SetColumnsInRowDocument = t.TypeOf<typeof setColumnsInRowDocumentCodec>;

export const deleteRowDocumentCodec = t.intersection([
    actionDocumentCodec,
    t.type({
        tableName: tableNameOrStringCodec,
        rowIndex: t.readonlyArray(rowIndexCodec),
    }),
]);
export type DeleteRowDocument = t.TypeOf<typeof deleteRowDocumentCodec>;

export const enqueueDeleteRowsRequestBody = t.intersection([
    appIDBody,
    t.type({
        tableName: tableNameCodec,
        rowIndexes: t.readonlyArray(rowIndexCodec),
        deviceID: t.string,
        fromBuilder: t.boolean,
        fromDataEditor: t.boolean,
        // NOTE: We're not adding `screenPath` here because we want to stop
        // using the special path for deleting rows, vs just posting regular
        // actions.
    }),
    t.partial({
        appUserID: t.string,
        jobID: t.string,
        writeSource: writeSourceType,
    }),
]);

export const deleteColumnDocumentCodec = t.intersection([
    actionDocumentCodec,
    t.type({
        tableName: tableNameCodec,
        columnName: t.string,
    }),
]);
export type DeleteColumnDocument = t.TypeOf<typeof deleteColumnDocumentCodec>;

const actionMetadataCodec = t.intersection([
    actionMetadataBaseCodec,
    t.type({
        jobID: t.string,
        deviceID: t.string,
        appID: t.string,
    }),
    t.partial({
        dependsOn: t.string,
    }),
]);
export type ActionMetadata = t.TypeOf<typeof actionMetadataCodec>;

const enqueueActionRequestKindAndPayloadCodec = t.union([
    t.intersection([
        t.type({ kind: t.literal(ActionKind.AddRowToTable) }),
        t.type({ payload: addRowToTableDocumentCodec }),
    ]),
    t.intersection([
        t.type({ kind: t.literal(ActionKind.SetColumnsInRow) }),
        t.type({ payload: setColumnsInRowDocumentCodec }),
    ]),
    t.intersection([t.type({ kind: t.literal(ActionKind.DeleteRow) }), t.type({ payload: deleteRowDocumentCodec })]),
]);
export type EnqueueActionRequestKindAndPayload = t.TypeOf<typeof enqueueActionRequestKindAndPayloadCodec>;

const enqueueSingleActionRequestCodec = t.intersection([
    enqueueActionRequestKindAndPayloadCodec,
    t.type({ actionMetadata: actionMetadataCodec }),
]);

export const enqueueActionRequestBodyCodec = t.intersection([
    // This is redundant, because the `actionMetadata` also includes it, but
    // we have a bunch of infrastructure that does stuff if the app ID is in
    // the body, such as adding it to Honeycomb, so we leave it here and
    // demand that it matches the metadata.
    appIDBody,
    enqueueSingleActionRequestCodec,
]);

export const enqueueActionBatchRequestBodyCodec = t.intersection([
    // This is redundant, because the `actionMetadata` also includes it, but
    // we have a bunch of infrastructure that does stuff if the app ID is in
    // the body, such as adding it to Honeycomb, so we leave it here and
    // demand that it matches the metadata.
    appIDBody,
    t.type({
        actions: t.readonlyArray(enqueueSingleActionRequestCodec),
    }),
]);

const enqueueActionResponseSuccessCodec = t.intersection([
    t.type({
        jobID: t.string,
        kind: actionKindCodec,
    }),
    t.partial({
        confirmedAtVersion: t.number,
        newRow: columnValuesCodec,
    }),
]);

export const enqueueActionResponseBodyCodec = t.intersection([
    // FIXME: Why do we include the app ID in the response???
    appIDBody,
    enqueueActionResponseSuccessCodec,
]);

const enqueueActionResponseFailureCodec = t.type({
    jobID: t.string,
    message: t.string,
    isPermanentFailure: t.boolean,
});

export const enqueueActionBatchResponseBodyCodec = t.type({
    successes: t.readonlyArray(enqueueActionResponseSuccessCodec),
    failures: t.readonlyArray(enqueueActionResponseFailureCodec),
});

const webhookValue = t.union([
    t.type({
        type: t.literal("number"),
        value: t.number,
    }),
    t.type({
        type: t.literal("string"),
        value: t.string,
    }),
    t.type({
        type: t.literal("boolean"),
        value: t.boolean,
    }),
    t.type({
        type: t.literal("date-time"),
        value: t.string,
    }),
]);
const webhookValues = t.record(t.string, webhookValue);
export type WebhookValue = t.TypeOf<typeof webhookValue>;
export type WebhookValues = t.TypeOf<typeof webhookValues>;

const webhookBody = t.intersection([
    appIDBody,
    t.type({
        webhookID: t.string,
        params: webhookValues,
    }),
    t.partial({
        deviceID: t.string,
        idempotencyKey: t.string,
    }),
]);

export const webhookResponseBody = t.partial({
    results: webhookValues,
});

const webhookWriteBackToColumn = t.type({
    tableName: tableNameCodec,
    rowIndex: rowIndexCodec,
    columnName: t.string,
});
export type WebhookWriteBackToColumn = t.TypeOf<typeof webhookWriteBackToColumn>;

const webhookWriteBackTo = t.intersection([
    t.type({
        results: t.record(t.string, webhookWriteBackToColumn),
        // The backend must verify that the requesting user is actually a
        // Glide user, and not just an app user, if this flag is set.
        fromBuilder: t.boolean,
        fromDataEditor: t.boolean,
    }),
    t.partial({
        // This will only be honored if the request comes from the builder.
        appUserID: t.string,
        // TODO: this should be moved to being non-optional after merge and everything is fully migrate
        writeSource: writeSourceType,
    }),
]);
export type WebhookWriteBackTo = t.TypeOf<typeof webhookWriteBackTo>;

export const triggerAppWebhookActionBody = t.intersection([
    webhookBody,
    t.partial({
        writeBackTo: webhookWriteBackTo,
    }),
]);

export const callAPIColumnBody = webhookBody;

export const apiColumnResponseBody = webhookValue;

export const addWebhookBody = t.intersection([
    appIDBody,
    t.type({
        name: t.string,
        url: t.string,
    }),
]);

export const getLongHTTPFunctionStatusRequestBody = t.type({
    jobID: t.string,
});

export const fetchAsyncEnumRequestBody = t.intersection([
    appIDBody,
    t.type({
        pluginParams: pluginConfigParametersCodec,
        pluginConfigID: t.union([t.string, t.undefined]),
        pluginID: t.string,
        key: t.string,
    }),
]);

export const runIntegrationsInstance = t.intersection([
    t.type({
        instanceID: t.string,
        actionParams: columnValuesCodec,
        writeBackTo: webhookWriteBackTo,
        isAction: t.boolean,
    }),
    t.partial({
        formattedActionParams: t.record(t.string, t.string),
    }),
]);

const continueWithSignalResponseCodec = t.type({
    // This specifies the "namespace" of the signal, which means the plugin ID
    // and the ID of the signal within the plugin.
    signalScope: t.string,
    // This is a UUID that defines this specific instance of that signal to
    // wait for.
    signalID: t.string,
    // How long to wait for the signal to be sent, in milliseconds.
    timeoutMS: t.number,
});
export type ContinueWithSignalResponse = t.TypeOf<typeof continueWithSignalResponseCodec>;

export const runIntegrationsBody = t.intersection([
    appIDBody,
    t.partial({
        // We will use these if passed from the builder, but we'll ignore them
        // from the player because we don't trust it.  The reason we pass it
        // from the builder is so we don't have to worry whether it already
        // has the app description saved when it calls.
        pluginParams: pluginConfigParametersCodec,
        formattedPluginParams: t.record(t.string, t.string),
        // We use this to look up the OAuth credentials.
        pluginConfigID: t.string,
    }),
    t.type({
        actionKind: t.string,
        deviceID: t.string,
        instances: t.readonlyArray(runIntegrationsInstance),
    }),
]);

export const runIntegrationsInstanceResponse = t.intersection([
    t.type({
        instanceID: t.string,
        statusCode: t.number,
    }),
    t.partial({
        cacheHit: t.boolean,
        isPermanent: t.boolean,
        data: t.record(t.string, t.unknown),
        continueWithSignal: continueWithSignalResponseCodec,
    }),
]);

export const validatePluginRequestBody = t.intersection([
    appIDBody,
    t.type({
        pluginParams: pluginConfigParametersCodec,
        pluginID: t.string,
        pluginConfigID: t.string,
    }),
]);

export interface ValidatePluginMessage {
    readonly message: string;
    readonly parameter: string | undefined;
}

export interface ValidatePluginResult {
    readonly ok: boolean;
    readonly errors: readonly ValidatePluginMessage[];
    readonly warnings: readonly ValidatePluginMessage[];
    readonly externalConfigurationStep: ExternalConfigurationStep | undefined;
}

type ReplaceDateWithString<T> = T extends Date ? string : T;
type ReplaceDatesInObjectWithStrings<T> = { [K in keyof T]: ReplaceDateWithString<T[K]> };

// This is the same as `ValidatePluginResult`, but with `Date`s replaced with
// `string`s.
export interface ValidatePluginResponseBody {
    readonly ok: boolean;
    readonly errors: readonly ValidatePluginMessage[];
    readonly warnings: readonly ValidatePluginMessage[];
    readonly externalConfigurationStep: ReplaceDatesInObjectWithStrings<ExternalConfigurationStep> | undefined;
}

export const completePluginRequestBody = validatePluginRequestBody;

export interface CompletePluginResponseBody {
    readonly updates: Record<string, PropertyDescription>;
}

export const triggerRemovePluginRequestBody = validatePluginRequestBody;

export interface TriggerRemovePluginResponseBody {
    readonly ok: boolean;
    readonly errors: readonly ValidatePluginMessage[];
}

export const validateAppPluginsRequestBody = appIDBody;

export const pluginValidationByAppResponse = t.array(
    t.string // pluginID that have errors
);

export const getPluginTriggerNotesRequestBody = t.intersection([
    appIDBody,
    t.type({
        triggerID: t.string,
        pluginParams: pluginConfigParametersCodec,
    }),
]);
export type GetPluginTriggerNotesRequestBody = t.TypeOf<typeof getPluginTriggerNotesRequestBody>;

export interface GetPluginTriggerNotesResponseBody {
    // This can be empty.
    readonly notes: readonly Note[];
}

export const getAutomationUsageRequestBody = t.intersection([
    organizationBody,
    appIDBody,
    t.type({
        periodStart: t.number,
        periodEnd: t.number,
    }),
]);
export type GetAutomationUsageRequestBody = t.TypeOf<typeof getAutomationUsageRequestBody>;

export const writeBackJobIdentityCodec = t.intersection([
    t.type({
        jobID: t.string,
        tableName: tableNameCodec,
        rowIndex: rowIndexCodec,
    }),
    t.partial({
        confirmedAtVersion: t.number,
    }),
]);

const cellValues = t.record(t.string, cellValueCodec);

export const writebackResponseResultCodec = t.intersection([
    t.type({
        results: resultPluginValues,
        actions: t.array(t.intersection([writeBackJobIdentityCodec, t.type({ columnValues: cellValues })])),
    }),
    t.partial({
        error: t.string,
    }),
]);
export type WritebackResponseResult = TypeOfIOTS<typeof writebackResponseResultCodec>;

// some actions (e.g. gmail) do not send an undefined result,
// which is then defaulted to an empty object in the response
// the EmptyType union allows an empty object to pass validation
const EmptyType = t.type({});
export const writebackResponseCodec = t.union([writebackResponseResultCodec, EmptyType]);
export type WritebackResponse = t.TypeOf<typeof writebackResponseCodec>;

export const getCompiledCustomCssBody = t.intersection([
    appIDBody,
    t.type({
        rawCss: t.string,
    }),
]);

export const addMemberIntegrationToOrgBody = t.intersection([appIDBody, sharedIntegration]);

export const setPluginSecretBodyCodec = t.intersection([
    appIDBody,
    t.type({
        secretID: t.string,
        secretValue: t.string,
    }),
]);

export const duplicatePluginSecretBodyCodec = t.intersection([
    appIDBody,
    t.type({
        originalSecretID: t.string,
        newSecretID: t.string,
    }),
]);

export const getPluginSecretBodyCodec = t.intersection([
    appIDBody,
    t.type({
        secretID: t.string,
        secretKind: t.union([t.literal("string"), t.literal("public-key")]),
    }),
]);

export const internalEmailAppendAuthorizationCodec = t.type({
    address: t.string,
    expiresAt: t.number,
});

export const getSubscriptionVerificationSecretResponse = t.union([
    t.undefined,
    t.type({
        clientSecret: t.string,
        paymentMethodID: t.string,
    }),
]);

export const sendFrontendPushNotificationsBody = t.intersection([
    appIDBody,
    t.type({
        title: t.string,
        body: t.string,
    }),
    t.partial({
        link: t.string,
        emails: t.array(t.string),
    }),
]);

export const getAvailableOwnerOAuthRequestBody = t.intersection([
    ownerIDBody,
    t.partial({ providerKinds: t.array(t.string) }),
]);

const availableOwnerOAuthResponse = t.intersection([
    t.type({
        providerKind: t.string,
        providerID: t.string,
        credentialID: t.string,
        glideAuthID: t.string,
    }),
    t.partial({
        displayName: t.string,
    }),
]);

export const getAvailableOwnerOAuthResponseBody = t.array(availableOwnerOAuthResponse);

export const updateOrDeleteOwnerOAuthRequestBody = t.intersection([ownerIDBody, t.type({ authID: t.string })]);

export const updateOwnerOAuthDisplayNameRequestBody = t.intersection([
    updateOrDeleteOwnerOAuthRequestBody,
    t.partial({ displayName: t.string }),
]);

export const getOrgMostRecentLoginLogsRequestBody = t.intersection([
    organizationBody,
    t.type({
        startDateTimestamp: t.number, // JS date
        endDateTimestamp: t.number, // JS date
    }),
]);

export const getOrgUsagesBody = t.intersection([
    organizationBody,
    t.partial({
        range: t.type({
            inclusiveStartTimestamp: t.number, // JS Date
            exclusiveEndTimestamp: t.number, // JS Date
        }),
        usagePeriodID: usagePeriodIDCodec,
    }),
]);

export const getFeaturesForOrgBody = t.intersection([
    organizationBody,
    // FIXME: we're making these fields optional to avoid breaking existing clients, but we should remove them in the
    // near future because they aren't required anymore.
    t.partial({
        pluginIDs: t.readonlyArray(t.string),
        booleanEminenceFlags: t.readonlyArray(t.string),
    }),
]);

// true means that the user can use it.
// false means that it's unavailable for every plan.
// The object with requiredTier and requiredPlanLabel determines what is the "shortest" upgrade path to it.
const featureStatusForOrg = t.union([t.boolean, t.type({ requiredTier: tierCodec, requiredPlanLabel: t.string })]);

export const getFeaturesForOrgResponse = t.type({
    features: t.record(t.string, featureStatusForOrg),
    newNewAppFlow: t.boolean,
    isV4SystemUser: t.boolean,
});
export const runCLIRoutinesRequestBodyCodec = t.intersection([
    t.type({
        routineName: t.string,
        supportEmail: t.string,
        supportPassword: t.string,
    }),
    t.partial({
        appID: t.string,
        domain: t.string,
        encodedTuple: t.string,
        email: t.string,
        mode: t.string,
        numDaysToRollBack: t.string,
        ownerID: t.string,
        orgID: t.string,
        pgMirrorTableID: t.string,
        tupleInput: t.string,
        userID: t.string,
        rawJson: t.boolean,
        overlay: t.string,
        user: t.string,
        feature: t.string,
        enabled: t.string,
        priceID: t.string,
        shortName: t.string,
        entitlements: t.string,
        reason: t.string,
        value: t.string,
    }),
]);

export const createNativeTablesFromGoogleSheetsBody = t.intersection([
    appIDBody,
    t.type({
        sheetsFileID: t.string,
    }),
]);

export const getDataSourceWarningsResponse = t.record(
    t.string,
    t.intersection([t.type({ title: t.string, content: t.string }), t.partial({ docsURL: t.string })])
);

export const updateIntercomUserBody = t.type({
    email: t.string,
    customAttributes: t.record(t.string, t.unknown),
});

export type AcceptInviteBody = TypeOfIOTS<typeof acceptInviteBody>;
export type GeneratePublishedAppDataFromSheetBody = TypeOfIOTS<typeof generatePublishedAppDataFromSheetBody>;
export type NewAppSurveyData = TypeOfIOTS<typeof newAppSurveyData>;
export type GeneratePublishedAppDataFromSheetResult =
    | (t.TypeOf<typeof generatePublishedAppDataFromSheetResult> & { schema: TypeSchema })
    | string;
export type GeneratePublishedAppDataFromExcelResult =
    | (t.TypeOf<typeof generatePublishedAppDataFromExcelResult> & { schema: TypeSchema })
    | string;
export type PrepareReplaceGoogleSheetsSourceBody = TypeOfIOTS<typeof prepareReplaceGoogleSheetsSourceBody>;
export type PrepareRemoveGoogleSheetsSourceBody = TypeOfIOTS<typeof prepareRemoveGoogleSheetsSourceBody>;
export type PrepareReplaceGoogleSheetsSourceResponse = TypeOfIOTS<typeof prepareReplaceGoogleSheetsSourceResponse>;
export type PrepareRemoveGoogleSheetsSourceResponse = TypeOfIOTS<typeof prepareRemoveGoogleSheetsSourceResponse>;
export type ReloadPublishedAppDataFromSheetBody = TypeOfIOTS<typeof reloadPublishedAppDataFromSheetBody>;
export type ReloadPublishedAppDataFromSheetResponse = TypeOfIOTS<typeof reloadPublishedAppDataFromSheetResponse>;
export type GenerateAppFromDescriptionBody = TypeOfIOTS<typeof generateAppFromDescriptionBody>;
export type GenerateAppFromDescriptionResponse = TypeOfIOTS<typeof generateAppFromDescriptionResponse>;
export type GetCustomTokenForAppBody = TypeOfIOTS<typeof getCustomTokenForAppBody>;
export type AuthorizeUserForAppBody = TypeOfIOTS<typeof authorizeUserForAppBody>;
export type GetAppUserForAuthenticatedUserBody = TypeOfIOTS<typeof getAppUserForAuthenticatedUserBody>;
export type SendPinForEmailBody = TypeOfIOTS<typeof sendPinForEmailBody>;
export type RequestAppUserAccessBody = TypeOfIOTS<typeof requestAppUserAccessBody>;
export type RequestAppUserAccessApprovalBody = TypeOfIOTS<typeof requestAppUserAccessApprovalBody>;
export type GetPasswordForEmailPinBody = TypeOfIOTS<typeof getPasswordForEmailPinBody>;
export type GetPasswordForOAuth2TokenBody = TypeOfIOTS<typeof getPasswordForOAuth2TokenBody>;
export type GetUnsplashImagesBody = TypeOfIOTS<typeof getUnsplashImagesBody>;
export type UnsplashPingDownloadBody = TypeOfIOTS<typeof unsplashPingDownloadBody>;
export type RegisterForPushNotificationsBody = TypeOfIOTS<typeof registerForPushNotificationsBody>;
export type DeleteAppBody = TypeOfIOTS<typeof deleteAppBody>;
export type GetConfiguredPluginsForAppBody = TypeOfIOTS<typeof getConfiguredPluginsForAppBody>;
export type SetOrUpdateUserBody = TypeOfIOTS<typeof setOrUpdateUserBody>;
export type StoreAppDevicePreferenceBody = TypeOfIOTS<typeof storeAppDevicePreferenceBody>;
export type IsEmailSignedUpBody = TypeOfIOTS<typeof isEmailSignedUpBody>;
export type GetOAuth2TokensForGoogleSheetsBody = TypeOfIOTS<typeof getOAuth2TokensForGoogleSheetsBody>;
export type DuplicateAppBody = TypeOfIOTS<typeof duplicateAppBody>;
export type DuplicateAppResponseBody = TypeOfIOTS<typeof duplicateAppResponseBody>;
export type DuplicateClassicAppAsPageBody = TypeOfIOTS<typeof duplicateClassicAppAsPageBody>;
export type DuplicateClassicAppAsPageResponseBody = TypeOfIOTS<typeof duplicateClassicAppAsPageResponseBody>;
export type AddTemplateTabToAppBody = TypeOfIOTS<typeof addTemplateTabToAppBody>;
export type AddTemplateTabToAppResponseBody = TypeOfIOTS<typeof addTemplateTabToAppResponseBody>;
export type SubmitTemplateBody = TypeOfIOTS<typeof submitTemplateBody>;
export type ShadowPublishTemplateBody = TypeOfIOTS<typeof shadowPublishTemplateBody>;
export type ShadowPublishTemplateResponse = TypeOfIOTS<typeof shadowPublishTemplateResponse>;
export type TemplateIDWithPreviewUrl = TypeOfIOTS<typeof templateIDWithPreviewUrl>;
export type TemplateID = TypeOfIOTS<typeof templateID>;
export type Range = TypeOfIOTS<typeof range>;
export type SelfReportedReferralSource = TypeOfIOTS<typeof selfReportedReferralSource>;
export type OptIntoBetaBody = TypeOfIOTS<typeof optIntoBetaBody>;
export type OrganizationBody = TypeOfIOTS<typeof organizationBody>;
export type OrganizationWithAppBody = TypeOfIOTS<typeof organizationWithAppBody>;
export type CreateOrganizationBody = TypeOfIOTS<typeof createOrganizationBody>;
export type EditOrganizationSettingsBody = TypeOfIOTS<typeof editOrganizationSettingsBody>;
export type SetOrgOnboardingBody = TypeOfIOTS<typeof setOrgOnboardingBody>;
export type CreateOrganizationResult = TypeOfIOTS<typeof createOrganizationResult>;
export type DeleteOrganizationBody = TypeOfIOTS<typeof deleteOrganizationBody>;
export type AcceptPluginAuthCodeBody = TypeOfIOTS<typeof acceptPluginAuthCodeBody>;
export type AcceptPluginAuthCodeForOwnerBody = TypeOfIOTS<typeof acceptPluginAuthCodeForOwnerBody>;
export type TransferAppToOrganizationBody = TypeOfIOTS<typeof transferAppToOrganizationBody>;
export type InviteToOrganizationBody = TypeOfIOTS<typeof inviteToOrganizationBody>;
export type InviteToOrganizationResult = TypeOfIOTS<typeof inviteToOrganizationResult>;
export type RemoveFromOrganizationBody = TypeOfIOTS<typeof removeFromOrganizationBody>;
export type RenameOrganizationBody = TypeOfIOTS<typeof renameOrganizationBody>;
export type RenameTableBody = TypeOfIOTS<typeof renameTableBody>;
export type TriggerAutomationBody = TypeOfIOTS<typeof triggerAutomationBody>;
export type ListTablesBody = TypeOfIOTS<typeof listTablesBody>;
export type ListTablesResult = t.TypeOf<typeof listTablesResult>;
export type GetOrganizationMembersBody = TypeOfIOTS<typeof getOrganizationMembersBody>;
export type GetDashboardDataBody = TypeOfIOTS<typeof getDashboardDataBody>;
export type GetOrganizationMemberIntegrationsBody = TypeOfIOTS<typeof getOrganizationMemberIntegrationsBody>;
export type GetOrganizationMemberIntegrationsResult = TypeOfIOTS<typeof getOrganizationMemberIntegrationsResult>;
export type GetOrganizationMembersResult = TypeOfIOTS<typeof getOrganizationMembersResult>;
export type GetOrganizationPersistentInviteLinkBody = TypeOfIOTS<typeof getOrganizationPersistentInviteLinkBody>;
export type GetOrganizationPersistentInviteLinkResult = TypeOfIOTS<typeof getOrganizationPersistentInviteLinkResult>;
export type GetOrganizationBillingBody = TypeOfIOTS<typeof getOrganizationBillingBody>;
export type GetOrganizationBillingResult = TypeOfIOTS<typeof getOrganizationBillingResult>;
export type SetEmailOwnersColumnsBody = TypeOfIOTS<typeof setEmailOwnersColumnsBody>;
export type ModifySyntheticColumnsBody = TypeOfIOTS<typeof modifySyntheticColumnsBody>;
export type CreateNativeTableForImport = t.TypeOf<typeof createNativeTableForImportBody>;
export type CreateNativeTableForImportResponse = t.TypeOf<typeof createNativeTableForImportResponse>;
export type ImportRowsIntoNativeTableBody = t.TypeOf<typeof importRowsIntoNativeTableBody>;
export type MoveTableInSchemaBody = TypeOfIOTS<typeof moveTableInSchemaBody>;
export type DeleteAllRowsInTableBody = TypeOfIOTS<typeof deleteAllRowsInTableBody>;
export type MoveOrgInUserBody = TypeOfIOTS<typeof moveOrgInUserBody>;
export type CreateAppFromTemplateBody = TypeOfIOTS<typeof createAppFromTemplateBody>;
export type CreateTemplaeFromAppBody = TypeOfIOTS<typeof createTemplateFromAppBody>;
export type ReportGeocodesInAppBody = TypeOfIOTS<typeof reportGeocodesInAppBody>;
export type WriteActionLogBody = TypeOfIOTS<typeof writeActionLogBody>;
export type GetAppActionLogsBody = TypeOfIOTS<typeof getAppActionLogsBody>;
export type GetAutomationsRunDataBody = TypeOfIOTS<typeof getAutomationsRunDataBody>;
export type GetAutomationRunsBody = TypeOfIOTS<typeof getAutomationRunsBody>;
export type GetAutomationRunBody = TypeOfIOTS<typeof getAutomationRunBody>;
export type GetAutomationRunStatusForPollingBody = TypeOfIOTS<typeof getAutomationRunStatusForPollingBody>;
export type GetAppActionLogsResponse = TypeOfIOTS<typeof getAppActionLogsResponse>;
export type ReportQuotaIncreaseBody = TypeOfIOTS<typeof reportQuotaIncreaseBody>;
export type GetSimpleValueBody = TypeOfIOTS<typeof getSimpleValueBody>;
export type CheckDomainConfiguredBody = TypeOfIOTS<typeof checkDomainConfiguredBody>;
export type AddTableBody = TypeOfIOTS<typeof addTableBody>;
export type RemoveTableBody = t.TypeOf<typeof removeTableBody>;
export type RemoveGoogleSheetBody = TypeOfIOTS<typeof removeGoogleSheetBody>;
export type DuplicateTableBody = t.TypeOf<typeof duplicateTableBody>;
export type LinkTablesBody = t.TypeOf<typeof linkTablesBody>;
export type LinkTablesResponseBody = t.TypeOf<typeof linkTablesResponseBody>;
export type SyncTablesBody = t.TypeOf<typeof syncTablesBody>;
export type DeleteTableBody = t.TypeOf<typeof deleteTableBody>;
export type SetCustomDomainBody = TypeOfIOTS<typeof setCustomDomainBody>;
export type SetShortNameBody = TypeOfIOTS<typeof setShortNameBody>;
export type CheckShortNameBody = TypeOfIOTS<typeof checkShortNameBody>;
export type ReportBillableBody = TypeOfIOTS<typeof reportBillable>;
export type SetPublishingActiveBody = TypeOfIOTS<typeof setPublishingActiveBody>;
export type SetPublishingActiveResponseBody = TypeOfIOTS<typeof setPublishingActiveResponseBody>;
export type DiscardPausedAppChangesBody = TypeOfIOTS<typeof discardPausedAppChangesBody>;
// FIXME: This has been deprecated.
export type ValidatePluginRequestBody = TypeOfIOTS<typeof validatePluginRequestBody>;
export type CompletePluginRequestBody = TypeOfIOTS<typeof completePluginRequestBody>;
export type TriggerRemovePluginRequestBody = TypeOfIOTS<typeof triggerRemovePluginRequestBody>;
export type ValidateAppPluginsRequestBody = TypeOfIOTS<typeof validateAppPluginsRequestBody>;
export type PluginValidationByAppResponse = TypeOfIOTS<typeof pluginValidationByAppResponse>;
export type WriteBackJobIdentity = TypeOfIOTS<typeof writeBackJobIdentityCodec>;
export type AcquireStripeSessionBody = TypeOfIOTS<typeof acquireStripeSessionBody>;
export type AcquireStripeSessionForProPurchaseBody = TypeOfIOTS<typeof acquireStripeSessionForProPurchaseBody>;
export type AcquireStripeSessionForTemplatePurchaseBody = TypeOfIOTS<
    typeof acquireStripeSessionForTemplatePurchaseBody
>;
export type UpdateUnifiedPaymentInformationBody = TypeOfIOTS<typeof updateUnifiedPaymmentInformationBody>;
export type IntegrateWithStripeBody = TypeOfIOTS<typeof integrateWithStripeBody>;
export type IntegrateWithMicrosoftRequestBody = TypeOfIOTS<typeof integrateWithMicrosoftRequestBody>;
export type IntegrateWithGCPRequestBody = TypeOfIOTS<typeof integrateWithGCPRequestBody>;
export type IntegrateWithAirtableRequestBody = TypeOfIOTS<typeof integrateWithAirtableRequestBody>;
export type StripeInAppPurchaseConfirmIntentBody = TypeOfIOTS<typeof stripeInAppPurchaseConfirmIntentBody>;
export type RequestedInAppPurchaseV1 = Readonly<TypeOfIOTS<typeof requestedInAppPurchaseV1>>;
export type RequestedInAppPurchaseV2 = Readonly<TypeOfIOTS<typeof requestedInAppPurchaseV2>>;
export type RequestedInAppPurchase = Readonly<TypeOfIOTS<typeof requestedInAppPurchase>>;
export type GeocodeAddressesBody = TypeOfIOTS<typeof geocodeAddressesBody>;
export type GetAppSnapshotRequestBody = TypeOfIOTS<typeof getAppSnapshotRequestBody>;
export type GetAppSnapshotResponseBody = TypeOfIOTS<typeof getAppSnapshotResponseBody>;
export type AppSnapshotURLs = TypeOfIOTS<typeof appSnapshotURLs>;
export type GetOrganizationPlansBody = TypeOfIOTS<typeof getOrganizationPlansBody>;
export type AppBeaconBody = TypeOfIOTS<typeof appBeaconBody>;
export type EnsureDataLivelinessBody = TypeOfIOTS<typeof ensureDataLivelinessBody>;
export type TriggerZapValues = TypeOfIOTS<typeof triggerZapValues>;
export type TriggerZapBody = TypeOfIOTS<typeof triggerZapBody>;
export type TranscribeAudioResponseBody = TypeOfIOTS<typeof transcribeAudioReponseBody>;
export type TestIframeEmbeddableBody = TypeOfIOTS<typeof testIframeEmbeddableBody>;
export type GetPreviewAsUserBody = TypeOfIOTS<typeof getPreviewAsUserBody>;
export type CheckDomainConfiguredResult = TypeOfIOTS<typeof checkDomainConfiguredResult>;
export type CheckDomainRecord = TypeOfIOTS<typeof checkDomainRecord>;
export type UploadComponentKind = TypeOfIOTS<typeof uploadComponentKind>;
export type UploadAppFileV2Body = TypeOfIOTS<typeof uploadAppFileV2Body>;
export type SendAppFeedbackBody = TypeOfIOTS<typeof sendAppFeedbackBody>;
export type ReportAppBody = TypeOfIOTS<typeof reportAppBody>;
export type MakeSupportCodeForAppBody = TypeOfIOTS<typeof makeSupportCodeForAppBody>;
export type AccessSupportCodeBody = TypeOfIOTS<typeof accessSupportCodeBody>;
export type ApplyPromoCodeBody = TypeOfIOTS<typeof applyPromoCodeBody>;
export type DeliverEmailFromActionBody = TypeOfIOTS<typeof deliverEmailFromActionBody>;
export type SetAdditionalBillingBody = TypeOfIOTS<typeof setAdditionalBillingBody>;
export type GetTaxBody = TypeOfIOTS<typeof getTaxBody>;
export type SetTaxBody = TypeOfIOTS<typeof setTaxBody>;
export type DeleteTaxBody = TypeOfIOTS<typeof deleteTaxBody>;
export type ExportAppDataBody = TypeOfIOTS<typeof exportAppDataBody>;
export type ExportQueryableTableBody = TypeOfIOTS<typeof exportQueryableTableBody>;
export type RequestDownloadLinkForExportBody = TypeOfIOTS<typeof requestDownloadLinkForExportBody>;
export type DeleteAppUserForAppBody = TypeOfIOTS<typeof deleteAppUserForAppBody>;
export type RequestDataExportBody = TypeOfIOTS<typeof requestDataExportBody>;
export type TriggerAppWebhookActionBody = TypeOfIOTS<typeof triggerAppWebhookActionBody>;
export type CallAPIColumnBody = TypeOfIOTS<typeof callAPIColumnBody>;
export type AddWebhookBody = TypeOfIOTS<typeof addWebhookBody>;
export type NewGlideTableAppBody = TypeOfIOTS<typeof newGlideTableAppBody>;
export type NewGlideTableAppResponse = TypeOfIOTS<typeof newGlideTableAppResponse>;
export type NewAppBody = t.TypeOf<typeof newAppBody>;
export type CheckExternalDataSourceExistsRequestBody = t.TypeOf<typeof checkExternalDataSourceExistsRequestBody>;
export type DataSourceDetails = TypeOfIOTS<typeof dataSourceDetails>;
export type AirtableUserForm = TypeOfIOTS<typeof airtableUserForm>;
export type ExcelFormData = TypeOfIOTS<typeof excelFormData>;
export type CreateAndSendAuthLinkBody = TypeOfIOTS<typeof createAndSendAuthLinkBody>;
export type SendEmailVerificationBody = TypeOfIOTS<typeof sendEmailVerificationBody>;
export type StoreChurnSurveyBody = TypeOfIOTS<typeof storeChurnSurveyBody>;
export type SetAppIsFavoriteBody = TypeOfIOTS<typeof setAppIsFavoriteBody>;
export type FinishOnboardingBody = TypeOfIOTS<typeof finishOnboardingBody>;
export type CheckReverseFreeTrialEligibilityBody = TypeOfIOTS<typeof checkReverseFreeTrialEligibilityBody>;
export type MaybeCreateReverseFreeTrialBody = TypeOfIOTS<typeof maybeCreateReverseFreeTrialBody>;
export type EndReverseFreeTrialBody = TypeOfIOTS<typeof endReverseFreeTrialBody>;
export type RequestTrialExtensionBody = TypeOfIOTS<typeof requestTrialExtensionBody>;
export type FutureDataSource = TypeOfIOTS<typeof futureDataSource>;
export type MutateTablesRequestBody = TypeOfIOTS<typeof mutateTablesRequestBody>;
export type QueryTablesRequestBody = TypeOfIOTS<typeof queryTablesRequestBody>;
export type CallOpenWeatherMapApiBody = TypeOfIOTS<typeof callOpenWeatherMapApiBody>;
export type GetExcelWorkbooksBody = TypeOfIOTS<typeof getExcelWorkbooksBody>;
export type GetAvailableMicrosoftDrivesBody = TypeOfIOTS<typeof getAvailableMicrosoftDrivesBody>;
export type MicrosoftDriveInfo = TypeOfIOTS<typeof microsoftDriveInfoCodec>;
export type RemoveMSAccountBody = TypeOfIOTS<typeof removeMSAccountBody>;
export type GetExcelWorkbooksResponse = TypeOfIOTS<typeof getExcelWorkbooksResponse>;
export type GetAirtableBasesBody = TypeOfIOTS<typeof getAirtableBasesBody>;
export type GetAirtableBasesResponse = TypeOfIOTS<typeof getAirtableBasesResponse>;
export type RecentListItems = TypeOfIOTS<typeof recentListItems>;
export type DriveListItems = TypeOfIOTS<typeof driveListItems>;
export type WorkbookListSelectionItems = TypeOfIOTS<typeof workbookListSelectionItems>;
export type WorkbookListSelectionItem = TypeOfIOTS<typeof workbookListSelectionItem>;
export type DeltaItems = TypeOfIOTS<typeof deltaItems>;
export type DeltaItem = TypeOfIOTS<typeof deltaItem>;
export type SubscriptionAddon = TypeOfIOTS<typeof subscriptionAddon>;
export type PreviewAddonChargeBody = TypeOfIOTS<typeof previewAddonChargeBody>;
export type LatestPriceForAddonBody = TypeOfIOTS<typeof getLatestPriceForAddonBody>;
export type SubscribeToAddonsBody = TypeOfIOTS<typeof subscribeToAddonsBody>;
export type SubscribeToPlanBody = TypeOfIOTS<typeof subscribeToPlanBody>;
export type OwnerIDBody = TypeOfIOTS<typeof ownerIDBody>;
export type GetSubscriptionVerificationSecretResponse = TypeOfIOTS<typeof getSubscriptionVerificationSecretResponse>;
export type CancelAppPlanBody = TypeOfIOTS<typeof cancelAppPlanBody>;
export type GetGlideSubscriptionBody = TypeOfIOTS<typeof getGlideSubscriptionBody>;
export type GetOwnerEminenceBody = TypeOfIOTS<typeof getOwnerEminenceBody>;
export type GetOwnerEminenceResponse = TypeOfIOTS<typeof getOwnerEminenceResponse>;
export type GetV4StripeCheckoutSessionBody = TypeOfIOTS<typeof getV4StripeCheckoutSessionBody>;
export type GetV4StripeCheckoutSessionResponse = TypeOfIOTS<typeof getV4StripeCheckoutSessionResponse>;
export type V4PricingTableBody = TypeOfIOTS<typeof v4PricingTableBody>;
export type V4PricingTableResponse = TypeOfIOTS<typeof v4PricingTableResponse>;
export type GetOrganizationFromCheckoutSessionBody = TypeOfIOTS<typeof getOrganizationFromCheckoutSessionBody>;
export type GetOrganizationFromCheckoutSessionResponse = TypeOfIOTS<typeof getOrganizationFromCheckoutSessionResponse>;
export type GetV4StripeCustomerPortalBody = TypeOfIOTS<typeof getV4StripeCustomerPortalBody>;
export type GetV4StripeCustomerPortalResponse = TypeOfIOTS<typeof getV4StripeCustomerPortalResponse>;
export type SetPayAsYouGoBody = TypeOfIOTS<typeof setPayAsYouGoBody>;
export type V4AppTransferCheckBody = TypeOfIOTS<typeof v4AppTransferCheckBody>;
export type V4AppTransferCheckResponse = TypeOfIOTS<typeof v4AppTransferCheckResponse>;
export type GetAppEminenceBody = TypeOfIOTS<typeof getAppEminenceBody>;
export type GetAllSubscriptionsForUserBody = TypeOfIOTS<typeof getAllSubscriptionsForUserBody>;
export type CustomHubspotEventBody = TypeOfIOTS<typeof sendCustomHubspotEventBody>;
export type LoadBuilderActionsBody = TypeOfIOTS<typeof loadBuilderActionsBody>;
export type AppModificationsBody = TypeOfIOTS<typeof getAppModificationsBody>;
export type LoginLogsBody = TypeOfIOTS<typeof getLoginLogsBody>;
export type OrgLoginLogsBody = TypeOfIOTS<typeof getOrgLoginLogsBody>;
export type OrgEditorCountBody = TypeOfIOTS<typeof getOrgEditorBody>;
export type AppsForOrgBody = TypeOfIOTS<typeof getAppsForOrgBody>;
export type QuotasForAppBody = TypeOfIOTS<typeof getQuotasForAppsBody>;
export type OrgModificationUsage = TypeOfIOTS<typeof orgModificationUsage>;
export type AppModificationsResponse = TypeOfIOTS<typeof getAppModificationsResponse>;
export type QuotaStateForOrgBody = TypeOfIOTS<typeof getQuotaStateForOrgBody>;
export type SendAppMagicLinksBody = TypeOfIOTS<typeof sendAppMagicLinksBody>;
export type GetAppMagicLinksBody = TypeOfIOTS<typeof getAppMagicLinksBody>;
export type GetTemplatePreviewUrlBody = TypeOfIOTS<typeof getTemplatePreviewUrlBody>;
export type GetTemplatePreviewUrlResponse = TypeOfIOTS<typeof getTemplatePreviewUrlResponse>;
export type QuotaStateForOrgResponse = TypeOfIOTS<typeof getQuotasStateForOrgResponse>;
export type DynamicAppModificationAggregate = TypeOfIOTS<typeof dynamicAppModificationAggregate>;
export type OrganizationUsageResponse = TypeOfIOTS<typeof getOrganizationUsageResponse>;
export type QuotaWithCap = TypeOfIOTS<typeof quotaWithCap>;
export type PreviewQueryRequestBody = t.TypeOf<typeof previewQueryRequestBodyCodec>;
export type PreviewQueryResponseBody = t.TypeOf<typeof previewQueryResponseBodyCodec>;
export type ContinuePreviewQueryRequestBody = t.TypeOf<typeof continuePreviewQueryRequestBodyCodec>;
export type ExecuteQueryRequest = TypeOfIOTS<typeof executeQueryRequest>;
export type ExecuteQueryResponseBody = t.TypeOf<typeof executeQueryResponseBodyCodec>;
export type QueryResponseEntry = t.TypeOf<typeof queryResponseEntryCodec>;
export type QueryResponseContinuation = TypeOfIOTS<typeof queryContinuationCodec>;
export type SaveQueryRequestBody = t.TypeOf<typeof saveQueryRequestBodyCodec>;
export type SaveQueryResponseBody = t.TypeOf<typeof saveQueryResponseBodyCodec>;
export type ExecuteQueryRequestBody = t.TypeOf<typeof executeQueryRequestBodyCodec>;
export type ContinueQueryRequest = TypeOfIOTS<typeof continueQueryRequest>;
export type ContinueQueryRequestBody = t.TypeOf<typeof continueQueryRequestBodyCodec>;
export type LoadQueryRequestBody = t.TypeOf<typeof loadQueryRequestBodyCodec>;
export type LoadQueryResponseBody = t.TypeOf<typeof loadQueryResponseBodyCodec>;
export type CustomComponentProxyBody = TypeOfIOTS<typeof customComponentProxyBody>;
export type CustomComponentProxyResponse = ReturnType<typeof decodeGeneratedComponent>;
export type CheckQueryTableVersionsRequestBody = TypeOfIOTS<typeof checkQueryTableVersionsRequestBodyCodec>;
export type CheckQueryTableVersionsResponseBody = TypeOfIOTS<typeof checkQueryTableVersionsResponseBodyCodec>;
export type ListBigQueryProjectIDsRequestBody = TypeOfIOTS<typeof listBigQueryProjectIDsRequestBodyCodec>;
export type ListBigQueryDatasetIDsRequestBody = TypeOfIOTS<typeof listBigQueryDatasetIDsRequestBodyCodec>;
export type SetupBigQueryDataSourceRequestBody = TypeOfIOTS<typeof setupBigQueryDataSourceRequestBodyCodec>;
export type EnqueueDeleteRowsRequestBody = TypeOfIOTS<typeof enqueueDeleteRowsRequestBody>;
export type EnqueueSingleActionRequest = TypeOfIOTS<typeof enqueueSingleActionRequestCodec>;
export type EnqueueActionRequestBody = TypeOfIOTS<typeof enqueueActionRequestBodyCodec>;
export type EnqueueActionResponseBody = TypeOfIOTS<typeof enqueueActionResponseBodyCodec>;
export type EnqueueActionBatchRequestBody = TypeOfIOTS<typeof enqueueActionBatchRequestBodyCodec>;
export type EnqueueActionResponseSuccess = TypeOfIOTS<typeof enqueueActionResponseSuccessCodec>;
export type EnqueueActionResponseFailure = TypeOfIOTS<typeof enqueueActionResponseFailureCodec>;
export type EnqueueActionBatchResponseBody = TypeOfIOTS<typeof enqueueActionBatchResponseBodyCodec>;
export type RunIntegrationsBody = TypeOfIOTS<typeof runIntegrationsBody>;
export type RunIntegrationsInstance = TypeOfIOTS<typeof runIntegrationsInstance>;
export type RunIntegrationsInstanceResponse = TypeOfIOTS<typeof runIntegrationsInstanceResponse>;
export type FetchAsyncEnumRequestBody = TypeOfIOTS<typeof fetchAsyncEnumRequestBody>;
export type GetLongHTTPFunctionStatusRequestBody = TypeOfIOTS<typeof getLongHTTPFunctionStatusRequestBody>;
export type SetPluginSecretBody = TypeOfIOTS<typeof setPluginSecretBodyCodec>;
export type DuplicatePluginSecretBody = TypeOfIOTS<typeof duplicatePluginSecretBodyCodec>;
export type GetPluginSecretBody = TypeOfIOTS<typeof getPluginSecretBodyCodec>;
export type GetCompiledCustomCssBody = TypeOfIOTS<typeof getCompiledCustomCssBody>;
export type AddMemberIntegrationToOrgBody = TypeOfIOTS<typeof addMemberIntegrationToOrgBody>;
export type SendFrontendPushNotificationBody = TypeOfIOTS<typeof sendFrontendPushNotificationsBody>;
export type GetAvailableOwnerOAuthRequestBody = TypeOfIOTS<typeof getAvailableOwnerOAuthRequestBody>;
export type UpdateOrDeleteOwnerOAuthRequestBody = TypeOfIOTS<typeof updateOrDeleteOwnerOAuthRequestBody>;
export type UpdateOwnerOAuthDisplayNameRequestBody = TypeOfIOTS<typeof updateOwnerOAuthDisplayNameRequestBody>;
export type GetOrgMostRecentLoginLogsRequestBody = TypeOfIOTS<typeof getOrgMostRecentLoginLogsRequestBody>;
export type GetOrgUsagesBody = TypeOfIOTS<typeof getOrgUsagesBody>;
export type GetFeaturesForOrgBody = TypeOfIOTS<typeof getFeaturesForOrgBody>;
export type GetFeaturesForOrgResponse = TypeOfIOTS<typeof getFeaturesForOrgResponse>;
export type runCLIRoutinesRequestBody = TypeOfIOTS<typeof runCLIRoutinesRequestBodyCodec>;
export type CreateNativeTablesFromGoogleSheetsBody = TypeOfIOTS<typeof createNativeTablesFromGoogleSheetsBody>;
export type GetDataSourceWarningsResponse = TypeOfIOTS<typeof getDataSourceWarningsResponse>;
export type StartAutomationRunBody = TypeOfIOTS<typeof startAutomationRunBody>;
export type StartAutomationRunResponseBody = t.TypeOf<typeof startAutomationRunResponseBody>;
export type FinishAutomationRunBody = TypeOfIOTS<typeof finishAutomationRunBody>;
export type TimeoutUnfinishedAutomationRunsBody = TypeOfIOTS<typeof timeoutUnfinishedAutomationRunsBody>;
export type TimeoutUnfinishedAutomationRunsResponseBody = TypeOfIOTS<
    typeof timeoutUnfinishedAutomationRunsResponseBody
>;
export type GetConfirmedMutationsBody = TypeOfIOTS<typeof getConfirmedMutationsBody>;
export type UpdateIntercomUserBody = TypeOfIOTS<typeof updateIntercomUserBody>;

export function isRequestedInAppPurchaseV1(purchase: RequestedInAppPurchase): purchase is RequestedInAppPurchaseV1 {
    return !hasOwnProperty(purchase, "version");
}

function convertRequestedInAppPurchaseV1toV2(purchase: RequestedInAppPurchaseV1): RequestedInAppPurchaseV2 {
    const {
        appID,
        buttonID,
        paymentProcessor,
        processorPayload,
        billingName,
        receiptEmail,
        shippingAddress,
        productSKU,
        rowIndex,
    } = purchase;
    return {
        version: 2,
        appID,
        paymentProcessor,
        processorPayload,
        billingName,
        receiptEmail,
        shippingAddress,
        items: [{ buttonID, productSKU, rowIndex }],
    };
}

export function ensureRequestedInAppPurchaseV2(purchase: RequestedInAppPurchase): RequestedInAppPurchaseV2 {
    if (isRequestedInAppPurchaseV1(purchase)) {
        return convertRequestedInAppPurchaseV1toV2(purchase);
    } else {
        return purchase;
    }
}

export type StripeSubscriptionRepresentation = Readonly<{
    subscriptionID: string;
    current: boolean;
    planSKU: string;
    appID: string | undefined;
    checkoutSessionID: string;
    cardInfo: StripeCardInfo | undefined;
    since: StripeDate;
    cancelsAt: StripeDate | undefined;
    nextInvoice: StripeDate | undefined;
    nextTotal: number | undefined;
    currency: string;
}>;

export interface AutomationsRunDataResult {
    readonly currentRun: AutomationRunInfo | undefined;
    readonly lastSuccess: AutomationRunInfo | undefined;
    readonly lastFailure: AutomationRunInfo | undefined;
    readonly lastCompleted: AutomationRunInfo | undefined;
    readonly queriedRun: AutomationRunInfo | undefined;
    readonly days: Record<string, boolean>;
}

export const retrieveOrCreateGlideUserBody = t.exact(
    t.type({
        email: t.string,
    })
);
export type RetrieveOrCreateGlideUserBody = TypeOfIOTS<typeof retrieveOrCreateGlideUserBody>;

export const retrieveOrCreateGlideUserResponse = t.exact(
    t.type({
        authID: t.string,
        userID: t.string,
    })
);

export type RetrieveOrCreateGlideUserResponse = TypeOfIOTS<typeof retrieveOrCreateGlideUserResponse>;

export const getTeamSelectorOrganizationsBody = t.intersection([
    t.type({ userID: t.string }),
    t.partial({ targetPlan: t.string }),
]);

export type GetTeamSelectorOrganizationsBody = TypeOfIOTS<typeof getTeamSelectorOrganizationsBody>;

export const teamSelectorOrganization = t.intersection([
    t.type({
        id: t.string,
        displayName: t.string,
        planName: t.string,
    }),
    t.partial({
        planKind: t.string,
        logoURL: t.string,
        defaultAppPrimaryColor: t.string,
    }),
]);

export type TeamSelectorOrganization = TypeOfIOTS<typeof teamSelectorOrganization>;

export const getTeamSelectorOrganizationsResponse = t.type({
    organizations: t.readonlyArray(teamSelectorOrganization),
});

export type GetTeamSelectorOrganizationsResponse = TypeOfIOTS<typeof getTeamSelectorOrganizationsResponse>;

export const generateBuilderMagicLinkBody = t.exact(
    t.type({
        email: t.string,
        redirectTo: t.string,
    })
);
export type GenerateBuilderMagicLinkBody = TypeOfIOTS<typeof generateBuilderMagicLinkBody>;

export const generateBuilderMagicLinkResponse = t.exact(
    t.type({
        builderMagicLink: t.string,
    })
);

export type GenerateBuilderMagicLinkResponse = TypeOfIOTS<typeof generateBuilderMagicLinkResponse>;
