import { getLocalizedString } from "@glide/localization";
import { type LoadedRow, type PrimitiveValue, type Row, Table, type Unbound } from "@glide/computation-model-types";
import { getRowColumn } from "@glide/common-core/dist/js/computation-model/data";
import { rowIndexColumnName, getTableColumn, getTableName, isColumnWritable } from "@glide/type-schema";
import {
    ArrayScreenFormat,
    getActionProperty,
    getArrayProperty,
    getColumnProperty,
    getEnumProperty,
} from "@glide/app-description";
import { makeRowID } from "@glide/common-core/dist/js/make-row-id";
import type {
    WireActionWithTitle,
    WireKanbanColumn,
    WireKanbanComponent,
    WireKanbanItem,
    WireKanbanTag,
} from "@glide/fluent-components/dist/js/base-components";
import { type KanbanCategoryDescription, Kanban } from "@glide/fluent-components/dist/js/fluent-components";
import type { InlineListComponentDescription } from "@glide/function-utils";
import { AppKind } from "@glide/location-common";
import { defined, mapFilterUndefined, DefaultMap, assert } from "@glideapps/ts-necessities";
import { isArray, nativeTableIndexer, tuple64Indexer } from "@glide/support";
import {
    type DynamicFilterResult,
    type RowBackends,
    type WireRowComponentHydrationBackend,
    type WireTableComponentHydrationBackend,
    type WireTableComponentHydrationResult,
    type WireTableComponentHydrator,
    type WireAction,
    type WireEditableValue,
    WireActionResult,
    ValueChangeSource,
    WireComponentKind,
    UITitleStyle,
} from "@glide/wire";
import { definedMap } from "collection-utils";
import isString from "lodash/isString";
import sortBy from "lodash/sortBy";
import {
    doTitleActionsForceComponentToShow,
    hydrateAction,
    inflateActions,
    inflateActionsWithTitles,
    inflateComponentEnricher,
    inflateStringProperty,
    inflateSwitchWithCondition,
    registerBusyActionRunner,
} from "../wire/utils";
import { makeFluentArrayContentHandler } from "./fluent-array-handler";

const KANBAN_MAX_COLUMNS = 25;

type ColumnInConstruction = WireKanbanColumn & { items: WireKanbanItem[] };

export const kanbanFluentArrayContentHandler = makeFluentArrayContentHandler(
    Kanban,
    (ib, desc, containingRowIB, _componentID, filterColumnName) => {
        if (containingRowIB === undefined) return undefined;

        const { tables } = ib;
        const inputTableName = getTableName(tables.input);

        const [componentTitleGetter] = inflateStringProperty(containingRowIB, desc.componentTitle, true);
        const titleStyle = getEnumProperty<UITitleStyle>(desc.titleStyle) ?? UITitleStyle.Simple;
        const [titleImageGetter] = inflateStringProperty(containingRowIB, desc.titleImage, false);

        const categoryDescs = getArrayProperty<KanbanCategoryDescription>(desc.categories);
        const categoryHydrators = mapFilterUndefined(categoryDescs ?? [], cd => {
            const [titleGetter, titleType] = inflateStringProperty(containingRowIB, cd.title, true);
            const [valueGetter, valueType] = inflateStringProperty(containingRowIB, cd.value, false);
            if (titleType === undefined && valueType === undefined) return undefined;
            return { titleGetter, valueGetter };
        });

        const [titleFormattedGetter, , hasTitleFormat] = inflateStringProperty(ib, desc.title, true);
        const [subtitleFormattedGetter, , hasSubtitleFormat] = inflateStringProperty(ib, desc.subtitle, true);

        const [imageGetter] = inflateStringProperty(ib, desc.image, true, { allowArrays: true });
        const [categoryGetter, categoryType] = inflateStringProperty(ib, desc.category, true);
        const [indexGetter, indexType] = inflateStringProperty(ib, desc.index, false);
        if (categoryType === undefined) return undefined;

        const inlineAddingGetter = inflateSwitchWithCondition(containingRowIB, desc.allowInlineAdding, true);
        const inlineEditingGetter = inflateSwitchWithCondition(containingRowIB, desc.allowInlineEditing, true);

        // We only need these to be able to edit.
        const titleColumn = getColumnProperty(desc.title);
        const subtitleColumn = getColumnProperty(desc.subtitle);

        const titleValueGetter = hasTitleFormat ? inflateStringProperty(ib, desc.title, false)[0] : undefined;
        const subtitleValueGetter = hasSubtitleFormat ? inflateStringProperty(ib, desc.subtitle, false)[0] : undefined;

        let indexColumn = definedMap(getColumnProperty(desc.index), n => getTableColumn(tables.input, n));
        if (
            indexColumn !== undefined &&
            !isColumnWritable(indexColumn, tables.input, true, { allowProtected: false })
        ) {
            indexColumn = undefined;
        }

        // If `indexType === undefined` it means the index isn't defined at
        // all, in which case we'll use state.  Otherwise we must be able to
        // write to the index column.
        const canWriteIndex = indexType === undefined || indexColumn !== undefined;

        let categoryColumn = definedMap(getColumnProperty(desc.category), n => getTableColumn(tables.input, n));
        if (
            categoryColumn !== undefined &&
            !isColumnWritable(categoryColumn, tables.input, true, { allowProtected: false })
        ) {
            categoryColumn = undefined;
        }

        let filterColumn = definedMap(filterColumnName, n => getTableColumn(tables.input, n));
        if (
            filterColumn !== undefined &&
            !isColumnWritable(filterColumn, tables.input, true, { allowProtected: false })
        ) {
            filterColumn = undefined;
        }

        const action = getActionProperty(desc.action);
        const actionHydrator = definedMap(action, a => inflateActions(ib, [a]));

        const moveAction = getActionProperty(desc.moveAction);
        const moveActionHydrator = definedMap(moveAction, a => inflateActions(ib, [a]));

        const [titleActionsHydrator] = inflateActionsWithTitles(containingRowIB, desc.titleActions, "title");
        const [itemActionsHydrator] = inflateActionsWithTitles(ib, desc.itemActions, "item");

        const indexer = tables.input.sourceMetadata?.externalSource !== undefined ? tuple64Indexer : nativeTableIndexer;

        const componentEnricher = inflateComponentEnricher<WireKanbanComponent>(
            ib,
            desc as unknown as InlineListComponentDescription
        );

        class Hydrator implements WireTableComponentHydrator {
            private columns: ColumnInConstruction[] = [];
            // This is in the same order as `columns` until `ensureStableColumnOrder` runs
            private readonly columnForCategory = new Map<string, ColumnInConstruction>();
            private readonly categoryForRow = new DefaultMap((r: Row) => categoryGetter(this.rowBackends.get(r)));
            private rows: readonly Row[] | undefined;
            private inlineAdding: boolean | undefined;
            private titleActions: readonly WireActionWithTitle[] | undefined;
            private showIfEmpty: boolean | undefined;

            constructor(
                private readonly chb: WireRowComponentHydrationBackend | undefined,
                private readonly rowBackends: RowBackends,
                private readonly searchActive: boolean
            ) {}

            private addColumn(c: ColumnInConstruction): void {
                this.columns.push(c);
                this.columnForCategory.set(c.category, c);
            }

            private ensureStableColumnOrder(): void {
                if (this.chb === undefined) return;

                if (this.columnForCategory.size === 0) return;

                const orderedCategories = this.chb.getState(
                    "categories",
                    (v): v is unknown[] => isArray(v),
                    Array.from(this.columnForCategory.keys()),
                    false
                );
                const categoriesLeft = new Map(this.columnForCategory);

                const newColumns: ColumnInConstruction[] = [];
                for (const category of orderedCategories.value) {
                    if (typeof category !== "string") continue;

                    let column = categoriesLeft.get(category);
                    if (column === undefined) {
                        if (category !== "") {
                            column = { title: category, category, items: [], addItemAction: undefined };
                            newColumns.push(column);
                            this.columnForCategory.set(category, column);
                        }
                        continue;
                    }

                    newColumns.push(column);
                    categoriesLeft.delete(category);
                }
                newColumns.push(...Array.from(categoriesLeft.values()));

                this.columns = newColumns;
            }

            public prefilterRows(
                thb: WireTableComponentHydrationBackend
            ): WireTableComponentHydrationBackend | undefined {
                if (this.chb === undefined) return undefined;

                this.inlineAdding = inlineAddingGetter(this.chb) === true;

                this.titleActions = titleActionsHydrator?.(this.chb, "") ?? [];
                this.showIfEmpty = this.inlineAdding || doTitleActionsForceComponentToShow(this.titleActions);

                this.rows = thb.tableScreenContext.asArray();

                // If the user has categories configured, we use those, otherwise
                // we gather the 6 most common ones and use those.
                if (categoryHydrators.length > 0) {
                    for (const ch of categoryHydrators) {
                        let title = ch.titleGetter(this.chb);
                        let value = ch.valueGetter(this.chb);

                        title = title ?? "";
                        value = value ?? "";

                        if (title === "") {
                            if (value === "") continue;
                            title = value;
                        }

                        if (this.columns.some(c => c.category === value)) continue;

                        this.addColumn({ title, category: value, items: [], addItemAction: undefined });
                    }
                } else {
                    let numEmpties = 0;
                    const valueCounts = new DefaultMap<string, number>(() => 0);
                    for (const r of this.rows) {
                        const value = this.categoryForRow.get(r);
                        if (value === null) continue;
                        if (value === "") {
                            numEmpties++;
                            continue;
                        }

                        valueCounts.update(value, x => x + 1);
                    }

                    const countsArray = Array.from(valueCounts.entries());
                    const topValues = new Set(
                        sortBy(countsArray, ([, c]) => c)
                            .slice(0, KANBAN_MAX_COLUMNS)
                            .map(([v]) => v)
                    );
                    // These are the top values in the order that they appear in
                    // the rows, vs sorted by count.
                    const orderedTopValues = mapFilterUndefined(countsArray, ([v]) =>
                        topValues.has(v) ? v : undefined
                    );

                    if (orderedTopValues.length > 0) {
                        for (const value of orderedTopValues) {
                            this.addColumn({ title: value, category: value, items: [], addItemAction: undefined });
                        }
                    } else if (numEmpties > 0 || this.showIfEmpty || this.searchActive) {
                        this.addColumn({
                            title: getLocalizedString("uncategorized", AppKind.Page),
                            category: "",
                            items: [],
                            addItemAction: undefined,
                        });
                    } else {
                        return undefined;
                    }

                    this.ensureStableColumnOrder();
                }

                const filteredRows = this.rows.filter(r => {
                    const c = this.categoryForRow.get(r);
                    if (c === null) return false;
                    return this.columnForCategory.has(c);
                });

                return thb.withTable(new Table(filteredRows));
            }

            public hydrate(
                thb: WireTableComponentHydrationBackend,
                dynamicFilterResult: DynamicFilterResult | undefined
            ): WireTableComponentHydrationResult | undefined {
                const { chb, searchActive, inlineAdding } = this;
                if (chb === undefined) return undefined;

                assert(inlineAdding !== undefined);
                const inlineEditing = inlineEditingGetter(chb) === true;

                const dynamicFilter = dynamicFilterResult?.dynamicFilterValues;

                let rows = thb.tableScreenContext.asArray();
                if (rows.length === 0 && !searchActive && !defined(this.showIfEmpty)) return undefined;

                const addedRow = chb.getState<Row | undefined>("addedRow", undefined, undefined, false);

                // if rows is filtered it might not contain addedRow
                if (addedRow.value !== undefined && rows.find(r => r.$rowID === addedRow.value?.$rowID) === undefined) {
                    rows = [addedRow.value, ...rows];
                }

                // FIXME: remove validator once we have support for `undefined`
                const allAddedRowIDsEditable = chb.getState(
                    "allAddedRowIDs",
                    (_v): _v is readonly string[] => true,
                    [],
                    false
                );
                const allAddedRowIDs = new Set(allAddedRowIDsEditable.value);

                function getStateForRow(rowID: string): WireEditableValue<string> {
                    return defined(chb).getState(`index-${rowID}`, isString, "", true);
                }

                const tagActions = new DefaultMap<PrimitiveValue, WireAction | undefined>(t => {
                    if (dynamicFilter === undefined) return undefined;
                    const tagIndex = dynamicFilter.filterValues.indexOf(t);
                    if (tagIndex < 0) return undefined;

                    const isSelected = dynamicFilter.filterEditable.value.includes(t);

                    const token = chb.registerAction(`tag-${tagIndex}`, async ab =>
                        ab.valueChanged(
                            defined(dynamicFilter.filterEditable.onChangeToken),
                            isSelected ? [] : [t],
                            ValueChangeSource.User
                        )
                    );

                    return { token };
                });

                const ttvp = thb.makeTableTransformValueProvider(inputTableName);

                let items: (Omit<WireKanbanItem, "insertBeforeIndex"> & {
                    indexValue: string;
                    column: ColumnInConstruction | undefined;
                })[] = [];
                let lastIndex = indexer.minusOne;
                for (const r of rows) {
                    const value = this.categoryForRow.get(r);
                    if (value === null) continue;

                    const column = this.columnForCategory.get(value);
                    if (column === undefined) continue;

                    const isLastAddedRow = r.$rowID === addedRow.value?.$rowID;
                    const isAddedRow = isLastAddedRow || allAddedRowIDs.has(r.$rowID);

                    const rhb = this.rowBackends.get(r);

                    // FIXME: We're repeating the same thing down below for
                    // subtitle - consolidate
                    const formattedTitle = titleFormattedGetter(rhb);
                    let title: WireEditableValue<string> | Unbound;
                    if (formattedTitle === null) {
                        title = null;
                    } else {
                        const token =
                            isAddedRow || inlineEditing
                                ? definedMap(titleColumn, c => rhb.registerOnValueChange("title", c))
                                : undefined;
                        title = {
                            value: formattedTitle,
                            onChangeToken: token === false ? undefined : token,
                        };
                        if ((inlineAdding || inlineEditing) && titleValueGetter !== undefined) {
                            title = {
                                value: titleValueGetter(rhb) ?? "",
                                displayValue: title.value,
                                onChangeToken: title.onChangeToken,
                            };
                        }
                    }

                    const formattedSubtitle = subtitleFormattedGetter(rhb);
                    let subtitle: WireEditableValue<string> | Unbound;
                    if (formattedSubtitle === null) {
                        subtitle = null;
                    } else {
                        const token =
                            isAddedRow || inlineEditing
                                ? definedMap(subtitleColumn, c => rhb.registerOnValueChange("subtitle", c))
                                : undefined;
                        subtitle = {
                            value: formattedSubtitle,
                            onChangeToken: token === false ? undefined : token,
                        };
                        if ((inlineAdding || inlineEditing) && subtitleValueGetter !== undefined) {
                            subtitle = {
                                value: subtitleValueGetter(rhb) ?? "",
                                displayValue: subtitle.value,
                                onChangeToken: subtitle.onChangeToken,
                            };
                        }
                    }

                    let maybeIndex: string | null;
                    let indexToken: string | undefined;
                    if (indexType !== undefined) {
                        maybeIndex = indexGetter(rhb);
                        if (indexColumn !== undefined) {
                            const token = rhb.registerOnValueChange("index", indexColumn.name);
                            if (token !== false) {
                                indexToken = token;
                            }
                        }
                    } else {
                        const state = getStateForRow(r.$rowID);
                        maybeIndex = state.value;
                        indexToken = state.onChangeToken;
                    }

                    let index: string;
                    if (maybeIndex !== null && maybeIndex !== "") {
                        index = indexer.cleanup(maybeIndex);
                    } else {
                        const rowIndex = getRowColumn(r, rowIndexColumnName);
                        if (typeof rowIndex === "number" && rowIndex >= 0) {
                            index = indexer.fromNonNegativeInteger(rowIndex);
                        } else if (typeof rowIndex === "string") {
                            index = indexer.cleanup(rowIndex);
                        } else {
                            index = indexer.nextNumber(lastIndex);
                        }
                    }
                    // We assume that no index ever goes to minus one
                    if (index <= indexer.minusOne) {
                        index = indexer.zero;
                    }
                    lastIndex = index;

                    let categoryToken: string | undefined;
                    if (categoryColumn !== undefined) {
                        const token = rhb.registerOnValueChange("category", categoryColumn.name);
                        if (token !== false) {
                            categoryToken = token;
                        }
                    }

                    let tags: WireKanbanTag[] | Unbound;
                    if ((dynamicFilter?.filterEditable.onChangeToken?.length ?? 0) === 0) {
                        tags = null;
                    } else {
                        assert(dynamicFilter !== undefined);

                        const tagValues = dynamicFilter?.valueAndFormatGetter(r, ttvp);

                        tags = [];
                        for (const [tagValue, display] of tagValues) {
                            const isSelected = dynamicFilter.filterEditable.value.includes(tagValue);
                            tags.push({ name: display, action: tagActions.get(tagValue), isSelected });
                        }
                    }

                    const onTap = definedMap(actionHydrator, ah =>
                        registerBusyActionRunner(rhb, "tap", () => hydrateAction(ah, rhb, false, title?.value))
                    );

                    const onDrag = definedMap(moveActionHydrator, ah =>
                        registerBusyActionRunner(rhb, "move", () => hydrateAction(ah, rhb, false, undefined))
                    );

                    const menuActions = itemActionsHydrator?.(rhb, r.$rowID) ?? [];

                    let deleteAction: WireAction | undefined;
                    if (isAddedRow) {
                        deleteAction = {
                            token: rhb.registerAction("delete", async ab =>
                                WireActionResult.fromResult(await ab.deleteRows(getTableName(tables.input), [r], true))
                            ),
                        };
                    }

                    items.push({
                        key: r.$rowID,
                        title,
                        subtitle,
                        image: imageGetter(rhb),
                        tags,
                        onTap,
                        onDrag,
                        menuActions,
                        indexToken,
                        categoryToken,
                        indexValue: index,
                        column,
                        autoFocus: isLastAddedRow,
                        deleteAction,
                        commitAction: isLastAddedRow
                            ? {
                                  token: rhb.registerAction("commit", async ab =>
                                      ab.valueChanged(addedRow.onChangeToken, undefined, ValueChangeSource.User)
                                  ),
                              }
                            : undefined,
                    });
                }

                items = sortBy(items, i => i.indexValue);

                let prevIndex: string | undefined;
                let insertBeforeFirstIndex: string | undefined;
                for (const i of items) {
                    const insertBeforeIndex = indexer.midpoint(prevIndex ?? indexer.minusOne, i.indexValue);
                    if (prevIndex === undefined) {
                        insertBeforeFirstIndex = insertBeforeIndex;
                    }

                    prevIndex = i.indexValue;

                    if (i.column === undefined) continue;

                    i.column.items.push({
                        key: i.key,
                        title: i.title,
                        subtitle: i.subtitle,
                        image: i.image,
                        tags: i.tags,
                        onTap: i.onTap,
                        onDrag: i.onDrag,
                        menuActions: i.menuActions,
                        indexToken: i.indexToken,
                        categoryToken: i.categoryToken,
                        insertBeforeIndex,
                        autoFocus: i.autoFocus,
                        deleteAction: i.deleteAction,
                        commitAction: i.commitAction,
                    });
                }
                if (insertBeforeFirstIndex === undefined) {
                    insertBeforeFirstIndex = indexer.midpoint(indexer.minusOne, indexer.zero);
                }

                const columns = this.columns.map((c, i) => {
                    let addItemAction: WireAction | undefined;
                    if (categoryColumn !== undefined && inlineAdding && canWriteIndex) {
                        const rowID = makeRowID();
                        const state = indexColumn === undefined ? getStateForRow(rowID) : undefined;
                        const token = chb.registerAction(`add-${i}`, async ab => {
                            const row: LoadedRow = {
                                $rowID: rowID,
                                $isVisible: false,
                                [defined(categoryColumn).name]: c.category,
                            };
                            const newIndex = c.items[0]?.insertBeforeIndex ?? insertBeforeFirstIndex;
                            if (indexColumn !== undefined) {
                                row[defined(indexColumn).name] = newIndex;
                            }
                            // We need to set this first so that the auto focus
                            // doesn't wait for the add row to finish.
                            ab.valueChanged(addedRow.onChangeToken, row, ValueChangeSource.User);
                            if ((dynamicFilter?.filterEditable.value.length ?? 0) > 0) {
                                assert(dynamicFilter !== undefined);
                                if (filterColumn !== undefined) {
                                    row[filterColumn.name] = dynamicFilter.filterEditable.value[0];
                                } else if (dynamicFilter.filterEditable.onChangeToken !== undefined) {
                                    ab.valueChanged(
                                        dynamicFilter.filterEditable.onChangeToken,
                                        [],
                                        ValueChangeSource.User
                                    );
                                }
                            }
                            if (state?.onChangeToken !== undefined) {
                                ab.valueChanged(state.onChangeToken, newIndex, ValueChangeSource.User);
                            }
                            ab.valueChanged(
                                allAddedRowIDsEditable.onChangeToken,
                                [...allAddedRowIDsEditable.value, rowID],
                                ValueChangeSource.User
                            );
                            const addResult = await ab.addRow(getTableName(tables.input), row, undefined, true);
                            const newRow = addResult.ok ? addResult.result : undefined;

                            // Set this again so that it contains the actual newRow object, not the one we created ourselves.
                            //  If our newly-added row was filtered by the static filter, this object will be inserted manually above,
                            //  but could get out of sync if edits are made and it's not the actual newRow object.
                            ab.valueChanged(addedRow.onChangeToken, newRow, ValueChangeSource.Internal);
                            return WireActionResult.fromResult(addResult);
                        });
                        addItemAction = { token };
                    }

                    return { ...c, addItemAction };
                });

                const component: WireKanbanComponent = componentEnricher({
                    kind: WireComponentKind.List,
                    format: ArrayScreenFormat.Kanban,
                    title: componentTitleGetter(chb),
                    titleImage: titleImageGetter(chb),
                    titleStyle,
                    columns,
                    // We never let the "last" index make it to the next full
                    // number, because that's where a new row from the source
                    // would come in, and we want that to always start out at the
                    // end.
                    newLastIndex:
                        definedMap(prevIndex, n => indexer.midpoint(n, indexer.nextNumber(n))) ??
                        insertBeforeFirstIndex,
                    titleActions: defined(this.titleActions),
                });
                return {
                    component,
                    isValid: true,
                };
            }
        }

        return {
            makeHydrator(rhb, rowBackends, searchActive) {
                return new Hydrator(rhb, rowBackends, searchActive);
            },
        };
    }
);
