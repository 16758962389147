import type {
    Plugin,
    ServerExecutionContext,
    NativePlugin,
    PluginComputationEvents,
    KeyStore,
    ParameterRecord,
} from "@glide/plugins";
import { Result, CommonExecutionContextBase } from "@glide/plugins";
import type { JSONObject } from "@glide/support";
import { left } from "fp-ts/Either";

// This is a dummy implementation used in the `plugin-data-command` CLI utility and playground.
export class DataExecutionContext extends CommonExecutionContextBase implements ServerExecutionContext {
    constructor(
        plugin: Plugin | NativePlugin,
        public readonly refreshAccessToken: () => Promise<Result<string>>,
        public readonly getAccessToken: () => Promise<Result<string>>,
        ctxFetch: typeof globalThis.fetch,
        private addToGlobalContext: (name: string, value: unknown) => void
    ) {
        // FIXME: What should we do about that icon?
        super(
            {
                id: "ID",
                name: "",
                appIconSubtitle: "by the app team",
                isFreeEminence: true,
                appPrimaryAccentColor: "pink",
                getIcon: async () => "",
                getPlayURL: async () => "",
            },
            undefined,
            plugin,
            ctxFetch,
            undefined
        );
    }

    public getSecret(_name: string): string | undefined {
        throw new Error("Method not implemented - this is a dummy");
    }

    public async updateConfig(_config: JSONObject | undefined): Promise<Result<{}>> {
        // FIXME: Actually implement this!
        return Result.Ok({});
    }

    public async uploadFile(): Promise<Result<string>> {
        throw new Error("Method not implemented - this is a dummy");
    }

    public consumeBillable(_amount?: number | undefined): void {
        // FIXME: Implement this
    }

    public trackEventMetadata(_key: string, _value: string | number | boolean) {
        // Event meta data is used for tracking events in our data warehouse. We don't need to do that here.
    }

    public trackMetric<Name extends keyof PluginComputationEvents>(
        event: Name,
        options: PluginComputationEvents[Name]
    ) {
        this.addToGlobalContext(event, options);
    }

    public async useCache<T>(factory: () => Promise<Result<T>>): Promise<Result<T>> {
        return await factory();
    }

    public get keyStore(): KeyStore {
        return {
            getValue: async () => left(Error("Method not implemented - this is a dummy")),
            setValue: async () => Error("Method not implemented - this is a dummy"),
            getOrSetValue: async () => left(Error("Method not implemented - this is a dummy")),
        };
    }

    public async invokeTriggers<
        _TRawData,
        _TResultParameters extends ParameterRecord,
        _TParameters extends ParameterRecord
    >(): Promise<Result<number>> {
        return Result.FailPermanent("Not implemented");
    }

    public makeEndpointURL(): string {
        throw new Error("Method not implemented - this is a dummy");
    }

    public async removeAPIKey(): Promise<Result> {
        return Result.Ok(undefined);
    }
}
